import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Quiz from "../../../images/quiz.png";
import appContext from "../../../context/appContext";
import Bell from "../../../images/whitebell.svg";
import Quizzes from "../../../images/circlefill.svg";
import ClassroomIcon from "../../../images/assignment.svg";
import Info from "../../../images/content_copy_footer.png";
import LightningIcon from "../../../images/xps.svg";
import WaterDropIcon from "../../../images/streak.svg";
import moment from "moment";
function StudentClassroomsCard({ state }) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  var id = params.get("id");
  const [studentClassroomData, setStudentClassroomData] = useState([]);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [gradientColor, setGradientColor] = useState([
    "#937CFE",
    "#B58DFE", // Gradient 1
    "#588FFE",
    "#6E9EFF", // Gradient 2
    "#FD6766",
    "#F98183", // Gradient 3
    "#00BEF2",
    "#00D5FF", // Gradient 4
  ]);
  useEffect(() => {
    if (!state.openJoinClassroomDialogue) {
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["authorization"] = token;
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/student_dashboard", {
          method: "POST",
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
          current_date_retrieval: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
        })
        .then((response) => {
          setIsLoading(false);
          setStudentClassroomData(Array.isArray(response.data) ? response.data : []);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleViewClass = (classroom) => {
    navigate("/student-view-classroom", {
      state: {
        studentClassroomData: classroom,
      },
    });
  };

  return (
    <div className="mt-[42px]">
      {isLoading && (
        <div className="flex items-center justify-center mt-[15%] ml-[-15%] w-full">
          <div role="status">
            <svg
              aria-hidden="true"
              className="w-20 h-20 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4 h-[280px] w-[80%]">
        {/* Join Class Card */}
        {!isLoading && (
          <div
            onClick={state.handleClickOpenJoinClassroomDialogue}
            className="border cursor-pointer border-gray-200 bg-[#F8F9FC] rounded-lg flex flex-col items-center justify-center p-6 shadow-md "
          >
            <img src={Quizzes} className="cursor-pointer" alt=" " />
            <div className="text-base font-semibold text-gray-800 mt-2">Join Class</div>
          </div>
        )}

        {/* Classroom Cards */}
        {studentClassroomData.length > 0 &&
          studentClassroomData?.map((item, index) => (
            <div
              key={item.classroom_id}
              style={{
                backgroundImage: `linear-gradient(to right, ${gradientColor[(index * 2) % gradientColor.length]}, ${
                  gradientColor[(index * 2 + 1) % gradientColor.length]
                })`,
              }}
              className="relative  rounded-lg p-3 text-white shadow-lg"
            >
              {/* Top Section */}
              <div className="text-lg font-bold truncate overflow-hidden whitespace-nowrap">{item.classroom_name}</div>

              <div className="text-sm mt-1 flex items-center space-x-2 ">
                <img src={ClassroomIcon} className="w-5 h-5" alt="subject icon" />
                <span className="truncate overflow-hidden whitespace-nowrap">{item.subject_name}</span>
              </div>

              <div className="text-sm mt-2 flex items-center space-x-2">
                <img src={Info} className="w-4 h-4" alt="info icon" />
                <span>Classcode: {item.classroom_code}</span>
              </div>

              {/* Middle Section */}
              <div className="flex items-center space-x-2 mt-2">
                <div className="flex items-center bg-white rounded-full px-3 py-1 shadow">
                  <img src={LightningIcon} alt="XP Icon" className="w-4 h-4 mr-2" />
                  <span className="text-sm font-bold text-gray-800">{item.student_class_xp}</span>
                </div>
                <div className="flex items-center bg-white rounded-full px-3 py-1 shadow">
                  <img src={WaterDropIcon} alt="Streak Icon" className="w-4 h-4 mr-2" />
                  <span className="text-sm font-bold text-gray-800">{item.student_class_streak}</span>
                </div>
              </div>

              {/* Progress Bar */}
              <div className="mt-2">
                <div className="text-sm flex">
                  Quizzes: {item.attempted_quizzes}/{item.total_quizzes}{" "}
                  {item.attempted_quizzes < item.total_quizzes && <img src={Bell} alt="Notification Bell" className="ml-2 w-6 h-6" />}
                </div>
                <div className="w-full bg-white rounded-full h-2 mt-2">
                  <div
                    className="bg-orange-500 h-2 rounded-full"
                    style={{
                      width:
                        item.total_quizzes === 0
                          ? "0%" // If total_quizzes is 0, set width to 0%
                          : `${(item.attempted_quizzes / item.total_quizzes) * 100}%`, // Otherwise, calculate the percentage
                    }}
                  ></div>
                </div>
              </div>

              {/* Bottom Section */}
              <button
                onClick={() => handleViewClass(item)}
                className="mt-4 text-[14px] leading-[20px] bg-white text-[#424242] font-bold px-4 py-2 rounded-lg w-full"
              >
                VIEW CLASS
              </button>

              {/* Notification Bell */}
            </div>
          ))}
      </div>
    </div>
  );
}

export default StudentClassroomsCard;
