import React, { useState, useRef, useEffect } from "react";

const CountDownTimer = (props) => {
  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("00:00:00");
  const quizzes = useRef();
  const currentQuiz = useRef();
  var graceTimeHeadsUp = false;
  var timeUpDialogueCount = false;

  let grace_time = props.grace_time;

  const getTimeRemaining = (e) => {
    var total = Date.parse(e) - Date.parse(new Date());
    if (grace_time === 0 && total === 30000 && props.autosubmission) {
      props.handleOpenAutoSubmissionDialogue();
    }
    if (
      currentQuiz.current.grace_deadline === null &&
      grace_time > 0 &&
      total === 0
    ) {
      props.handleOpenGraceTimeDialogue();
    }
    if (graceTimeHeadsUp && total === 30000 && props.autosubmission) {
      props.handleOpenAutoSubmissionDialogue();
    }
    if (grace_time > 0 && total < 0) {
      graceTimeHeadsUp = true;
      props.handleChangeGraceTime();
      clearTimer(getDeadTime(grace_time, 1));

      let deadline;
      if (currentQuiz.current.grace_deadline === null) {
        deadline = new Date();
        updateQuizLocalStorage(deadline, 2);
      } else {
        deadline = new Date(currentQuiz.current.grace_deadline);
      }

      // This is where you need to adjust if
      // you entend to add more time
      deadline.setSeconds(deadline.getSeconds() + props.grace_time);
      total = deadline - Date.parse(new Date());

      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / 1000 / 60 / 60) % 24);
      props.updateRemainingTime(hours * 60 * 60 + minutes * 60 + seconds);

      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );

      grace_time = 0;
      return {
        total,
        hours,
        minutes,
        seconds,
      };
      //   console.log("GRACE TIME:: ",props.grace_time)
      // total = props.grace_time;
    } else if (total < 0) {
      total = Date.parse(new Date()) - Date.parse(e);

      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / 1000 / 60 / 60) % 24);

      if (!props.autosubmission && timeUpDialogueCount === false) {
        props.handleOpenExtraTimeDialogue();
        timeUpDialogueCount = true;
      }

      if (props.autosubmission) {
        props.handleOpenQuizSubmittedDialogue();
        props.submitForm();

        return {
          total,
          hours,
          minutes,
          seconds,
        };
      }

      props.updateRemainingTime(
        (hours * 60 * 60 + minutes * 60 + seconds) * -1
      );

      setTimer(
        "-" +
          (hours > 9 ? hours : "0" + hours) +
          ":" +
          (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );

      document.getElementById("timer_label").style.color = "red";

      return {
        total,
        hours,
        minutes,
        seconds,
      };
    }
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    props.updateRemainingTime(hours * 60 * 60 + minutes * 60 + seconds);

    setTimer(
      (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
    );

    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    // if (total >= 0) {

    //     // update the timer
    //     // check if less than 10 then we need to
    //     // add '0' at the beginning of the variable
    //     setTimer(
    //         (hours > 9 ? hours : '0' + hours) + ':' +
    //         (minutes > 9 ? minutes : '0' + minutes) + ':'
    //         + (seconds > 9 ? seconds : '0' + seconds)
    //     )
    // }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next

    let deadline = new Date();

    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + props.start_time);
    const total = deadline - Date.parse(new Date());

    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);

    setTimer(
      (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
    );

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    props.refreshIntervalId.current = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = props.refreshIntervalId.current;
  };

  const updateQuizLocalStorage = (deadline, durationType) => {
    var quizList = [];
    quizList =
      localStorage.getItem("quizzes") !== null
        ? JSON.parse(localStorage.getItem("quizzes"))
        : [];
    for (let index = 0; index < quizList.length; index++) {
      const element = quizList[index];
      if (element.secret_key === currentQuiz.current.secret_key) {
        if (durationType === 1) {
          quizList[index].deadline = deadline.toString();
          currentQuiz.current.deadline = deadline.toString();
        } else {
          quizList[index].grace_deadline = deadline.toString();
          currentQuiz.current.grace_deadline = deadline.toString();
        }
      }
    }
    localStorage.setItem("quizzes", JSON.stringify(quizList));
  };

  const getDeadTime = (start_time, isGrace) => {
    if (isGrace === 1) {
      let deadline;
      if (currentQuiz.current.grace_deadline === null) {
        deadline = new Date();
        updateQuizLocalStorage(deadline, 2);
      } else {
        deadline = new Date(currentQuiz.current.grace_deadline);
      }
      deadline.setSeconds(deadline.getSeconds() + start_time);
      return deadline;
    }
    let deadline;
    if (currentQuiz.current.deadline === null) {
      deadline = new Date();
      updateQuizLocalStorage(deadline, 1);
    } else if (currentQuiz.current.grace_deadline === null) {
      deadline = new Date(currentQuiz.current.deadline);
    } else {
      deadline = new Date(currentQuiz.current.grace_deadline);
    }
    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + start_time);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    //clearTimer(getDeadTime());
    onClickReset();
  }, []);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    var quizList = [];
    var secretKeyList = [];
    quizList =
      localStorage.getItem("quizzes") !== null
        ? JSON.parse(localStorage.getItem("quizzes"))
        : [];
    var quiz = {
      secret_key: props.quiz_secret_key,
      grace_deadline: null,
      deadline: null,
    };
    if (quizList.length === 0) {
      quizList.push(quiz);
      localStorage.setItem("quizzes", JSON.stringify(quizList));
      currentQuiz.current = quiz;
    } else {
      for (let index = 0; index < quizList.length; index++) {
        const element = quizList[index].secret_key;
        secretKeyList.push(element);
      }
      if (secretKeyList.includes(props.quiz_secret_key)) {
        currentQuiz.current =
          quizList[secretKeyList.indexOf(props.quiz_secret_key)];
      } else {
        quizList.push(quiz);
        localStorage.setItem("quizzes", JSON.stringify(quizList));
        currentQuiz.current = quiz;
      }
    }
    // if(localStorage.getItem("quizzes") === null || )
    if (currentQuiz.current.grace_deadline === null) {
      clearTimer(getDeadTime(props.start_time, 0));
    } else {
      clearTimer(getDeadTime(props.grace_time, 1));
    }
  };

  return (
    <div className="App">
      <h3 id="timer_label">Time left: {timer}</h3>
    </div>
  );
};

export default CountDownTimer;
