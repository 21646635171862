import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";

const MasteryLevels = ({ masteryPercentage, c_name, subjectName, classroom_id, masteryLevels, xp }) => {
  const navigate = useNavigate();
  const getColorForPercentage = (percentageString) => {
    const value = parseInt(percentageString, 10); // Parse percentage as integer
    if (value >= 80) {
      return "#4CAF50";
    } else if (value >= 60) {
      return "#F2D249";
    } else if (value >= 40) {
      return "#F2C475";
    } else if (value < 40 && value > 0) {
      return "#F8B250";
    } else if (value === 0) {
      return "#FAAD98";
    }
  };

  const handleParticipationText = (participationPercentage) => {
    if (!isNaN(participationPercentage)) {
      if (participationPercentage >= 80) {
        return "Mastered";
      } else if (participationPercentage >= 60 && participationPercentage < 80) {
        return "Competent";
      } else if (participationPercentage >= 40 && participationPercentage < 60) {
        return "Intermediate";
      } else if (participationPercentage < 40 && participationPercentage > 0) {
        return "Developing";
      } else if (participationPercentage === 0) {
        return "Insufficient";
      }
    }
  };

  return (
    <Tooltip title={xp === 0 ? "Complete a quiz to unlock mastery." : ""} arrow placement="top">
      <div className="bg-[#F7F2F9] p-2 rounded-lg flex items-center mb-2 h-[182px] md:w-[470px] sm:w-full">
        {/* Circular Progress Indicator */}

        <div className="relative ml-2 mr-2">
          <div>
            <CircularProgressbar
              value={masteryPercentage}
              text={`${masteryPercentage}%`}
              className="h-[150px] w-[150px]"
              styles={buildStyles({
                textColor: "transparent", // Hide default text
                pathColor: getColorForPercentage(masteryPercentage), // Progress color
                trailColor: "#E6E6E6", // Background color with 52% opacity
                textSize: "12px",
              })}
            />
            {/* Custom Text */}
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <span className="text-gray-800 font-bold text-sm">{masteryPercentage}%</span>
              <span className="text-gray-600 text-xs font-bold">{handleParticipationText(masteryPercentage)}</span>
            </div>
          </div>
        </div>

        {/* Mastery Level Content */}
        <div>
          <h2 className="text-[24px] leading-[32px] font-semibold text-[#49454F]">Mastery Levels</h2>
          <p className="text-gray-600 text-sm">
            {xp === 0
              ? "You haven't attempted any quizzes yet. Start your first quiz to see your mastery progress here."
              : "Keep going! Complete more quizzes to improve your mastery level and track your progress here."}
          </p>
          <button
            onClick={() => {
              if (xp > 0) {
                navigate("/student-mastery", {
                  state: {
                    subjectName: subjectName,
                    classroom_name: c_name,
                    classroom_id: classroom_id,
                    masteryLevels: masteryLevels,
                  },
                });
              }
            }}
            className="text-[#6750A4] text-[14px] leading-[20px] font-semibold mt-2 inline-block hover:underline"
          >
            View more
          </button>
        </div>
      </div>
    </Tooltip>
  );
};

export default MasteryLevels;
