import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../Componenets/Sidebar";
import moment from "moment";
import axios from "axios";
import amplitude from "amplitude-js";

import EventCalender from "../../Componenets/Calendar/containers/eventCalendar";
import ClassroomDetailBanner from "./widgets/ClassroomDetailBanner";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { ToastHeader } from "react-bootstrap";
function ViewLessonPlan() {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [topics, setTopics] = useState([]);
  const [planList, setPlanList] = useState({});
  const [counterEffect, setCounterEffect] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [show2, setShow2] = useState(false);
  const getTopicList = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    let id = location.state.selectedSubject.subject_id;

    console.log("brand id:", id);
    console.log("Classes data", location.state.subject_status_have_lesson);
    if (location.state.subject_status_have_lesson === 0) {
      axios
        .request({
          method: "POST",
          url: process.env.REACT_APP_REST_API_BASE_URL + "/get_topic_for_lesson",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: token,
          },
          data: JSON.stringify({
            subject_id: id,
          }),
        })
        .then((res) => {
          console.log("objedsdct");
          console.log(res.data);

          setTopics(res.data);
          setCounterEffect(true);
        });
    } else {
      axios
        .request({
          method: "POST",
          url: process.env.REACT_APP_REST_API_BASE_URL + "/get_super_topic_for_lesson",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            authorization: token,
          },
          data: JSON.stringify({
            subject_id: id,
          }),
        })
        .then((res) => {
          console.log("objedsdct");
          console.log(res.data);

          setTopics(res.data);
          setCounterEffect(true);
        });
    }
  };
  const handleCreate = () => {
    setIsSubmitting(true);
    // console.log("learn date", moment(planList[0]?.start).format("YYYY-MM-DD"));
    console.log("Lesson  Plan", planList[0].contentType);
    // Create an array of plans with 'learn_date' and 'area_ids_csv'
    const list_of_plans = planList.map((plan) => ({
      area_ids_csv: plan.contentType === "concept" ? plan.data.area_ids_csv : null,
      learn_date: moment(plan.start).format("YYYY-MM-DD"),
      sub_topic_ids_csv: plan.contentType === "lesson" ? plan.data.area_ids_csv : null, // Current date
    }));
    console.log("list of plans:", list_of_plans);
    // Make the POST request with the list_of_plans
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_classroom", {
        method: "POST",
        class_name: location.state.data.quizName,
        subject_id: location.state.selectedSubject.subject_id,
        list_of_plans: list_of_plans, // Pass the formatted list_of_plans here
        product_type: localStorage.getItem("user_type") === "3" ? 2 : 1,
      })
      .then((response) => {
        // Show the toast
        setIsSubmitting(false);
        // Set a timeout for the duration you expect the toast to be visible

        // Navigate after the toast has been shown for the duration
        navigate("/share", {
          state: {
            classes_data: response.data,
          },
        });

        // alert("Class created successfully");
      })
      .catch((error) => {
        setIsSubmitting(false);
        alert("Class with the same name already exists.");
        console.error(error);
      });
  };
  useEffect(() => {
    if (counterEffect === false) {
      getTopicList();
    }
  }, []);
  return (
    <>
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast style={{ backgroundColor: "#f7f2f9" }} onClose={() => setShow2(false)} show={show2} delay={3000} autohide>
          <ToastHeader>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <mask id="mask0_99_1907" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_99_1907)">
                <path
                  d="M9.54961 18.0001L3.84961 12.3001L5.27461 10.8751L9.54961 15.1501L18.7246 5.9751L20.1496 7.4001L9.54961 18.0001Z"
                  fill="#0AB30A"
                />
              </g>
            </svg>
            <span className="snackbarh" style={{ marginRight: "10%" }}>
              Deleted
            </span>
          </ToastHeader>
          <Toast.Body>
            <span className="snackbarp">Lesson plan has been deleted</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Sidebar item_id="quiz" />
      <div className="sm:ml-[10px] md:ml-[315px] ">
        {location.state.classroomData ? [<ClassroomDetailBanner classroomData={location.state.classroomData} />] : <div></div>}
        <div className="home sm:ml[0px] md:ml-[65px]">
          <div>
            <label className="dashboard-label" style={{ color: "#403151" }}>
              Create Class
            </label>
          </div>
          <div className="pl-10">
            <label className="disabled-label" style={{ color: "#403151" }}>
              Add lesson
              <svg xmlns="http://www.w3.org/2000/svg" width="70" height="14" viewBox="0 0 82 14" fill="none">
                <path d="M2.5 14C2.5 12.3431 3.84315 11 5.5 11H76.5C78.1569 11 79.5 12.3431 79.5 14H2.5Z" fill="#403151" />
              </svg>
            </label>
          </div>
          {location.state ? (
            <>
              {!location.state.classroomData ? (
                <div className="pl-10">
                  <label className="disabled-label" style={{ color: "#403151" }}>
                    Share
                  </label>
                </div>
              ) : (
                <div></div>
              )}
            </>
          ) : null}
        </div>
        <div className="container">
          <div className="  justify-center items-center sm:mr-[5px] md:mr-[0%] ml-[2%] md:mt-[3%]">
            <EventCalender
              subject_id={location.state.selectedSubject}
              newTopic={topics}
              classes={location.state.classes_data}
              name="Add"
              setPlanList={setPlanList}
              setShow2={setShow2}
            />
            <div style={{ display: "flex" }}>
              <div className="w-[100%] mt-[1%] ml-[28px] mb-[40px]">
                <button
                  onClick={() => {
                    navigate("/create-classroom", {
                      state: {
                        folder_id: selectedFolder !== null ? selectedFolder.folder_id : null,
                      },
                    });
                  }}
                  className="teepee-button sm:w-[6%] md:w-[10%] hover:bg-black hover:cursor-pointer ml-[1px]"
                  style={{
                    background: "transparent",
                    border: "1px solid #7E418B",
                    color: "#7E418B",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "600px",
                    fontFamily: "Roboto",
                    float: "left",
                    fontSize: "12px",
                  }}
                >
                  Back
                </button>

                <button
                  className={`teepee-button ${
                    planList.length === 0 ? "disable-button" : ""
                  }  sm:w-[6%] md:w-[10%] hover:bg-black hover:cursor-pointer`}
                  style={{
                    backgroundColor: "#7E418B",
                    color: "#FFFFFF",
                    fontSize: "12px",
                  }}
                  onClick={handleCreate}
                  disabled={planList.length === 0 || isSubmitting === true}
                >
                  {!isSubmitting && "Create Class"}
                  {isSubmitting && (
                    <svg
                      aria-hidden="true"
                      role="status"
                      class="inline w-4 h-4 me-3  animate-spin ml-1"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="white"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ViewLessonPlan;
