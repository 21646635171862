import React from "react";
import LiveUsersTable from "./LiveUsersTable";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

function LiveUsersTabs({ liveUsersData }) {
  var tabState = localStorage.getItem("tabState");

  const data = [
    {
      label: "Active Teachers",
      value: "1",
      body: (
        <LiveUsersTable data={liveUsersData.teachers_data} userType="Teacher" />
      ),
    },
    {
      label: "Active Student",
      value: "2",
      body: (
        <LiveUsersTable data={liveUsersData.students_data} userType="Student" />
      ),
    },
  ];
  return (
    <div>
      <Tabs id="custom-animation" value={tabState} className="w-[100%] ">
        <div>
          <center>
            <div className="flex">
              <div className="w-[30%] ml-[auto]">
                <TabsHeader
                  className="w-[100%] h-[58px] justify-center bg-[#6750A414]"
                  indicatorProps={{
                    className: "bg-[#6750A414] shadow-none !text-gray-900",
                  }}
                >
                  {data.map(({ label, value, icon }) => (
                    <div
                      onClick={() => {
                        localStorage.setItem("tabState", value);
                      }}
                    >
                      <Tab
                        id="ABC"
                        key={value}
                        value={value}
                        icon={icon}
                        className="w-[100%] mr-[20px] "
                      >
                        <div>{label}</div>
                      </Tab>
                    </div>
                  ))}
                </TabsHeader>
              </div>
            </div>
          </center>
          <TabsBody animate={{}}>
            {data.map(({ value, body }) => (
              <TabPanel key={value} value={value} className="p-0 overflow-auto">
                {body}
              </TabPanel>
            ))}
          </TabsBody>
        </div>
      </Tabs>
    </div>
  );
}

export default LiveUsersTabs;
