import React, { useEffect, useRef, useState } from "react";
import { Button, TextField, IconButton, InputAdornment } from "@mui/material";
import close_icon from "../../images/close_1.svg";
import SendIcon from "../../images/send_icon.svg"; // This will be the send icon
import ai_profile from "../../images/AI_PROFILE.svg";
import axios from "axios";
import thumbup from "../../images/thumbup.png";
import thumbup1 from "../../images/thumbup1.png";
import thumbdown from "../../images/thumbdown.png";
import thumbdown1 from "../../images/thumbdown1.png";
const RecheckSidePanelTopicBased = ({
  open,
  closePanel,
  selectedQuestion,
  totalQuestions,
  selectedQuizQuestion,
  thumbs_up_down,
  setThumbs_up_down,
  setQuiz_performance,
  quiz_performance,
}) => {
  const [inputMessage, setInputMessage] = useState(""); // Manage the input field value
  const [chatStep, setChatStep] = useState(1); // Tracks the response state
  const [loading, setLoading] = useState(false); // To show a loading state while fetching API response
  const [reason, setReason] = useState();
  const [showInput, setShowInput] = useState(true);
  const [chatHistory, setChatHistory] = useState([
    {
      sender: "ai",
      message: `Hello!`,
    },
    {
      sender: "ai",
      message: `I’m here to help with your recheck request for <b>Question ${selectedQuestion + 1}</b>. What’s your concern?`,
    },
  ]);

  const latestMessageRef = useRef(null);

  // Scroll to the latest message when chatHistory changes
  useEffect(() => {
    if (latestMessageRef.current) {
      latestMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatHistory]);

  const getPanelStyle = () => {
    const isMobile = window.innerWidth <= 768; // Define mobile screen width (e.g., 768px or adjust accordingly)

    return {
      position: "fixed",
      top: 0,
      right: open ? 0 : isMobile ? "-100%" : "-40%", // Sidebar slides in and out from the right
      height: "100vh", // Full viewport height
      width: isMobile ? "100%" : "40%", // Adjust width for mobile or desktop view
      backgroundColor: "#fff", // Background color
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 8px", // Box shadow for the panel
      zIndex: 1000, // Ensure it's on top
      display: "flex",
      flexDirection: "column",
      transition: "right 0.3s ease-in-out", // Smooth slide-in effect
    };
  };

  // Inside your component's render or return
  const panelStyle = getPanelStyle();

  const headerStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "linear-gradient(180deg, #6750A4, #8468CD)",
    color: "#fff",
    padding: "15px",
    fontSize: "18px",
    fontWeight: "bold",
  };

  const contentStyle = {
    flex: 1,
    padding: "30px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  };

  const inputContainerStyle = {
    padding: "10px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  };

  // Function to show typing/loading indicator in chat
  const showTypingIndicator = () => {
    setChatHistory((prevChat) => [
      ...prevChat,
      {
        sender: "ai",
        message: `<i><div class="flex gap-2">
    <div class="w-2 h-2 rounded-full animate-pulse bg-blue-600"></div>
    <div class="w-2 h-2 rounded-full animate-pulse bg-blue-600"></div>
    <div class="w-2 h-2 rounded-full animate-pulse bg-blue-600"></div>
</div></i>`,
      }, // Add typing/loading indicator
    ]);
  };

  const handleButtonClick = (userMessage, systemResponse) => {
    // Add user's message to the chat
    setReason(userMessage);

    setChatHistory((prevChat) => [
      ...prevChat,
      { sender: "user", message: userMessage },
      {
        sender: "ai",
        message: `<i><div class="flex gap-2">
    <div class="w-2 h-2 rounded-full animate-pulse bg-blue-600"></div>
    <div class="w-2 h-2 rounded-full animate-pulse bg-blue-600"></div>
    <div class="w-2 h-2 rounded-full animate-pulse bg-blue-600"></div>
</div></i>`,
      }, // Show typing/loading indicator
    ]);

    // Simulate a delay before showing the system response to replace the typing indicator
    setTimeout(() => {
      setLoading(false);
      setChatHistory((prevChat) => {
        const updatedChat = [...prevChat];
        updatedChat.pop(); // Remove the typing indicator
        updatedChat.push({ sender: "ai", message: systemResponse }); // Add the actual AI/system response
        return updatedChat;
      });
    }, 2000); // Adjust the delay time as necessary (2000ms = 2 seconds)
    setLoading(true);
    // Move to the next chat step
    setChatStep(chatStep + 1);
  };

  const fetchApiResponse = async (userMessage) => {
    try {
      const quizData = selectedQuizQuestion;
      const questionData = JSON.parse(selectedQuizQuestion[selectedQuestion].quiz_question);

      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

      // Await the axios post call
      const response = await axios.post(process.env.REACT_APP_REST_API_BASE_URL + "/submit_recheck_request_topic_based", {
        method: "POST",
        category: reason,
        reason: userMessage,
        marks: quizData[selectedQuestion].score,
        feedback: quizData[selectedQuestion].feedback,
        student_answer:
          quizData[selectedQuestion].feedback === "Your answer is invalid as it is empty. Please provide an answer to the question."
            ? " "
            : quizData[selectedQuestion].student_response,
        predefined_question_answer_id: questionData.predefined_question_answer_id,
        question_response_id: quizData[selectedQuestion].question_response_id,
      });

      // Update the quiz question with the new feedback and marks
      if (response.data) {
        // Update the selected question directly with the new data
        selectedQuizQuestion[selectedQuestion].recheck_status = 1; // Update recheck_status to 1
        selectedQuizQuestion[selectedQuestion].recheck_data.updated_marks = response.data.u_marks || selectedQuizQuestion[selectedQuestion].score; // Updated marks
        selectedQuizQuestion[selectedQuestion].recheck_data.updated_feedback =
          response.data.u_feedback || selectedQuizQuestion[selectedQuestion].feedback; // Updated feedback
        selectedQuizQuestion[selectedQuestion].recheck_data.tip = response.data.tip || ""; // Tip if provided
        selectedQuizQuestion[selectedQuestion].recheck_data.reason = userMessage;
        selectedQuizQuestion[selectedQuestion].recheck_data.category = reason;
        selectedQuizQuestion[selectedQuestion].recheck_data.question_recheck_id = response.data.question_recheck_id;
        selectedQuizQuestion[selectedQuestion].time = new Date();
      }
      if (response.data.tip) {
        return [
          `<div style="padding: 24px; border-radius: 8px; background-color: #f3f3f3;">
               <b>Thank you for submitting your objection!</b><br>After reviewing it, we have determined that the original marks/feedback were correct. Here’s why:<br>
               <ul style="margin-top: 12px;">
                 <li style="margin-bottom: 8px;"><b>Reason:</b> ${response.data.u_feedback}</li>
                 <li><b>Tip:</b> ${response.data.tip}</li>
               </ul>
             </div>`,
        ];
      } else if (response.data.u_marks) {
        let marks = parseInt(response.data.total_score);
        setQuiz_performance(marks);
        selectedQuizQuestion[selectedQuestion].score = response.data.u_marks;
        return [
          `<div style="padding: 10px; border-radius: 8px; background-color: #f3f3f3;">
            <b>Great work on spotting that!</b><br>After reviewing your objection, we’ve identified an issue with the original marks/feedback. Here’s what has changed:<br>
               <ul style="margin-top: 20px;">
                 <li style="margin-bottom: 16px;"><b>Updated Marks:</b> Your score has been adjusted to <b>${response.data.u_marks}/${selectedQuizQuestion[selectedQuestion]?.question_marks}</b>.</li>
                 <li><b>Updated Feedback:</b> ${response.data.u_feedback}</li>
               </ul>
             </div>`,
        ];
      }
    } catch (error) {
      console.error("Error fetching API response:", error);
      return ["Sorry, there was an issue submitting your request. Please try again later."];
    }
  };

  // Function to handle user input submission
  // Function to handle user input submission
  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return; // Prevent sending empty messages
    setShowInput(false);

    // Step 1: Add user's message to the chat
    setChatHistory((prevChat) => [...prevChat, { sender: "user", message: inputMessage }]);

    // Step 2: Show typing indicator immediately
    showTypingIndicator();

    setLoading(true); // Set loading state

    // Step 3: Delay for 2 seconds to show typing indicator before the "Got it! We’re on it..." message appears
    setTimeout(() => {
      // Step 4: Remove typing indicator and show "Got it! We’re on it..." message
      setChatHistory((prevChat) => {
        const updatedChat = [...prevChat];
        updatedChat.pop(); // Remove typing indicator
        updatedChat.push({ sender: "ai", message: "Got it! We’re on it. Hang tight, it can take up to a minute." });
        return updatedChat;
      });

      // Step 5: Show typing indicator again for the API call delay
      showTypingIndicator();

      // Step 6: Delay API call by another 2 seconds to simulate a realistic interaction
      setTimeout(async () => {
        try {
          // Step 7: Make the API call after the typing indicator delay
          const apiResponses = await fetchApiResponse(inputMessage); // Fetch AI response

          // Step 8: Remove the typing indicator and show the actual AI response
          setChatHistory((prevChat) => {
            const updatedChat = [...prevChat];
            updatedChat.pop(); // Remove the typing indicator
            apiResponses.forEach((response) => {
              updatedChat.push({ sender: "ai", message: response });
            });
            return updatedChat;
          });
        } catch (error) {
          console.error("Error sending message:", error);
          setChatHistory((prevChat) => [...prevChat, { sender: "ai", message: "Error processing request." }]);
        } finally {
          setLoading(false); // Stop loading state
        }
      }, 2000); // 2-second delay for typing indicator during API call
    }, 2000); // 2-second delay to show the typing indicator before the "Got it!" message

    setInputMessage(""); // Clear the input field
  };

  const inputStyle = {
    borderRadius: "4px", // Rounded input field
    padding: "10px", // Add padding inside the input field
    margin: "10px", // Add margin outside the input field
  };
  useEffect(() => {
    // console.log("marks", quiz_performace.current);
    // console.log("marks here", selectedQuizQuestion[selectedQuestion].question_marks);
    // if (!thumbs_up_down) {
    //   setThumbs_up_down(selectedQuizQuestion[selectedQuestion]?.recheck_data?.thumbs_up_down);
    // }
    console.log("data here", selectedQuizQuestion[selectedQuestion]?.recheck_data);
    let recheck_data = selectedQuizQuestion[selectedQuestion]?.recheck_data;

    if (selectedQuizQuestion[selectedQuestion]?.recheck_status === 1) {
      setShowInput(false);
      setChatHistory([
        {
          sender: "ai",
          message: `Hello!`,
        },
        {
          sender: "ai",
          message: `I’m here to help with your recheck request for <b>Question ${selectedQuestion + 1}</b>. What’s your concern?`,
        },
      ]);
      let response = " ";
      if (recheck_data.category === "Marks") {
        response = "You’ve selected <b>Marks</b>. Could you please tell us what specific concerns you have regarding the marks you received?";
      } else if (recheck_data.category === "Feedback") {
        response = "You’ve selected Feedback. Could you please tell us what feedback you would like to provide on the question?";
      } else {
        response =
          "You’ve selected Both. Could you please provide details about your concerns regarding both the marks handleButtonClickand the feedback you received?";
      }
      setChatHistory((prevChat) => [...prevChat, { sender: "user", message: recheck_data.category }]);
      setChatHistory((prevChat) => [...prevChat, { sender: "ai", message: response }]);
      //   (recheck_data.category, response);
      setChatHistory((prevChat) => [...prevChat, { sender: "user", message: recheck_data.reason }]);
      setChatHistory((prevChat) => [...prevChat, { sender: "ai", message: "Got it! We’re on it. Hang tight, it can take up to a minute." }]);
      if (recheck_data.tip) {
        setChatHistory((prevChat) => [
          ...prevChat,
          {
            sender: "ai",
            message: `<div style="padding: 10px; border-radius: 8px; background-color: #f3f3f3;">
        <b>Thank you for submitting your objection!</b><br>After reviewing it, we have determined that the original marks/feedback were correct. Here’s why:<br>
        <ul style="margin-top: 20px;">
          <li style="margin-bottom: 16px;"><b>Reason:</b> ${recheck_data.updated_feedback}</li>
          <li><b>Tip:</b> ${recheck_data.tip}</li>
        </ul>
      </div>`,
          },
        ]);
      } else {
        setChatHistory((prevChat) => [
          ...prevChat,
          {
            sender: "ai",
            message: `<div style="padding: 10px; border-radius: 8px; background-color: #f3f3f3;">
            <b>Great work on spotting that!</b><br>After reviewing your objection, we’ve identified an issue with the original marks/feedback. Here’s what has changed:<br>
               <ul style="margin-top: 20px;">
                 <li style="margin-bottom: 16px;"><b>Updated Marks:</b> Your score has been adjusted to <b>${recheck_data.updated_marks}/${selectedQuizQuestion[selectedQuestion]?.question_marks}</b>.</li>
                 <li><b>Updated Feedback:</b> ${recheck_data?.updated_feedback}</li>
               </ul>
             </div>`,
          },
        ]);
      }
      setChatStep(2);
    } else {
      setChatHistory([
        {
          sender: "ai",
          message: `Hello!`,
        },
        {
          sender: "ai",
          message: `I’m here to help with your recheck request for <b>Question ${selectedQuestion + 1}</b>. What’s your concern?`,
        },
      ]);
      setChatStep(1);
      setShowInput(true);
    }
  }, [open]);
  const feedback = async (id, status) => {
    let recheck_data = selectedQuizQuestion[selectedQuestion]?.recheck_data;
    try {
      var token = "Bearer " + localStorage.getItem("access_token");

      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

      // Await the axios post call
      const response = await axios.post(process.env.REACT_APP_REST_API_BASE_URL + "/question_recheck_feedback_topic_based", {
        method: "POST",
        thumbs_up_down: status,
        question_recheck_id: id,
      });
      if (response) {
        setThumbs_up_down(status);
        recheck_data.thumbs_up_down = status;
      }
      // Update the quiz question with the new feedback and marks
    } catch (error) {
      console.error("Error fetching API response:", error);
    }
  };
  return (
    <div style={panelStyle}>
      <div style={headerStyle}>
        <Button
          onClick={closePanel}
          style={{
            color: "#fff",
            backgroundColor: "rgba(255, 255, 255, 0.25)",
            width: "40px", // Set width to contain the icon
            height: "40px", // Set height to contain the icon
            minWidth: "40px", // Ensure min-width doesn't expand
            borderRadius: "10%", // Make it circular
            padding: "0", // Remove any default padding
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={close_icon} alt="close" style={{ width: "28px", height: "28px" }} />
        </Button>
      </div>

      {/* Main Content Area */}
      <div className="scrollable-content" style={contentStyle}>
        <span className="font-bold text-[22px] text-[#49454F] mb-[28px]" style={{ fontFamily: "Roboto" }}>
          Question {selectedQuestion + 1} of {totalQuestions}
        </span>

        {/* Display chat history */}
        {chatHistory.map((chat, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "10px",
              flexDirection: "column",
              alignSelf: chat.sender === "user" ? "flex-end" : "flex-start", // Align user message to the right
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "10px",
                justifyContent: chat.sender === "user" ? "flex-end" : "flex-start", // Align user message to the right
              }}
            >
              {chat.sender === "ai" && (index === 0 || chatHistory[index - 1].sender !== "ai") ? (
                <img src={ai_profile} alt="ai" className="" />
              ) : (
                <img src={ai_profile} alt="ai" className="invisible" /> // Hidden but occupies space
              )}
              <p
                className="bg-[#F3F2F5] font-roboto text-[16px] text-[#54595E] font-medium"
                style={{
                  display: "inline-block",
                  padding: "10px",
                  borderRadius: "8px",
                  margin: 0,
                  //   marginLeft: chat.sender === "ai" && (index === 0 || chatHistory[index - 1].sender !== "ai") ? " " : "13%",
                  alignSelf: chat.sender === "user" ? "flex-end" : "flex-start",
                  backgroundColor: chat.sender === "user" ? "#9777EC" : "#F3F2F5",
                  color: chat.sender === "user" ? "white" : "black",
                }}
                dangerouslySetInnerHTML={{ __html: chat.message }} // Render HTML
              />
            </div>
            {index === chatHistory.length - 1 && <div ref={latestMessageRef} />}
            {/* Note message below the appropriate chat message */}
            {(chat.message === "You’ve selected Feedback. Could you please tell us what feedback you would like to provide on the question?" ||
              chat.message ===
                "You’ve selected <b>Marks</b>. Could you please tell us what specific concerns you have regarding the marks you received?" ||
              chat.message ===
                "You’ve selected Both. Could you please provide details about your concerns regarding both the marks and the feedback you received?") && (
              <p
                className="text-[#49454F] italic text-[12px] font-roboto w-[75%]"
                style={{
                  marginTop: "0px",
                  alignSelf: "flex-start", // Always align the note on the left
                  marginLeft: chat.sender === "user" ? "auto" : "10%",
                }}
              >
                Note: You can use this feature only once, so please ensure you provide detailed and serious feedback.
              </p>
            )}
            <div className="w-full">
              <center className="">
                {showInput === false && index === chatHistory.length - 1 && loading === false && (
                  <p className="text-grey-300 text-center text-[11px] ">
                    <span className="flex justify-center">
                      {" "}
                      How do you rate Teepee.ai’s analysis?{" "}
                      <img
                        onClick={() => feedback(selectedQuizQuestion[selectedQuestion]?.recheck_data.question_recheck_id, 1)}
                        src={thumbs_up_down === 1 ? thumbup1 : thumbup}
                        className="ml-[5px] mr-[5px] cursor-pointer"
                        alt="Thumbs Up"
                        style={{ width: "17px", height: "17px", objectFit: "cover" }} // Set image size and prevent stretching
                      />
                      <img
                        onClick={() => feedback(selectedQuizQuestion[selectedQuestion]?.recheck_data.question_recheck_id, 0)}
                        className="cursor-pointer"
                        src={thumbs_up_down === 0 ? thumbdown1 : thumbdown}
                        alt="Thumbs Down"
                        style={{ width: "17px", height: "17px", objectFit: "cover" }} // Set image size and prevent stretching
                      />
                    </span>
                  </p>
                )}
              </center>
            </div>
          </div>
        ))}
        {/* Chat buttons for user input */}
        {chatStep === 1 && (
          <div style={{ marginTop: "0px", marginLeft: "12%" }}>
            <Button
              variant="outlined"
              sx={{
                color: "#6750A4",
                fontFamily: "Roboto",
                border: "1px solid #6750A4",
                marginBottom: "10px", // Add spacing between buttons
              }}
              onClick={() =>
                handleButtonClick(
                  "Marks",
                  "You’ve selected <b>Marks</b>. Could you please tell us what specific concerns you have regarding the marks you received?"
                )
              }
            >
              Marks
            </Button>
            <br></br>
            <Button
              variant="outlined"
              sx={{
                color: "#6750A4",
                fontFamily: "Roboto",
                border: "1px solid #6750A4",
                marginBottom: "10px", // Add spacing between buttons
              }}
              onClick={() =>
                handleButtonClick(
                  "Feedback",
                  "You’ve selected Feedback. Could you please tell us what feedback you would like to provide on the question?"
                )
              }
            >
              Feedback
            </Button>
            <br></br>
            <Button
              variant="outlined"
              sx={{
                color: "#6750A4",
                fontFamily: "Roboto",
                border: "1px solid #6750A4",
              }}
              onClick={() =>
                handleButtonClick(
                  "Both",
                  "You’ve selected Both. Could you please provide details about your concerns regarding both the marks and the feedback you received?"
                )
              }
            >
              Both
            </Button>
          </div>
        )}
      </div>

      {/* Input Area */}
      <div style={inputContainerStyle}>
        <TextField
          style={{ flex: 1, display: showInput === false ? "none" : "", ...inputStyle }} // Full-width and custom input style
          variant="outlined"
          placeholder="Start typing..."
          size="medium"
          disabled={chatStep === 1 || loading === true}
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)} // Update input state
          onKeyPress={(e) => {
            if (e.key === "Enter") handleSendMessage(); // Send message on Enter key press
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton edge="end" color="primary" disabled={chatStep === 1 || loading === true} onClick={handleSendMessage}>
                  <img src={SendIcon} alt="Send" className="h-[40px] w-[40px]" />
                </IconButton>
              </InputAdornment>
            ),
            style: {
              borderRadius: "4px", // Match the input field border radius to look like your image
              paddingRight: "20px", // Remove padding on the right so the button fits nicely
              margin: "10px",
            },
          }}
        />
      </div>
    </div>
  );
};

export default RecheckSidePanelTopicBased;
