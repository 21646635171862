import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import subject_icon from "../../../images/subject_icon.svg";
import Sidebar from "../../Sidebar";
import useGetLeaderboard from "../../../Hooks/Custom_hooks/TeacherClassroomHome/Leaderboard/getLeaderboard";
import Leaderboard from "../../TeacherClassroomHome/leaderboard";

const StudentLeaderboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [leaderboardData, setLeaderboardData] = useState();
  const getLeaderBoard = useGetLeaderboard();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const formatDate = (date) => {
    if (date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two digits
      const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
      return `${year}-${month}-${day}`;
    }
  };
  const viewLeaderBoard = () => {
    // Get the current date as the end date
    const endDate = new Date();

    // Calculate the start date by subtracting 7 days from the end date
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 7);

    // Format the dates
    const startingDate = formatDate(startDate);
    const endingDate = formatDate(endDate);

    let body = {
      start_date: startingDate,
      end_date: endingDate,
      classroom_id: location.state?.classroom_id,
    };

    getLeaderBoard(body, setLeaderboardData);
  };

  useEffect(() => {
    viewLeaderBoard();
  }, []);

  return (
    <div>
      <Sidebar />
      <div className="home sm:ml[25px]   h-[150px] justify-evenly">
        <div className="classroom_name_div sm:w-[50%] md:w-[40%] mt-[3%]  !overflow-visible">
          {}{" "}
          <div className="home sm:ml[25px]">
            <button className=" flex">
              <span className="teacher_navigation_text" onClick={() => navigate("/student-dashboard")}>
                Home
              </span>{" "}
              <span className="ml-1 mr-1 text-decoration-none teacher_navigation_text" style={{ textDecoration: "none !important" }}>
                {">"}
              </span>{" "}
              <span onClick={() => navigate(-1)} className="teacher_navigation_text">
                {location.state !== undefined && location.state?.classroom_name}
              </span>
            </button>
            <div style={{ visibility: "hidden", height: "10px" }}>dasdasd dasd </div>
          </div>
          <div className="classroom_details_label flex block mt-2 ">
            <img src={subject_icon} className="mr-1" alt=" " />
            <span className="text-display-h3">{location.state !== undefined && location.state?.subjectName}</span>
          </div>
        </div>
        <div>
          <label className="classroom_details_label"></label>
        </div>
        <div className="flex mb-[13px]">
          {/* <div className="mt-[auto] mb-[12px] leave-classroom">
            <button onClick={() => handleOpenLeaveClassroomDialogue()}>Leave Classroom</button>
          </div> */}
        </div>
      </div>
      {leaderboardData?.data && (
        <div className="w-[70%] mx-auto">
          <Leaderboard
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            leaderboardData={leaderboardData}
          />
        </div>
      )}
    </div>
  );
};

export default StudentLeaderboard;
