import React from "react";
import CircularProgress from "../../../Componenets/Teacher/widgets/CircularProgress";

const StudentSubjectAnalysis = ({ StudentParticipationRate }) => {
  const getColorForPercentage = (percentage) => {
    if (percentage >= 80) return "#4CAF50"; // Green
    if (percentage >= 60) return "#835BEF"; // Purple
    if (percentage >= 40) return "#F8B250"; // Yellow
    if (percentage >= 20) return "#0293EE"; // Blue
    return "#DF557F"; // Red
  };

  const handleParticipationText = (percentage) => {
    if (percentage >= 80) return "Mastered";
    if (percentage >= 60) return "Competent";
    if (percentage >= 40) return "Intermediate";
    if (percentage >= 20) return "Developing";
    return "Insufficient";
  };

  return (
    <div className="bg-[#F7F2F9] md:w-[60%] sm:w-[100%] shadow-md rounded-lg p-6 flex flex-col items-center">
      {/* Circular Progress Section */}
      <div className="mb-4">
        <CircularProgress
          StudentParticipationRate={StudentParticipationRate}
          label={handleParticipationText(parseInt(StudentParticipationRate))}
          color={getColorForPercentage(parseInt(StudentParticipationRate))}
        />
      </div>

      {/* Legend Section */}
      <div className="grid md:grid-cols-3 sm:grid-cols-1 gap-x-2 gap-y-2 text-center text-sm font-medium text-gray-600">
        <div className="flex items-center space-x-2">
          <span className="h-2 w-2 rounded-full bg-[#4CAF50]"></span>
          <span>Above 80% - Mastered</span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="h-2 w-2 rounded-full bg-[#835BEF]"></span>
          <span>60 - 79% - Competent</span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="h-2 w-2 rounded-full bg-[#F8B250]"></span>
          <span>40 - 59% - Intermediate</span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="h-2 w-2 rounded-full bg-[#0293EE]"></span>
          <span>20 - 39% - Developing</span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="h-2 w-2 rounded-full bg-[#DF557F]"></span>
          <span>Below 20% - Insufficient</span>
        </div>
      </div>
    </div>
  );
};

export default StudentSubjectAnalysis;
