import React from "react";
import leave_icon from "../../../images/exit_to_app.svg";
const LeaveClassroomDialog = ({ isOpen, onClose, onLeave }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-[90%] sm:w-[400px] p-[44px] rounded-lg shadow-lg relative">
        {/* Close Button */}
        <button className="absolute top-5 right-5 text-gray-600 hover:text-gray-800 text-[30px]" onClick={onClose}>
          &times;
        </button>

        {/* Icon */}
        <div className="flex justify-center mb-2">
          <img src={leave_icon} alt="" />
        </div>

        {/* Title */}
        <h2 className="text-center text-xl font-semibold mb-2">Leave classroom?</h2>

        {/* Message */}
        <p className="text-center text-gray-600 mb-2">Are you sure you want to leave this classroom?</p>

        {/* Leave Button */}
        <div className="flex justify-center">
          <button onClick={onLeave} className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition">
            Leave Classroom
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeaveClassroomDialog;
