import { useEffect } from "react";

const Chatbot = ({ condition }) => {
  useEffect(() => {
    if (condition) {
      (function (w, d) {
        w.CollectId = "675a6bffd3925dd2f895b18c";
        const h = d.head || d.getElementsByTagName("head")[0];
        const s = d.createElement("script");
        s.setAttribute("type", "text/javascript");
        s.async = true;
        s.setAttribute("src", "https://collectcdn.com/launcher.js");
        h.appendChild(s);
      })(window, document);
    }
  }, [condition]);

  return null; // This component doesn't render anything visually
};

export default Chatbot;
