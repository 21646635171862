import React from "react";

function TopicAnalysisCard({ topicData }) {
  const handleColor = (parentIndex) => {
    for (let index = 0; index < topicData.length; index++) {
      console.log("checking index:", topicData[index]);
      if (parentIndex === index) {
        if (topicData[index].percentage >= 80) {
          return "#55CE98";
        } else if (topicData[index].percentage >= 70) {
          return "#BBEABB";
        } else if (topicData[index].percentage >= 50) {
          return "#FEF1CE";
        } else {
          return "#FAAD98";
        }
      }
    }
  };
  return (
    <>
      {topicData.map((topic, index) => (
        <div
          className="md:w-[34%] rounded-xl mr-[8px] sm:mt-[10px]"
          style={{ backgroundColor: handleColor(index) }}
        >
          <div className="p-[12px] ">
            <div className="topic_name_topic_analysis_card">
              {topic.topic_name}
            </div>
            <div className="subheading_topic_analysis_card mt-[7px]">
              {topic.percentage > 90
                ? "The topic is fully mastered"
                : topic.percentage > 80
                ? "Students are well-prepared in this area"
                : topic.percentage > 70
                ? "Mostly solid understanding."
                : topic.percentage > 60
                ? "Immediate review is recommended"
                : "Significant focus is required for this topic."}
            </div>
          </div>
          <div className="percentage_in_topic_analysis_card mt-[56px] pb-[12px] text-center">
            {`${topic.percentage.toFixed(0)}%`}
          </div>
        </div>
      ))}
    </>
  );
}

export default TopicAnalysisCard;
