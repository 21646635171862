import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Header from "../../../images/header-trial-popup.svg";
import GetStartedHeadingCard from "./GetStartedHeadingCard";
import appContext from "../../../context/appContext";
import { useContext } from "react";
import moment from "moment";
import axios from "axios";

function GetStartedPopUp(props) {
  const state = useContext(appContext);
  const handleRequestedUserFlow = () => {
    if (localStorage.getItem("email_address") === process.env.REACT_APP_REQUESTED_USER && state.selectedSubjectIdForClass === 10) {
      return 0;
    } else {
      return 1;
    }
  };
  const startTrialPeriod = () => {
    const currentDate = moment();
    const futureDate = currentDate.add(1, "hours"); // Adding 1 hour
    const expiryDate = futureDate.format("Y-MM-DD HH:mm:ss");
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/start_trial_period", {
        method: "POST",
        // user_feedback: user_feedback,
        subject_id: state.selectedSubjectIdForClass,
        trial_start_date: moment().format("Y-MM-DD HH:mm:ss"),
        trial_end_date: expiryDate,
        request_user: handleRequestedUserFlow(),
      })
      .then((response) => {
        console.log("response: ", response);
        state.handleCloseTrialGetStartedPopup();
        state.handleOpenTrialAcknowledgePopup();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTotalNumberOfQuizzes = () => {
    if (localStorage.getItem("email_address") === process.env.REACT_APP_REQUESTED_USER && state.selectedSubjectIdForClass === 10) {
      return 35;
    } else {
      return 7;
    }
  };
  return (
    <div>
      <Dialog
        PaperProps={{
          sx: {
            width: "1024px",
            maxWidth: "1024px",
            height: "502px",
            maxHeight: "502px",
            borderRadius: "12px",
            overflow: "hidden",
            overflowY: "scroll",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          },
        }}
        open={props.openTrialGetStartedPopup}
        // onClose={props.handleCloseTrialGetStartedPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="flex p-0 ">
          <div className="text-center w-[100%] create_new_classroom_dialogue_title  text-white">
            <div
              className=" w-full p-[5%] " // This maintains the aspect ratio
              style={{
                backgroundImage: `url(${Header})`,
                backgroundSize: "cover",
              }}
            >
              <div className=" md:text-[28px] sm:text-[16px]  font-bold mb-[8px]">{"Get Started with " + state.selectedSubjectForClass}</div>
              <div className=" md:text-[16px] sm:text-[14px]  font-bold">
                No credit card required! Get full access to this subject anytime for just £5/student yearly!
              </div>
            </div>
          </div>
        </DialogTitle>

        <div>
          <DialogContent className="sm:ml-[4px] sm:mr-[4px] md:ml-[32px] md:mr-[32px] !pt-[4%] ">
            <div className="flex sm:flex-col md:flex-row">
              <div className="md:w-[33%] sm:w-[100%] sm:mb-2 md:mb-0 sm:text-center md:text-left">
                <GetStartedHeadingCard
                  heading="Quiz Creation"
                  subHeading="Generate and share up to 50 quizzes
            (Formative/Exam-Style/Mini-Mocks)"
                />{" "}
              </div>
              <div className="md:w-[33%] sm:w-[100%] sm:mb-2 md:mb-0 sm:text-center md:text-left">
                <GetStartedHeadingCard
                  heading="Classroom Management"
                  subHeading="Create and manage classes, with the ability to enroll students seamlessly."
                />
              </div>
              <div className="md:w-[33%] sm:w-[100%] sm:mb-2 md:mb-0 sm:text-center md:text-left">
                <GetStartedHeadingCard
                  heading="Quiz Customisation"
                  subHeading="Utilize the full suite of quiz management tools, including copying, renaming, and saving drafts of quizzes."
                />
              </div>
            </div>
            <div className="md:mt-[5%]">
              <div className="flex sm:flex-col md:flex-row">
                <div className="md:w-[33%] sm:w-[100%] sm:mb-2 md:mb-0 sm:text-center md:text-left">
                  <GetStartedHeadingCard
                    heading="Analytics Access"
                    subHeading="View detailed quiz analytics to gauge class performance and individual student progress."
                  />{" "}
                </div>
                <div className="md:w-[33%] sm:w-[100%] sm:mb-2 md:mb-0 sm:text-center md:text-left">
                  <GetStartedHeadingCard
                    heading="personalised Feedback"
                    subHeading="Experience AI-driven feedback on student attempts for a personalised teaching approach."
                  />
                </div>
                <div className="md:w-[33%] sm:w-[100%] sm:mb-2 md:mb-0 sm:text-center md:text-left">
                  <GetStartedHeadingCard
                    heading="Feedback Control"
                    subHeading="Teachers can tailor feedback and marks, ensuring customised guidance for students."
                  />
                </div>
              </div>
            </div>
            <div className="md:mt-[4%] sm:mt-[8%] flex justify-center ">
              <button
                className="teepee-button md:w-[30%] sm:w-[60%] !text-sm !font-semibold !text-[#FFFFFF] !py-2.5"
                onClick={() => startTrialPeriod()}
              >
                {`Try Free for ${handleTotalNumberOfQuizzes()} days`}
              </button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

export default GetStartedPopUp;
