import axios from "axios";
import React, { useEffect, useState } from "react";

function TrackingTableInsideClassroom(props) {
  return (
    <div className="flex " style={{ width: "100%" }}>
      <div id="first-table " style={{ width: "40%" }}>
        <table style={{ width: "100%" }}>
          <thead class="text-xs text-black dark:bg-white dark:text-white ">
            <tr className="tracking_table">
              <th
                style={{ width: "5px" }}
                scope="col"
                class="px-6 py-3 tracking_table spth bg-[#F7F2F9]"
              >
                ID
              </th>

              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[80%]"
              >
                Email
              </th>
            </tr>
          </thead>
          <tbody>
            {props.insideClassroomStats.length > 0 && (
              <>
                {props.insideClassroomStats.map((stat, index) => (
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td
                      class="px-6 py-4  tracking_table tracking_table_td"
                      style={{ backgroundColor: "rgba(103, 80, 164, 0.08)" }}
                    >
                      {index + 1}
                    </td>
                    <td
                      class="px-6 py-4  tracking_table tracking_table_td"
                      style={{ backgroundColor: "rgba(103, 80, 164, 0.08)" }}
                    >
                      {stat.email_address}
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div
        id="second-table "
        style={{ width: "75%" }}
        className="overflow-auto"
      >
        <table style={{ width: "100%" }}>
          <thead class="text-xs text-black dark:bg-white dark:text-white ">
            <tr className="tracking_table">
              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%] whitespace-nowrap"
              >
                Account Creation
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%] whitespace-nowrap"
              >
                First Classroom Creation
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%] whitespace-nowrap"
              >
                Total Classrooms
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%] whitespace-nowrap"
              >
                Total Students
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%] whitespace-nowrap"
              >
                Total Quizzes Created
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%] whitespace-nowrap"
              >
                Total Quizzes Shared
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%] whitespace-nowrap"
              >
                Total Quizzes Drafted
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table whitespace-nowrap"
              >
                Total Attempts
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%] whitespace-nowrap"
              >
                Last Quiz Created
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%] whitespace-nowrap"
              >
                Last Quiz Attempted
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%] whitespace-nowrap"
              >
                Evaluation Experience
              </th>
            </tr>
          </thead>
          <tbody>
            {props.insideClassroomStats.length > 0 && (
              <>
                {props.insideClassroomStats.map((stat, index) => (
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <td class="px-6 py-4 text-center tracking_table_td">
                      {stat.account_creation}
                    </td>

                    <td class="px-6 py-4 text-center tracking_table_td">
                      {stat.first_classroom_creation}
                    </td>
                    <td class="px-6 py-4 text-center tracking_table_td">
                      {stat.total_classrooms}
                    </td>
                    <td class="px-6 py-4 text-center tracking_table_td">
                      {stat.total_students}
                    </td>
                    <td class="px-6 py-4 text-center tracking_table_td">
                      {stat.total_quiz_created !== null
                        ? stat.total_quiz_created
                        : 0}
                    </td>
                    <td class="px-6 py-4 text-center tracking_table_td">
                      {stat.total_quiz_shared}
                    </td>
                    <td class="px-6 py-4 text-center tracking_table_td">
                      {stat.total_quiz_drafted ? stat.total_quiz_drafted : 0}
                    </td>
                    <td class="px-6 py-4 text-center tracking_table_td">
                      {stat.total_attempts}
                    </td>
                    <td class="px-6 py-4 text-center tracking_table_td">
                      {stat.last_quiz_created}
                    </td>
                    <td class="px-6 py-4 text-center tracking_table_td">
                      {stat.last_quiz_response}
                    </td>
                    <td class="px-6 py-4 text-center tracking_table_td">
                      {stat.evaluation_experience}
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
    // <div className="overflow-x-auto">
    //   <table
    //     class="w-full text-sm text-left text-white dark:text-white"
    //     className="tracking_table w-[100%]"
    //     id="tbl"
    //   >
    //     <thead class="text-xs text-black dark:bg-white dark:text-white ">
    //       <tr className="tracking_table">
    //         <th scope="col" class="px-6 py-3 tracking_table spth bg-[#F7F2F9]">
    //           ID
    //         </th>
    //         <th
    //           scope="col"
    //           class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%]"
    //         >
    //           Email
    //         </th>
    //         <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
    //           Account Creation
    //         </th>
    //         <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
    //           First Classroom Creation
    //         </th>
    //         <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
    //           Total Classrooms
    //         </th>
    //         <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
    //           Total Students
    //         </th>
    //         <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
    //           Total Quizzes Created
    //         </th>
    //         <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
    //           Total Quizzes Shared
    //         </th>
    //         <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
    //           Total Quizzes Drafted
    //         </th>
    //         <th scope="col" class="px-6 py-3 tracking_table">
    //           Last Quiz Attempted
    //         </th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
    //         <th
    //           scope="row"
    //           class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white tracking_table_td"
    //           style={{ backgroundColor: "rgba(103, 80, 164, 0.08)" }}
    //         >
    //           1
    //         </th>
    //         <td
    //           class="px-6 py-4 max-w-xs tracking_table tracking_table_td"
    //           style={{ backgroundColor: "rgba(103, 80, 164, 0.08)" }}
    //         >
    //           abc@gmail.com
    //         </td>
    //         <td class="px-6 py-4 text-center tracking_table_td">dasdasda</td>
    //         <td class="px-6 py-4 text-center tracking_table_td">
    //           abc@gmail.com
    //         </td>
    //         <td class="px-6 py-4 text-center tracking_table_td">2</td>
    //         <td class="px-6 py-4 text-center tracking_table_td">3</td>
    //         <td class="px-6 py-4 text-center tracking_table_td">26/06/99</td>
    //       </tr>
    //     </tbody>
    //   </table>
    // </div>
  );
}

export default TrackingTableInsideClassroom;
