import moment from "moment";
import * as types from "./eventActionTypes";
// import localForage from "localforage";

let initialState = {
  allEvents: [],
};

export default function reduce(state = initialState, action = {}) {
  let newState; // Declare newState variable

  switch (action.type) {
    case types.ALL_EVENTS:
      return { ...state, allEvents: action.allEvents };

    case types.REMOVE_EVENT:
      newState = {
        ...state,
        allEvents: state.allEvents.filter(
          (event) => event.calendar_id !== action.payload
        ),
      };

      // localForage
      //   .setItem("AllEvents", newState.allEvents)
      //   .catch((e) => console.error("Failed to save events:", e));
      return newState;

    case types.ADD_EVENT:
      newState = {
        ...state,
        allEvents: state.allEvents.concat(action.payload),
      }; // Use concat for immutability
      // localForage
      //   .setItem("AllEvents", newState.allEvents)
      //   .catch((e) => console.error("Failed to save events:", e));
      return newState;

    case types.UPDATE_EVENT:
      newState = { ...state };
      console.log("update state", newState.allEvents);
      let index;
      if (action.payload.text === "Add") {
        for (var i = 0; i < newState.allEvents.length; i++) {
          if (newState.allEvents[i].calendar_id === action.payload.id) {
            index = i;
          }
        }
      } else {
        // const index = newState.allEvents.findIndex(
        //   (event) => action.payload.id === event.calender_id
        // ); // Find the index of the event
        console.log("OBJ", newState.allEvents[0]);
        for (var i = 0; i < action.payload.obj.data.length; i++) {
          // console.log(
          //   "Array is",
          //   action.payload.obj.data[i].calendar_id + "old id",
          //   action.payload.id
          // );
          if (action.payload.id === action.payload.obj.data[i].calendar_id) {
            index = i;
            console.log("new index", i);
          }
        }
        console.log("index", index);
        if (index !== -1) {
          newState.allEvents[index] = action.payload.obj1; // Update the event
        }
      }
      if (index !== -1) {
        console.log("New state updated:", action.payload.obj1);
        newState.allEvents[index] = action.payload.obj1; // Update the event
      }
      // localForage
      //   .setItem("AllEvents", newState.allEvents)
      //   .catch((e) => console.error("Failed to update events:", e));
      return newState;

    default:
      return state;
  }
}
