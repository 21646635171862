import React, { useEffect, useState } from "react";
import Info from "../../../images/info_new.svg";
import Close from "../../../images/close.png";
import Tick from "../../../images/tick.png";
import xps from "../../../images/10xps.svg";
import QuizResult from "./QuizResult";
import ChunkProgressBar from "./ChunkProgressBar";
import QuizHeader from "./QuizHeader";
import Stepper from "./Stepper";
import Lottie from "lottie-react";
import celebration from "../../../images/celebration.json";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

function QuizCard({
  quizData,
  selectedQuizQuestionIterationIndex,
  handleIterationIndex,
  selectedAnswerIndex,
  handleSelectedAnswer,
  isSubmitted,
  handleSubmittedStatus,
  quizCompleted,
  quizScore,
  quizTotalMarks,
  submitQuiz,
  quizType,
  quizMarksReview,
  nextQuiz_id,
  nextQuiz,
  handleSelectedIndex,
  currentIndex,
  handleClickQuizReview,
  sidebarData,
  setNextQuiz,
  handleLessonClick,
  setOpenAccordian,
  setOpenSubtopicAccordian,
  setFirstIteration,
  state,
  quizOrLesson,
  handleQuizClick,
  getSidebarData,
  isQuizSubmitted,
  selectedTopicId,
  selectedSubTopicId,
  mastery,
  oldXp,
}) {
  const handleSubmit = () => {
    handleSubmittedStatus(true);
  };
  const [isAnimation, setIsAnimation] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  console.log("IS SUBMITTED STATUS: ", isSubmitted);
  console.log("Quiz Completed", quizCompleted);
  const recallStatusCount = quizData.filter((item) => item?.recall_status === 3).length;

  const [options] = useState([
    { label: "A" },
    { label: "B" },
    { label: "C" },
    { label: "D" },
    { label: "E" },
    { label: "F" },
    { label: "G" },
    { label: "H" },
    { label: "I" },
    { label: "J" },
    { label: "K" },
    { label: "L" },
    { label: "M" },
    { label: "N" },
    { label: "O" },
    { label: "P" },
    { label: "Q" },
    { label: "R" },
    { label: "S" },
    { label: "T" },
  ]);
  useEffect(() => {
    if (
      isSubmitted &&
      quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_score === 1 &&
      quizData[selectedQuizQuestionIterationIndex]?.recall_status === 3
    ) {
      setIsAnimation(true);

      // Set timeout to disable animation after 3 seconds
      const timer = setTimeout(() => {
        setIsAnimation(false);
      }, 3000);

      // Clear timeout if the component unmounts or if the effect re-runs
      return () => clearTimeout(timer);
    } else {
      setIsAnimation(false);
    }
  }, [isSubmitted, quizData, selectedQuizQuestionIterationIndex, selectedAnswerIndex]);
  return (
    <>
      <QuizHeader quizCompleted={quizCompleted} />
      <div className="flex justify-center items-center h-full mt-[5%]">
        <div className="md:w-[45%] sm:w-[90%] mb-[5%]">
          {quizData !== undefined && !quizCompleted ? (
            <div>
              <div className="mb-[3%]">
                <ChunkProgressBar currentQuestionIndex={selectedQuizQuestionIterationIndex + 1} totalQuestions={quizData.length} />
              </div>
              <div id="questionIteration" className="question-iteration ">
                {`Question ${selectedQuizQuestionIterationIndex + 1} of ${quizData.length}`}
              </div>
              <div
                id="questionText"
                className="question-text"
                dangerouslySetInnerHTML={{
                  __html: quizData[selectedQuizQuestionIterationIndex].quiz_question,
                }}
              ></div>
              {quizData[selectedQuizQuestionIterationIndex]?.recall_status && (
                <div id="instructions" className="flex mt-[1%]">
                  {/*<div className="ml-[1%] instruction-text">Tap the correct answer</div> */}
                  <Stepper
                    steps={[1, 2, 3]}
                    currentStep={
                      !isSubmitted
                        ? quizData[selectedQuizQuestionIterationIndex]?.recall_status
                        : quizData[selectedQuizQuestionIterationIndex]?.answers[selectedAnswerIndex]?.quiz_options_score === 1
                        ? quizData[selectedQuizQuestionIterationIndex]?.recall_status + 1
                        : 1
                    }
                  />{" "}
                  <Tooltip title="You need to answer this question correctly three times to master it. Keep going!" placement="top">
                    <img src={Info} height={20} width={20} className="ml-1" alt="Info Icon" />
                  </Tooltip>
                </div>
              )}

              <div id="optionButtons" className="mt-[0%]">
                {quizData[selectedQuizQuestionIterationIndex].answers.map((answer, index) => (
                  <button
                    disabled={isSubmitted}
                    style={{
                      border:
                        !isSubmitted && selectedAnswerIndex !== -1 && index === selectedAnswerIndex
                          ? "1.5px solid #7E418B"
                          : isSubmitted &&
                            selectedAnswerIndex === index &&
                            quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_score === 1
                          ? "1.5px solid #74DA7F"
                          : selectedAnswerIndex === index
                          ? "1.5px solid #EB6262"
                          : "",
                      lineHeight: "normal",
                    }}
                    className="option-buttons"
                    onClick={() => handleSelectedAnswer(index)}
                  >
                    {`${options[index].label}. ` + answer.quiz_option_text}{" "}
                    {isSubmitted &&
                      quizData[selectedQuizQuestionIterationIndex]?.recall_status &&
                      selectedAnswerIndex === index &&
                      quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_score === 1 && (
                        <img style={{ zIndex: "100" }} src={xps} className="absolute move-up-vanish  ml-[35%]" />
                      )}
                  </button>
                ))}
              </div>
              <div id="submit" className="mt-[6%]">
                <button
                  style={{ display: isSubmitted && "none" }}
                  disabled={selectedAnswerIndex === -1}
                  onClick={() => (isSubmitted ? handleIterationIndex() : handleSubmit())}
                  className={selectedAnswerIndex === -1 ? "disabled-submit-button" : "enabled-submit-button"}
                >
                  {"Submit"}
                </button>
              </div>
              {isSubmitted && (
                <div id="submit" className={`mt-[6%] ${quizType === 2 && "justify-between flex"}`}>
                  <button
                    style={{
                      display: quizType === 1 || selectedQuizQuestionIterationIndex === 0 ? "none" : "block",
                    }}
                    onClick={() => handleIterationIndex(-1)}
                    className={"enabled-submit-button !bg-[white] !text-[#7E418B] !border-[#7E418B] border-[1px]	"}
                  >
                    Back
                  </button>
                  {quizData[selectedQuizQuestionIterationIndex]?.recall_status && (
                    <button onClick={() => handleIterationIndex(1)} className={"enabled-submit-button"}>
                      {selectedQuizQuestionIterationIndex === quizData.length - 1 ? "View Result" : quizType === 1 ? "Continue" : "Next"}
                    </button>
                  )}
                  {!quizData[selectedQuizQuestionIterationIndex]?.recall_status && (
                    <button
                      onClick={() => (quizData.length - 1 === selectedQuizQuestionIterationIndex ? navigate(-1) : handleIterationIndex(1))}
                      className={"enabled-submit-button"}
                    >
                      {quizData.length - 1 === selectedQuizQuestionIterationIndex ? "Continue" : "Next"}
                    </button>
                  )}
                </div>
              )}
              {isSubmitted && (
                <div className="flex ">
                  <div
                    style={{
                      padding: "10px",
                      border:
                        quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_score === 0
                          ? "1.5px solid #EB6262"
                          : "1.5px solid #74DA7F",
                    }}
                    className="option-buttons flex space-x-2"
                  >
                    <img
                      style={{ objectFit: "contain", visibility: "hidden" }}
                      src={quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_score === 0 ? Close : Tick}
                      alt=""
                    />
                    <div className="feedback-text" style={{ lineHeight: "20px" }}>
                      {quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_score === 0
                        ? quizData[selectedQuizQuestionIterationIndex].quiz_incorrect_feedback
                        : quizData[selectedQuizQuestionIterationIndex].quiz_correct_feedback}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            quizCompleted && (
              <QuizResult
                quizScore={quizScore}
                recallStatusCount={recallStatusCount}
                quizTotalMarks={quizTotalMarks}
                submitQuiz={submitQuiz}
                quizType={quizType}
                quizMarksReview={quizMarksReview}
                nextQuiz_id={nextQuiz_id}
                nextQuiz={nextQuiz}
                handleSelectedIndex={handleSelectedIndex}
                currentIndex={currentIndex}
                handleClickQuizReview={handleClickQuizReview}
                sidebarData={sidebarData}
                setNextQuiz={setNextQuiz}
                handleLessonClick={handleLessonClick}
                setOpenAccordian={setOpenAccordian}
                setOpenSubtopicAccordian={setOpenSubtopicAccordian}
                setFirstIteration={setFirstIteration}
                state={state}
                quizOrLesson={quizOrLesson}
                handleQuizClick={handleQuizClick}
                getSidebarData={getSidebarData}
                isQuizSubmitted={isQuizSubmitted}
                selectedTopicId={selectedTopicId}
                selectedSubTopicId={selectedSubTopicId}
                quizData={quizData}
                mastery={mastery}
                oldXp={oldXp}
              />
            )
          )}
        </div>
      </div>
      {isSubmitted &&
        quizData[selectedQuizQuestionIterationIndex].answers[selectedAnswerIndex].quiz_options_score === 1 &&
        quizData[selectedQuizQuestionIterationIndex]?.recall_status === 3 &&
        isAnimation && (
          <div className="fixed inset-0 flex items-center justify-center z-50" style={{ backgroundColor: "rgba(0, 0, 0, 0.42)" }}>
            <Lottie animationData={celebration} style={{ height: 500, width: 500 }} loop={true} />
          </div>
        )}
    </>
  );
}

export default QuizCard;
