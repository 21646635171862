import React from "react";
import Success from "../../../images/success.png";
import Failure from "../../../images/failed.png";

function PromoCodeStatus(props) {
  return (
    <div>
      <div className="flex mt-2 gap-1">
        <img
          src={
            props.promocodeValidationStatus.redeemed_status === 0
              ? Success
              : Failure
          }
          alt=""
        />
        <span
          style={{
            fontFamily: "Roboto",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "18px",
            color: "#47434D",
          }}
        >
          {props.promocodeValidationStatus.redeemed_status === 0
            ? "Promo Code Applied!"
            : props.promocodeValidationStatus.redeemed_status === 1
            ? "You have already availed this promo code!"
            : "The promo code is invalid. Please try again."}
        </span>
      </div>
      {props.saveAmount && (
        <p
          className="ml-6"
          style={{
            display:
              props.promocodeValidationStatus.redeemed_status !== 0 && "none",
            fontFamily: "Roboto",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "16px",
            color: "#47434D",
          }}
        >
          {"You save £ " + props.saveAmount.toFixed(2)}
        </p>
      )}
    </div>
  );
}

export default PromoCodeStatus;
