import React from "react";
import SubtopicAnalysisWidget from "./SubtopicAnalysisWidget";

function ProgressAnalysisCard({ data, percentage, value }) {
  return (
    <div className="grid md:grid-cols-2 sm:grid-cols-1  gap-2 w-[100%]">
      {data.map((data, forSingleSubtopicIndex) => (
        <>
          {percentage[forSingleSubtopicIndex] !== undefined && (
            <div
              className="  mobile-border-radius border-[1px] border-[#79747E] p-[20px] pt-[30px] sm:pl-[10px] md:pl-[20px] bg-[#FEF7FF]"
              style={
                forSingleSubtopicIndex % 2 == 0
                  ? {
                      borderTopLeftRadius: "12px",
                      borderBottomLeftRadius: "12px",
                    }
                  : {
                      borderTopRightRadius: "12px",
                      borderBottomRightRadius: "12px",
                    }
              }
            >
              <div className="progress_analytics_parent_heading">
                {value === 1 ? data.topic_name : data.subtopic_name}
              </div>
              <SubtopicAnalysisWidget
                value={value}
                data={data}
                percentage={percentage}
                forSingleSubtopicIndex={forSingleSubtopicIndex}
              />
            </div>
          )}
        </>
      ))}
    </div>
  );
}

export default ProgressAnalysisCard;
