import React, { useState } from "react";

const TeacherDetailsDialogue = ({ onSubmitTeacherDetailsDialog, isSubmit }) => {
  const [teacherEmail, setTeacherEmail] = useState("");
  const handleSubmit = (e) => {
    console.log(e);
    e.preventDefault();
    onSubmitTeacherDetailsDialog(teacherEmail);
  };

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
      <div className="w-full max-w-lg bg-white rounded-lg shadow-lg">
        {/* Header */}
        <div className="p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-2">You're One Step Away!</h2>
          <p className="text-sm text-gray-600">We’ll send you a copy of AI evaluations and instant marking results.</p>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} className="p-6 pt-0 space-y-4">
          <div className="space-y-4">
            {/* Name Input */}
            {/* <div>
              <input
                disabled={isSubmit}
                onChange={(e) => setTeacherName(e.target.value)}
                type="text"
                placeholder="Enter your Name"
                className="w-full px-3 py-2.5 bg-gray-100 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-600 placeholder:text-gray-500"
                required
              />
            </div> */}

            {/* Email Input */}
            <div>
              <input
                disabled={isSubmit}
                onChange={(e) => setTeacherEmail(e.target.value)}
                type="email"
                placeholder="Enter your email address to receive a copy of the report."
                className="w-full px-3 py-2.5 bg-gray-100 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-600 placeholder:text-gray-500"
                required
              />
            </div>

            {/* Quiz Name Input */}
            {/* <div>
              <input
                disabled={isSubmit}
                onChange={(e) => setQuizName(e.target.value)}
                type="text"
                placeholder="Name this practice exam "
                className="w-full px-3 py-2.5 bg-gray-100 border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 text-gray-600 placeholder:text-gray-500"
                required
              />
            </div> */}
          </div>

          {/* Submit Button */}
          <button
            disabled={isSubmit}
            type="submit"
            className={`w-full mt-6 px-4 py-2 ${
              teacherEmail.length > 0 ? "bg-[#6750A4] text-white" : "bg-gray-200 text-gray-700"
            } rounded-md transition-colors duration-200`}
          >
            {isSubmit && (
              <div className="spinner-border spinner-border-m float-left !w-[24px] !h-[24px]" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
            Show My AI Marked Results Now!{" "}
          </button>
        </form>
        <div className="max-w-[100rem] mx-auto px-6 mb-3">
          <p className="text-sm text-gray-400 mb-0 text-center">
            Powered by
            <a href="https://teepee.ai" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600 ml-1">
              Teepee.ai
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TeacherDetailsDialogue;
