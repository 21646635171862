import React, { useEffect, useState } from "react";
import Sidebar from "../../Sidebar";
import { FormControl, Select } from "@material-ui/core";
import axios from "axios";
function TrialAndSubscriptionConfigure() {
  const [subjectData, setSubjectsData] = useState([]);
  const [time, setTime] = useState({});

  const handleChange = (e, subject) => {
    const newUnit = e.target.value;
    const updatedSubjects = subjectData.map((subject) => {
      if (subject.subject_id === subject.subject_id) {
        return { ...subject, trial_unit: newUnit };
      }
      return subject;
    });
    setSubjectsData(updatedSubjects);

    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/update_trial_period_status",
        {
          method: "POST",
          subject_id: subject.subject_id,
          trial_period: subject.trial_period,
          trial_unit: parseInt(e.target.value),
          trial_duration: subject.trial_duration,
          subscription_duration: null,
          subscription_unit: null,
        }
      )

      .then((response) => {
        getSubjectsList();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to update subject status");
      });
  };
  const selectFieldStyles = {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid",
      borderColor: "black",
      // Change the border color to your desired focus color
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#79747e", // Change the normal border color here
    },
    "& .MuiSelect-select.MuiSelect-select": {
      marginLeft: "12px",

      color: "#474747", // Change the color of the selected option(s)
      fontSize: "18px",
      fontWeight: "500",
      fontFamily:
        'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"', // Change the font size of the selected option(s)
    },
  };

  useEffect(() => {
    getSubjectsList();
  }, []);

  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log(res.data);
        setSubjectsData(res.data);
      });
  };

  const updateTrialPeriodStatus = (status, subject) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/update_trial_period_status",
        {
          method: "POST",
          subject_id: subject.subject_id,
          trial_period: status,
          trial_unit: subject.trial_unit,
          trial_duration: subject.trial_duration,
          subscription_duration: null,
          subscription_unit: null,
        }
      )

      .then((response) => {
        alert("Subject Status changed");
        getSubjectsList();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to update subject status");
      });
  };

  const updateTrialPeriodDuration = (subject) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/update_trial_period_status",
        {
          method: "POST",
          subject_id: subject.subject_id,
          trial_period: subject.trial_period,
          trial_unit: subject.trial_unit,
          trial_duration: subject.trial_duration,
          subscription_duration: null,
          subscription_unit: null,
        }
      )

      .then((response) => {
        alert("Subject Status changed");
        getSubjectsList();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to update subject status");
      });
  };

  const handleChangeTimeDuration = (e, subject_id) => {
    const newDuration = e.target.value; // Get the new value from the input box
    const updatedSubjects = subjectData.map((subject) => {
      if (subject.subject_id === subject_id) {
        return { ...subject, trial_duration: newDuration };
      }
      return subject;
    });
    setSubjectsData(updatedSubjects); // Update the 'subjectData' state
  };

  return (
    <div>
      <Sidebar item_id="configure" />

      <div className="sm:ml-0 md:ml-[350px] mb-[40px] mr-[1%]">
        <div>
          {" "}
          <label className="dashboard-label  mb-[12px]">
            Trial setting <div className="teepee-divider"></div>
          </label>
        </div>
        <table
          class="w-full text-sm text-left text-white dark:text-white "
          className="tracking_table w-[100%] "
          id="tbl"
        >
          <thead class="text-xs text-black dark:bg-white dark:text-white ">
            <tr className="tracking_table">
              <th
                scope="col"
                class="px-6 py-3 tracking_table spth bg-[#F7F2F9]"
              >
                ID
              </th>
              <th
                scope="col"
                class="px-6 py-3 tracking_table spth bg-[#F7F2F9]"
              >
                Subject Name
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%]"
              >
                Trial Duration
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%]"
              >
                Unit
              </th>
              <th
                scope="col"
                class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%]"
              >
                Trial Status
              </th>
            </tr>
          </thead>
          <tbody>
            {subjectData !== undefined &&
              subjectData.map((stats, index) => (
                <tr
                  key={index}
                  class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <td
                    class="px-6 py-4  tracking_table_td  bg-[#F7F2F9]"
                    style={{ border: "1px solid rgba(73, 69, 79, 0.4)" }}
                  >
                    {index + 1}
                  </td>
                  <td
                    class="px-6 py-4  tracking_table_td  bg-[#F7F2F9]"
                    style={{ border: "1px solid rgba(73, 69, 79, 0.4)" }}
                  >
                    {stats.subject_name}
                  </td>
                  <td
                    className="px-6 py-4 tracking_table_td"
                    style={{ border: "1px solid rgba(73, 69, 79, 0.4)" }}
                  >
                    <input
                      type="text"
                      name="daysInput"
                      placeholder="Enter Time Duration"
                      value={stats.trial_duration}
                      onChange={(e) =>
                        handleChangeTimeDuration(e, stats.subject_id)
                      }
                      style={{
                        marginRight: "10px",
                        border: "1px solid black", // This adds a light grey border
                        padding: "8px", // Optional: Adds some padding inside the input box for better text visibility
                        borderRadius: "4px", // Optional: Rounds the corners of the input box
                      }}
                    />

                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={() => updateTrialPeriodDuration(stats)}
                    >
                      Submit
                    </button>
                  </td>
                  <td
                    class="px-6 py-4  tracking_table_td"
                    style={{ border: "1px solid rgba(73, 69, 79, 0.4)" }}
                  >
                    <FormControl sx={selectFieldStyles} className="w-[100px]">
                      <Select
                        native
                        value={stats.trial_unit}
                        onChange={(event) => handleChange(event, stats)}
                        label={stats.trial_unit === 0 ? "Minutes" : "Days"}
                        inputProps={{
                          name: `time-${stats.subject_id}`,
                          id: `time-select-${stats.subject_id}`,
                        }}
                        style={{
                          background: "white",
                          borderRadius: 4,
                          borderColor: "rgba(73, 69, 79, 0.4)",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          borderWidth: 1,
                          borderStyle: "solid",
                          boxShadow: "0px 3px 5px rgba(100, 100, 100, 0.2)",
                          fontFamily: "Roboto",
                          fontWeight: "600",
                          color: "#6750A4",
                          "&:focus": {
                            borderRadius: 4,
                            borderColor: "rgba(73, 69, 79, 0.4)",
                            boxShadow:
                              "0px 0px 0px 0.2rem rgba(116, 79, 149, 0.25)",
                          },
                        }}
                      >
                        <option value={0} className="text-center">
                          Minutes
                        </option>
                        <option value={1} className="text-center">
                          Days
                        </option>
                        {/* ...other options... */}
                      </Select>
                    </FormControl>
                  </td>
                  <td class="px-6 py-4  tracking_table_td">
                    {stats.trial_period === 0 ? (
                      <button
                        onClick={() => updateTrialPeriodStatus(1, stats)}
                        className="btn w-50"
                        style={{ backgroundColor: "#62C370", color: "white" }}
                      >
                        Activate
                      </button>
                    ) : (
                      <button
                        onClick={() => updateTrialPeriodStatus(0, stats)}
                        className="btn btn-danger w-50"
                      >
                        De-activate
                      </button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TrialAndSubscriptionConfigure;
