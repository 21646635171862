import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import subject_icon from "../../../images/subject_icon.svg";
import Sidebar from "../../Sidebar";
import StudentMasteryLevelTabBody from "./StudentMasteryLevelTabBody";
const StudentMasteryLevel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div>
      <Sidebar />
      <div className="home sm:ml[25px]   h-[150px] justify-evenly">
        <div className="classroom_name_div sm:w-[50%] md:w-[40%] mt-[3%]  !overflow-visible">
          {}{" "}
          <div className="home sm:ml[25px]">
            <button className=" flex">
              <span className="teacher_navigation_text" onClick={() => navigate("/student-dashboard")}>
                Home
              </span>{" "}
              <span className="ml-1 mr-1 text-decoration-none teacher_navigation_text" style={{ textDecoration: "none !important" }}>
                {">"}
              </span>{" "}
              <span onClick={() => navigate(-1)} className="teacher_navigation_text">
                {location.state !== undefined && location.state?.classroom_name}
              </span>
            </button>
            <div style={{ visibility: "hidden", height: "10px" }}>dasdasd dasd </div>
          </div>
          <div className="classroom_details_label flex block mt-2 ">
            <img src={subject_icon} className="mr-1" alt=" " />
            <span className="text-display-h3">{location.state !== undefined && location.state?.subjectName}</span>
          </div>
        </div>
        <div>
          <label className="classroom_details_label"></label>
        </div>

        <div className="flex mb-[13px]">
          {/* <div className="mt-[auto] mb-[12px] leave-classroom">
          <button onClick={() => handleOpenLeaveClassroomDialogue()}>Leave Classroom</button>
        </div> */}
        </div>
      </div>
      <div className="w-[70%] mx-auto">
        <label className="dashboard-label" style={{ fontSize: "16px" }}>
          Subject Mastery Levels <div className="teepee-divider"></div>
        </label>
        <StudentMasteryLevelTabBody masteryLevels={location.state?.masteryLevels} />
      </div>
    </div>
  );
};

export default StudentMasteryLevel;
