import React, { useRef, useState, useEffect } from "react";
import Sidebar from "./Sidebar";
// import { useNavigate } from "react-router-dom";
import axios from "axios";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

// import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import ReactSwitch from "react-switch";
function SubjectPrompt(props) {
  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const subjectData = props.subject_data;
  const options = ["gpt-4o-mini", "gpt-3.5-turbo-16k-0613", "gpt-4"];

  let defaultOptionGeneration = subjectData.generation_model;
  let defaultOptionEvaluation = subjectData.evaluation_model;
  useEffect(() => {
    document.getElementById("generation_identity").value = subjectData.generation_identity;
    document.getElementById("generation_example").value = subjectData.generation_example;
    document.getElementById("generation_query").value = subjectData.generation_query;
    document.getElementById("evaluation_identity").value = subjectData.evaluation_identity;
    document.getElementById("evaluation_example").value = subjectData.evaluation_example;
    document.getElementById("evaluation_query").value = subjectData.evaluation_query;
  }, [subjectData]);

  const isSubmitting = useRef(false);
  function submitForm() {
    var token = "Bearer " + localStorage.getItem("access_token");
    isSubmitting.current = true;
    subjectData.evaluation_model = defaultOptionEvaluation;
    subjectData.evaluation_identity = document.getElementById("evaluation_identity").value;
    subjectData.evaluation_example = document.getElementById("evaluation_example").value;
    subjectData.evaluation_query = document.getElementById("evaluation_query").value;

    subjectData.generation_model = defaultOptionGeneration;
    subjectData.generation_identity = document.getElementById("generation_identity").value;
    subjectData.generation_example = document.getElementById("generation_example").value;
    subjectData.generation_query = document.getElementById("generation_query").value;

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/submit_subject_for_formative_assessment_prompt_update", {
        method: "POST",
        subject: subjectData,
      })

      .then((response) => {
        console.log(response);
        isSubmitting.current = false;
        props.getSubjectDetails(subjectData.subject_id);
        setShow(true);
      })
      .catch((error) => {
        console.log(error);
        isSubmitting.current = false;
      });
  }

  const handleCloseWithCancel = () => {
    setOpen(false);
    isSubmitting.current = false;
  };

  const handleCloseWithYes = () => {
    console.log("VALUE IN THE handleCloseWithYes FUNCTION 2: ", defaultOptionEvaluation, defaultOptionGeneration);
    setOpen(false);
    submitForm();
  };

  const handleSubmitButtonClick = (event) => {
    console.log("VALUE IN THE handleSubmitButtonClick FUNCTION: ", defaultOptionEvaluation, defaultOptionGeneration);
    var evaluation_model = defaultOptionEvaluation;
    var evaluation_identity = document.getElementById("evaluation_identity").value;
    var evaluation_example = document.getElementById("evaluation_example").value;
    var evaluation_query = document.getElementById("evaluation_query").value;

    if (evaluation_model.length <= 0 || evaluation_identity.length <= 0 || evaluation_example.length <= 0 || evaluation_query.length <= 0) {
      alert("Cannot submit with empty field");
      return;
    }

    var generation_model = defaultOptionGeneration;
    var generation_identity = document.getElementById("generation_identity").value;
    var generation_example = document.getElementById("generation_example").value;
    var generation_query = document.getElementById("generation_query").value;

    if (generation_model.length <= 0 || generation_identity.length <= 0 || generation_example.length <= 0 || generation_query.length <= 0) {
      alert("Cannot submit with empty field");
      return;
    }
    submitForm();
    // setOpen(true);
    console.log("VALUE IN THE handleSubmitButtonClick FUNCTION 2: ", defaultOptionEvaluation, defaultOptionGeneration);
  };

  const handleChange = (value) => {
    subjectData.formative_quiz_type_status = value ? 1 : 0;
    submitForm();
  };
  const onChangeGenerationModel = (e) => {
    defaultOptionGeneration = e.value;
  };
  const onChangeEvaluationModel = (e) => {
    defaultOptionEvaluation = e.value;
  };

  return (
    <>
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast style={{ backgroundColor: "#f7f2f9" }} onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
            <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
            <strong className="me-auto">We're on it!</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>Prompt data updated successfully</Toast.Body>
        </Toast>
      </ToastContainer>
      <Dialog open={open} onClose={handleCloseWithCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <b>Confirm</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to update prompt data?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>NO</Button>
          <Button className="delete-button" onClick={handleCloseWithYes} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <div>
        <div className=" flex-row justify-left items-center pl-[0px] pr-[0px] mt-[20px]">
          <div className=""></div>
          <div className="selectionDiv mb-[100px]">
            <div>
              <ReactSwitch className="float-right" checked={subjectData.formative_quiz_type_status} onChange={(value) => handleChange(value)} />
              <p
                className="mt-[2px] float-right mr-[20px]"
                style={{
                  fontSize: "16px",
                  fontFamily: "Roboto",
                  color: "#49454F",
                }}
              >
                <b>Enable Formative Quiz Type</b>
              </p>

              <div className="w-full">
                <div>
                  <p
                    className="mt-[10px] mb-[5px]"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                    }}
                  >
                    <b>Generation Model: </b>
                  </p>
                </div>
                <div>
                  {/* <input
                    type="text"
                    name="generation_model"
                    disabled
                    value="gpt-3.5-turbo"
                    defaultValue={"gpt-3.5-turbo"}
                    required={true}
                    className="p-2.5 shadow-sm outline-none rounded-[5px] border-[1px] border-[#474747] w-full h-[50px] text-[#474747] leading-[30px] text-[16px] font-[400] px-4 bg-[#efeded]"
                    id="generation_model"
                    placeholder=""
                  /> */}
                </div>
                <Dropdown
                  controlClassName="!p-2.5 !shadow-sm !outline-none !rounded-[5px] !border-[1px] !border-[#474747] w-full h-[50px] !text-[#474747] !leading-[30px] !text-[16px] !font-[400] !px-4 !bg-[#efeded]"
                  options={options}
                  onChange={onChangeGenerationModel}
                  value={defaultOptionGeneration}
                  placeholder="Select an option"
                />
                <div>
                  <p
                    className="mt-[10px] mb-[5px]"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                    }}
                  >
                    <b>Generation Identity: </b>
                  </p>
                </div>
                <div>
                  <textarea
                    type="text"
                    name="generation_identity"
                    required={true}
                    id="generation_identity"
                    className="teepee-prompt-textarea"
                    placeholder=""
                  />
                </div>
                <div>
                  <p
                    className="mt-[10px] mb-[5px]"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                    }}
                  >
                    <b>Generation Example: </b>
                  </p>
                </div>
                <div>
                  <textarea
                    type="text"
                    name="generation_example"
                    required={true}
                    id="generation_example"
                    className="teepee-prompt-textarea"
                    placeholder=""
                  />
                </div>
                <div>
                  <p
                    className="mt-[10px] mb-[5px]"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                    }}
                  >
                    <b>Generation Query: </b>
                  </p>
                </div>
                <div>
                  <textarea
                    type="text"
                    name="generation_query"
                    required={true}
                    id="generation_query"
                    className="teepee-prompt-textarea"
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            <div className="mt-[30px] mb-[30px]">
              <div className="w-[100%]">
                <div>
                  <p
                    className="mt-[10px] mb-[5px]"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                    }}
                  >
                    <b>Evaluation Model: </b>
                  </p>
                </div>
                <div>
                  {/* <input
                    type="text"
                    name="evaluation_model"
                    disabled
                    value="gpt-3.5-turbo"
                    required={true}
                    className="p-2.5 bg-[#efeded] shadow-sm outline-none rounded-[5px] border-[1px] border-[#474747] w-full h-[50px] text-[#474747] leading-[30px] text-[16px] font-[400] px-4"
                    id="evaluation_model"
                    placeholder=""
                  /> */}
                </div>
                <Dropdown
                  controlClassName="!p-2.5 !shadow-sm !outline-none !rounded-[5px] !border-[1px] !border-[#474747] w-full h-[50px] !text-[#474747] !leading-[30px] !text-[16px] !font-[400] !px-4 !bg-[#efeded]"
                  options={options}
                  onChange={onChangeEvaluationModel}
                  value={defaultOptionEvaluation}
                  placeholder="Select an option"
                />

                <div>
                  <p
                    className="mt-[10px] mb-[5px]"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                    }}
                  >
                    <b>Evaluation Identity: </b>
                  </p>
                </div>
                <div>
                  <textarea
                    type="text"
                    name="evaluation_identity"
                    required={true}
                    id="evaluation_identity"
                    className="teepee-prompt-textarea"
                    placeholder=""
                  />
                </div>

                <div>
                  <p
                    className="mt-[10px] mb-[5px]"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                    }}
                  >
                    <b>Evaluation Example: </b>
                  </p>
                </div>
                <div>
                  <textarea
                    type="text"
                    name="evaluation_example"
                    required={true}
                    id="evaluation_example"
                    className="teepee-prompt-textarea"
                    placeholder=""
                  />
                </div>

                <div>
                  <p
                    className="mt-[10px] mb-[5px]"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                    }}
                  >
                    <b>Evaluation Query: </b>
                  </p>
                </div>
                <div>
                  <textarea
                    type="text"
                    name="evaluation_query"
                    required={true}
                    id="evaluation_query"
                    className="teepee-prompt-textarea"
                    placeholder=""
                  />
                </div>

                <div className="sm:mb-[20px] md:mb-[10px]">
                  <button
                    onClick={handleSubmitButtonClick}
                    id="submit_button"
                    className="teepee-button hover:bg-black sm:w-full md:w-[46%]"
                    // style={{ width: "46%" }}
                  >
                    {isSubmitting.current && (
                      <div className="spinner-border spinner-border-m float-left" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubjectPrompt;
