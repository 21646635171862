import React, { useState } from "react";
import LoginOptions from "../LoginOptions";
import { useRef } from "react";
import axios from "axios";

function LoginWithStudent(props) {
  const [loginOptions, setLoginOptions] = useState(false);
  let classCode = useRef();
  const handleClickContinue = () => {
    if (classCode.current.length <= 0) {
      alert("Enter the class code");
      return;
    }
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/check_classroom_code", {
        method: "POST",
        class_code: classCode.current,
      })
      .then((res) => {
        console.log("classroom : ", res.data);
        if (typeof res.data === "number") {
          setLoginOptions(true);
          localStorage.setItem("class_code", classCode.current);
        } else {
          alert(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickExistingAccount = () => {
    setLoginOptions(true);
  };
  const [code, setCode] = useState(["", "", "", "", "", "", "", ""]);
  const inputRefs = [];

  const handleChange = (index, event) => {
    const input = event.target.value;
    if (input.length <= 1) {
      const newCode = [...code];
      newCode[index] = input;
      setCode(newCode);
      if (index < inputRefs.length - 1 && input.length === 1) {
        inputRefs[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && code[index] === "" && index > 0) {
      const newCode = [...code];
      newCode[index - 1] = "";
      setCode(newCode);
      inputRefs[index - 1].focus();
    }
  };
  const handlePaste = (index, event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text").trim();

    if (pastedText.length <= 8 - index) {
      const newCode = [...code];
      for (let i = 0; i < pastedText.length; i++) {
        newCode[index + i] = pastedText[i];
      }
      setCode(newCode);

      // Move focus to the next empty input field
      for (let i = index + pastedText.length; i < inputRefs.length; i++) {
        if (newCode[i] === "") {
          inputRefs[i].focus();
          break;
        }
      }
    }
  };

  classCode.current = code.join("");

  return (
    <div>
      {!loginOptions ? (
        <div>
          {" "}
          <div className="enter_classcode">Enter your class code</div>
          <div className="code_instruction mt-[8px]">
            Please enter the code provided by the teacher
          </div>
          <div>
            {" "}
            <div className=" mt-[24px]">
              <center>
                <div className="code_div pt-[25px] pl-[2px] w-[66%]">
                  {code.map((digit, index) => (
                    <input
                      key={index}
                      ref={(ref) => (inputRefs[index] = ref)}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(event) => handleChange(index, event)}
                      onKeyDown={(event) => handleKeyDown(index, event)}
                      onPaste={(event) => handlePaste(index, event)}
                      style={{
                        outline: "none",
                        width: "10%",
                        marginRight: "5px",
                        textAlign: "center",
                        borderBottom: "1px solid black",
                      }}
                    />
                  ))}
                </div>
              </center>
            </div>
            <div>
              <div className="flex justify-center mt-[24px]">
                <button
                  className="teepee-button float-none w-[66%] h-[20%]"
                  onClick={handleClickContinue}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
          <div className="already_account mt-[8px]">
            <button onClick={handleClickExistingAccount}>
              Already have an account?
            </button>
          </div>
        </div>
      ) : (
        <LoginOptions
          classCode={classCode.current}
          user_type={props.user_type}
        />
      )}
    </div>
  );
}

export default LoginWithStudent;
