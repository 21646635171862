import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgress = ({ StudentParticipationRate, color, label, isSize }) => {
  return (
    <div
      style={{
        display: isSize ? "flex" : "block",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "relative",
      }}
    >
      <div
        style={{
          width: isSize ? "50px" : "180px",
          height: isSize ? "50px" : "180px",
          position: "relative",
        }}
      >
        <CircularProgressbar
          value={parseInt(StudentParticipationRate)}
          styles={buildStyles({
            textColor: "#333",
            pathColor: color,
            trailColor: parseInt(StudentParticipationRate) === 0 ? "#FAAD98" : "#f0f0f0",
            textSize: "10px",
          })}
        />
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            fontSize: isSize ? "9px" : "16px",
            color: "#333",
            fontWeight: "600",
          }}
        >
          <div>{parseInt(StudentParticipationRate)}%</div>
          {!isSize && (
            <div
              style={{
                fontSize: "12px",
                color: "#aaa",
                lineHeight: "1.2",
                marginTop: "4px",
              }}
            >
              {label}
            </div>
          )}
        </div>
      </div>
      {isSize && (
        <div
          style={{
            marginLeft: "8px",
            textAlign: "left",
            fontSize: "10px",
            color: "#aaa",
            lineHeight: "1.5",
          }}
        >
          {label}
        </div>
      )}
    </div>
  );
};

export default CircularProgress;
