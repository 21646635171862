import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
const TopicSelector = ({ selectedTopic, setSelectedTopic }) => {
  const selectFieldStyles = {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid",
      borderColor: "black",
      // Change the border color to your desired focus color
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#79747e", // Change the normal border color here
    },
    "& .MuiSelect-select.MuiSelect-select": {
      lineHeight: "0.44em",
      height: "5px",
      minHeight: "5px",
      marginLeft: "13px",
      color: "#474747", // Change the color of the selected option(s)
      fontSize: "12px",
      fontWeight: "399",
      fontFamily:
        'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"', // Change the font size of the selected option(s)
    },
  };
  const options = [
    { value: "topic", label: "Topic" },
    { value: "subtopic", label: "Subtopic" },
  ];

  return (
    <div className="mt-6 flex justify-end">
      <FormControl
        sx={selectFieldStyles}
        className=" float-right "
        style={{ marginTop: "10px" }}
      >
        <InputLabel
          shrink={false}
          id="demo-simple-select-label"
          style={{ marginBottom: "10px" }}
        ></InputLabel>
        <Select
          name="typeFilter"
          defaultValue="topic"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          // value={typeFilter}
          // onChange={onChangeFilter}
          value={selectedTopic}
          onChange={(e) => setSelectedTopic(e.target.value)}
        >
          <MenuItem value="topic">Topic</MenuItem>
          <MenuItem value="subtopic">Subtopic</MenuItem>
        </Select>
      </FormControl>
      {/* <select
        className="border border-gray-300 rounded-md pt-2 pb-2"
        value={selectedTopic}
        onChange={(e) => setSelectedTopic(e.target.value)}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select> */}
    </div>
  );
};

export default TopicSelector;
