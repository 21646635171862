import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import axios from "axios";
import StudentClassroomsCard from "./widget/StudentClassroomsCard";
import ClassroomIcon from "../../images/Classroom-icon-student.png";
import appContext from "../../context/appContext";
function StudentDashbaord() {
  const state = useContext(appContext);
  return (
    <div>
      <Sidebar />

      <div className=" sm:ml-0 md:ml-[250px] mb-[40px] mt-[2%]">
        <div className="flex pl-[2%]">
          <div className="flex items-center">
            <img src={ClassroomIcon} />
          </div>
          <div className="classroom_heading ml-[1%]">Classrooms</div>
        </div>
        <StudentClassroomsCard state={state} />
      </div>
    </div>
  );
}

export default StudentDashbaord;
