import React from "react";
import EnrolledStudentIcon from "../../images/enrolled.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import BlueBubble from "../../images/blue bubbles.png";
import GreenBubble from "../../images/green bubbles.png";
import RedBubble from "../../images/red bubbles.png";
import { useNavigate } from "react-router-dom";

function TrialSubjectsCard({ subject, index, open, handleClose, handleOpen }) {
  const navigate = useNavigate();
  const handleColor = () => {
    if (subject.account_status === 1 || (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left > 0)) {
      return "subscribed-cards-type-1";
    } else if (
      subject.account_status === 2 ||
      subject.account_status === 4 ||
      (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left <= 0) ||
      (subject.subscription_days_left && subject.account_status === 5 && subject.subscription_days_left <= 0)
    ) {
      return "subscribed-cards-type-2";
    } else if (
      subject.account_status === 3 ||
      (!subject.subscription_days_left && subject.days_left > 0 && subject.account_status === 5) ||
      (subject.subscription_days_left && subject.account_status === 5 && subject.subscription_days_left > 0)
    ) {
      return "subscribed-cards-type-3";
    }
  };

  const handleSubscriptionType = () => {
    if (subject.account_status === 1 || (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left > 0)) {
      return "Trial";
    } else if (subject.account_status === 2) {
      return "Trial Expired";
    } else if (
      subject.account_status === 3 ||
      (subject.account_status === 5 && subject.subscription_days_left && subject.subscription_days_left > 0)
    ) {
      return "Subscribed";
    } else if (subject.account_status === 4 || (subject.account_status === 5 && subject.subscription_days_left <= 0)) {
      return "Subscription Ended";
    } else if (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left <= 0) {
      return "Trial Expired";
    } else if (subject.account_status === 5 && subject.days_left > 0) {
      return "Subscribed";
    }
  };

  const handleBubble = () => {
    if (subject.account_status === 1 || (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left > 0)) {
      return BlueBubble;
    } else if (
      subject.account_status === 2 ||
      subject.account_status === 4 ||
      (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left <= 0) ||
      (subject.subscription_days_left && subject.account_status === 5 && subject.subscription_days_left <= 0)
    ) {
      return RedBubble;
    } else if (
      subject.account_status === 3 ||
      (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left > 0) ||
      (subject.subscription_days_left && subject.account_status === 5 && subject.subscription_days_left > 0)
    ) {
      return GreenBubble;
    }
  };

  const handleTextColor = () => {
    if (subject.account_status === 1 || (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left > 0)) {
      return "#1B94B8";
    } else if (
      subject.account_status === 2 ||
      subject.account_status === 4 ||
      (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left <= 0) ||
      (subject.subscription_days_left && subject.account_status === 5 && subject.subscription_days_left <= 0)
    ) {
      return "#D82929";
    } else if (
      subject.account_status === 3 ||
      (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left > 0) ||
      (subject.subscription_days_left && subject.account_status === 5 && subject.subscription_days_left > 0)
    ) {
      return "#1CBD7F";
    }
  };

  const handleManageSubject = () => {
    navigate("/manage-subject", {
      state: {
        selectedSubject: subject,
      },
    });
  };

  const handleVouchers = () => {
    if (subject.account_status === 1) {
      return "Unlimited";
    } else if (subject.account_status === 2 || subject.account_status === 4) {
      return "0";
    } else if (subject.account_status === 3) {
      if (subject.account_status && subject.pending_voucher === 0) {
        return subject.vouchers_bought;
      } else if (subject.account_status && subject.pending_voucher > 0) {
        return subject.vouchers_bought + ` (PO pending for ${subject.pending_voucher} vouchers )`;
      } else {
        return "0";
      }
    } else if (subject.account_status === 5) {
      if (subject.subscription_days_left) {
        if (subject.account_status && subject.subscription_days_left > 0) {
          return subject.vouchers_bought + ` (PO pending for ${subject.pending_voucher} vouchers )`;
        } else if (subject.account_status && subject.subscription_days_left <= 0) {
          return "0 " + ` (PO pending for ${subject.pending_voucher} vouchers )`;
        }
      } else {
        if (subject.account_status === 5 && !subject.subscription_days_left && subject.days_left <= 0) {
          if (subject.subscription_end_date) {
            return `0 `;
          } else {
            return `0 (PO pending ${subject.pending_voucher} vouchers)`;
          }
        }
        return `Unlimited (PO pending ${subject.pending_voucher} vouchers)`;
      }
    }
  };
  const handleTotalNumberOfQuizzes = () => {
    if (localStorage.getItem("email_address") === process.env.REACT_APP_REQUESTED_USER && subject.subject_id === 10) {
      return 20;
    } else {
      return process.env.REACT_APP_TRIAL_QUIZ;
    }
  };

  const handleProgressBarTest = () => {
    if (
      (!subject.subscription_days_left && handleTotalNumberOfQuizzes() - subject.quiz_count === 0) ||
      subject.account_status === 2 ||
      subject.account_status === 4 ||
      (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left <= 0) ||
      (subject.subscription_days_left && subject.account_status === 5 && subject.subscription_days_left <= 0) ||
      subject.quiz_count_subscribed >= process.env.REACT_APP_SUBSCRIPTION_QUIZ
    ) {
      return "0";
    } else {
      if (subject.account_status === 1 || (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left > 0)) {
        return handleTotalNumberOfQuizzes() - subject.quiz_count;
      } else {
        return process.env.REACT_APP_SUBSCRIPTION_QUIZ - subject.quiz_count_subscribed;
      }
    }
  };
  const handleProgressBarValue = () => {
    if (
      (!subject.subscription_days_left && handleTotalNumberOfQuizzes() - subject.quiz_count === 0) ||
      subject.account_status === 2 ||
      subject.account_status === 4 ||
      (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left <= 0) ||
      (subject.subscription_days_left && subject.account_status === 5 && subject.subscription_days_left <= 0) ||
      subject.quiz_count_subscribed >= process.env.REACT_APP_SUBSCRIPTION_QUIZ
    ) {
      return 0;
    } else {
      if (subject.account_status === 1 || (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left > 0)) {
        return 100 - (subject.quiz_count * 100) / handleTotalNumberOfQuizzes();
      } else {
        return 100 - (subject.quiz_count_subscribed * 100) / process.env.REACT_APP_SUBSCRIPTION_QUIZ;
      }
    }
  };
  const handleBuyVoucherButtonState = () => {
    // Disable the button if account_status is 4
    if (subject.account_status === 4) {
      return true;
    }

    // Additionally disable the button if account_status is 5 and subscription_days_left is 0 or less
    if (subject.account_status === 5 && !subject.subscription_days_left && subject.days_left <= 0 && subject.subscription_end_date) {
      return true;
    }

    // Enable the button otherwise
  };

  const handleExpiration = () => {
    if (subject.account_status === 1 || (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left > 0)) {
      return `(ends ${subject.trial_end_date})`;
    } else if (
      subject.account_status === 2 ||
      subject.account_status === 4 ||
      (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left <= 0) ||
      (subject.subscription_days_left && subject.account_status === 5 && subject.subscription_days_left <= 0)
    ) {
      return "(Ended)";
    } else if (
      subject.account_status === 3 ||
      (!subject.subscription_days_left && subject.days_left > 0 && subject.account_status === 5) ||
      (subject.subscription_days_left && subject.account_status === 5 && subject.subscription_days_left > 0)
    ) {
      const date = new Date(subject.subscription_end_date);
      return `(ends ${
        date.getDate().toString().padStart(2, "0") + "-" + (date.getMonth() + 1).toString().padStart(2, "0") + "-" + date.getFullYear()
      })`;
    }
  };
  return (
    <div className={`${handleColor()} quiz-div w-full !rounded-xl p-4 relative top-0 !left-0 `}>
      <div className="flex justify-between">
        <div className="text-3xl font-medium text-white whitespace-nowrap overflow-hidden text-ellipsis">{subject.subject_name}</div>
        <div className="flex pl-5 pr-5 bg-white rounded-3xl">
          <div className="flex items-center mr-[5px]">
            <img className="w-[10px] max-w-fit	" src={EnrolledStudentIcon} />
          </div>
          <div className="text-[#777777] flex items-center">{subject.students}</div>
        </div>
      </div>
      <div className="mt-[7%] flex justify-between">
        <div className="">
          <div className="text-white flex">
            <div className="text-base whitespace-nowrap	">Status : </div>
            <div className="flex">
              <div className="flex ml-[5px] font-semibold	text-base ">
                {handleSubscriptionType()}
                <div className="text-xs flex font-normal items-center z-10 ml-2">{handleExpiration()}</div>
              </div>
            </div>
          </div>
          {/* <div className="flex  mt-[5%] text-white text-base">
            <div>Vouchers: </div>
            <div className="flex items-center ml-[2%] md:ml-2 mt-[2px] md:mt-[1px] z-10 text-[10.2px] md:text-[12px]">{handleVouchers()}</div>
          </div> */}
        </div>
        <div className="z-10">
          <div style={{ width: 60, marginLeft: "auto", marginRight: "auto" }}>
            <CircularProgressbar
              strokeWidth={12}
              counterClockwise={true}
              value={handleProgressBarValue()}
              text={handleProgressBarTest()}
              styles={buildStyles({
                strokeLinecap: "butt",
                textSize: "24px",
                pathColor: `white`,
                textColor: "white",
                trailColor: "#81C7D7",
              })}
            />
          </div>
          <div className="text-center text-[8px] text-white mt-[5px]">Tasks left</div>
        </div>
      </div>
      <div className="mt-[5%] flex justify-between">
        <button
          onClick={() => handleManageSubject()}
          className="pl-6 pr-6 pt-[10px] pb-[10px]  rounded border-white border-2 w-[48%] text-[13px] font-medium text-white bg-transparent z-10 opacity-90	"
        >
          Manage subject
        </button>
        <button
          disabled={handleBuyVoucherButtonState()}
          className={`pl-6 pr-6 pt-[10px] pb-[10px]  rounded border-white border-2 w-[48%] text-[13px] font-medium  bg-white z-10`}
          style={{ color: handleTextColor() }}
          onClick={() => handleOpen(subject)}
        >
          Upgrade
        </button>
      </div>
      <div
        className={`absolute bottom-0 ${
          (subject.account_status === 1 || (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left > 0)) && "left-[27%]"
        } `}
      >
        <img
          style={{
            width:
              subject.account_status === 1 || (!subject.subscription_days_left && subject.account_status === 5 && subject.days_left > 0)
                ? "284px"
                : "380px",
          }}
          src={handleBubble()}
        />
      </div>
    </div>
  );
}

export default TrialSubjectsCard;
