import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import EditPredifinedQuestions from "./EditPredefinedQuestions";
import Typography from "@mui/material/Typography";
import SubAccordions from "./SubAccordian";
import EdiText from "react-editext";
import Button from "@mui/material/Button";
import axios from "axios";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "../images/edit.png";
import ReactQuill from "react-quill";
import Tick from "../images/tick.png";
import Cancel from "../images/cancel.png";
import YesNoDialog from "../Componenets/YesNoDialog";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
//   flexDirection: "row-reverse",
//   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//     transform: "rotate(90deg)",
//   },
//   "& .MuiAccordionSummary-content": {
//     marginLeft: theme.spacing(1),
//   },
// }));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

var modules = {
  toolbar: [
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
    ],
    [
      {
        color: [
          "#000000",
          "#e60000",
          "#ff9900",
          "#ffff00",
          "#008a00",
          "#0066cc",
          "#9933ff",
          "#ffffff",
          "#facccc",
          "#ffebcc",
          "#ffffcc",
          "#cce8cc",
          "#cce0f5",
          "#ebd6ff",
          "#bbbbbb",
          "#f06666",
          "#ffc266",
          "#ffff66",
          "#66b966",
          "#66a3e0",
          "#c285ff",
          "#888888",
          "#a10000",
          "#b26b00",
          "#b2b200",
          "#006100",
          "#0047b2",
          "#6b24b2",
          "#444444",
          "#5c0000",
          "#663d00",
          "#666600",
          "#003700",
          "#002966",
          "#3d1466",
          "custom-color",
        ],
      },
    ],
  ],
};
var formats = [
  "header",
  "height",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "color",
  "bullet",
  "indent",
  "align",
  "size",
];

var editValue;
const handleProcedureContentChange = (content) => {
  editValue = content;
};
const onSaveChapter = (value, chapter) => {
  chapter.chapter_name = value;
  var token = "Bearer " + localStorage.getItem("access_token");
  axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  axios.defaults.headers.post["Content-Type"] =
    "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.post["authorization"] = token;
  axios
    .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_chapter", {
      method: "POST",
      chapter: chapter,
    })

    .then((response) => {
      // var subjects_list = JSON.parse(
      //   response.data["subject"]
      // );
      //console.log(subjects_list);
      // setSubjectData(subjects_list)
    })
    .catch((error) => {
      console.log(error);
      alert("unable to update subject");
    });
};

// const addNewTopic = (chapter, addTodo) => {
//   // alert(subject.subject_name);

//   var token = "Bearer " + localStorage.getItem("access_token");
//     axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
//     axios.defaults.headers.post["Content-Type"] =
//       "application/json;charset=utf-8";
//     axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
//     axios.defaults.headers.post["authorization"] = token;
//     axios
//       .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_topic", {
//         method: "POST",
//         chapter_id: chapter.chapter_id,
//       })

//       .then((response) => {
//         addTodo();
//       })
//       .catch((error) => {
//         console.log(error);
//         alert("unable to add new topic");
//       });

// }

export default function QuestionContextAccordian(props) {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [
    predefinedSelectedQuestionDeletionObject,
    setPredefinedSelectedQuestionDeletionObject,
  ] = useState();
  const questionContext = props.question_context;
  const [predefinedQuestionAnswerList, setPredefinedQuestionAnswerList] =
    React.useState(questionContext.predefined_question_answer_list);
  const [expand, setExpand] = React.useState(false);
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  const onSaveBtnClick = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/create_predefined_question",
        {
          method: "POST",
          question: "",
          answer: "",
          rubric: "",
          question_marks: 1,
          question_context_id: questionContext.question_context_id,
          sub_topic_id: null,
        }
      )

      .then((response) => {
        getPredefinedQuestionAnswerList();

        // alert("successfully inserted new question")
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onUpdateQuestionContextName = (value, questionContext, index, id) => {
    if (value === undefined) {
      document.getElementById(`questionC${id}`).style.display = "none";
      document.getElementById(`qc${id}`).style.display = "flex";
      return;
    }

    // chapter.chapter_name=value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
          "/update_question_context_name",
        {
          method: "POST",
          question_context_name: value,
          question_context_id: questionContext.question_context_id,
        }
      )

      .then((response) => {
        props.editMockExamQuestion(value, index, props.mockExamsListIndex);
        document.getElementById(`questionC${id}`).style.display = "none";
        document.getElementById(`qc${id}`).style.display = "flex";
      })
      .catch((error) => {
        console.log(error);
        alert("unable to update subject");
      });
  };

  const deleteQuestionContext = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/delete_question_context",
        {
          method: "POST",
          question_context_id: props.question_context.question_context_id,
        }
      )

      .then((response) => {
        props.addTodo();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPredefinedQuestionAnswerList = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
          "/get_predefined_questions_list_for_admin",
        {
          method: "POST",
          question_context_id: questionContext.question_context_id,
        }
      )

      .then((response) => {
        setPredefinedQuestionAnswerList(
          response.data.predefined_question_answer_list
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOpenEditor = (questionIndex) => {
    editValue = undefined;
    document.getElementById(`questionC${questionIndex}`).style.display = "flex";
    document.getElementById(`qc${questionIndex}`).style.display = "none";
  };

  const handleCloseEditor = (index) => {
    document.getElementById(`questionC${index}`).style.display = "none";
    document.getElementById(`qc${index}`).style.display = "flex";
  };

  const onDeleteClick = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseWithNo = () => {
    setOpenConfirmationDialog(false);
  };

  const handleCloseWithYes = () => {
    setOpenConfirmationDialog(false);
    deleteQuestionContext();
  };
  return (
    <div>
      <YesNoDialog
        handleCloseWithNo={handleCloseWithNo}
        handleCloseWithYes={handleCloseWithYes}
        open={openConfirmationDialog}
        title={"Confirm"}
        description={"Are you sure you want to delete this record?"}
      />
      <Accordion expanded={expand}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            onClick: toggleAcordion,
          }}
          style={{
            backgroundColor: "rgba(255, 255, 255, .05)",
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
            "& .MuiAccordionSummary-content": {
              marginLeft: 0,
            },
            padding: 0,
          }}
        >
          <Typography className="w-full">
            <p
              className="answer-paragraph mb-0"
              style={{
                fontFamily: "Roboto",
              }}
            >
              <div
                id={`qc${questionContext.question_context_id}`}
                className="flex mt-[2%]"
              >
                <div className="mr-[5%] w-[90%]">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: questionContext.question_context_name,
                    }}
                  ></div>
                </div>
                <div>
                  <button
                    className="p-[4px]"
                    style={{
                      border: "1px solid",
                      borderColor:
                        "rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)",
                    }}
                    onClick={() => {
                      handleOpenEditor(questionContext.question_context_id);
                    }}
                  >
                    <img src={EditIcon}></img>
                  </button>
                </div>
              </div>

              <div
                id={`questionC${questionContext.question_context_id}`}
                style={{ display: "none", width: "95%" }}
                className="mb-[10%] mt-[4%]"
              >
                <ReactQuill
                  id={`valueC${questionContext.question_context_id}`}
                  value={questionContext.question_context_name}
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  placeholder="write your content ...."
                  onChange={handleProcedureContentChange}
                  style={{ height: "220px", width: "100%" }}
                ></ReactQuill>
                <div className="self-center mt-[6%] ml-[2%]">
                  <button
                    onClick={() =>
                      onUpdateQuestionContextName(
                        editValue,
                        questionContext,
                        props.questioncontextIndex,
                        questionContext.question_context_id
                      )
                    }
                    className="p-[4px]"
                    style={{
                      border: "1px solid",
                      borderColor:
                        "rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)",
                    }}
                  >
                    <img src={Tick}></img>
                  </button>
                  <button
                    onClick={() =>
                      handleCloseEditor(
                        props.question_context.question_context_id
                      )
                    }
                    className="p-[4px]"
                    style={{
                      border: "1px solid",
                      borderColor:
                        "rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186)",
                    }}
                  >
                    <img src={Cancel}></img>
                  </button>
                </div>
              </div>

              {/* <EdiText
                className="w-full"
                type="textarea"
                inputProps={{
                  onChange: (e) => {
                    //   setCount(e.target.value.length)
                  },
                  className: "textarea",
                  placeholder: "Type your content here",
                  style: {
                    width: "100%",
                    height: "100%",
                    padding: "5px",
                  },
                  rows: 10,
                }}
                onSave={(value) => {
                  onUpdateQuestionContextName(value, questionContext);
                }}
                value={questionContext.question_context_name}
              /> */}
            </p>
          </Typography>

          <Button style={{ float: "right" }} onClick={onDeleteClick}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <EditPredifinedQuestions
              addTodoParent={props.addTodoParent}
              addTodo={props.addTodo}
              question_context={questionContext}
              getPredefinedQuestionAnswerList={getPredefinedQuestionAnswerList}
              predefinedQuestionAnswerList={predefinedQuestionAnswerList}
              mockExamsListIndex={props.mockExamsListIndex}
              subject={props.subject}
              predefinedQuestionsPromptList={
                props.predefinedQuestionsPromptList
              }
            />

            <Button
              className="delete-button"
              style={{ color: "#1976d2", padding: "6px 8px" }}
              onClick={() => onSaveBtnClick()}
            >
              Add Part
            </Button>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
