import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

function SubjectAnalysis() {
  const [subjectData, setSubjectData] = useState([]);
  const [mostUsedSubject, setMostUsedSubject] = useState([]);
  const [mostUsedSubjectClass, setMostUsedSubjectsClass] = useState([]);
  const [open, setOpen] = useState(false);
  const [startData, setStartDate] = useState(null);
  const [endData, setEndDate] = useState(null);

  const handleClose = () => {
    setStartDate(null);
    setEndDate(null);
    setOpen(false);
  };

  const handleConfirm = () => {
    if (startData === null || endData === null) {
      alert("please add both dates");
      return;
    }
    console.log("TAB VALUE : ", startData, endData);
    const year = startData.$y;
    const month = startData.$M + 1; // Adding 1 because months are 0-based
    const day = startData.$D;

    const year2 = endData.$y;
    const month2 = endData.$M + 1; // Adding 1 because months are 0-based
    const day2 = endData.$D;

    const startDate = `${year}-${month}-${day}`;
    const endDate = `${year2}-${month2}-${day2}`;

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/subject_key_matrices", {
        method: "POST",
        start_date: startDate,
        end_date: endDate,
      })

      .then((response) => {
        console.log(response);
        // setKeyMatricsData(response.data);
        handleClose();
        console.log(response);
        setSubjectData(response.data);
        setMostUsedSubject(response.data.overall_used_subjects);
        setMostUsedSubjectsClass(response.data.subjects_used_in_class);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectFieldStyles = {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid",
      borderColor: "black",
      // Change the border color to your desired focus color
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#79747e", // Change the normal border color here
    },
    "& .MuiSelect-select.MuiSelect-select": {
      marginLeft: "13px",
      color: "#474747", // Change the color of the selected option(s)
      fontSize: "18px",
      fontWeight: "399",
      fontFamily:
        'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"', // Change the font size of the selected option(s)
    },
  };
  useEffect(() => {
    getSubjectAnalysisDefault();
  }, []);
  const getSubjectAnalysisDefault = () => {
    var numberOfDaysToAdd = 7;
    let dateObj = new Date();
    dateObj.setDate(dateObj.getDate() - numberOfDaysToAdd);
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();

    let endDateObj = new Date();
    let month2 = endDateObj.getUTCMonth() + 1;
    let day2 = endDateObj.getUTCDate();
    let year2 = endDateObj.getUTCFullYear();

    const startDate = `${year}-${month}-${day}`;
    const endDate = `${year2}-${month2}-${day2}`;

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/subject_key_matrices", {
        method: "POST",
        start_date: startDate,
        end_date: endDate,
      })

      .then((response) => {
        console.log(response);
        setSubjectData(response.data);
        setMostUsedSubject(response.data.overall_used_subjects);
        setMostUsedSubjectsClass(response.data.subjects_used_in_class);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      <Sidebar item_id="subject-analysis" />
      <div className="sm:ml-0 md:ml-[350px] mb-[40px] ">
        <div>
          <div className="w-[15%] float-right mb-[10px] mr-[5%]">
            <FormControl sx={selectFieldStyles} className="w-[100%]">
              <InputLabel
                shrink={false}
                id="demo-simple-select-label"
                style={{ marginBottom: "10px" }}
              >
                Date
              </InputLabel>
              <Select
                open={open}
                onClose={handleClose}
                onOpen={() => setOpen(true)}
                style={{ height: "53px" }}
                className=""
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                name="chapter"
              >
                <div className="pr-[35px] pl-[35px] pt-[12px]">
                  <div>
                    <div className="mb-[8px]">Start Date</div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={startData}
                        onChange={(newValue) => setStartDate(newValue)}
                        format="YYYY-MM-DD"
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="pt-[8px]">End Date</div>
                  <div className="mt-[8px]">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={endData}
                        onChange={(newValue) => setEndDate(newValue)}
                        format="YYYY-MM-DD"
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="">
                    <button
                      className="dialogue-button-text mt-[30px]"
                      onClick={() => handleClose()}
                    >
                      Cancel
                    </button>
                    <button
                      className="teepee-button  mt-[20px] mb-[17px]"
                      onClick={() => handleConfirm()}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="mt-[6%]">
        <div className="sm:ml-0 md:ml-[350px] mb-[40px] mr-[1%]">
          <div className="flex justify-evenly">
            <div className="w-[30%] ">
              <div className="mb-[2%]">Top 10 most in demand subject</div>
              <table
                class="w-full text-sm text-left text-white dark:text-white"
                className="tracking_table w-[100%] "
                id="tbl"
              >
                <thead class="text-xs text-black dark:bg-white dark:text-white ">
                  <tr className="tracking_table">
                    <th
                      scope="col"
                      class="px-6 py-3 tracking_table spth bg-[#F7F2F9]"
                    >
                      Subject Name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%]"
                    >
                      Interest
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {subjectData.subject_demanded !== undefined &&
                    subjectData.subject_demanded.map((stats, index) => (
                      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td
                          class="px-6 py-4  tracking_table_td"
                          style={{ border: "1px solid rgba(73, 69, 79, 0.4)" }}
                        >
                          {stats.Subject_name}
                        </td>
                        <td class="px-6 py-4  tracking_table_td">
                          {stats.Frequency}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className="w-[30%] ">
              <div className="mb-[2%]">
                Top 10 most used subjects by teachers
              </div>

              <table
                class="w-full text-sm text-left text-white dark:text-white"
                className="tracking_table w-[100%] "
                id="tbl"
              >
                <thead class="text-xs text-black dark:bg-white dark:text-white ">
                  <tr className="tracking_table">
                    <th
                      scope="col"
                      class="px-6 py-3 tracking_table spth bg-[#F7F2F9]"
                    >
                      Subject Name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%]"
                    >
                      Frequency
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {mostUsedSubject !== undefined &&
                    mostUsedSubject.map((stats, index) => (
                      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td
                          class="px-6 py-4  tracking_table_td"
                          style={{ border: "1px solid rgba(73, 69, 79, 0.4)" }}
                        >
                          {stats.Subject_name}
                        </td>
                        <td class="px-6 py-4  tracking_table_td">
                          {stats.Frequency}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="w-[30%] mt-[10%] ">
              <div className="mb-[2%]">
                Top 10 most used subjects by teachers in classroom
              </div>
              <table
                class="w-full text-sm text-left text-white dark:text-white"
                className="tracking_table w-[100%] "
                id="tbl"
              >
                <thead class="text-xs text-black dark:bg-white dark:text-white ">
                  <tr className="tracking_table">
                    <th
                      scope="col"
                      class="px-6 py-3 tracking_table spth bg-[#F7F2F9]"
                    >
                      Subject Name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%]"
                    >
                      Interest
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {mostUsedSubjectClass !== undefined &&
                    mostUsedSubjectClass.map((stats, index) => (
                      <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td
                          class="px-6 py-4  tracking_table_td"
                          style={{ border: "1px solid rgba(73, 69, 79, 0.4)" }}
                        >
                          {stats.Subject_name}
                        </td>
                        <td class="px-6 py-4  tracking_table_td">
                          {stats.Frequency}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubjectAnalysis;
