import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import EdiText from "react-editext";
import axios from "axios";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "@mui/material/Button";
import PrefinedQuestions from "./PredefinedQuestions";
import { useNavigate } from "react-router-dom";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
//   flexDirection: "row-reverse",
//   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//     transform: "rotate(90deg)",
//   },
//   "& .MuiAccordionSummary-content": {
//     marginLeft: theme.spacing(1),
//   },
// }));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function EditableSubTopicAccordion(props) {
  const navigate = useNavigate();
  // const [expanded, setExpanded] = React.useState("");

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };
  const [count, setCount] = React.useState(0);
  const [expand, setExpand] = React.useState(false);
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  React.useEffect(() => {
    setCount(props.sub_topic.content.length);
  }, [props.sub_topic.content]);

  const onSaveSubTopicName = (value, subTopic) => {
    subTopic.sub_topic_name = value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/update_sub_topic_name",
        {
          method: "POST",
          sub_topic: subTopic,
        }
      )

      .then((response) => {})
      .catch((error) => {
        console.log(error);
        alert("unable to update sub topic");
      });
  };

  const removeQuestion = (predefined_question_answer_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/delete_predefined_question",
        {
          method: "POST",
          predefined_question_answer_id: predefined_question_answer_id,
        }
      )

      .then((response) => {
        props.addTodo();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onAddNewQuestionBtnClick = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/create_predefined_question",
        {
          method: "POST",
          question: "",
          answer: "",
          rubric: "",
          question_marks: 1,
          question_context_id: null,
          sub_topic_id: props.sub_topic.sub_topic_id,
          predefined_question_answer_status:
            props.predefined_question_answer_status,
        }
      )

      .then((response) => {
        props.addTodo();

        // alert("successfully inserted new question")
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSaveSubTopicContent = (value, subTopic) => {
    subTopic.content = value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/update_sub_topic_content",
        {
          method: "POST",
          sub_topic: subTopic,
        }
      )

      .then((response) => {})
      .catch((error) => {
        console.log(error);
        alert("unable to update sub topic");
      });
  };

  // const onEditPredefinedQuestionClick=(sub_topic) =>{
  //   navigate(`/edit_predefined_questions`, {
  //     state: {subject: props.subject, chapter: props.chapter, topic: props.topic, sub_topic: sub_topic },
  //   });
  // }

  return (
    <div>
      <Accordion
        expanded={expand}
        // onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            onClick: toggleAcordion,
          }}
          style={{
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
            "& .MuiAccordionSummary-content": {
              marginLeft: 0,
            },
            padding: 0,
          }}
        >
          <Typography className="w-full">
            {" "}
            <EdiText
              editOnViewClick
              className="w-full"
              type="text"
              onSave={(value) => onSaveSubTopicName(value, props.sub_topic)}
              value={props.sub_topic.sub_topic_name}
            />
          </Typography>

          <Button
            style={{ float: "right" }}
            onClick={() =>
              props.onDeleteClick(
                props.sub_topic,
                process.env.REACT_APP_DELETING_OBJECT_TYPE_SUBTOPIC
              )
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p
              style={{
                display: props.chapter.ai_enabled ? "block" : "none",
              }}
            >
              <EdiText
                type="textarea"
                inputProps={{
                  onChange: (e) => {
                    setCount(e.target.value.length);
                  },
                  className: "textarea",
                  placeholder: "Type your content here",
                  style: {
                    width: "100%",
                    height: "100%",
                    padding: "5px",
                  },
                  rows: 10,
                }}
                value={props.sub_topic.content}
                onSave={(value) =>
                  onSaveSubTopicContent(value, props.sub_topic)
                }
              />
            </p>

            <p
              style={{
                display: props.chapter.ai_enabled ? "none" : "block",
                fontSize: "14px",
                fontFamily: "Roboto",
                color: "#49454F",
              }}
            >
              <PrefinedQuestions
                is_mini_mock={false}
                addTodo={props.addTodo}
                removeQuestion={removeQuestion}
                predefined_question_answer_list={
                  props.sub_topic.predefined_question_answer_list
                }
                predefinedQuestionsPromptList={
                  props.predefinedQuestionsPromptList
                }
              />
            </p>

            <Button
              style={{
                display: props.chapter.ai_enabled ? "none" : "block",
                color: "#1976d2",
                padding: "6px 8px",
              }}
              onClick={() => onAddNewQuestionBtnClick()}
            >
              Add Question
            </Button>

            <div
              style={{
                display: props.chapter.ai_enabled ? "block" : "none",
                float: "right",
                color: count > 9000 ? "#F56260" : "black",
              }}
            >
              <p>Characters count: [{count}]</p>
            </div>

            <div className="mt-[40px]">
              {/* <Button className="delete-button" style={{color: "#1976d2", padding: "6px 8px"}}
                                     
                                      onClick={() => onEditPredefinedQuestionClick(props.sub_topic)}
                                     >
                                     
                                      Add Questions
                                    </Button> */}
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
