import React, { useState } from "react";
import YouTube from "react-youtube";
import Modal from "react-modal";
import amplitude from "amplitude-js";

function TutorialsVideoCard({ videoList }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const opts = {
    height: "300",
    width: "100%",
    playerVars: {},
  };

  const _onReady = (event) => {
    event.target.pauseVideo();
  };
  const onPlayerStateChange = (event, videoLink) => {
    console.log("video links", event);
    if (event.data === window.YT.PlayerState.PLAYING) {
      event.target.pauseVideo();
      setSelectedVideo(videoLink);
      setModalIsOpen(true);
    }
  };
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedVideo(null);
  };
  return (
    <div>
      {videoList.length === 0 && (
        <div>
          <div className="no-quiz-label text-center">
            No video content available.
          </div>
        </div>
      )}
      <div className="flex flex-col items-center">
        <div className="grid xlg:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-2 mt-0  w-full">
          {videoList.length > 0 && (
            <>
              {videoList.map((item) => (
                <div className="quiz-div  " style={{ height: "auto" }}>
                  <div>
                    <YouTube
                      iframeClassName="rounded-xl"
                      videoId={item.video_links}
                      opts={opts}
                      onReady={_onReady}
                      onPlay={() => {
                        amplitude
                          .getInstance()
                          .setUserId(localStorage.getItem("email_address"));
                        amplitude.getInstance().logEvent("PlayVideoBTN");
                      }}
                      onStateChange={(event) =>
                        onPlayerStateChange(event, item.video_links)
                      }
                    />
                  </div>
                </div>
              ))}
              <Modal
                className="md:ml-[320px] md:mt-[10%] md:mr-[10%]"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Video Modal"
                // Add your modal styling here
              >
                {selectedVideo && (
                  <iframe
                    className="text-center"
                    width="100%"
                    height="500" // Adjust as needed
                    src={`https://www.youtube.com/embed/${selectedVideo}`}
                    title="YouTube video player"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                )}
                {/* <button onClick={closeModal}>Close</button> */}
              </Modal>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TutorialsVideoCard;
