import React from "react";

const DataStatistics = ({ studentId }) => (
  <div className=" mt-4">
    <div className="font-bold text-[16px] text-[#252744]">
      The data and statistics shown here are based on retrieval practice at both
      the topic and sub-topic levels.
    </div>
    <div className="text-[14px] text-[#252744]">
      {studentId === null
        ? "This data highlights how well students are mastering specific topics and sub-topics, along with the average attempts to reach mastery. These insights represent the performance of the entire class."
        : "This data highlights how well a student is mastering specific topics and sub-topics, along with the average attempts to reach mastery."}
    </div>
  </div>
);

export default DataStatistics;
