import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import QuizScoreTable from "./QuizScoreTable";
import TopicAnalysisCard from "../TopicAnalysisCard";
import ProgressAnalysisCard from "../ProgressAnalysisCard";
import Download from "../../../images/download.png";
import * as XLSX from "xlsx";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

function QuizAnalysisTabBody({
  selectedStudent,
  quiz_title,
  created_timestamp,
  quizType,
  quiz_duration,
  quizData,
  url,
  studentName,
  topicData,
  subtopicData,
  subtopicPercentage,
  areaData,
  areaPercentage,
  tableSelection,
  areaNames,
  marks,
  tableData,
  averagePercentage,
  averageScore,
  questionMarks,
  minimockQuestionSequence,
  handleChange,
  handleIndividualQuiz,
  totalAreaMarks,
  handleOpenComingSoonDialogue,
  tableDataForCSV,
  questionClassPercentage,
}) {
  console.log("subtopic HAS WALA DATA is: ", minimockQuestionSequence);
  const quizInfo = [
    { A: "", B: "" }, // Assuming you will have a title or some text here
    {
      A: "Topic Analysis",
      B: "The analysis or percentages displayed here pertain solely to this specific task. They do not represent the overall proficiency in the entire subject.",
    }, // Assuming you will have a title or some text here

    { A: "Task Name", B: "Quiz Date", C: "Quiz Type" },
    {
      A: quiz_title,
      B: created_timestamp,
      C: quizType === 1 ? "Formative" : quizType === 2 ? "Mini-mock" : "Exam style",
    },
    {}, // Blank row to mimic the separation
    {},
    {},
    {},
    {},
    { A: "Topic Name", B: "Topic Percentage (%)" },
    // ... followed by topic data
  ];

  // Flatten the topicData array and append it to quizInfo
  const topicRows = topicData.map((value) => ({
    A: value.topic_name,
    B: value.percentage.toFixed(0),
  }));

  const subtopicAnalysis = [
    { A: "", B: "" },
    {
      A: "Subtopic Analysis",
      B: "The analysis or percentages displayed here pertain solely to this specific task. They do not represent the overall proficiency in the entire subject.",
    }, // Title
    {}, // Blank row
    { A: "Topic", B: "Subtopic", C: "Subtopic Percentage (%)" },
    ...subtopicData.flatMap((subtopic) =>
      subtopic.sub_topic.map((st) => ({
        A: subtopic.topic_name,
        B: st.name,
        C: st.percentage.toFixed(0),
      }))
    ),
  ];
  const areaAnalysis = [
    { A: "", B: "" },
    {
      A: "Area Analysis",
      B: "The analysis or percentages displayed here pertain solely to this specific task. They do not represent the overall proficiency in the entire subject.",
    }, // Title
    {}, // Blank row
    { A: "Subtopic", B: "Area", C: "Area Percentage %" },
    ...areaData.flatMap((area) =>
      area.area.map((st) => ({
        A: area.subtopic_name,
        B: st.name,
        C: st.percentage.toFixed(0),
      }))
    ),
  ];

  const scoreGridArea = [
    { A: "", B: "" },
    {
      A: "Task Score Grid",
      B: "Area Analysis",
      G: "The analysis or percentages displayed here pertain solely to this specific task. They do not represent the overall proficiency in the entire subject.",
    }, // Title
    {},
    { A: "Student Name" },
  ];

  let currentColumn = "B";
  {
    areaNames.length > 0 &&
      areaNames.forEach((areaName) => {
        scoreGridArea[3][currentColumn] = areaName + " (%) ";
        // Move to the next column by incrementing the character code
        currentColumn = String.fromCharCode(currentColumn.charCodeAt(0) + 1);
      });
  }
  {
    tableData !== undefined &&
      tableData.forEach((student) => {
        // Create a row object starting with the student's name
        let row = { A: student.student_names };

        // Add each mark to the row object under the correct column
        student.marks.forEach((mark, index) => {
          // 'B'.charCodeAt(0) gives us the char code for 'B'. Adding index will give us subsequent columns.
          const columnName = String.fromCharCode("B".charCodeAt(0) + index);
          row[columnName] = mark;
        });

        // Push the row object to the scoreGridArea array
        scoreGridArea.push(row);
      });
  }

  let averageRow = { A: "Class Average Score Percentage (%)" };
  {
    areaPercentage.length > 0 &&
      averagePercentage.forEach((avg, index) => {
        const columnName = String.fromCharCode("B".charCodeAt(0) + index);
        averageRow[columnName] = avg.toFixed(0); // Assign the average to the appropriate column
      });
  }

  scoreGridArea.push(averageRow);

  const scoreGridQuestionsMinimock = [
    {
      A: "Task Score Grid",
      B: "Question Analysis",
      C: "The analysis or percentages displayed here pertain solely to this specific task. They do not represent the overall proficiency in the entire subject.",
    },
    {},
    { A: "Task Name", B: "Quiz Date", C: "Quiz Type" },
    {
      A: quiz_title,
      B: created_timestamp,
      C: quizType === 1 ? "Formative" : quizType === 2 ? "Mini-mock" : "Exam style",
    },

    {},
    { A: "Student Name" },
  ];

  let currentColumnMinimock = "B";
  {
    minimockQuestionSequence.length > 0 &&
      minimockQuestionSequence.forEach((areaName) => {
        scoreGridQuestionsMinimock[5][currentColumnMinimock] = "Q " + areaName;
        // Move to the next column by incrementing the character code
        currentColumnMinimock = String.fromCharCode(currentColumnMinimock.charCodeAt(0) + 1);
      });
  }

  {
    tableData !== undefined &&
      tableData.forEach((student) => {
        // Create a row object starting with the student's name
        let row = { A: student.student_names };

        // Add each mark to the row object under the correct column
        student.marks.forEach((mark, index) => {
          // 'B'.charCodeAt(0) gives us the char code for 'B'. Adding index will give us subsequent columns.
          const columnName = String.fromCharCode("B".charCodeAt(0) + index);
          row[columnName] = mark;
        });

        // Push the row object to the scoreGridArea array
        scoreGridQuestionsMinimock.push(row);
      });
  }

  const exportData = quizInfo.concat(topicRows);
  let averageRowMinimock = { A: "Class Average Score Percentage (%)" };

  {
    averagePercentage.length > 0 &&
      averagePercentage.forEach((avg, index) => {
        const columnName = String.fromCharCode("B".charCodeAt(0) + index);
        averageRowMinimock[columnName] = avg.toFixed(0); // Assign the average to the appropriate column
      });
  }

  scoreGridQuestionsMinimock.push(averageRowMinimock);

  const scoreGridQuestions = [
    {
      A: "Task Score Grid",
      B: "Question Analysis",
      C: "The analysis or percentages displayed here pertain solely to this specific task. They do not represent the overall proficiency in the entire subject.",
    },
    {},
    { A: "Task Name", B: "Quiz Date", C: "Quiz Type" },
    {
      A: quiz_title,
      B: created_timestamp,
      C: quizType === 1 ? "Formative" : quizType === 2 ? "Mini-mock" : "Exam style",
    },

    {},
    { A: "Student Name" },
  ];

  let currentColumnQuestion = "B";
  {
    questionClassPercentage.length > 0 &&
      questionClassPercentage.forEach((areaname, index) => {
        scoreGridQuestions[5][currentColumnQuestion] = "Q " + ++index;
        currentColumnQuestion = String.fromCharCode(currentColumnQuestion.charCodeAt(0) + 1);
      });
  }

  {
    tableDataForCSV !== undefined &&
      tableDataForCSV.forEach((student) => {
        // Create a row object starting with the student's name
        let row = { A: student.student_names };

        // Add each mark to the row object under the correct column
        student.marks.forEach((mark, index) => {
          // 'B'.charCodeAt(0) gives us the char code for 'B'. Adding index will give us subsequent columns.
          const columnName = String.fromCharCode("B".charCodeAt(0) + index);
          row[columnName] = mark;
        });

        // Push the row object to the scoreGridArea array
        scoreGridQuestions.push(row);
      });
  }

  let averageRowQuestion = { A: "Class Average Score Percentage (%)" };

  {
    questionClassPercentage.length > 0 &&
      questionClassPercentage.forEach((avg, index) => {
        const columnName = String.fromCharCode("B".charCodeAt(0) + index);
        averageRowQuestion[columnName] = avg.toFixed(0); // Assign the average to the appropriate column
      });
  }

  scoreGridQuestions.push(averageRowQuestion);

  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData, {
      skipHeader: true,
    });
    const worksheet2 = XLSX.utils.json_to_sheet(subtopicAnalysis, {
      skipHeader: true,
    });
    const worksheet3 = XLSX.utils.json_to_sheet(areaAnalysis, {
      skipHeader: true,
    });
    const worksheet4 = XLSX.utils.json_to_sheet(scoreGridArea, {
      skipHeader: true,
    });
    const worksheet5 = XLSX.utils.json_to_sheet(scoreGridQuestions, {
      skipHeader: true,
    });

    const boldStyle = { font: { bold: true } };

    worksheet["A2"].s = boldStyle;

    // Merge cells for the title "Topic Analysis"
    XLSX.utils.sheet_add_aoa(worksheet, [[worksheet["A1"].v]], {
      origin: "A1",
    });
    worksheet["!merges"] = [
      // Merge title cells from A1 to G1
      { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } },
      // Merge description cells from B1 to G2
      { s: { r: 0, c: 1 }, e: { r: 1, c: 6 } },
    ];

    // Set the width of the first and second columns
    worksheet["!cols"] = [{ wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }];
    XLSX.utils.sheet_add_aoa(worksheet2, [[worksheet2["A1"].v]], {
      origin: "A1",
    });
    worksheet2["!merges"] = [
      // Merge title cells from A1 to G1
      { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } },
      // Merge description cells from B1 to G2
      { s: { r: 0, c: 1 }, e: { r: 1, c: 6 } },
    ];

    // Set the width of the first and second columns
    worksheet2["!cols"] = [{ wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }];
    XLSX.utils.sheet_add_aoa(worksheet3, [[worksheet3["A1"].v]], {
      origin: "A1",
    });
    worksheet3["!merges"] = [
      // Merge title cells from A1 to G1
      { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } },
      // Merge description cells from B1 to G2
      { s: { r: 0, c: 1 }, e: { r: 1, c: 6 } },
    ];

    // Set the width of the first and second columns
    worksheet3["!cols"] = [{ wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }];
    XLSX.utils.sheet_add_aoa(worksheet4, [[worksheet4["A1"].v]], {
      origin: "A1",
    });
    worksheet4["!merges"] = [
      // Merge title cells from A1 to G1
      { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } },
      // Merge description cells from B1 to G2
      { s: { r: 0, c: 1 }, e: { r: 1, c: 6 } },
    ];

    // Set the width of the first and second columns
    worksheet4["!cols"] = [{ wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }];

    XLSX.utils.sheet_add_aoa(worksheet5, [[worksheet5["A1"].v]], {
      origin: "A1",
    });

    // Set the width of the first and second columns
    worksheet5["!cols"] = [{ wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }, { wpx: 150 }];

    XLSX.utils.book_append_sheet(workbook, worksheet, `Topic Analysis`);
    XLSX.utils.book_append_sheet(workbook, worksheet2, `Subtopic Analysis`);
    XLSX.utils.book_append_sheet(workbook, worksheet3, `Area Analysis`);
    XLSX.utils.book_append_sheet(workbook, worksheet4, `Task Score Grid Analysis: Area`);
    XLSX.utils.book_append_sheet(workbook, worksheet5, `Task Score Grid: Question`);
    XLSX.writeFile(workbook, `${quiz_title}.xlsx`);
  };

  const exportToExcelMinimock = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(scoreGridQuestionsMinimock, {
      skipHeader: true,
    });

    XLSX.utils.sheet_add_aoa(worksheet, [[worksheet["A1"].v]], {
      origin: "A1",
    });

    // Set the width of the first and second columns
    worksheet["!cols"] = [{ wpx: 150 }, { wpx: 150 }];

    XLSX.utils.book_append_sheet(workbook, worksheet, `Question Analysis`);
    XLSX.writeFile(workbook, `${quiz_title}.xlsx`);
  };

  const search = window.location.search;
  const params = new URLSearchParams(search);
  // const [tableData, setTableData] = useState([]);
  // const [marks, setMarks] = useState([]);
  // const [averagePercentage, setAveragePercentage] = useState([]);
  // const [averageScore, setAverageScore] = useState([]);
  var id = params.get("id");

  // const [tableSelection, setTableSelection] = React.useState(2);
  // const [areaNames, setAreaNames] = useState([]);
  // const [topicData, setTopicData] = useState([]);
  // const [subtopicData, setSubtopicData] = useState([]);
  // const [areaData, setAreaData] = useState([]);
  // const [subtopicPercentage, setSubtopicPercentage] = useState([]);
  // const [areaPercentage, setAreaPercentage] = useState([]);
  // const [studentName, setStudentName] = useState("");
  // const [student, setStudent] = useState();
  // const [questionMarks, setQuestionMarks] = useState([]);

  // const handleChange = (event) => {
  //   setTableSelection(event.target.value);
  //   var url = null;
  //   if (event.target.value === 1 && localStorage.getItem("student") === null) {
  //     url = "/teacher_portal_quiz_level_analytics";
  //   } else if (
  //     event.target.value === 2 &&
  //     localStorage.getItem("student") === null
  //   ) {
  //     url = "/teacher_portal_quiz_level_area_analytics";
  //   } else if (
  //     event.target.value === 1 &&
  //     localStorage.getItem("student") !== null
  //   ) {
  //     url = "/teacher_portal_quiz_level_analytics_individual_student";
  //   } else if (
  //     event.target.value === 2 &&
  //     localStorage.getItem("student") !== null
  //   ) {
  //     url = "/teacher_portal_quiz_level_area_analytics_individual_student";
  //   }
  //   // const url =
  //   //   event.target.value === 1
  //   //     ? "/teacher_portal_quiz_level_analytics"
  //   //     : event.target.value === 2
  //   //     ? "/teacher_portal_quiz_level_area_analytics"
  //   //     : "";
  //   var token = "Bearer " + localStorage.getItem("access_token");
  //   axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  //   axios.defaults.headers.post["Content-Type"] =
  //     "application/json;charset=utf-8";
  //   axios.defaults.headers.post["authorization"] = token;
  //   axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

  //   axios
  //     .post(process.env.REACT_APP_REST_API_BASE_URL + url, {
  //       method: "POST",
  //       quiz_secret_key: id,
  //       quiz_response_id: student !== undefined ? student.student_id : "",
  //     })
  //     .then((response) => {
  //       console.log(response.data);
  //       setTableData(response.data.table_data);
  //       setMarks(response.data.table_data[0].marks);
  //       setAverageScore(response.data.average_scores);
  //       setAveragePercentage(response.data.average_percentages);
  //       setQuestionMarks(response.data.question_marks_list)
  //       setAreaNames(response.data.area_name);

  //       if (tableSelection === 2) {
  //         setAreaNames(response.data.area_name);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // const handleIndividualQuiz = (student) => {
  //   setStudent(student);
  //   setStudentName(student.student_names);
  //   localStorage.setItem("student", student.student_names);
  //   var token = "Bearer " + localStorage.getItem("access_token");
  //   axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  //   axios.defaults.headers.post["Content-Type"] =
  //     "application/json;charset=utf-8";
  //   axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  //   axios.defaults.headers.post["authorization"] = token;
  //   axios
  //     .post(
  //       process.env.REACT_APP_REST_API_BASE_URL +
  //         "/teacher_portal_quiz_level_area_analytics_individual_student",
  //       {
  //         method: "POST",
  //         quiz_secret_key: id,
  //         quiz_response_id: student.student_id,
  //       }
  //     )

  //     .then((response) => {
  //       console.log(response.data);
  //       setTableData(response.data.table_data);
  //       setMarks(response.data.table_data[0].marks);
  //       setAverageScore(response.data.average_scores);
  //       setAveragePercentage(response.data.average_percentages);
  //       if (tableSelection === 2) {
  //         setAreaNames(response.data.area_name);
  //         setTopicData(response.data.topic_percentage_);
  //         setSubtopicData(response.data.topic_has_subtopic_list);
  //         setAreaData(response.data.subtopic_has_area_list);
  //         setSubtopicPercentage(response.data.subtopic_percentage);
  //         setAreaPercentage(response.data.average_percentages);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const selectFieldStyles = {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid",
      borderColor: "black",
      // Change the border color to your desired focus color
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#79747e", // Change the normal border color here
    },
    "& .MuiSelect-select.MuiSelect-select": {
      marginLeft: "13px",
      color: "#474747", // Change the color of the selected option(s)
      fontSize: "18px",
      fontWeight: "399",
      fontFamily:
        'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"', // Change the font size of the selected option(s)
    },
  };
  // useEffect(() => {
  //   var token = "Bearer " + localStorage.getItem("access_token");
  //   axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  //   axios.defaults.headers.post["Content-Type"] =
  //     "application/json;charset=utf-8";
  //   axios.defaults.headers.post["authorization"] = token;
  //   axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

  //   axios
  //     .post(
  //       process.env.REACT_APP_REST_API_BASE_URL +
  //         "/teacher_portal_quiz_level_area_analytics",
  //       {
  //         method: "POST",
  //         quiz_secret_key: id,
  //       }
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //       setTableData(response.data.table_data);
  //       setMarks(response.data.table_data[0].marks);
  //       setAverageScore(response.data.average_scores);
  //       setAveragePercentage(response.data.average_percentages);
  //       if (tableSelection === 2) {
  //         setAreaNames(response.data.area_name);
  //         setTopicData(response.data.topic_percentage_);
  //         setSubtopicData(response.data.topic_has_subtopic_list);
  //         setAreaData(response.data.subtopic_has_area_list);
  //         setSubtopicPercentage(response.data.subtopic_percentage);
  //         setAreaPercentage(response.data.average_percentages);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  return (
    <div>
      <div className="inside_classroom_quiz_info flex justify-between sm:mr-[10px] sm:ml-[10px] md:mr-[120px] md:ml-[40px] mt-[16px]">
        Task Analysis
        <button
          className="flex downloadCSV-button items-center"
          onClick={
            () => (quizType === 2 ? exportToExcelMinimock() : exportToExcel())
            // handleOpenComingSoonDialogue()
          }
        >
          <img src={Download} className="mr-[8px]" />
          <div>Download CSV</div>
        </button>
      </div>
      <div>
        <div
          className=" flex sm:mr-[10px] sm:ml-[10px] md:mr-[120px] md:ml-[40px] mt-[16px]"
          style={{ display: studentName === "" ? "none" : "block" }}
        >
          <div className="student-info md:w-[60%] ">
            <label className="teepee-label mr-[10px]">Student Name:</label>
            <label className="teepee-label">{studentName}</label>
          </div>
        </div>
        <div className=" flex sm:mr-[10px] sm:ml-[10px] md:mr-[120px] md:ml-[40px] mt-[16px]">
          <div className="student-info md:w-[60%]">
            <label className="teepee-label mr-[10px]">Task Name:</label>
            <label className="teepee-label">{quiz_title}</label>
          </div>
        </div>
        <div className=" flex sm:mr-[10px] sm:ml-[10px] md:mr-[120px] md:ml-[40px]">
          <div className="quiz-info-light md:items-center w-[60%]">
            {created_timestamp}
            <div className="ml-2">
              {quizType === 2 && <>&#8226; {quiz_duration / 60 + " min "}</>}
              {/* &#8226; */}
              {/* {quizType === 1 ? " formative quiz" : quizType === 2 ? " mini-mock" : " exam style"} */}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="md:w-[80%] " style={{ display: quizData.length === 0 ? "none" : "block" }}>
          <div className="sm:mr-[10px] sm:ml-[10px] md:mr-[120px] md:ml-[40px] mt-[16px]" style={{ display: quizType === 2 ? "none" : "block" }}>
            <div className="inside_classroom_quiz_analysis_heading">Topic Analysis</div>
            <div className="inside_classroom_quiz_analysis_subheading">
              The analysis or percentages displayed here pertain solely to this specific task. They do not represent the overall proficiency in the
              entire subject.
            </div>
            <div className="md:flex mt-[5%]">
              <TopicAnalysisCard topicData={topicData} />
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-[80%] ">
        <div className="sm:mr-[10px] sm:ml-[10px] md:mr-[120px] md:ml-[40px] mt-[16px]" style={{ display: quizData.length === 0 ? "none" : "block" }}>
          <div className="inside_classroom_quiz_analysis_heading mt-[5%]" style={{ display: quizType === 2 ? "none" : "block" }}>
            Subtopic Analysis
          </div>
          <div className="inside_classroom_quiz_analysis_subheading" style={{ display: quizType === 2 ? "none" : "block" }}>
            The analysis or percentages displayed here pertain solely to this specific task. They do not represent the overall proficiency in the
            entire subject.
          </div>
          <div className="mt-[3%]" style={{ display: quizType === 2 ? "none" : "block" }}>
            <ProgressAnalysisCard value={1} data={subtopicData} percentage={subtopicPercentage} />
          </div>
          <div className="inside_classroom_quiz_analysis_heading mt-[5%] !hidden" style={{ display: quizType === 2 ? "none" : "block" }}>
            Area Analysis
          </div>
          <div className="inside_classroom_quiz_analysis_subheading !hidden" style={{ display: quizType === 2 ? "none" : "block" }}>
            The analysis or percentages displayed here pertain solely to this specific task. They do not represent the overall proficiency in the
            entire subject.
          </div>
          <div className="mt-[3%] hidden">
            <ProgressAnalysisCard value={2} data={areaData} percentage={areaPercentage} />
          </div>
          <div className="inside_classroom_quiz_analysis_heading mt-[5%]">Task Score Grid</div>
          <div className="inside_classroom_quiz_analysis_subheading">
            The analysis or percentages displayed here pertain solely to this specific task. They do not represent the overall proficiency in the
            entire subject.
          </div>
          <div>
            <FormControl sx={selectFieldStyles} className="md:w-[20%] float-right " style={{ marginTop: "25px" }}>
              <InputLabel shrink={false} id="demo-simple-select-label" style={{ marginBottom: "10px" }}>
                {tableSelection === "" ? "Question Analysis" : ""}
              </InputLabel>
              <Select defaultValue={1} labelId="demo-simple-select-label" id="demo-simple-select" value={tableSelection} onChange={handleChange}>
                <MenuItem value={1}>Question Analysis</MenuItem>
                <MenuItem value={2} style={{ display: quizType === 2 && "none" }}>
                  Area Analysis
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <QuizScoreTable
            selectedStudent={selectedStudent}
            totalAreaMarks={totalAreaMarks}
            quizType={quizType}
            minimockQuestionSequence={minimockQuestionSequence}
            handleIndividualQuiz={handleIndividualQuiz}
            areaNames={areaNames}
            tableSelection={tableSelection}
            marks={marks}
            tableData={tableData}
            averagePercentage={averagePercentage}
            averageScore={averageScore}
            questionMarks={questionMarks}
          />
          <button
            onClick={() => {
              localStorage.removeItem("student");
              window.location.reload();
            }}
            className="teacher_navigation_text mt-[10px]"
            style={{
              display: localStorage.getItem("student") !== null ? "block" : "none",
            }}
          >
            Return to Quiz Analytics
          </button>
        </div>
        <div className="w-[120%] text-center sm:mt-[5%]" style={{ display: quizData.length === 0 ? "block" : "none" }}>
          Awaiting Task Analytics
        </div>
      </div>
    </div>
  );
}

export default QuizAnalysisTabBody;
