import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import KeyMatricsCard from "./widgets/KeyMatricsCard";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";

function KeyMatrics() {
  const [liveUsersData, setLiveUsersData] = useState([]);
  const [liveUsers, setLiveUsers] = useState([]);
  const [keyMatricsData, setKeyMatricsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [startData, setStartDate] = useState(null);
  const [endData, setEndDate] = useState(null);
  const navigate = useNavigate();

  const handleClose = () => {
    setStartDate(null);
    setEndDate(null);
    setOpen(false);
  };

  useEffect(() => {
    getLiveUser();
    defaultGetKeyMatrics();
  }, []);
  const getLiveUser = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/active_users", {
        method: "POST",
      })
      .then((response) => {
        console.log(response);
        console.log("dasdas", response.data);
        setLiveUsersData(response.data);
        setLiveUsers(response.data.total_active);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const defaultGetKeyMatrics = () => {
    var numberOfDaysToAdd = 7;
    let dateObj = new Date();
    dateObj.setDate(dateObj.getDate() - numberOfDaysToAdd);
    let month = dateObj.getUTCMonth() + 1;
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();

    let endDateObj = new Date();
    let month2 = endDateObj.getUTCMonth() + 1;
    let day2 = endDateObj.getUTCDate();
    let year2 = endDateObj.getUTCFullYear();

    const startDate = `${year}-${month}-${day}`;
    const endDate = `${year2}-${month2}-${day2}`;

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/filter_key_matrices", {
        method: "POST",
        start_date: startDate,
        end_date: endDate,
      })

      .then((response) => {
        console.log(response);
        setKeyMatricsData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleConfirm = () => {
    if (startData === null || endData === null) {
      alert("please add both dates");
      return;
    }
    console.log("TAB VALUE : ", startData, endData);
    const year = startData.$y;
    const month = startData.$M + 1; // Adding 1 because months are 0-based
    const day = startData.$D;

    const year2 = endData.$y;
    const month2 = endData.$M + 1; // Adding 1 because months are 0-based
    const day2 = endData.$D;

    const startDate = `${year}-${month}-${day}`;
    const endDate = `${year2}-${month2}-${day2}`;

    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/filter_key_matrices", {
        method: "POST",
        start_date: startDate,
        end_date: endDate,
      })

      .then((response) => {
        console.log(response);
        setKeyMatricsData(response.data);
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectFieldStyles = {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid",
      borderColor: "black",
      // Change the border color to your desired focus color
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#79747e", // Change the normal border color here
    },
    "& .MuiSelect-select.MuiSelect-select": {
      marginLeft: "13px",
      color: "#474747", // Change the color of the selected option(s)
      fontSize: "18px",
      fontWeight: "399",
      fontFamily:
        'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"', // Change the font size of the selected option(s)
    },
  };

  const onClickLiveUserButton = () => {
    navigate("/live-users");
  };
  return (
    <div className="">
      <Sidebar item_id="dashboard" />
      <div className="sm:ml-0 md:ml-[316px] mb-[40px] ">
        <div className="w-[100%] mb-[7%] flex justify-center">
          <button className="w-[20%]" onClick={() => onClickLiveUserButton()}>
            <KeyMatricsCard
              text="Total Live Users"
              number={liveUsers !== undefined && liveUsers.total_active_users}
            />
          </button>
        </div>
        <div className="w-[10%] float-right mb-[10px] mr-[16%]">
          <FormControl sx={selectFieldStyles} className="w-[100%]">
            <InputLabel
              shrink={false}
              id="demo-simple-select-label"
              style={{ marginBottom: "10px" }}
            >
              Date
            </InputLabel>
            <Select
              open={open}
              onClose={handleClose}
              onOpen={() => setOpen(true)}
              style={{ height: "53px" }}
              className=""
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              name="chapter"
            >
              <div className="pr-[35px] pl-[35px] pt-[12px]">
                <div>
                  <div className="mb-[8px]">Start Date</div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={startData}
                      onChange={(newValue) => setStartDate(newValue)}
                      format="YYYY-MM-DD"
                    />
                  </LocalizationProvider>
                </div>
                <div className="pt-[8px]">End Date</div>
                <div className="mt-[8px]">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={endData}
                      onChange={(newValue) => setEndDate(newValue)}
                      format="YYYY-MM-DD"
                    />
                  </LocalizationProvider>
                </div>
                <div className="">
                  <button
                    className="dialogue-button-text mt-[30px]"
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </button>
                  <button
                    className="teepee-button  mt-[20px] mb-[17px]"
                    onClick={() => handleConfirm()}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Select>
          </FormControl>
        </div>
        <div className="flex w-[100%] justify-center">
          <div className="ml-8 w-[20%]">
            <Tooltip placement="top" title={"Teachers who have logged in."}>
              <div>
                <KeyMatricsCard
                  text="Total active teachers"
                  number={keyMatricsData.Active_Teachers}
                />
              </div>
            </Tooltip>
          </div>
          <div className="ml-8 w-[20%]">
            <Tooltip placement="top" title={"Students who have logged in."}>
              <div>
                <KeyMatricsCard
                  text="Total active students"
                  number={keyMatricsData.Active_Students}
                />
              </div>
            </Tooltip>
          </div>
          <div className="ml-8 w-[20%]">
            <Tooltip
              placement="top"
              title={
                "An active classroom is defined as one in which quizzes have been attempted or created."
              }
            >
              <div>
                <KeyMatricsCard
                  text="Total number of active classroom"
                  number={keyMatricsData.Active_Classrooms}
                />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="flex w-[100%] justify-center mt-[5%]">
          <div className="ml-8 w-[20%]">
            <Tooltip placement="top" title={"Registered as a teacher."}>
              <div>
                <KeyMatricsCard
                  text="Number of New teachers joined the platform"
                  number={keyMatricsData.New_Teachers}
                />
              </div>
            </Tooltip>
          </div>
          <div className="ml-8 w-[20%]">
            <Tooltip placement="top" title={"Registered as a student."}>
              <div>
                <KeyMatricsCard
                  text="Number of New students joined the platform"
                  number={keyMatricsData.New_Students}
                />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="flex w-[100%] justify-center mt-[5%]">
          <div className="ml-8 w-[20%]">
            <Tooltip placement="top" title={"Classrooms created by teachers."}>
              <div>
                <KeyMatricsCard
                  text="Total number of classroom created"
                  number={keyMatricsData.total_classroom}
                />
              </div>
            </Tooltip>
          </div>
          <div className="ml-8 w-[20%]">
            <Tooltip
              placement="top"
              title={"Quizzes included both within and outside the classrooms."}
            >
              <div>
                <KeyMatricsCard
                  text="Total number of quizzes created"
                  number={keyMatricsData.total_quizzes}
                />
              </div>
            </Tooltip>
          </div>
          <div className="ml-8 w-[20%]">
            <Tooltip
              placement="top"
              title={
                "Includes quizzes attempted by teachers or students, both inside and outside the classroom."
              }
            >
              <div>
                <KeyMatricsCard
                  text="Total number of quiz attempts"
                  number={keyMatricsData.total_quiz_response}
                />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="flex w-[100%] justify-center mt-[5%]">
          <div className="ml-8 w-[20%]">
            <Tooltip
              placement="top"
              title={"Includes only quizzes created within the classroom."}
            >
              <div>
                <KeyMatricsCard
                  text="Total number of quizzes created within the classroom"
                  number={keyMatricsData.total_quiz_in_classroom}
                />
              </div>
            </Tooltip>
          </div>
          <div className="ml-8 w-[20%]">
            <Tooltip
              placement="top"
              title={
                "Includes quizzes attempted by students within the classroom."
              }
            >
              <div>
                <KeyMatricsCard
                  text="Total number of quiz attempted inside classroom"
                  number={keyMatricsData.quiz_response_in_class}
                />
              </div>
            </Tooltip>
          </div>
          <div className="ml-8 w-[20%]">
            <Tooltip
              placement="top"
              title={"Tokens consumed during quiz creation."}
            >
              <div>
                <KeyMatricsCard
                  text="Token consumption creation"
                  number={keyMatricsData.total_creation_token}
                />
              </div>
            </Tooltip>
          </div>
          <div className="ml-8 w-[20%]">
            <Tooltip
              placement="top"
              title={"Tokens consumed during quiz evaluations."}
            >
              <div>
                <KeyMatricsCard
                  text="Token consumption evaluation"
                  number={keyMatricsData.total_evaluation_tokens}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KeyMatrics;
