import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import axios from "axios";
import OnBoardingSubjectWidget from "./OnBoardingSubjectWidget";
import { arrayMoveImmutable } from "array-move";

function OnboardingVideos() {
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    getVideosList();
  }, []);

  const getVideosList = () => {
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_videos",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log(JSON.parse(res.data.data));
        setVideoList(JSON.parse(res.data.data));
      });
  };
  const handleSubmitOptionClick = (event) => {
    var video_id = document.getElementById("video_id").value;
    if (video_id.length > 0) {
    } else {
      alert("Please enter all details");
      return;
    }
    submitForm();
  };

  function submitForm() {
    var token = "Bearer " + localStorage.getItem("access_token");
    var video_id = document.getElementById("video_id").value;
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_videos", {
        method: "POST",
        video_link: video_id,
      })
      .then((response) => {
        console.log(response);
        alert("New video added successfully");
        document.getElementById("video_id").value = "";
        getVideosList();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setVideoList((prevItem) =>
      arrayMoveImmutable(prevItem, oldIndex, newIndex)
    );

    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_videos_order", {
        method: "POST",
        list_of_videos: arrayMoveImmutable(videoList, oldIndex, newIndex),
      })
      .then((response) => {
        console.log(response);
        setVideoList(JSON.parse(response.data.video_data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onSaveSubject = (value, video) => {
    video.video_link = value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_videos", {
        method: "POST",
        tutorial_videos_id: video.id_tutorial_videos,
        video_link: video.video_link,
      })

      .then((response) => {
        getVideosList();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to update video");
      });
  };

  const sendDeleteRequest = (object_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/delete_videos", {
        method: "POST",
        tutorial_videos_id: object_id,
      })
      .then((response) => {
        alert("successfully deleted");
        getVideosList();
        // getSubjectsList();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to delete the content");
      });
  };
  return (
    <div>
      <Sidebar item_id="onboarding_video" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px] ">
        <div className="flex flex-row md:ml-[60px] items-center ">
          <div className=""></div>
          <div className="selectionDiv sm:w-[100%] md:w-[600px]">
            <div className="form-div">
              <div className="md:w-[600px]">
                <div>
                  <label className="create_quiz_label">Enter Video Id</label>
                </div>
                <div>
                  <input
                    type="text"
                    name="video_id"
                    id="video_id"
                    className="teepee-input"
                    placeholder=""
                    required
                  />
                </div>
                <button
                  onClick={handleSubmitOptionClick}
                  className="teepee-button mt-[10px] hover:bg-black"
                  style={{ width: "46%" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-[60px] mt-[3%]">
          <OnBoardingSubjectWidget
            isVideo={true}
            subjectsList={videoList}
            onSortEnd={onSortEnd}
            onSaveSubject={onSaveSubject}
            sendDeleteRequest={sendDeleteRequest}
          />
        </div>
      </div>
    </div>
  );
}

export default OnboardingVideos;
