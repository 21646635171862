import * as React from "react";
import { useState } from "react";
function IncrementDecrementCounter(props) {
  let [num, setNum] = useState(0);
  React.useEffect(() => {
    if (props.marks !== undefined) {
      setNum(props.marks);
      document.getElementById("marks").value = props.marks;
    }
  }, [props.marks]);

  let incNum = () => {
    if (num < props.uper_limit) {
      setNum(Number(num) + 1);
      if (props.onSaveSubTopicMarks !== undefined) {
        props.onSaveSubTopicMarks(
          Number(num) + 1,
          props.predefined_question_answer,
          props.predinedQuestionAnswerIndex
        );
      }
      if (props.onIncDecClick !== undefined) {
        props.onIncDecClick(Number(num) + 1);
      }
    }
  };
  let decNum = () => {
    if (num > props.lower_limit) {
      setNum(num - 1);
      if (props.onSaveSubTopicMarks !== undefined) {
        props.onSaveSubTopicMarks(
          Number(num) - 1,
          props.predefined_question_answer,
          props.predinedQuestionAnswerIndex
        );
      }
      if (props.onIncDecClick !== undefined) {
        props.onIncDecClick(Number(num) - 1);
      }
    }
  };
  let handleChange = (e) => {
    setNum(e.target.value);
  };

  return (
    <>
      <div>
        <div className="input-group">
          <div className="input-group-prepend" style={{ float: "left" }}>
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={decNum}
            >
              -
            </button>
          </div>
          <label
            id="marks"
            type="text"
            className=""
            onChange={handleChange}
            style={{ width: "40px", marginLeft: "20px", marginTop: "4px" }}
          >
            {num} {props.label}
          </label>
          <div className="input-group-prepend" style={{ float: "left" }}>
            <button
              className="btn btn-outline-secondary mr-[10px]"
              type="button"
              onClick={incNum}
            >
              +
            </button>
            min
          </div>
        </div>
      </div>
    </>
  );
}

export default IncrementDecrementCounter;
