export const REACT_APP_REST_API_BASE_URL = process.env.REACT_APP_REST_API_BASE_URL;
export const REACT_APP_GET_PROMOCODE_END_POINT = process.env.REACT_APP_GET_PROMOCODE_END_POINT;
export const REACT_APP_INSERT_PROMOCODE_END_POINT = process.env.REACT_APP_INSERT_PROMOCODE_END_POINT;
export const REACT_APP_UPDATE_PROMOCODE_END_POINT = process.env.REACT_APP_UPDATE_PROMOCODE_END_POINT;
export const REACT_APP_DELETE_PROMOCODE_END_POINT = process.env.REACT_APP_DELETE_PROMOCODE_END_POINT;
export const REACT_APP_VALIDATE_PROMOCODE_END_POINT = process.env.REACT_APP_VALIDATE_PROMOCODE_END_POINT;
export const REACT_APP_GET_NOTIFICATION_END_POINT = process.env.REACT_APP_GET_NOTIFICATION_END_POINT;
export const REACT_APP_CREATE_NOTIFICATION_END_POINT = process.env.REACT_APP_CREATE_NOTIFICATION_END_POINT;
export const REACT_APP_UPDATE_NOTIFICATION_END_POINT = process.env.REACT_APP_UPDATE_NOTIFICATION_END_POINT;
export const REACT_APP_DELETE_NOTIFICATION_END_POINT = process.env.REACT_APP_DELETE_NOTIFICATION_END_POINT;
export const REACT_APP_GET_PRICING_END_POINT = process.env.REACT_APP_GET_PRICING_END_POINT;
export const REACT_APP_CREATE_PRICING_END_POINT = process.env.REACT_APP_CREATE_PRICING_END_POINT;
export const REACT_APP_UPDATE_PRICING_END_POINT = process.env.REACT_APP_UPDATE_PRICING_END_POINT;
