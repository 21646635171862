import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Cross from "../../images/cross.png";

function NewSubjectDialogue(props) {
  return (
    <div>
      <Dialog
        PaperProps={{
          sx: {
            width: "552px",
            height: "320px",
            maxWidth: "552px",
            maxHeight: "320px",
          },
        }}
        open={props.subjectDialogue}
        onClose={props.handleCloseDialogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="flex">
          <div className="text-center w-[100%] create_new_classroom_dialogue_title"></div>
          <button onClick={() => props.handleCloseDialogue()}>
            <img src={Cross}></img>
          </button>
        </DialogTitle>
        <div>
          <DialogContent
            // style={{ marginLeft: "30px", marginRight: "50px" }}
            className="sm:ml-0 md:ml-[7%] md:mr-[7%]"
          >
            <DialogContentText id="new-classroom-dialog-description">
              <div className="mb-[5%]">
                <label className="new_subject_label !text-2xl">
                  Don’t see your subjects?
                </label>
              </div>

              <div>
                <label className="subject_name_hint_label">
                  Just type it in!
                </label>
              </div>
            </DialogContentText>
            <div>
              <div>
                <input
                  maxLength={45}
                  onChange={(e) => props.onChangeNumber(e)}
                  placeholder="Ex: CHEM 101, Introduction to Chemistry"
                  style={{ width: "100%", marginRight: "20px" }}
                  type="text"
                  name="class_code"
                  id="class_code"
                  className="class_name_input pl-[23px]"
                  required
                />
              </div>
            </div>
            <div>
              <button
                // style={{ width: "110px" }}
                id="button"
                onClick={props.handleClickContinue}
                className="mt-[4%] teepee-button md:ml-12 hover:bg-black sm:w-[100%] md:w-[100%]"
              >
                Done
              </button>
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

export default NewSubjectDialogue;
