import React from "react";
import Sidebar from "../../Sidebar";
import SubscriptionDetailsCard from "../SubscriptionDetailsCard";
import SubscriptionDetailsTable from "./SubscriptionDetailsTable";

function AdminSubscriptionTab({
  subscriptionDetails,
  getSubscriptionDetailsForAdmin,
}) {
  return (
    <div>
      <Sidebar item_id="subscription" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px] ">
        <div className="home sm:ml[25px] md:ml-[30px]">
          <div>
            <label className="dashboard-label">Subscription Details </label>
            <div className="teepee-divider"></div>
          </div>
        </div>
        <div className="flex flex-row md:justify-left md:items-center sm:ml-0 md:ml-[30px] mt-[20px] overflow-auto">
          <SubscriptionDetailsTable
            subscriptionDetails={subscriptionDetails}
            getSubscriptionDetailsForAdmin={getSubscriptionDetailsForAdmin}
          />
        </div>
      </div>
    </div>
  );
}

export default AdminSubscriptionTab;
