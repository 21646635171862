import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const WeeklyAchievers = ({ rank, c_name, subjectName, classroom_id }) => {
  const navigate = useNavigate();
  return (
    <div className="shadow-sm bg-[#FAFAFA] rounded-lg p-3 w-[328px] h-[140px]">
      {/* Header */}
      <div className="text-[12px] leading-[20px] font-bold text-gray-400 uppercase mb-1">Weekly Achievers</div>

      {/* Rank */}
      <div className="text-[18px] leading-[24px] font-bold text-gray-800 mb-1">
        {rank > 0 && (
          <>
            {" "}
            You ranked" <span className="text-orange-600">#{rank}</span>
          </>
        )}
        {rank === 0 && "Weekly Leaderboard"}
      </div>

      {/* Description */}
      <div className="text-[12px] leading-[20px] text-gray-600 mb-1">
        {rank > 0
          ? " Every quiz attempt counts. Keep pushing to climb the leaderboard after each attempt!"
          : "Compete with your classmates to earn the most XP this week!"}
      </div>

      {/* Link */}
      <button
        onClick={() => {
          navigate("/student-leaderboard", {
            state: {
              subjectName: subjectName,
              classroom_name: c_name,
              classroom_id: classroom_id,
            },
          });
        }}
        className="text-[#6750A4] font-semibold flex items-center text-[12px] hover:underline"
      >
        Go to Leaderboard <AiOutlineArrowRight className="ml-1" />
      </button>
    </div>
  );
};

export default WeeklyAchievers;
