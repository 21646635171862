import React from "react";
import SortableList from "./DragNDrop/SortableList";

function OnBoardingSubjectWidget({
  isVideo,
  subjectsList,
  onSortEnd,
  onSaveSubject,
  sendDeleteRequest,
}) {
  return (
    <div>
      <SortableList
        isVideo={isVideo}
        items={subjectsList}
        onSortEnd={onSortEnd}
        onSaveSubject={onSaveSubject}
        sendDeleteRequest={sendDeleteRequest}
      />
    </div>
  );
}

export default OnBoardingSubjectWidget;
