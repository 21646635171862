import React from "react";

const ConfirmedPopUp = ({ isOpen, onClose, title, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" style={{ zIndex: "1000" }}>
      <div className="bg-white p-4 w-[360px] rounded-lg shadow-lg text-left relative">
        <div className="flex justify-between items-left mb-4">
          <h3 className="text-lg font-semibold">{title}</h3>
          <button onClick={onClose} className="text-gray-600 hover:text-gray-800 text-xl">
            &times;
          </button>
        </div>
        <p className="text-gray-700 mb-6">{message}</p>
        <button onClick={onClose} className="bg-[#6750A4] text-white py-2 px-4 rounded hover:bg-black mt-[20px] float-right">
          Okay
        </button>
      </div>
    </div>
  );
};

export default ConfirmedPopUp;
