import React from "react";
import { CSVLink } from "react-csv";
import amplitude from "amplitude-js";
import Tooltip from "@mui/material/Tooltip";
import { Progress } from "@material-tailwind/react";

import lateSubmissionLogo from "../../../images/ic_warning_triangle.svg";
import ReEvaluateButton from "../../ReEvaluateButton";
import { useReducer } from "react";
import { useRef } from "react";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

function ResultsTabBody({
  quiz_grace_time,
  quiz_title,
  created_timestamp,
  quizType,
  quiz_duration,
  csvReportForMiniMock,
  quiz_total_marks,
  quizData,
  getTimeString,
  handlerPerformance,
  get_list_of_attempted_students,
  handleSeeMoreClick,
  userType,
  handleRawResponseClick,
}) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const timeTaken = useRef(0);
  var id = params.get("id");

  return (
    <div>
      <div className=" flex sm:mr-[10px] sm:ml-[10px] md:mr-[120px] md:ml-[110px] mt-[16px]">
        <div className="student-info md:w-[60%] sm:mb-[10%] md:mb-0">
          <label className="teepee-label mr-[10px]">Task Name:</label>
          <label className="teepee-label">{quiz_title}</label>
        </div>
      </div>
      <div className=" flex sm:mr-[10px] sm:ml-[10px] md:mr-[120px] md:ml-[110px]">
        <div className="quiz-info-light md:items-center w-[60%]">
          {created_timestamp}
          <div className="ml-2">
            {quiz_duration !== 0 && <>&#8226; {(quiz_duration + quiz_grace_time) / 60 + " min "}</>}
            &#8226;
            {quizType === 1 ? " formative quiz" : quizType === 2 ? " mini-mock" : " exam style"}
          </div>
        </div>
        <div className="teepee-label justify-end w-[40%] ">
          <CSVLink
            className="border-[1px] p-2 text-[14px] text-gray border-[#8E8888] rounded-[10px]"
            style={{ color: "#8E8888", textDecoration: "none" }}
            onClick={() => {
              amplitude.getInstance().logEvent("Download CSV (mini-mock)");
            }}
            {...csvReportForMiniMock}
          >
            Download CSV
          </CSVLink>
        </div>
      </div>
      <div className="sm:pl-[10px] sm:pr-[10px] md:pr-0 md:pl-[60px] mt-[20px] flex view-quiz-table sm:overflow-scroll md:overflow-hidden md:ml-[55px]">
        {quizData.length > 0 ? (
          <table id="studenTable" className="w-[93%]">
            <tr className="tr">
              <th className="md:w-[70px]">Sr#</th>
              <th className="sm:text-center md:text-left sm:pr-[10px] sm:pl-[10px] md:pr-0 md:pl-0 ">Student Name</th>
              <th
                className="sm:pr-[20px] md:pr-0"
                style={{
                  textAlign: "center",
                  display: quiz_duration === 0 && "none",
                }}
              >
                Time taken (min)
              </th>
              <th className="sm:pr-[20px] md:pr-0" style={{ textAlign: "center" }}>
                Date
              </th>
              <th className="sm:pr-[10px] sm:pl-[10px] md:pr-0 md:pl-0" style={{ textAlign: "center" }}>
                Performance
              </th>
              <th className="sm:pr-[10px] sm:pl-[10px] md:pr-0 md:pl-0" style={{ textAlign: "center" }}>
                Marks ({quiz_total_marks})
              </th>
              <th></th>
            </tr>
            {quizData.map((quiz, key) => {
              return (
                <>
                  <tr className="tr" key={key}>
                    <td>{key + 1 + "."}</td>
                    <td className="sm:pr-[10px] sm:pl-[10px] md:pr-0 md:pl-0">{quiz.student_name}</td>
                    <td
                      className="sm:pr-[40px] sm:pl-[20px] md:pr-0 md:pl-0"
                      style={{
                        textAlign: "center",
                        display: quiz_duration === 0 && "none",
                      }}
                    >
                      {parseInt(quiz.quiz_attempt_duration / 60)}
                    </td>
                    <td className="sm:pr-[40px] sm:pl-[20px] md:pr-0 md:pl-0" style={{ textAlign: "center" }}>
                      <div
                        className="inline-flex"
                        style={{
                          marginTop: quizType === 2 && quiz.quiz_attempt_duration > quiz_duration + quiz_grace_time ? "10px" : "0px",
                        }}
                      >
                        {/* if quiz type is mini-mock and quiz attempt duration is greater then quiz_duration then displaying late submission warning icon */}
                        {quiz.quiz_attempt_duration > quiz_duration + quiz_grace_time && (
                          <Tooltip title={"Late Submission: " + getTimeString(quiz_duration + quiz_grace_time - quiz.quiz_attempt_duration)}>
                            <img alt="" src={lateSubmissionLogo}></img>
                          </Tooltip>
                        )}
                        {quiz.submitted_timestamp}
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <div className="performace-label">
                        {handlerPerformance(quiz)}
                        {quiz.partially_evaluated === 1 && (
                          <ReEvaluateButton quiz_secret_key={id} quiz={quiz} get_list_of_attempted_students={get_list_of_attempted_students} />
                        )}
                      </div>
                      <div className="flex w-full flex-col gap-4">
                        <Progress
                          value={quiz.performance}
                          color={
                            quiz.performance >= 80 ? "green" : quiz.performance >= 70 ? "light-green" : quiz.performance >= 50 ? "yellow" : "red"
                          }
                        />
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>{((quiz_total_marks * quiz.overall_score) / 100).toFixed(2)}</td>

                    <td className="text-center">
                      <button
                        id="button"
                        name={quiz.student_name}
                        value={key}
                        onClick={handleSeeMoreClick}
                        className="my-quizzes-card-buttons my-quizzes-card-view-quiz-button"
                      >
                        See more
                      </button>
                    </td>
                    <td
                      style={{
                        display: userType === "2" ? "" : "none",
                      }}
                    >
                      <button
                        id="button1"
                        name={quiz.student_name}
                        value={key}
                        onClick={() => {
                          handleRawResponseClick(quiz.quiz_response_id);
                        }}
                        className="my-quizzes-card-buttons my-quizzes-card-view-quiz-button"
                      >
                        Raw Response
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
          </table>
        ) : (
          <div className="text-center w-[100%] mr-[20%]">No task Results Yet.</div>
        )}
      </div>
    </div>
  );
}

export default ResultsTabBody;
