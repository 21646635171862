import React, { useState } from "react";
import participation_icon from "../../../images/d1.png";
import completion_icon from "../../../images/d2.png";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MoreVertIcon from "../../../images/info12.svg";

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} placement="top" />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    border: "1px solid #dadde9",
    borderRadius: "8px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(12),
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    maxWidth: 250,
    opacity: "0.8",
    padding: "12px 16px",
  },
}));

const Metric = ({ icon, label, tooltip, value }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="flex items-center w-full pt-4 pl-5 pb-3 pr-2 bg-[#F1F2F7] rounded-lg justify-between shadow-sm">
      <div className="flex w-full justify-between items-center">
        <div className="text-[#403151]  w-full opacity-75 text-[16px] leading-[24px] font-roboto">
          {" "}
          <div className="flex  items-center w-full justify-between">
            <div className="md:w-[100%] sm:w-[80%] text-[#6A6A6AFF] text-[1rem] font-semibold	tracking-[0.15px] leading-6	">
              {label}{" "}
              <ClickAwayListener onClickAway={handleClose}>
                <HtmlTooltip
                  title={tooltip}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  enterTouchDelay={0}
                  leaveTouchDelay={10}
                  open={open}
                  onOpen={handleOpen}
                  onClose={handleClose}
                >
                  <img className="float-right " src={MoreVertIcon} />
                  <button className="ml-[5px] mt-[2px]" onClick={handleOpen}></button>
                </HtmlTooltip>
              </ClickAwayListener>
            </div>
          </div>
          <div className="space-y-2 mt-3">
            <div className="text-[#403151] text-[28px] leading-[20px] font-bold">
              {value !== undefined || value !== NaN ? `${parseInt(value)}%` : "0%"}
            </div>
            <div className="text-[14px]">
              {label === "🎯 Quiz Participation Rate" ? "Student participating in quizzes" : "Percentage of quizzes fully completed"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ParticipationMetrics = ({ data }) => (
  <div className="md:ml-[20px] flex flex-col gap-4 justify-center w-full">
    <Metric
      icon={participation_icon}
      label="🎯 Quiz Participation Rate"
      tooltip="The percentage of students participating in quizzes."
      value={data?.class_analytics_data?.participation_rate ? data?.class_analytics_data?.participation_rate : 0}
    />
    <Metric
      icon={completion_icon}
      label="✅ Quiz Completion Rate"
      tooltip="The percentage of quizzes fully completed."
      value={data?.class_analytics_data?.completion_rate ? data?.class_analytics_data?.completion_rate : 0}
    />
  </div>
);

export default ParticipationMetrics;
