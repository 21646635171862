import React, { useContext, useEffect, useRef, useState } from "react";
import Sidebar from "../Sidebar";
import { json, useLocation } from "react-router-dom";
import PaymentRadioButtons from "./widgets/PaymentRadioButtons";
import PurchaseOrderDiv from "./widgets/PurchaseOrderDiv";
import TermsAndConditions from "./widgets/TermsAndConditions";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Payment from "./Stripe/Payment";
import { loadStripe } from "@stripe/stripe-js";
import OrderPlacedCheckout from "./widgets/OrderPlacedCheckout";
import success_img from "../../images/success.png";
import useValidatePromoCode from "../PromocodeApiCall/ValidatePromocodeApi";
import failed from "../../images/failed.png";
import PromoCodeStatus from "./widgets/PromoCodeStatus";
import appContext from "../../context/appContext";

function Checkout() {
  const location = useLocation();
  const state = useContext(appContext);
  const storedState = sessionStorage.getItem("checkoutState");
  let data = JSON.parse(storedState);
  const [value, setValue] = React.useState("purchase");
  const [purchaseOrder, setPurchaseOrder] = useState();
  const [stripePublishableKey, setStripePublishableKey] = useState();
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const childRef = useRef();
  const [checked, setChecked] = useState([false, false]);
  const [isLoading, setIsLoading] = useState(false);
  const [promocode, setPromoCode] = useState();
  const [promocodeValidationStatus, setPromocodeValidationStatus] = useState();
  const [discountedAmount, setDiscountedAmount] = useState(parseFloat(data.vouchersRate) + parseFloat((data.vouchersRate * 20) / 100));
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [saveAmount, setSaveAmount] = useState();
  const [detailAdded, setDetailAdded] = useState(false);

  const [email, setEmail] = useState();
  const [po, setPo] = useState();

  const validatePormoCode = useValidatePromoCode();
  const handleChangeCheckBox = (event, index) => {
    const updatedChecked = [...checked];
    updatedChecked[index] = event.target.checked;
    setChecked(updatedChecked);
  };
  const discountedPriceForStripeIntant = useRef(parseFloat(data.vouchersRate) + parseFloat((data.vouchersRate * 20) / 100));

  const navigate = useNavigate();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChancePurchaseOrder = (event) => {
    setPurchaseOrder(event.target.value);
  };
  const handlePromocodeValidation = () => {
    validatePormoCode(
      promocode,
      data,
      setPromocodeValidationStatus,
      setDiscountedAmount,
      setSaveAmount,
      discountedPriceForStripeIntant,
      updatePaymentIntent
    );
  };
  const handleInputFieldValidation = () => {
    const purchaseOrderNumber = document.getElementById("purchaseOrderNumber");
    const schoolName = document.getElementById("schoolName");
    const contactPerson = document.getElementById("contactPerson");
    const emailAddress = document.getElementById("emailAddress");
    const phoneNumber = document.getElementById("phoneNumber");
    const additionalComments = document.getElementById("additionalComments");

    setEmail(emailAddress);
    setPo(purchaseOrderNumber);
    // Helper function to highlight fields
    const highlightField = (field) => {
      field.style.borderColor = "red";
      field.style.borderWidth = "2px";
    };

    // Clear previous highlights
    const clearHighlight = (field) => {
      field.style.borderColor = "";
      field.style.borderWidth = "";
    };

    // Clear all highlights before validation
    [schoolName, contactPerson, emailAddress, additionalComments].forEach(clearHighlight);

    // Check if all fields are filled
    if (schoolName.value.length === 0 || contactPerson.value.length === 0 || emailAddress.value.length === 0) {
      alert("Please enter all fields");
      [schoolName, contactPerson, emailAddress].forEach((field) => {
        if (field.value.length === 0) highlightField(field);
      });
      return true;
    }

    // Validate purchase order number if value is not "credit"
    // if (value !== "credit" && purchaseOrderNumber.value.length === 0) {
    //   alert("Please enter a purchase order number");
    //   highlightField(purchaseOrderNumber);
    //   return true;
    // }

    // Validate email address
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(emailAddress.value)) {
      alert("Please enter a valid email address");
      highlightField(emailAddress);
      return true;
    }

    // Validate phone number
    const phonePattern = /^\d+$/;
    if (!phonePattern.test(phoneNumber.value) && phoneNumber.value.length > 0) {
      alert("Please enter a valid phone number");
      highlightField(phoneNumber);
      return true;
    }

    return false;
  };

  const handleClickPlaceOrder = () => {
    if (value !== "credit") {
      if (handleInputFieldValidation()) {
        return;
      }
      // if (!purchaseOrder) {
      //   alert("Please enter purchase order");
      //   return;
      // }
    }
    setIsLoading(true);
    const fullName = document.getElementById("contactPerson").value;

    // Split the full name into first name and last name
    const [first_name, last_name] = fullName.split(" ");

    const currentDate = moment();
    const futureDate = currentDate.add(
      process.env.REACT_APP_SUBSCRIPTION_TIME,
      data.plan === "Year" ? process.env.REACT_APP_SUBSCRIPTION_UNIT : process.env.REACT_APP_SUBSCRIPTION_UNIT_MONTH
    ); // Adding 1 hour // Adding 1 hour
    const expiryDate = futureDate.format("Y-MM-DD HH:mm:ss");
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/buy_subject", {
        method: "POST",
        trial_period_tracking_id: data.subject.trial_period_tracking_id,
        order_start_date: moment().format("Y-MM-DD HH:mm:ss"),
        order_end_date: expiryDate,
        purchase_verification_status: value === "credit" ? 1 : 0,
        voucher_bought: data.noOfStudent,
        purchase_key:
          document.getElementById("purchaseOrderNumber").value.length > 0 ? document.getElementById("purchaseOrderNumber").value : "optional",
        first_name: first_name,
        last_name: last_name ? last_name : "",
        school: document.getElementById("schoolName").value,
        country: "",
        town_city: "",
        post_code: "",
        street_address: "",
        email: document.getElementById("emailAddress").value,
        phone: document.getElementById("phoneNumber").value,
        comment: document.getElementById("additionalComments").value,
        bill_paid: data.vouchersRate,
        promocode_validation_status: promocodeValidationStatus
          ? promocodeValidationStatus
          : {
              discount_percentage: 0,
              promocode_id: null,
              redeemed_status: 0,
            },
        subject_name: data.subjectName,
      })
      .then((response) => {
        setIsLoading(true);
        console.log(response);
        // navigate("/subscription");
        setOrderPlaced(true);
        getOrderDetails();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getOrderDetails = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.get["authorization"] = token;
    axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_purchase_orders",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log(res.data);
        setSubscriptionDetails(res.data.admin_data);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  useEffect(() => {
    getPublishableKey();
  }, []);

  useEffect(() => {
    createPaymentIntent();
  }, []);

  const createPaymentIntent = () => {
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/create-payment-intent",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          amount: parseInt(discountedPriceForStripeIntant.current + ((discountedPriceForStripeIntant.current * 1) / 20) * 100),
          payment_method_types: "card",
        }),
      })
      .then((res) => {
        setClientSecret(res.data.clientSecret);
        setPaymentIntent(res.data.id);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const updatePaymentIntent = () => {
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/update-payment-intent",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          amount: parseInt(discountedPriceForStripeIntant.current * 100),
          payment_intent_id: paymentIntent,
          payment_method_types: "card",
        }),
      })
      .then((res) => {
        setClientSecret(res.data.clientSecret);
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const getPublishableKey = () => {
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/config",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setStripePublishableKey(res.data.publishableKey);
        setStripePromise(loadStripe(res.data.publishableKey));
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const callChildFunction = (e) => {
    setIsLoading(true);
    if (childRef.current) {
      childRef.current.handleSubmit(e);
      setIsLoading(false);
    }
  };
  const handleAmount = () => {
    var totalAmount = (data.vouchersRate * 20) / 100 + data.vouchersRate;
    var offAmount = 0;
    if (promocodeValidationStatus) {
      offAmount = (totalAmount * promocodeValidationStatus.discount_percentage) / 100;
      setDiscountedAmount((totalAmount - offAmount).toFixed(2));
      setSaveAmount(offAmount);
      return (totalAmount - offAmount).toFixed(2);
    } else {
      return totalAmount;
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("SUBJECT_DATA: ", data);

  return (
    <div>
      <Sidebar item_id="subscription_teacher" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px]">
        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label className="dashboard-label">Checkout</label>
            <div className="teepee-divider"></div>
          </div>
        </div>
        {!orderPlaced ? (
          <div className="flex sm:flex-col md:flex-row md:justify-between  sm:ml-0 md:ml-[3.5%] md:mr-[5%] mt-[3%] mb-[5%]">
            <div className="md:w-[55%]">
              <Payment
                stripePromise={stripePromise}
                clientSecret={clientSecret}
                value={value}
                handleClickPlaceOrder={handleClickPlaceOrder}
                ref={childRef}
                handleInputFieldValidation={handleInputFieldValidation}
              />
            </div>
            <div className="container subscription-card-box-shadow rounded-xl md:!w-[35%] p-4 text-[#1D192B] md:w-[38%] sm:mt-[2%] md:mt-0">
              <div className="font-bold text-[24px] ">Your order</div>
              <div className="flex mt-[5%] justify-between font-bold text-[18px]">
                <div>Product</div>
                <div>Subtotal</div>
              </div>
              <hr className="solid"></hr>
              <div className="flex justify-between">
                <div className="flex w-[70%] font-semibold">{data.subjectName + " Membership x " + data.noOfStudent}</div>
                <div className="text-[18px]">{"£" + data.vouchersRate}</div>
              </div>
              <div className="font-semibold text-[18px] mt-[2%]">Group Title: </div>
              <div className="text-[18px]">{data.subjectName}</div>
              <hr className="solid"></hr>
              <div className="flex justify-between">
                <div className="font-semibold text-[18px]">Subtotal</div>
                <div className="text-[18px]">{"£" + data.vouchersRate}</div>
              </div>
              <hr className="solid"></hr>
              <div className="flex justify-between">
                <div className="font-semibold text-[18px]">VAT</div>
                <div className="text-[18px]">£ {parseFloat((data.vouchersRate * 20) / 100).toFixed(2)}</div>
              </div>
              <hr className="solid"></hr>
              <div className="mb-4">
                <div className="font-semibold text-[18px] hidden">
                  Optional promo code
                  <hr className="solid" />
                  <div className="relative mt-2">
                    <input
                      type="text"
                      className="form-control w-full pr-16"
                      id="firstName"
                      placeholder="Optional promo code"
                      onChange={(e) => {
                        setPromoCode(e.target.value);
                      }}
                      value={promocode}
                    />
                    <button
                      onClick={handlePromocodeValidation}
                      type="button"
                      className="absolute right-2 top-0 mt-[5px] h-[28px] w-[83px] bg-[#6750A4] text-white rounded"
                      style={{
                        display: !promocode ? "none" : "",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        lineHeight: "16px",
                        textAlign: "center",
                        letterSpacing: "0.1px",
                      }}
                    >
                      Apply code
                    </button>
                  </div>
                  {promocodeValidationStatus && (
                    <PromoCodeStatus
                      handleAmount={handleAmount}
                      promocodeValidationStatus={promocodeValidationStatus}
                      totalAmount={(data.vouchersRate * 20) / 100 + data.vouchersRate}
                      saveAmount={saveAmount}
                    />
                  )}
                </div>
              </div>

              <div className="flex justify-between">
                <div className="font-semibold text-[18px]">Total</div>
                <div className=" text-[18px]">{"£" + parseFloat(discountedAmount).toFixed(2)}</div>
              </div>
              <hr className="solid"></hr>
              <div>
                <PaymentRadioButtons value={value} handleChange={handleChange} />
              </div>
              {/* <div className={`${value !== "purchase" && "hidden"}`}>
                <PurchaseOrderDiv purchaseOrder={purchaseOrder} handleChancePurchaseOrder={handleChancePurchaseOrder} />
              </div> */}
              <p className="flex mt-2">
                <div>
                  I consent to the terms in the{" "}
                  <button onClick={() => window.open("https://teepee.ai/privacy-policy-for-teepee-ai/")}>
                    <span className="text-[#6750A4]"> Privacy policy</span>{" "}
                  </button>
                </div>
              </p>

              <div>
                <TermsAndConditions checked={checked} handleChange={handleChangeCheckBox} />
              </div>
              <div className="mt-[5%]">
                <button
                  disabled={!checked[0] || !checked[1]}
                  className="teepee-button w-full !text-[14px] !font-semibold h-[40px]"
                  style={{
                    background: !checked[0] || !checked[1] ? "grey" : "",
                    color: !checked[0] || !checked[1] ? "white" : "",
                  }}
                  onClick={(e) => (value === "credit" ? callChildFunction(e) : handleClickPlaceOrder())}
                >
                  {isLoading && (
                    <div style={{ height: "20px", width: "20px" }} className="spinner-border spinner-border-m float-left" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  Place Order
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <OrderPlacedCheckout
              subscriptionDetails={subscriptionDetails && subscriptionDetails.length > 0 ? subscriptionDetails[0] : null}
              data={data}
              discountedAmount={discountedAmount}
              value={value}
              email={email}
              purchaseOrderNumber={po}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Checkout;
