import React from "react";
import * as RiIcons from "react-icons/ri";
import MyQuizLogo from "../images/ic_my_quizzes.svg";
import UsersLogo from "../images/ic_users.svg";
import FeedbackLogo from "../images/ic_feedback.svg";
import ShareFeedbackLogo from "../images/ic_share_feedback.svg";
import ViewSubjectsLogo from "../images/ic_view_subjects.svg";
import UsersFeedbackLogo from "../images/ic_users_feedback.svg";
import EvaluationFeedbackLogo from "../images/ic_evaluation_feedback.svg";
import promo_code_icon from "../images/promocode.png";
import Home from "../images/icon.png";
import Tracking from "../images/Tracking.png";
import OnBoard from "../images/luggage_ 1.png";
import Video from "../images/video.png";
import Tutorials from "../images/sound_sampler.png";
import Dashboard from "../images/dashboard.png";
import KeyMatrics from "../images/key_ 1.png";
import Subject from "../images/analytics.png";
import contact from "../images/mail.png";
import evExp from "../images/Vector.svg";
import Subscribe from "../images/credit_card.png";
import notification_icon from "../images/notification.svg";
import pricing_icon from "../images/pricing.svg";
export const SidebarData = [
  {
    id: "quiz",
    title: "Home",
    path: "/",
    backgroundColor: "transparent",
    icon: Home,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    id: "dashboard",
    title: "Dashboard",
    path: "/key-metrics",
    icon: Dashboard,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        id: "dashboard",
        title: "Key Metrics",
        path: "/key-metrics",
        icon: KeyMatrics,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        id: "subject-analysis",
        title: "Subject Analysis",
        path: "/subject-analysis",
        icon: Subject,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
    ],
  },
  // {
  //   id: "folder",
  //   title: "My Folders",
  //   path: "/dashboard/folders",
  //   backgroundColor: "transparent",
  //   icon: MyQuizLogo,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  {
    id: "user_option",
    title: "Users",
    backgroundColor: "transparent",
    path: "/users",
    icon: UsersLogo,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  // {
  //   id: "tutorials",
  //   title: "Tutorials",
  //   backgroundColor: "transparent",
  //   path: "/tutorials",
  //   icon: Tutorials,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },
  {
    id: "tracking_analytics",
    title: "Tracking analytics",
    backgroundColor: "transparent",
    path: "/tracking_analytics",
    icon: Tracking,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    id: "report_problem",
    title: "Share feedback",
    path: "/report_problem",
    icon: ShareFeedbackLogo,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    id: "contact_us",
    title: "Contact us",
    path: "/contact_us",
    icon: contact,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    id: "subscription_teacher",
    title: "Subscription",
    path: "/subscription",
    icon: Subscribe,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    id: "configure_subscription",
    title: "Subscription",
    path: "/subscription-tracking",
    icon: Subscribe,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        id: "tracking_sub",
        title: "Tracking",
        path: "/subscription-tracking",
        icon: contact,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        id: "subscription",
        title: "Subscription Details",
        path: "/subscription",
        icon: UsersFeedbackLogo,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        id: "configure",
        title: "Configure",
        path: "/configure",
        icon: EvaluationFeedbackLogo,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        id: "promo_code",
        title: "Promocode",
        path: "/promo_code",
        icon: promo_code_icon,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
    ],
  },
  {
    id: "view_subject",
    title: "View Subjects",
    path: "/view_subject",
    icon: ViewSubjectsLogo,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    id: "view_onboarding_subject",
    title: "Onboarding",
    path: "/onboarding-subject",
    icon: OnBoard,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        id: "view_onboarding_subject",
        title: "Onboarding Subjects",
        path: "/onboarding-subject",
        icon: OnBoard,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        id: "interest_analytics",
        title: "Interest Analytics",
        path: "/interest-analytics",
        icon: EvaluationFeedbackLogo,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        id: "onboarding_video",
        title: "Onboarding Videos",
        path: "/onboarding-video",
        icon: Video,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        id: "evaluation_experience",
        title: "Evaluation Experience",
        path: "/evaluation-experience",
        icon: evExp,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
    ],
  },

  // {
  //   id:"view_subject_detail",
  //   title: "View Subject Detail",
  //   path: "/view_subject_detail",
  //   icon: FeedbackLogo,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  // },

  {
    id: "users_feedback",
    title: "Feedback",
    path: "/users_feedback",
    icon: FeedbackLogo,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        id: "users_feedback",
        title: "Users Feedback",
        path: "/users_feedback",
        icon: UsersFeedbackLogo,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        id: "evaluation_feedback",
        title: "Evaluation Feedback",
        path: "/evaluation_feedback",
        icon: EvaluationFeedbackLogo,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
      {
        id: "contact_us2",
        title: "Email Us",
        path: "/email_us",
        icon: contact,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
      },
    ],
  },
  {
    id: "custom_notification",
    title: "Custom notification",
    backgroundColor: "transparent",
    path: "/custom_notification",
    icon: notification_icon,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    id: "pricing",
    title: "Pricing",
    backgroundColor: "transparent",
    path: "/admin_pricing",
    icon: pricing_icon,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
];
