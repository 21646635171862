import React from "react";
import trophyIcon from "../../../images/trophy2.svg"; // Replace with actual path
import starIcon from "../../../images/star2.svg";
import searchIcon from "../../../images/search2.svg";
import targetIcon from "../../../images/spot2.svg";
import magicIcon from "../../../images/magic.svg";

const RewardsList = ({ quizScore, recallStatusCount, quizTotalMarks }) => {
  const rewardsData = [
    {
      icon: trophyIcon,
      title: "Quiz Champion",
      multiplier: <img src={magicIcon} alt="Magic Icon" className="w-[32px] h-[32px] inline float-right mr-2" />,
      xp: 50,
    },
    { icon: starIcon, title: "Flawless Victory", multiplier: null, xp: quizScore === quizTotalMarks ? 100 : "0" },
    { icon: searchIcon, title: "Question Mastery", multiplier: `${recallStatusCount} × 200 XP`, xp: recallStatusCount * 200 },
    { icon: targetIcon, title: "Spot-On", multiplier: `${quizScore} × 10 XP`, xp: quizScore * 10 },
  ];

  return (
    <div className="bg-[#F1EEF6] p-4 rounded-lg shadow-md w-full sm:w-[98%] md:w-[100%] relative">
      {/* Vertical Divider Line (Visible on larger screens only) */}
      <div className="hidden md:block absolute top-4 bottom-4 left-[78%] w-0.5 bg-[#D7D5DA]"></div>

      {/* Rewards List Items */}
      {rewardsData.map((reward, index) => (
        <div key={index} className="grid grid-cols-[60%_20%_20%] items-center py-2 border-b last:border-b-0 border-gray-200">
          {/* First Column: Icon and Title */}
          <div className="flex items-center space-x-2">
            <img src={reward.icon} alt={reward.title} className="w-6 h-6" />
            <div className="text-[16px] leading-[24px] font-medium text-[#252744]" style={{ fontFamily: "Roboto" }}>
              {reward.title}
            </div>
          </div>

          {/* Second Column: Multiplier */}
          <div className="text-[16px] leading-[24px] text-[#252744]" style={{ fontFamily: "Roboto" }}>
            {reward.multiplier || ""}
          </div>

          {/* Third Column: XP */}
          <div className="text-[16px] leading-[24px] text-[#252744] text-right" style={{ fontFamily: "Roboto" }}>
            + {reward.xp} XP
          </div>
        </div>
      ))}
    </div>
  );
};

export default RewardsList;
