import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function CustomDialog({ open, handleClose, header, message }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="custom-dialog-title"
      PaperProps={{
        sx: {
          padding: "16px",
          width: "400px",
        },
      }}
    >
      <DialogTitle
        id="custom-dialog-title"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <b> {header}</b>
        <IconButton onClick={handleClose} style={{ padding: 0, top: "-20px", right: "-20px" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            fontSize: "14px",
            color: "#000",
            textAlign: "left",
          }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          style={{
            backgroundColor: "#6750A4",
            color: "#fff",
            padding: "8px 16px",
            textTransform: "none",
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
