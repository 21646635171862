import axios from "axios";
import {
  REACT_APP_REST_API_BASE_URL,
  REACT_APP_INSERT_PROMOCODE_END_POINT,
} from "../../Constants/ApiConstant";

const useInsertPromoCode = () => {
  const insertPromoCode = (callback, promo_code, discount, setData) => {
    const access_token = "Bearer " + localStorage.getItem("access_token");

    axios
      .post(
        REACT_APP_REST_API_BASE_URL + REACT_APP_INSERT_PROMOCODE_END_POINT,
        {
          promocode: promo_code,
          promocode_disccount_percentage: discount,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        callback(response.data);
        const new_values = [
          {
            promocode_id: response.data,
            promocode: promo_code,
            discount_percentage: discount,
            status: 0,
          },
        ];
        setData((prevData) => [...prevData, ...new_values]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return insertPromoCode;
};

export default useInsertPromoCode;
