import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import QuizReviewCard from "./QuizReviewCard";
import Sidebar from "./Sidebar";
import Download from "../images/download.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import QuizReviewDocument from "./QuizReviewDocument";
import QuizReviewDocumentMinimock from "./QuizReviewDocumentMinimock";
import { cleanString } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

function QuizDetailReview() {
  const location = useLocation();
  const navigate = useNavigate();
  const { student_name } = location.state ? location.state : "";
  const { quiz_secret_key } = location.state ? location.state : "";
  const { quiz_response_id } = location.state ? location.state : "";
  const { is_code } = location.state ? location.state : "";
  // const { quiz_performace } = location.state ? location.state : "";
  const quiz_performace = useRef(0);
  const [quiz_performance, setQuiz_performance] = useState(0);
  const { show_teacher_feedback } = location.state ? location.state : "";
  const { quiz_total_marks } = location.state ? location.state : "";
  const { rubric } = location.state ? location.state : "";
  const { title } = location.state ? location.state : "";
  const authtype = localStorage.getItem("user_type");
  const [quizData, setQuizData] = useState([]);
  const [quizType, setQuizType] = useState();
  const [mock_exam, setMockExam] = useState(null);
  const [isEditing, setIsEditing] = useState(-1);
  const pdfRef = useRef();
  const [percentage, setPercentage] = useState("");
  const [isAnswerRubric, setIsAnswerRubric] = useState();
  const class_code = is_code?.toString();
  useEffect(() => {
    console.log("code here is", class_code);
    getQuizReviewData();
  }, [quiz_secret_key, quiz_response_id]);

  const getQuizReviewData = () => {
    console.log("rubric is:", quiz_response_id);
    var token = "Bearer " + localStorage.getItem("access_token");
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_question_response_list_for_teacher",

        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
        data: JSON.stringify({
          quiz_secret_key: quiz_secret_key,
          quiz_response_id: quiz_response_id,
          get_teacher_feedback: show_teacher_feedback,
          rubric: rubric,
        }),
      })
      .then((res) => {
        console.log("total marks=" + location.state?.quiz_total_marks);
        console.log("answer rubric here", res.data["show_answer_rubric"]);
        setIsAnswerRubric(res.data["show_answer_rubric"]);
        setPercentage(res.data["percentage"] !== null && res.data["percentage"] ? res.data["percentage"] : 0);
        console.log("Total Marks: ", res.data["percentage"]);

        const questionResponseList = JSON.parse(res.data["question_reponse_list"]);
        const totalScore = questionResponseList.reduce((accumulator, currentQuiz) => accumulator + currentQuiz.score, 0);
        let calculatedPerformance = totalScore ? totalScore : 0;

        // Set the quiz data and calculated performance
        setQuizData(questionResponseList);
        quiz_performace.current = calculatedPerformance; // Assuming this is a ref to hold the performance value
        setQuiz_performance(calculatedPerformance);
        setQuizType(res.data["quiz_type"]);
        console.log("true", res.data);
        if (res.data["mock_exam"].length > 0) {
          setMockExam(JSON.parse(res.data["mock_exam"]));
        }
      });
  };

  const handleEditMarksButton = (index) => {
    console.log("SAVED INDEX", index);
    setIsEditing(index);
  };
  const handleCloseEditMarksButton = () => {
    setIsEditing(-1);
  };
  const handleBack = (e) => {
    e.preventDefault(); // Prevents the default action, if necessary

    if (authtype === "aa") {
      navigate("/student-view-classroom"); // Navigates to the student classroom view if authtype is "3"
    } else {
      navigate(-1); // Navigates one step back in the history
    }
  };

  return (
    <>
      <Sidebar item_id="quiz"></Sidebar>
      <div id="moving" className="feedback md:w-[850px] md:ml-[380px] sm:ml-[10px] sm:mr-[10px] sm:w-[95%] ">
        <div className="home !text-[#6750A4] sm:ml[25px] md:ml-[60px] underline teacher_navigation_text mt-[1.5vw] mb-2">
          <button className="" onClick={handleBack}>
            {"Back"}
          </button>
        </div>
        <div className="home sm:ml[25px] md:ml-[60px] mt-[20px] justify-between">
          {/* <div className="view-result-quiz-name">{title}</div> */}
          <div>
            <div className="view-result-quiz-name md:w-[340px] md:text-[26px] sm:text-[20px] xs:text-[20px] sm:mt-[1px]">Task Detail Review</div>
          </div>
          <div>
            {quizType === 1 || quizType === 3 || (mock_exam === null && quizType === 2) ? (
              <>
                {/* <PDFViewer>
                  <QuizReviewDocument
                    quizData={quizData}
                    student_name={student_name}
                    marks_achieved={quiz_performace.current}
                  />
                </PDFViewer> */}
                <PDFDownloadLink
                  document={
                    <QuizReviewDocument
                      is_code={class_code}
                      isAnswerRubric={isAnswerRubric}
                      quizData={quizData}
                      student_name={student_name}
                      marks_achieved={quiz_performance}
                      display_rubric={isAnswerRubric === 1 ? false : true}
                      percentage={percentage}
                      quiz_total_marks={quiz_total_marks}
                    />
                  }
                  fileName={`${title}` + "_" + `${student_name}` + ".pdf"}
                >
                  <button className="flex downloadCSV-button items-center">
                    <img src={Download} className="mr-[8px]" />
                    <div>Download PDF</div>
                  </button>
                </PDFDownloadLink>
              </>
            ) : (
              mock_exam !== null &&
              quizData.length > 0 && (
                <>
                  {/* <PDFViewer>
                    <QuizReviewDocumentMinimock
                      mock_exam={mock_exam}
                      quizData={quizData}
                    />
                  </PDFViewer> */}
                  <PDFDownloadLink
                    document={
                      <QuizReviewDocumentMinimock
                        mock_exam={mock_exam}
                        quizData={quizData}
                        display_rubric={isAnswerRubric === 1 ? false : true}
                        student_name={student_name}
                        marks_achieved={quiz_performance}
                        percentage={percentage}
                        quiz_total_marks={quiz_total_marks}
                      />
                    }
                    fileName={`${title}` + "_" + `${student_name}` + ".pdf"}
                  >
                    <button className="flex downloadCSV-button items-center sm:w-[100%]">
                      <img src={Download} className="mr-[8px]" />
                      <div>Download PDF</div>
                    </button>
                  </PDFDownloadLink>
                </>
              )
            )}
          </div>
        </div>
        <div className=" md:ml-[60px] mt-[20px]">
          <div className="md:flex">
            <div className="student-info md:w-[60%]">
              <label className="teepee-label mr-[10px]">Student Name:</label>
              <div>{student_name}</div>
            </div>
            <div className="teepee-label md:justify-end sm:pt-[30px] md:pt-0 md:w-[40%]">
              Marks Achieved:
              <div className="ml-2"> {quiz_performance + "/" + quiz_total_marks}</div>
            </div>
          </div>

          <div className="mt-[65px]">
            <QuizReviewCard
              readOnly={true}
              is_code={class_code}
              isAnswerRubric={isAnswerRubric}
              display_rubric={true}
              show_teacher_feedback={show_teacher_feedback}
              viewThumbsAction={true}
              quizData={quizData}
              mock_exam={mock_exam}
              quizType={quizType}
              handleEditMarksButton={handleEditMarksButton}
              handleCloseEditMarksButton={handleCloseEditMarksButton}
              isEditing={isEditing}
              quiz_total_marks={quiz_total_marks}
              quiz_response_id={quiz_response_id}
              setQuiz_performance={setQuiz_performance}
              quiz_performance={quiz_performance}
              getQuizReviewData={getQuizReviewData}
            ></QuizReviewCard>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuizDetailReview;
