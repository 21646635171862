import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Cross from "../../../images/cross.png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import appContext from "../../../context/appContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import amplitude from "amplitude-js";
import moment from "moment";

function CreateClassroomDialogue(props) {
  const state = useContext(appContext);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState();
  const newSelectedSubject = useRef();
  const classNameLength = useRef(0);
  const navigate = useNavigate();
  const [inputText, setInputText] = useState("");
  const [trialState, setTrialState] = useState();

  useEffect(() => {
    newSelectedSubject.current = "";
    if (props.openCreateClassroomDialogue) {
      getSubjectsList();
    }
  }, [props.openCreateClassroomDialogue]);

  useEffect(() => {
    if (props.openCreateClassroomDialogue === false) {
      classNameLength.current = 0;
    }
  }, [props.openCreateClassroomDialogue]);

  const onChangeSubject = (e) => {
    var selectedSubjectName = e.target.value;
    var selectedSubject = subjects.filter((subject) => selectedSubjectName.includes(subject.subject_name));
    setSelectedSubject(selectedSubject[0]);
    newSelectedSubject.current = selectedSubject[0];
  };

  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log(res.data);
        setSubjects(res.data);
        // newSelectedSubject.current = res.data[0];
      });
  };
  const subjectList = subjects.map((subject) => <option id={subject.subject_id}>{subject.subject_name}</option>);

  const createClassroom = () => {
    console.log("SELECTED SUBJECT IS: ", newSelectedSubject.current);
    let class_name = document.getElementById("class_name").value;
    if (state.selectedSubjectForPopUp !== null) {
      var selectedSubject = subjects.filter((subject) => state.selectedSubjectForPopUp.subject_name.includes(subject.subject_name));
      setSelectedSubject(selectedSubject[0]);
      newSelectedSubject.current = selectedSubject[0];
    }
    if (class_name.length <= 0 || newSelectedSubject.current === undefined || class_name.trim().length === 0) {
      alert("Please enter all the details");
      return;
    }

    // console.log("SUBJECT INFROMATION: ", selectedSubject[0].subject_id);

    const currentDate = moment();
    const futureDate = currentDate.add(1, "hours"); // Adding 1 hour
    const expiryDate = futureDate.format("Y-MM-DD HH:mm:ss");

    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_classroom", {
        method: "POST",
        // user_feedback: user_feedback,
        subject_id: newSelectedSubject.current.subject_id,
        class_name: document.getElementById("class_name").value,
        trial_start_date: moment().format("Y-MM-DD HH:mm:ss"),
        trial_end_date: expiryDate,
      })
      .then((response) => {
        // state.setSelectedSubjectForPopUp(newSelectedSubject.current);
        setTrialState(response.data.state);
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("CreateClass");
        console.log(response.data.class_data);
        if (typeof response.data.class_data === "string") {
          alert(response.data.class_data);
        } else {
          if (response.data.state === 0 || response.data.state === -1) {
            // Old flow before trial period
            state.handleCloseCreateClassroomDialogue();
            state.setOpenShareClassroomDialogue(true);
            state.setClassroomData(response.data.class_data);
            amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
            amplitude.getInstance().logEvent("ICSelect Subject [" + newSelectedSubject.current.subject_name + "]");
            navigate("/teacher-classroom-home?id=" + response.data.class_data.classroom_id);
          } else {
            //New Flow After Trial Period Requirement
            // state.handleOpenTrialGetStartedPopup();
            // startTrialPeriod();
            state.handleCloseCreateClassroomDialogue();
            state.setClassroomData(response.data.class_data);
            state.setSelectedSubjectForClass(newSelectedSubject.current.subject_name);

            amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
            amplitude.getInstance().logEvent("ICSelect Subject [" + newSelectedSubject.current.subject_name + "]");
            navigate("/teacher-classroom-home?id=" + response.data.class_data.classroom_id);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onChange = (e) => {
    setInputText(e.target.value);
  };
  const characterCount = inputText.length;

  const handleRequestedUserFlow = () => {
    if (localStorage.getItem("email_address") === process.env.REACT_APP_REQUESTED_USER && state.selectedSubjectIdForClass === 10) {
      return 0;
    } else {
      return 1;
    }
  };
  const startTrialPeriod = () => {
    console.log("ID OF THE SUBJECT: ", state.selectedSubjectIdForClass);
    const currentDate = moment();
    const futureDate = currentDate.add(1, "hours"); // Adding 1 hour
    const expiryDate = futureDate.format("Y-MM-DD HH:mm:ss");
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/start_trial_period", {
        method: "POST",
        // user_feedback: user_feedback,
        subject_id: newSelectedSubject.current.subject_id,
        trial_start_date: moment().format("Y-MM-DD HH:mm:ss"),
        trial_end_date: expiryDate,
        request_user: handleRequestedUserFlow(),
      })
      .then((response) => {
        console.log("response: ", response);
        // state.handleCloseTrialGetStartedPopup();
        state.handleOpenTrialAcknowledgePopup();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div>
      {/* <ShareClassCodeDialogue
        handleCloseShareClassroomDialogue={handleClose}
        openShareClassroomDialogue={openShareClassroomDialogue}
        classroomData={classroomData !== null ? classroomData : ""}
      /> */}
      <Dialog
        PaperProps={{
          sx: {
            width: "768px",
            height: "562px",
            maxWidth: "768px",
            height: "562px",
            maxHeight: "562px",
          },
        }}
        open={props.openCreateClassroomDialogue}
        onClose={props.handleClickCloseCreateClassroomDialogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="flex">
          <button onClick={() => props.handleClickCloseCreateClassroomDialogue()}>
            <img src={Cross}></img>
          </button>
          <div className="text-center w-[100%] create_new_classroom_dialogue_title">Create new class</div>
        </DialogTitle>
        <hr className="mt-0 "></hr>

        <div>
          <DialogContent className="sm:ml-[4px] sm:mr-[4px] md:ml-[32px] md:mr-[32px]">
            <DialogContentText id="new-classroom-dialog-description">
              <div>
                <label className="create_your_class_label">Create your class</label>
              </div>
              <div>
                <label className="class_dialogue_label">Enter your Class name</label>
              </div>
              <div>
                <label className="class_name_hint_label">This name is what your students will see</label>
              </div>
            </DialogContentText>
            <div>
              <input
                onChange={onChange}
                type="text"
                name="class_name"
                id="class_name"
                className="class_name_input_create pl-[23px] sm:w-[100%] md:w-[100%]  "
                placeholder="e.g., Year 9 GCSE Computer Science"
                maxLength={50}
                required
              />
            </div>
            <div className="float-right pt-[5px]">{characterCount}/50</div>
            <div>
              <label className="class_dialogue_label mt-[34px] mb-[12px]">Select your Subject</label>
            </div>
            <div>
              <div>
                <select
                  value={
                    state.selectedSubjectForPopUp !== null
                      ? state.selectedSubjectForPopUp.subject_name
                      : newSelectedSubject.current && newSelectedSubject.current.subject_name
                  }
                  disabled={state.selectedSubjectForPopUp !== null}
                  onChange={onChangeSubject}
                  name="quizSubject"
                  className="class_name_input_create shadow-none leading-[30px]  mb-5 font-[300] px-4 sm:w-[100%] md:w-[100%]  h-[100%] "
                  style={{
                    backgroundColor: state.selectedSubjectForPopUp !== null && "#1C1B1F1F",
                  }}
                >
                  <option value="" disabled selected hidden></option>
                  {subjectList}
                </select>
              </div>
            </div>
          </DialogContent>
        </div>
        <hr style={{ marginTop: "auto", width: "100%", marginBottom: "0px" }}></hr>

        <DialogActions
          style={{
            marginRight: "15px",
          }}
        >
          <button
            style={{ width: "110px" }}
            id="button"
            onClick={createClassroom}
            className="teepee-button md:ml-12 hover:bg-black sm:w-[50%] md:w-[46%]"
          >
            Next
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CreateClassroomDialogue;
