import React from "react";
import { CheckCircle2 } from "lucide-react";

function QuizCopiedToaster() {
  return (
    <div className="fixed top-4 right-4 z-50">
      <div className="bg-green-50 border border-green-100 rounded-lg p-4 flex items-start shadow-sm">
        <CheckCircle2 className="h-4 w-4 text-green-600 mt-1" />
        <div className="ml-2">
          <div className="text-green-800">
            <div className="font-medium">Quiz link copied!</div>
            <div className="text-sm text-green-700">Share it with your students to get started.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizCopiedToaster;
