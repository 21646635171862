import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import OnBoardingSubjectsWidgetTeacher from "./OnBoardingSubjectsWidgetTeacher";
import NewSubjectDialogue from "./NewSubjectDialogue";
import TeepeeIcon from "../../images/avatar4.png";
import { useNavigate } from "react-router-dom";
import amplitude from "amplitude-js";

function OnBoarding() {
  const [subjectsList, setSubjectsList] = useState([]);
  const [activeButtons, setActiveButtons] = useState([]);
  const [openSubjectDialogue, setOpenSubjectDilaogue] = useState(false);
  const [inputText, setInputText] = useState("");
  const [loadTime, setLoadTime] = useState(false);
  const navigate = useNavigate();

  const handleOpenSubjectDialogue = () => {
    setOpenSubjectDilaogue(true);
  };
  const handleCloseSubjectDialogue = () => {
    setOpenSubjectDilaogue(false);
    setInputText("");
  };
  const handleClickContinue = () => {
    handleLetsGoButton(3);
  };
  const onChangeNumber = (e) => {
    setInputText(e.target.value);
  };

  const handleOnClick = (subject_id, index) => {
    if (index !== subjectsList.length - 1) {
      if (activeButtons.includes(subject_id)) {
        setActiveButtons((prev) => prev.filter((button) => button !== subject_id));
      } else {
        setActiveButtons((prev) => [...prev, subject_id]);
      }
    } else {
      handleOpenSubjectDialogue();
    }
  };
  useEffect(() => {
    amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
    amplitude.getInstance().logEvent("InterestsPageviews");
    getSubjectsList();
  }, []);

  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_onboarding_subjects",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log(JSON.parse(res.data.data));
        let array = [];
        array = JSON.parse(res.data.data);
        array.push("");
        setSubjectsList(array);
      });
  };
  const handleAmplitude = (status, subjectData) => {
    if (status === 0) {
      for (let index = 0; index < subjectData.length; index++) {
        const subject = subjectData[index];
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent(`Pick${subject}`);
      }
    }
    if (status === 1) {
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("SkipPersonalization");
    }
    if (status === 3) {
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("WhereismySubject");
    }
  };
  const handleLetsGoButton = (status) => {
    if (status === 3) {
      if (inputText.length === 0) {
        alert("Please enter the subject name");
        return;
      }
    }
    let subject_data = [];
    subject_data = subjectsList
      .filter((subject) => activeButtons.includes(subject.subject_interest_id))
      .map((subject) => subject.subject_interest_id);
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/insert_new_user_have_interest", {
        method: "POST",
        subject_data: status === 1 ? "Skip personalization" : status === 0 ? subject_data.toString() : inputText.concat(" (other)"),
      })
      .then((response) => {
        console.log(response);
        navigate("/", { replace: true });
        if (status === 3) {
          handleCloseSubjectDialogue();
        }
        amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        amplitude.getInstance().logEvent("InterestsPageActiontaken");
        handleAmplitude(
          status,
          subjectsList.filter((subject) => activeButtons.includes(subject.subject_interest_id)).map((subject) => subject.subject_name)
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <NewSubjectDialogue
        subjectDialogue={openSubjectDialogue}
        handleCloseDialogue={handleCloseSubjectDialogue}
        handleClickContinue={handleClickContinue}
        onChangeNumber={onChangeNumber}
        inputText={inputText}
      />
      <div className="jess_icon left-[35px] md:left-[10%] sm:left-[25%]">
        <img src={TeepeeIcon} alt="" />
      </div>
      <div className="w-[100%] md:mt-[10%] sm:mt-[2%]">
        <div className="h-[100%] flex items-center justify-center flex-col ">
          <div className="welcome-teepee-text md:w-[40%] md:text-left sm:w-[90%] sm:text-center mb-[4px] sm:mt-[30%] md:mt-0">
            Welcome to Teepee.ai!
          </div>
          <div className="welcome-teepee-sub-text w-[40%] sm:w-[90%] md:w-[40%] sm:text-center md:text-left mb-[16px]">
            Choose your subject, and let Teepee create a personalised experience for you.
          </div>

          <OnBoardingSubjectsWidgetTeacher subjectsList={subjectsList} handleOnClick={handleOnClick} activeButton={activeButtons} />
          <div className="md:relative flex mt-[2%] justify-between md:w-[40%] sm:w-[90%] sm:flex-col md:flex-row mb-[3%] ">
            <button
              disabled={activeButtons.length === 0 ? true : false}
              className={
                activeButtons.length > 0
                  ? "teepee-button md:absolute md:right-0 sm:mb-[10%]"
                  : "teepee-disabled-button md:absolute md:right-0 sm:mb-[10%]"
              }
              onClick={() => handleLetsGoButton(0)}
            >
              Let's Go!
            </button>
            <button className="skip float-left" onClick={() => handleLetsGoButton(1)}>
              Skip personalization
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default OnBoarding;
