import React, { Fragment, useEffect, useState, useCallback } from "react";
import Sidebar from "./Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import EdiText from "react-editext";
import MockExamAccordian from "./MockExamAccordian";
import ReactSwitch from "react-switch";
import Button from "@mui/material/Button";
import YesNoDialog from "./YesNoDialog";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import IncDecCounter from "./IncDecCounter";
import ConfirmationDialogue from "./Teacher/confirmationdialogue/ConfirmationDialogue";
function AddExamPaper(props) {
  // const search = window.location.search;
  // const params = new URLSearchParams(search);
  // var id = props.subject_id;
  const [show, setShow] = useState(false);
  const [mockExamsList, setMockExamsList] = useState();
  const [deletingObject, setDeletingObject] = useState([null, null]);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [papers, setPapers] = useState([]);
  const [open, setOpen] = useState(false);
  const [deletedId, setDeletedId] = useState();
  const onDeleteClick = (object, type) => {
    setOpenConfirmationDialog(true);
    setDeletingObject([object, type]);
  };

  const handleCloseWithNo = () => {
    setOpenConfirmationDialog(false);
  };

  const handleCloseWithYes = () => {
    setOpenConfirmationDialog(false);
    var type = deletingObject[1];
    var id = null;
    if (type === process.env.REACT_APP_DELETING_OBJECT_TYPE_CHAPTER) {
      id = deletingObject[0].chapter_id;
    } else if (type === process.env.REACT_APP_DELETING_OBJECT_TYPE_TOPIC) {
      id = deletingObject[0].topic_id;
    } else if (type === process.env.REACT_APP_DELETING_OBJECT_TYPE_SUBTOPIC) {
      id = deletingObject[0].sub_topic_id;
    }

    sendDeleteRequest(type, id);
  };

  function sendDeleteRequest(type, object_id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/delete_content", {
        method: "POST",
        type: type,
        id: object_id,
      })
      .then((response) => {
        alert("successfully deleted");
        props.addTodo();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to delete the content");
      });
  }

  function addNewMockExam() {
    // alert(subject.subject_name);

    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_mock_exam", {
        method: "POST",
        subject_id: props.subject_data.subject_id,
      })

      .then((response) => {
        //props.addTodo();
        getMockExams(subjectData.subject_id);
      })
      .catch((error) => {
        console.log(error);
        alert("unable to add new module");
      });
  }

  const deleteMockExam = (mock_exam_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/delete_mock_exam", {
        method: "POST",
        mock_exam_id: mock_exam_id,
      })

      .then((response) => {
        getMockExams(subjectData.subject_id);
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [subjectData, setSubjectData] = React.useState(props.subject_data);

  useEffect(() => {
    getMockExams(subjectData.subject_id);
    getPaperCategories(subjectData.subject_id);
  }, [subjectData]);

  const handlePaper = (paper) => {
    setPapers(papers.concat("paper " + `${++paper}`));
  };

  const getPaperCategories = (subject_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_paper_category", {
        method: "POST",
        subject_id: subject_id,
      })

      .then((response) => {
        console.log("RESPONSE IS: ", response);
        setPapers(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getMockExams(subject_id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/get_list_of_mock_exams",
        {
          method: "POST",
          subject_id: subject_id,
        }
      )

      .then((response) => {
        var mock_exams_list = JSON.parse(response.data["mock_exams_list"]);

        console.log("MOCK", mock_exams_list);

        setMockExamsList(mock_exams_list);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const addTodo = () => {
    console.log("CALLED");
    getMockExams(props.subject_data.subject_id);
  };

  const handleChange = (value, mock_exam_id) => {
    var mock_exams_list = [];

    for (var index = 0; index < mockExamsList.length; index++) {
      if (mockExamsList[index].mock_exam_id === mock_exam_id) {
        mockExamsList[index].is_enabled = value;
      }
      mock_exams_list.push(mockExamsList[index]);
    }
    setMockExamsList(mock_exams_list);
    sendMockExamStatusUpdateRequest(value, mock_exam_id);
  };

  function sendMockExamStatusUpdateRequest(value, mock_exam_id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/update_mock_exam_status",
        {
          method: "POST",
          is_enabled: value,
          mock_exam_id: mock_exam_id,
        }
      )
      .then((response) => {
        setShow(true);
      })
      .catch((error) => {
        console.log(error);
        alert("failed to change mock exam status");
      });
  }

  const editMockExamQuestion = (value, index, parentIndex) => {
    let newArrayParent = [...mockExamsList];
    let newArray = [...mockExamsList];
    newArray = JSON.parse(newArray[parentIndex].question_context_list);
    newArray[index].question_context_name = value;
    newArrayParent[parentIndex].question_context_list =
      JSON.stringify(newArray);
    setMockExamsList(newArrayParent);
  };

  // const paperList = papers.map((paper) => <option>{paper.paper_name}</option>);

  const getPaperCategoriesList = (minimock) => {
    const paperList = papers.map((paper) => (
      <option
        id={paper.paper_id}
        selected={minimock.category === paper.paper_id}
      >
        {paper.paper_name}
      </option>
    ));

    return paperList;
  };

  const handleCloseWithConfirm = () => {
    deleteMockExam(deletedId);
  };
  const handleCloseWithCancel = () => {
    setOpen(false);
  };

  const handleOpenDeleteDialogue = (deletedId) => {
    setDeletedId(deletedId);
    setOpen(true);
  };

  return (
    <div>
      <ConfirmationDialogue
        title={"Delete"}
        text={"Are you sure you want to delete?"}
        handleCloseWithConfirm={handleCloseWithConfirm}
        handleCloseWithCancel={handleCloseWithCancel}
        open={open}
      />
      <ToastContainer className="p-3 position-fixed top-12 right-0">
        <Toast
          style={{ backgroundColor: "#f7f2f9" }}
          onClose={() => setShow(false)}
          show={show}
          delay={5000}
          autohide
        >
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">We're on it!</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>Mock Exam status changed</Toast.Body>
        </Toast>
      </ToastContainer>
      <YesNoDialog
        handleCloseWithNo={handleCloseWithNo}
        handleCloseWithYes={handleCloseWithYes}
        open={openConfirmationDialog}
        title={"Confirm"}
        description={"Are you sure you want to delete this record?"}
      />
      <div className="form-div">
        <div className="ml-[30px] mr-[30px] w-full">
          <div style={{}}>
            <div>
              <p
                style={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  color: "#49454F",
                }}
              >
                {mockExamsList?.map((mockExam, chapterIndex) => (
                  <Fragment key={chapterIndex}>
                    <div style={{}}>
                      <div
                        style={{
                          padding: "10px",
                          borderRadius: "5px",
                          width: "100%",
                          marginBottom: "30px",
                          backgroundColor: "#F0F0F0",
                        }}
                      >
                        <table className="w-full">
                          <tr>
                            <td>
                              <p
                                className="mt-[10px]"
                                style={{
                                  fontSize: "16px",
                                  fontFamily: "Roboto",
                                  color: "#49454F",
                                }}
                              >
                                <b>Mock Exam: {chapterIndex + 1}</b>
                              </p>
                            </td>
                            <td>
                              <ReactSwitch
                                className="float-right"
                                checked={mockExam.is_enabled}
                                onChange={(value) =>
                                  handleChange(value, mockExam.mock_exam_id)
                                }
                              />
                            </td>
                          </tr>
                        </table>
                        <p>
                          <MockExamAccordian
                            addTodoParent={props.addTodoParent}
                            addTodo={addTodo}
                            mock_exam={mockExam}
                            deleteMockExam={deleteMockExam}
                            mockExamsListIndex={chapterIndex}
                            editMockExamQuestion={editMockExamQuestion}
                            subject={props.subject_data}
                            papers={papers}
                            // paperList={paperList}
                            handlePaper={handlePaper}
                            getPaperCategories={getPaperCategories}
                            getPaperCategoriesList={getPaperCategoriesList}
                            handleOpenDeleteDialogue={handleOpenDeleteDialogue}
                            predefinedQuestionsPromptList={
                              props.predefinedQuestionsPromptList
                            }
                          />
                        </p>
                      </div>
                    </div>

                    <div
                      style={{
                        flex: 1,
                        height: "1px",
                        backgroundColor: "#B3B3B3",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Fragment>
                ))}
              </p>
            </div>
          </div>
          <Button
            onClick={addNewMockExam}
            className="delete-button"
            style={{ color: "#1976d2", padding: "6px 8px" }}
          >
            Add New MOCK EXAM
          </Button>
          <div
            style={{
              flex: 1,
              height: "1px",
              backgroundColor: "#B3B3B3",
              marginTop: "100px",
              marginBottom: "10px",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default AddExamPaper;
