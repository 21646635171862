import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import QuizSettingsWidgetTeacher from "./QuizSettingsWidgetTeacher";
import TeepeeIcon from "../../images/avatar4.png";
import { useLocation, useNavigate } from "react-router-dom";
import amplitude from "amplitude-js";
import Transition from "./Transition";
export const SelectSubject = () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  var id = params.get("id");
  const location = useLocation("");
  const [subjectsList, setSubjectsList] = useState([]);
  const [activeButtons, setActiveButtons] = useState([]);
  const [loadTime, setLoadTime] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      const localstate = localStorage.getItem("experience");
      var subjectArray = subjectsList.filter((subjectObj) => {
        return subjectObj.subject_id === parseInt(id);
      });

      var subject = subjectArray[0];

      setLoadTime(true);
      const timeoutId = setTimeout(() => {
        navigate(`/quiz?id=${subject.exam_style[0].secret_key}`, {
          state: {
            subject_name: subject.subject_name,
            minimock: subject.minimock,
            quiz_setting: 1,
            exam_style: subject.exam_style,
            experience: localstate,
          },
        });
        setLoadTime(false);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [subjectsList]);

  const handleOnClick = (subject, index) => {
    const localstate = localStorage.getItem("experience");
    var subj = subject.subject_name.replace(/\s/g, "");

    if (localstate === "Teacher") {
      amplitude.getInstance().logEvent("TESelectSubject" + subj);
    } else {
      amplitude.getInstance().logEvent("VESelectSubject" + subj);
    }

    setLoadTime(true);
    const timeoutId = setTimeout(() => {
      navigate(`/quiz?id=${subject.exam_style[0].secret_key}`, {
        state: {
          subject_name: subject.subject_name,
          minimock: subject.minimock,
          quiz_setting: 1,
          exam_style: subject.exam_style,
          experience: localstate,
        },
      });
      setLoadTime(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  };

  useEffect(() => {
    if (localStorage.getItem("experience") === "Teacher") {
      amplitude.getInstance().logEvent("TEPageView");
    } else {
      amplitude.getInstance().logEvent("VEMainPageView");
    }
    getSubjectsList();
  }, []);

  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects_for_trial",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log(JSON.parse(res.data.data));

        let array = JSON.parse(res.data.data);

        // Filter out objects where exam_style is null
        const filteredArray = array.filter((item) => Array.isArray(item.exam_style) && item.exam_style.length > 0);
        filteredArray.push("");
        setSubjectsList(filteredArray);
      });
  };

  return (
    <>
      <div style={{ display: loadTime ? "none" : "block" }} className="jess_icon left-[35px] md:left-[10%] sm:left-[25%]">
        <img src={TeepeeIcon} alt="" />
      </div>
      <div style={{ display: loadTime ? "none" : "block" }} className="w-[100%] md:mt-[10%] sm:mt-[2%]">
        <div className="h-[100%] flex items-center justify-center flex-col ">
          <div
            style={{ display: loadTime ? "none" : "block" }}
            className="welcome-teepee-text md:w-[40%] md:text-left sm:w-[90%] sm:text-center mb-[4px] sm:mt-[30%] md:mt-0"
          >
            Experience AI Evaluation
          </div>
          <div
            style={{ display: loadTime ? "none" : "block" }}
            className="welcome-teepee-sub-text w-[40%] sm:w-[90%] md:w-[40%] sm:text-center md:text-left mb-[16px]"
          >
            Choose your subject, and let Teepee create a personalised experience for you.
          </div>

          <QuizSettingsWidgetTeacher subjectsList={subjectsList} handleOnClick={handleOnClick} activeButton={activeButtons} />
        </div>
      </div>
      <div className="w-[100%] md:mt-[10%] sm:mt-[40%] sm:mt-[2%]">
        <div className="h-[100%] flex items-center justify-center flex-col ">
          <div
            className="md:relative flex mt-[2%] justify-between md:w-[40%] sm:w-[90%] sm:flex-col md:flex-row mb-[3%] "
            style={{ display: loadTime ? "block" : "none" }}
          >
            <Transition />
          </div>
        </div>
      </div>
    </>
  );
};
