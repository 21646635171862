import axios from "axios";
import {
  REACT_APP_REST_API_BASE_URL,
  REACT_APP_UPDATE_PROMOCODE_END_POINT,
} from "../../Constants/ApiConstant";

const useUpdatePromoCode = () => {
  const updatePromoCode = (id, status) => {
    const access_token = "Bearer " + localStorage.getItem("access_token");

    axios
      .post(
        REACT_APP_REST_API_BASE_URL + REACT_APP_UPDATE_PROMOCODE_END_POINT,
        {
          promocode_id: id,
          status: status,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: access_token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        console.log("updated");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return updatePromoCode;
};

export default useUpdatePromoCode;
