import React, { useContext, useEffect } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import LoginOptions from "./LoginOptions";
import LoginWithStudent from "./Student/LoginWithStudent";
import appContext from "../context/appContext";

function LoginTabs() {
  const state = useContext(appContext);
  useEffect(() => {
    if (state.loginState === 2) {
      document.getElementById("teacher").style.color = "white";
      document.getElementById("student").style.color = "#6750A4";
    } else {
      document.getElementById("student").style.color = "white";
      document.getElementById("teacher").style.color = "#6750A4";
    }
  }, [state.loginState]);
  const data = [
    {
      label: "Student",
      value: "student",
      body: (
        <>{state.loginState === 2 ? "" : <LoginWithStudent user_type={3} />}</>
      ),
    },
    {
      label: "Teacher",
      value: "teacher",
      body: <>{state.loginState === 1 ? "" : <LoginOptions teacher={2} />}</>,
    },
  ];
  const handleClick = (selectedTab) => {
    if (selectedTab === "teacher") {
      state.setLoginState(2);
      document.getElementById(selectedTab).style.color = "white";
      document.getElementById("student").style.color = "#6750A4";
    } else {
      document.getElementById(selectedTab).style.color = "white";
      document.getElementById("teacher").style.color = "#6750A4";
      state.setLoginState(1);
    }
  };
  return (
    <Tabs
      id="custom-animation"
      value={state.loginState === 1 ? "student" : "teacher"}
      className="w-[100%] "
    >
      <div className="">
        <center>
          <TabsHeader
            className="w-[50%] h-[48px] justify-center bg-[white] p-0 "
            style={{ border: "1px solid #6750A4" }}
            indicatorProps={{
              className: "bg-[#6750A4] shadow-none text-white",

              style: {
                borderRadius:
                  state.loginState === 2
                    ? "0px 6px 6px 0px"
                    : "6px 0px 0px 6px",
              },
            }}
          >
            {data.map(({ label, value }) => (
              <div onClick={() => handleClick(value)} style={{ width: "100%" }}>
                <Tab id={value} key={value} value={value}>
                  {label}
                </Tab>
              </div>
            ))}
          </TabsHeader>
        </center>
      </div>

      <TabsBody animate={{}}>
        {data.map(({ value, body }) => (
          <TabPanel key={value} value={value}>
            {body}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}

export default LoginTabs;
