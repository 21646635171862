import React from "react";
import ParticipationMetrics from "./ParticipationMetrics";
import AreasToFocusOn from "./AreasToFocusOn";
import CommonMisconceptionsTable from "./CommonMisconceptionsTable";
import StudentsNeedingAttention from "./StudentsNeedingAttention";
import DashboardLabel from "./DashboardLabel";
import QuizzesSentCard from "./QuizzesSentCard";
import RetrievalMetrics from "./RetrievalMetrics";

function ManageRetrievalDashboardTabBody(props) {
  console.log("WTF", props);
  const misconceptions = props.data?.class_analytics_data?.misconception_questions;
  const have_student = props.data?.have_students;
  return (
    <div>
      <div className="mt-[3%]">
        <DashboardLabel text="Student Engagement Insights" />
      </div>
      <div className="text-[16px]  mt-2 ml-4" style={{ fontFamily: "Roboto" }}>
        Track your class's participation and completion rates.
      </div>
      <div className="w-full flex md:flex-row sm:flex-col mt-3 ml-4 mb-[3%]">
        <div className="md:w-[40%] sm:w-[100%]">
          <QuizzesSentCard quizzesSent={props?.data?.class_analytics_data?.total_quizzes} lastUpdated="1 hour" />
        </div>
        <div className="md:w-[40%] sm:w-[100%]">
          <ParticipationMetrics data={props?.data} />
        </div>
      </div>
      <div className="mt-[6%]">
        <DashboardLabel text="Retrieval Accuracy Overview" />
      </div>
      <div className="text-[16px]  mt-2 ml-4 mb-3" style={{ fontFamily: "Roboto" }}>
        Track the overall accuracy across each retrieval stage.
      </div>

      <div className="ml-4 w-[88%]">
        <RetrievalMetrics data={props?.data} />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-[6%] mt-[6%]">
        <AreasToFocusOn data={props?.data} />
        <StudentsNeedingAttention data={props?.data} />
      </div>
      {misconceptions && misconceptions.length > 0 ? (
        <CommonMisconceptionsTable data={props?.data} />
      ) : (
        <div>
          <DashboardLabel text="Common Misconceptions" />
          <div className="py-[5%] flex justify-center ">
            <div className="md:w-[50%] md:text-[0.83vw] text-center">
              {have_student === false
                ? "Data will appear here once students begin attempting quizzes, highlighting common misconceptions for targeted review."
                : "All questions with more than 70% inaccuracy will appear here for targeted review."}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManageRetrievalDashboardTabBody;
