import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

function TermsAndConditions({ handleChange, checked }) {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked[0]}
            onChange={(e) => handleChange(e, 0)}
            size="extra-small"
            sx={{
              "&.Mui-checked": {
                color: "#6750A4",
              },
            }}
          />
        }
        label={"I have read and agree to the website terms and conditions"}
        sx={{
          typography: "body2",
          "& .MuiTypography-root": {
            fontSize: "12px !important",
            fontWeight: "600",
          },
        }}
      />
      <FormControlLabel
        required
        control={
          <Checkbox
            checked={checked[1]}
            onChange={(e) => handleChange(e, 1)}
            size="extra-small"
            sx={{
              "&.Mui-checked": {
                color: "#6750A4",
              },
            }}
          />
        }
        label="I want to receive updates about products and promotions."
        sx={{
          typography: "body2",
          "& .MuiTypography-root": {
            fontSize: "12px !important",
            fontWeight: "600",
          },
        }}
      />
    </FormGroup>
  );
}

export default TermsAndConditions;
