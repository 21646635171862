import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import SubAccordions from "./SubAccordian";
import EdiText from "react-editext";
import Button from "@mui/material/Button";
import axios from "axios";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ReactSwitch from "react-switch";
import "@material/react-radio/dist/radio.css";
import Radio from "@mui/material/Radio";
import PrefinedQuestions from "./PredefinedQuestions";
import { useNavigate } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
//   flexDirection: "row-reverse",
//   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//     transform: "rotate(90deg)",
//   },
//   "& .MuiAccordionSummary-content": {
//     marginLeft: theme.spacing(1),
//   },
// }));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const onSaveChapter = (value, chapter, quiz_type) => {
  console.log("sdadad", quiz_type);
  console.log("Chh::", chapter);
  chapter.chapter_name = value;

  var token = "Bearer " + localStorage.getItem("access_token");
  axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  axios.defaults.headers.post["Content-Type"] =
    "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.post["authorization"] = token;
  axios
    .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_sub_topic_name", {
      method: "POST",
      area_name: value,
      area_id: chapter.area_id,
    })
    .then((response) => {
      // var subjects_list = JSON.parse(
      //   response.data["subject"]
      // );
      //console.log(subjects_list);
      // setSubjectData(subjects_list)
      // alert("topic added");
    })
    .catch((error) => {
      console.log(error);
      // alert("unable to update subject");
    });
};

const onChangeAiEnabledDisabledChapter = (
  value,
  chapter,
  addTodo,
  quiz_type,
  addTodoOld
) => {
  chapter.ai_enabled = parseInt(value);
  var token = "Bearer " + localStorage.getItem("access_token");
  axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  axios.defaults.headers.post["Content-Type"] =
    "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.post["authorization"] = token;
  axios
    .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_sub_topic_name", {
      method: "POST",
      area_name: chapter.area_name,
      area_id: chapter.area_id,
    })

    .then((response) => {
      addTodo();
      addTodoOld();
      // var subjects_list = JSON.parse(
      //   response.data["subject"]
      // );

      //console.log(subjects_list);

      // setSubjectData(subjects_list)
    })
    .catch((error) => {
      console.log(error);
      // alert("unable to update subject");
    });
};

const addNewTopic = (chapter, addTodo, id, addTodoOld) => {
  // alert(subject.subject_name);

  var statuss = localStorage.getItem("lesson_status");
  var token = "Bearer " + localStorage.getItem("access_token");
  axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  axios.defaults.headers.post["Content-Type"] =
    "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.post["authorization"] = token;
  axios
    .post(
      process.env.REACT_APP_REST_API_BASE_URL +
        "/create_predefined_question_mcqs",
      {
        method: "POST",
        area_id: chapter.area_id,
        question_marks: 1,
        question: "",
        correct_feedback: "",
        incorrect_feedback: "",
        question_importance: 1,
        right_option_id: null,
        image_file_name: null,
      }
    )

    .then((response) => {
      addTodo();
      addTodoOld();
      // alert("successfully inserted new question");
    })
    .catch((error) => {
      console.log(error);
      // alert("failed inserted new question");
    });
};

export default function ConceptAccordian(props) {
  var stats = localStorage.getItem("lesson_status");
  const navigate = useNavigate();
  // const [expanded, setExpanded] = React.useState("");

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  const [expand, setExpand] = React.useState(false);
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  const [selectedValue, setSelectedValue] = React.useState(
    props.title.ai_enabled
  );

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onChangeAiEnabledDisabledChapter(
      event.target.value,
      props.title,
      props.addTodo,
      props.predefined_question_answer_status,
      props.addTodoOld
    );
  };

  return (
    <div className="overflow-auto">
      <Accordion
        expanded={expand}
        // expanded={expanded === "panel1"}
        // onChange={handleChange("panel1")}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            onClick: toggleAcordion,
          }}
          style={{
            backgroundColor: "rgba(255, 255, 255, .05)",
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
            "& .MuiAccordionSummary-content": {
              marginLeft: 0,
            },
            padding: 0,
          }}
        >
          <Typography className="w-full">
            {" "}
            <EdiText
              className="w-full"
              type="text"
              onSave={(value) =>
                onSaveChapter(
                  value,
                  props.title,
                  props.predefined_question_answer_status
                )
              }
              value={props.title.area_name}
            />
          </Typography>
          {/* <table className="w-full"><tr><td>
                                  <p className="mt-[10px]" style={{ fontSize: "16px", fontFamily: "Roboto", color: "#49454F", }}>
                                      <b>AI Based Question</b>
                                  </p>
                                  
                                  </td><td>
                                  
                                  <ReactSwitch
                                  // className="float-right"
                                    checked={props.title.ai_enabled}
                                    onChange={(value)=>onChangeAiEnabledDisabledChapter(value, props.title, props.addTodo)}
                                  />
                                  </td><td>
                                  <p className="mt-[10px]" style={{ fontSize: "16px", fontFamily: "Roboto", color: "#49454F", }}>
                                      <b>Predefined Question</b>
                                  </p>
                                  
                                  </td></tr></table> */}
          {/* <Radio label='AI Based Question' key='ai_based'>
                                    <NativeRadioControl
                                      
                                      // checked={props.title.ai_enabled}
                                      name='mode'
                                      value='1'
                                      id='ai_based_radio'
                                      onChange={(e) => {onChangeAiEnabledDisabledChapter(e.target.value, props.title, props.addTodo); }}
                                    />
                                  </Radio>
                                  <Radio label='Predefined Question' key='predefined'>
                                    <NativeRadioControl
                                      name='mode'
                                      value='0'
                                      // checked={props.title.ai_enabled}
                                      id='predefined_radio'
                                      onChange={(e) => {onChangeAiEnabledDisabledChapter(e.target.value, props.title, props.addTodo); }}
                                    />
                                  </Radio> */}

          <Button
            style={{ float: "right" }}
            onClick={() =>
              props.onDeleteClick(
                props.title,
                process.env.REACT_APP_DELETING_OBJECT_TYPE_SUBTOPIC
              )
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              {/* {props.title["area_has_question_list"]?.map(
                (topic, subIopicIndex) => ( */}
              <Fragment>
                <div>
                  <p
                    style={{
                      fontSize: "14px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                    }}
                  >
                    {
                      <PrefinedQuestions
                        addTodo={props.addTodo}
                        removeQuestion={props.removeQuestion}
                        predefined_question_answer_list={
                          props.title["area_has_question_list"]
                        }
                        predefinedQuestionsPromptList={
                          props.predefinedQuestionsPromptList
                        }
                        addTodoOld={props.addTodoOld}
                      />
                    }
                  </p>
                </div>
              </Fragment>
              {/* )
              )} */}
              <Button
                className="delete-button"
                style={{ color: "#1976d2", padding: "6px 8px" }}
                onClick={() =>
                  addNewTopic(
                    props.title,
                    props.addTodo,
                    props.subject_id,
                    props.addTodoOld
                  )
                }
              >
                {" "}
                <span className=" mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 10 11"
                    fill="none"
                  >
                    <path
                      d="M4.5 7.59961H5.5V5.59961H7.5V4.59961H5.5V2.59961H4.5V4.59961H2.5V5.59961H4.5V7.59961ZM5 10.0996C4.30833 10.0996 3.65833 9.96836 3.05 9.70586C2.44167 9.44336 1.9125 9.08711 1.4625 8.63711C1.0125 8.18711 0.65625 7.65794 0.39375 7.04961C0.13125 6.44128 0 5.79128 0 5.09961C0 4.40794 0.13125 3.75794 0.39375 3.14961C0.65625 2.54128 1.0125 2.01211 1.4625 1.56211C1.9125 1.11211 2.44167 0.755859 3.05 0.493359C3.65833 0.230859 4.30833 0.0996094 5 0.0996094C5.69167 0.0996094 6.34167 0.230859 6.95 0.493359C7.55833 0.755859 8.0875 1.11211 8.5375 1.56211C8.9875 2.01211 9.34375 2.54128 9.60625 3.14961C9.86875 3.75794 10 4.40794 10 5.09961C10 5.79128 9.86875 6.44128 9.60625 7.04961C9.34375 7.65794 8.9875 8.18711 8.5375 8.63711C8.0875 9.08711 7.55833 9.44336 6.95 9.70586C6.34167 9.96836 5.69167 10.0996 5 10.0996ZM5 9.09961C6.11667 9.09961 7.0625 8.71211 7.8375 7.93711C8.6125 7.16211 9 6.21628 9 5.09961C9 3.98294 8.6125 3.03711 7.8375 2.26211C7.0625 1.48711 6.11667 1.09961 5 1.09961C3.88333 1.09961 2.9375 1.48711 2.1625 2.26211C1.3875 3.03711 1 3.98294 1 5.09961C1 6.21628 1.3875 7.16211 2.1625 7.93711C2.9375 8.71211 3.88333 9.09961 5 9.09961Z"
                      fill="#49454F"
                    />
                  </svg>
                </span>{" "}
                Add Question
              </Button>
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
