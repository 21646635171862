import React from "react";
import ComingSoonImage from "../../images/comingsoon.gif";
function ComingSoon() {
  return (
    <div>
      <div>
        &#128251;<b style={{ fontWeight: "bold" }}> Stay tuned, Educators! </b>
        <br></br> Feature under construction, Coming soon! &#128679;
      </div>
      <div>
        <img style={{ width: "250px" }} src={ComingSoonImage}></img>
      </div>
    </div>
  );
}

export default ComingSoon;
