import React, { useState, useEffect, useRef } from "react";
import Sidebar from "./Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import amplitude from "amplitude-js";
import ClassroomDetailBanner from "./Teacher/widgets/ClassroomDetailBanner";
import TeacherNavigationText from "./Teacher/widgets/TeacherNavigationText";
import SubscriptionExpirationBanner from "./Teacher/widgets/SubscriptionExpirationBanner";
function CreateQuiz() {
  const location = useLocation();
  const [createQuiz, setcreateQuiz] = useState({
    quizName: "",
    quizSubject: location.state ? location.state.classroomData && location.state.classroomData.subject.subject_name : "",
    hasExamStylePrompt: 0,
    hasMockExams: 0,
    formativeQuizTypeStatus: 0,
    folder_id: location.state !== null && location.state.folder_id !== null ? location.state.folder_id : null,
  });
  const hasExamStylePrompt = useRef(0);
  const formativeQuizTypeStatus = useRef(0);
  const hasMockExams = useRef(0);
  const [subjects, setSubjects] = useState([]);
  const navigate = useNavigate();
  const selectedSubject = useRef(null);
  //const [selectedSubject, setSelectedSubject] = useState([]);
  useEffect(() => {
    getSubjectsList();
    if (location.state.classroomData) {
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("CreateQuiz", "user has visited the create quiz page");
    } else {
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("OCCreateQuiz", "user has visited the create quiz page");
    }
  }, []);

  const onChangeQuizName = (e) => {
    setcreateQuiz({ ...createQuiz, [e.target.name]: e.target.value });
  };

  const onChangeSubject = (e) => {
    setcreateQuiz({ ...createQuiz, [e.target.name]: e.target.value });
    hasExamStylePrompt.current = subjects[e.target.selectedIndex - 1].exam_style_quiz_status;
    formativeQuizTypeStatus.current = subjects[e.target.selectedIndex - 1].formative_quiz_type_status;
    hasMockExams.current = subjects[e.target.selectedIndex - 1].has_mock_exams;
    localStorage.setItem("selected_subject_id", e.target.selectedOptions[0].id);
    selectedSubject.current = subjects.filter((subject) => subject.subject_id === parseInt(e.target.selectedOptions[0].id));
    amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
    amplitude.getInstance().logEvent("OCSelectSubject [" + e.target.value + "]");
  };

  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log("objedsdct");
        console.log(res.data);
        setSubjects(res.data);
        var subjects = res.data;
        if (location.state.classroomData) {
          subjects = subjects.filter((subject) => location.state.classroomData.subject.subject_id === subject.subject_id);
          hasExamStylePrompt.current = subjects[0].exam_style_quiz_status;
          formativeQuizTypeStatus.current = subjects[0].formative_quiz_type_status;
          hasMockExams.current = subjects[0].has_mock_exams;
          localStorage.setItem("selected_subject_id", subjects[0].subject_id);
          // amplitude
          //   .getInstance()
          //   .setUserId(localStorage.getItem("email_address"));
          // amplitude
          //   .getInstance()
          //   .logEvent("Select Subject [" + subjects[0].subject_name + "]");
        }
      });
  };

  const handleClick = () => {
    if (!location.state.classroomData) {
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("OCSelect Subject [" + subjects[0].subject_name + "]");
    }
    console.log(createQuiz.quizSubject.length, createQuiz.quizName.length);
    if (location.state.classroomData) {
      selectedSubject.current = subjects.filter((subject) => subject.subject_id === location.state.classroomData.subject.subject_id);
    }
    console.log("DATA REQUIRED IS: ", selectedSubject.current[0].is_code);
    if (createQuiz.quizName.length > 0 && createQuiz.quizSubject.length > 0) {
      navigate("/add-details", {
        state: {
          data: createQuiz,
          formativeQuizTypeStatus: formativeQuizTypeStatus.current,
          hasExamStylePrompt: hasExamStylePrompt.current,
          hasMockExams: hasMockExams.current,
          classroomData: location.state.classroomData,
          url: location.state.url,
          selectedSubject: selectedSubject.current[0],
          bannerObject: location.state.bannerObject,
          subjectData: selectedSubject.current[0],
          is_code: selectedSubject.current[0].is_code,
        },
      });
    } else {
      alert("Please Enter all the fields");
    }
  };

  const subjectList = subjects.map((subject) => <option id={subject.subject_id}>{subject.subject_name}</option>);

  const subjectData = location.state.classroomData ? location.state.classroomData.subject : null;

  console.log("CLASSSROOM DATA IN CREATE QUIZ: ", location.state.classroomData);

  const handleOnClick = () => {
    if (location.state) {
      if (location.state.url) {
        console.log("CALLED", location.state.url);
        window.location = location.state.url;
      }
    }
  };

  return (
    <>
      <Sidebar item_id="quiz" />
      <div className="sm:ml-[10px] md:ml-[316px]">
        {location.state.classroomData ? (
          [
            <>
              {location.state.bannerObject && (
                <SubscriptionExpirationBanner bannerObject={location.state.bannerObject} subjectData={location.state.subjectData} />
              )}
              <ClassroomDetailBanner classroomData={location.state.classroomData} />
              <div className="home sm:ml[25px] md:ml-[60px] underline teacher_navigation_text">
                <button className="" onClick={handleOnClick}>
                  {/* {props.text} */}
                  {"Classroom"}
                </button>
                <button>{" " + " > Create Quiz"}</button>
              </div>
              ,
            </>,
          ]
        ) : (
          <div></div>
        )}

        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label className="dashboard-label">Create Quiz</label>
            <div className="teepee-divider"></div>
          </div>
          <div className="pl-10">
            <label className="disabled-label">Add Details</label>
          </div>
          {location.state ? (
            <>
              {!location.state.classroomData ? (
                <div className="pl-10">
                  <label className="disabled-label">Share</label>
                </div>
              ) : (
                <div></div>
              )}
            </>
          ) : null}
        </div>
        <div className="flex flex-row justify-center items-center sm:mr-[10px] md:mr-0">
          <div className=""></div>

          <div className="selectionDiv">
            <div className="form-div">
              <div className="sm:w-full md:w-[600px]">
                <div>
                  <label className="create_quiz_label">1. Name Your Task</label>
                </div>
                <div>
                  <input
                    type="text"
                    name="quizName"
                    value={createQuiz.quizName}
                    onChange={onChangeQuizName}
                    id="quizName"
                    maxLength={45}
                    className="teepee-input"
                    placeholder="e.g Task 1"
                    required
                  />
                </div>

                <label className="character-length-label md:pl-[450px] sm:pb-[10px]"> Max. 45 characters</label>
                <div>
                  <label className="create_quiz_label">2. Select the subject you'd like to create the quiz from.</label>
                </div>
                <div>
                  <div className="">
                    <select
                      value={subjectData !== null ? subjectData.subject_name : createQuiz.quizSubject}
                      disabled={subjectData !== null ? true : false}
                      onChange={onChangeSubject}
                      name="quizSubject"
                      className="teepee-select shadow-none leading-[30px]  mb-5 font-[300] px-4 w-[100%] h-[100%] "
                      style={{
                        backgroundColor: subjectData !== null && "#1C1B1F1F",
                      }}
                    >
                      <option value="" disabled selected hidden>
                        Select subject of your choice
                      </option>
                      {subjectList}
                    </select>
                  </div>
                </div>
                <div>
                  <button onClick={handleClick} className="teepee-button sm:w-[100%] md:w-[100%] hover:bg-black hover:cursor-pointer">
                    Add my quiz details
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateQuiz;
