import React, { useState } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

function LikeDislike(props) {
  const [activeBtn, setActiveBtn] = useState(
    props.quiz.teacher_like_dislike_status === 0
      ? "none"
      : props.quiz.teacher_like_dislike_status === -1
      ? "dislike"
      : "like"
  );
  const [open, setOpen] = React.useState(false);
  const [teacherFeedback, setTeacherFeedback] = React.useState("");
  const updateTeacherFeebackAgainstQuestioinResponse = (
    question_response,
    teacher_like_dislike_status,
    feedbackText
  ) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
          "/update_teacher_feedback_against_question_evaluation",
        {
          method: "POST",
          question_response_id: question_response.question_response_id,
          teacher_like_dislike_status: teacher_like_dislike_status,
          teacher_feedback: feedbackText,
        }
      )

      .then((response) => {})
      .catch((error) => {
        console.log(error);
        alert("unable to perform this action. please try again");
      });
  };

  // useEffect(() => {

  //     updateTeacherFeebackAgainstQuestioinResponse(activeBtn);

  //   }, [activeBtn]);

  const handleLikeClick = (question_response) => {
    if (props.show_teacher_feedback === true) {
      return;
    }
    let teacher_like_dislike_status = 0;
    if (activeBtn === "none") {
      setActiveBtn("like");
      teacher_like_dislike_status = 1;
    } else if (activeBtn === "like") {
      setActiveBtn("none");
      teacher_like_dislike_status = 0;
    } else if (activeBtn === "dislike") {
      setActiveBtn("like");
      teacher_like_dislike_status = 1;
    }
    updateTeacherFeebackAgainstQuestioinResponse(
      question_response,
      teacher_like_dislike_status,
      ""
    );
  };

  const handleDisikeClick = (question_response) => {
    if (props.show_teacher_feedback === true) {
      return;
    }
    let teacher_like_dislike_status = 0;
    if (activeBtn === "none") {
      setActiveBtn("dislike");
      teacher_like_dislike_status = -1;
    } else if (activeBtn === "dislike") {
      setActiveBtn("none");
      teacher_like_dislike_status = 0;
    } else if (activeBtn === "like") {
      setActiveBtn("dislike");
      teacher_like_dislike_status = -1;
    }
    if (teacher_like_dislike_status === -1) {
      setOpen(true);
      return;
    }

    updateTeacherFeebackAgainstQuestioinResponse(
      question_response,
      teacher_like_dislike_status,
      ""
    );
  };

  const handleCloseWithCancel = () => {
    let teacher_like_dislike_status = -1;
    setOpen(false);
    updateTeacherFeebackAgainstQuestioinResponse(
      props.quiz,
      teacher_like_dislike_status,
      teacherFeedback
    );
  };

  const handleCloseWithYes = () => {
    let teacher_like_dislike_status = -1;
    setOpen(false);
    updateTeacherFeebackAgainstQuestioinResponse(
      props.quiz,
      teacher_like_dislike_status,
      teacherFeedback
    );
  };

  const onChangeFeedback = (e) => {
    setTeacherFeedback(e.target.value);
  };

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handleCloseWithCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="flex center">
              <span
                className="material-symbols-rounded mr-[10px]"
                style={{ color: "#FF0063" }}
              >
                thumb_down
              </span>

              <b>Provide additional feedback</b>
            </div>
          </DialogContentText>
          <textarea
            onChange={onChangeFeedback}
            type="text"
            name="subject_name"
            id="subject_name"
            className="teepee-input"
            maxLength={1000}
            style={{ fontSize: "14px" }}
            placeholder=""
            required
          />
          {/* <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit your feedback?
          </DialogContentText> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>Cancel</Button>
          <Button
            className="delete-button"
            onClick={handleCloseWithYes}
            autoFocus
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <div className="like-dislike-container">
        <div
          className="flex float-right mt-[4px] "
          style={{ display: props.show_teacher_feedback && "none" }}
        >
          <div className="md:mr-[20px] sm:mr-[4px] feedback_text">
            Please share your feedback about how you rate the analysis by
            Teepee.ai
          </div>
        </div>
        <div className="like-dislike-btn-container">
          <span
            className={`material-symbols-rounded ${
              activeBtn === "like" ? "like-active  material-icons" : ""
            }`}
            onClick={() => {
              handleLikeClick(props.quiz);
            }}
          >
            thumb_up
          </span>
          <span
            className={`material-symbols-rounded ${
              activeBtn === "dislike" ? "dislike-active  material-icons" : ""
            }`}
            onClick={() => {
              handleDisikeClick(props.quiz);
            }}
          >
            thumb_down
          </span>
        </div>
      </div>
      {props.show_teacher_feedback && (
        <div className="mt-[5px] mb-[30px]">
          <div className="flex quiz-review mb-[5px]">
            <p className="answer-paragraph">
              <b>Teacher Feedback: </b>
              {props.quiz.teacher_feedback}
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default LikeDislike;
