import React from "react";

function KeyMatricsCard({ text, number }) {
  return (
    <div className="flex justify-center">
      <div className="quiz-div w-[100%] text-center h-40 ">
        <div className="h-[50%] inner-my-quizzes !pl-0">
          <label className="dashboard-label cursor-pointer">{text}</label>
        </div>
        <div className="h-[100%] flex items-center justify-center">
          {number}
        </div>
      </div>
    </div>
  );
}

export default KeyMatricsCard;
