import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../Sidebar";
import Switch from "@mui/material/Switch";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import useGetPromoCode from "../../PromocodeApiCall/GetPromocodeApi";
import useInsertPromoCode from "../../PromocodeApiCall/InsertPromocodeApi";
import useUpdatePromoCode from "../../PromocodeApiCall/UpdatePromocodeApi";
import useDeletePromoCode from "../../PromocodeApiCall/DeletePromcodeApi";
const PromocodeTab = () => {
  const [promoText, setPromoText] = useState();
  const [discount, setDiscount] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [id, setId] = useState(1);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(0);
  const isSubmitting = useRef(false);
  const getPormoCode = useGetPromoCode();
  const InsertPormoCode = useInsertPromoCode();
  const UpdatePromoCode = useUpdatePromoCode();
  const DeletePromocode = useDeletePromoCode();

  const handleSubmit = () => {
    if (!promoText || !discount) {
      alert("Please fill both fields");
      return;
    }
    const isNumeric = (string) => /^[+-]?\d+(\.\d+)?$/.test(string);
    if (!isNumeric(discount)) {
      alert("Discount should be in numbers");
      return;
    }
    isSubmitting.current = true;
    InsertPormoCode(setId, promoText, discount, setData);
    setPromoText("");
    setDiscount("");
    isSubmitting.current = false;
    console.log("data here is ", data);
  };
  const handleUpdate = (ID, status, index) => {
    setData((prevState) => {
      return prevState.map((item, idx) => {
        if (idx === index) {
          return { ...item, status: status ? 1 : 0 };
        }
        return item;
      });
    });
    UpdatePromoCode(ID, status ? 1 : 0);
  };

  useEffect(() => {
    getPormoCode(setData);
  }, []);
  return (
    <div>
      <Sidebar item_id="promo_code" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px]">
        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label className="dashboard-label">Add Promocode</label>
            <div className="teepee-divider"></div>
          </div>
        </div>
        <div className="flex flex-row justify-center items-center ">
          <div className=""></div>
          <div className="selectionDiv">
            <div className="form-div">
              <div className="md:w-[600px]">
                <div>
                  <label className="create_quiz_label">Enter Promocode</label>
                </div>
                <div>
                  <input
                    type="text"
                    name="promocode"
                    id="promocode"
                    className="teepee-input"
                    placeholder="Please enter promocode"
                    onChange={(e) => {
                      setPromoText(e.target.value);
                    }}
                    value={promoText}
                    required
                  />
                </div>
                <div>
                  <label className="create_quiz_label">
                    Enter Discount Percentage
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    name="promocode"
                    id="promocode"
                    className="teepee-input"
                    placeholder="Please enter discount percentage"
                    value={discount}
                    onChange={(e) => {
                      setDiscount(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="mt-4 ">
                  <button
                    onClick={handleSubmit}
                    disabled={isSubmitting.current}
                    className="teepee-button hover:bg-black"
                    style={{ width: "200px" }}
                  >
                    {isSubmitting.current && (
                      <div
                        className="spinner-border spinner-border-m  ml-1 float-right"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                    Add promocode
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home sm:ml[25px] md:ml-[60px] mt-8">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 840 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        backgroundColor: "#f7f2f9",
                      }}
                    >
                      ID
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#f7f2f9",
                      }}
                    >
                      Promocode
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#f7f2f9",
                      }}
                    >
                      Discount
                    </TableCell>
                    <TableCell
                      style={{
                        backgroundColor: "#f7f2f9",
                      }}
                    >
                      Status
                    </TableCell>
                    {/* <TableCell
                      style={{
                        backgroundColor: "#f7f2f9",
                      }}
                    >
                      {" "}
                      Action
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            backgroundColor: "#f7f2f9",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell style={{}}>{row.promocode}</TableCell>
                        <TableCell style={{}}>
                          {row.discount_percentage + "%"}
                        </TableCell>
                        <TableCell style={{}}>
                          <Switch
                            checked={row.status === 1}
                            onChange={(e) => {
                              handleUpdate(
                                row.promocode_id,
                                e.target.checked,
                                index
                              );
                            }}
                          />
                        </TableCell>
                        {/* <TableCell style={{}}>
                          <button
                            className="btn btn-danger"
                            onClick={() => {
                              handleDelete(row.promocode_id);
                            }}
                          >
                            Delete
                          </button>
                        </TableCell> */}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{ backgroundColor: "#f7f2f9" }}
              rowsPerPageOptions={[100, 150, 200]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              //   onPageChange={handleChangePage}
              //   onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default PromocodeTab;
