import React, { Fragment, useEffect, useState } from "react";
import CustomizedAccordions from "./Accordian";
import AnswerAccordian from "./AnswerAccordian";
import LikeDislike from "./LikeDislike";
import { EditText, EditTextarea } from "react-edit-text";
import axios from "axios";
import EdiText from "react-editext";
import Edit from ".././images/edit.png";
import amplitude from "amplitude-js";
import LoadingSpinner from "./widgets/LoadingSpinner";
import ShimmerEffect from "./widgets/ShimmerEffect";
import TrialAttemptsButton from "./QuizSettings/trialAttemptsButton";
import { useLocation } from "react-router-dom";
import RecheckDialog from "./widgets/RecheckDialog";
import CodeEditor from "./widgets/CodeEditor";
import FeedbackDisplay from "./widgets/FeedbackDisplay";

function QuizReviewCard(props) {
  const location = useLocation();
  const quizData = props.quizData;
  const quizType = props.quizType;

  const mock_exam = props.mock_exam;
  const show_teacher_feedback = props.show_teacher_feedback;
  const quiz_instant_feedback_status = props.quiz_instant_feedback_status;
  const quiz_response_id = props.quiz_response_id;
  const spinnerVisibility = props.spinnerVisibility;
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [thumbs_up_down, setThumbs_up_down] = useState();
  const auth_type = localStorage.getItem("user_type");
  const [totalQuestions, setTotalQuestions] = useState();
  const [selectedQuizQuestion, setSelectedQuizQuestion] = useState([]);
  const [open, setOpen] = useState(false);
  let q_index = 0;
  useEffect(() => {
    if (quizData.length > 0 && location.state) {
      if (location.state?.experience === "Teacher") {
        amplitude.getInstance().logEvent("TEInstantFeedbackScreenView");
      } else {
        amplitude.getInstance().logEvent("VEInstantFeedbackScreenView");
      }
    }
  }, [quizData]);
  const getQuizResponseByQuestionContextId = (question_context_id, index) => {
    let question_answers_array = [];
    for (let quiz_response in quizData) {
      let quiz_question = JSON.parse(quizData[quiz_response].quiz_question);
      if (quiz_question.question_context_id === question_context_id) {
        question_answers_array.push(quizData[quiz_response]);
      }
    }
    console.log("TEACHER: ", question_answers_array[index]);
    return question_answers_array[index];
  };
  const handleOpen = (index, quiz) => {
    console.log("here", quiz[index].recheck_data);
    setThumbs_up_down(quiz[index].recheck_data.thumbs_up_down);

    setSelectedQuizQuestion(quiz);
    setTotalQuestions(quiz.length);
    setSelectedQuestion(index);

    if (open === false) {
      setOpen(true);
    } else {
      setOpen(false);
      // setOpen(true);
      setTimeout(() => {
        setOpen(true); // Close the sidebar after 1 second
      }, 500); // 1000 milliseconds = 1 second
    }
  };

  // Method to close the dialog
  const handleClose = () => {
    setOpen(false);
  };
  const onSaveFeedback = (feedback, question) => {
    if (feedback !== question.feedback) {
      var token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["authorization"] = token;
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_question_feedback", {
          method: "POST",
          question_response_id: question.question_response_id,
          feedback: feedback,
        })

        .then((response) => {
          console.log("RESPONSE OF EDIT FEEDBACK: ", response);
          amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
          amplitude.getInstance().logEvent("EditTeepeeFeedback");
          props.getQuizReviewData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const onSaveMarks = (marks, question) => {
    // console.log("called", marks, question)
    // if(marks > question.question_marks){
    //   alert("please edit marks less than the total marks")
    //   return;
    // }

    if (marks.toString() !== question.score.toString()) {
      var token = "Bearer " + localStorage.getItem("access_token");
      console.log("data is: ", marks, question);
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.post["authorization"] = token;
      axios
        .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_question_marks", {
          method: "POST",
          question_response_id: question.question_response_id,
          feedback: parseFloat(marks).toFixed(2),
          quiz_response_id: quiz_response_id,
          quiz_total_marks: props.quiz_total_marks,
        })

        .then((response) => {
          console.log(response);
          props.getQuizReviewData();
          amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
          amplitude.getInstance().logEvent("EditTeepeeMarks");
          // question.score_edited_status = 1;
          // question.score = marks
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleActualAnswerStatus = () => {
    if (quiz_instant_feedback_status === 1 && localStorage.getItem("user_type") === "3") {
      console.log("none called");
      return "none";
    } else {
      return "block";
    }
  };
  const validation = (value, question) => {
    console.log("VALIDATION CALLED", value, question.question_marks);

    // Check if value contains any of the excluded characters

    if (value > question.question_marks) {
      return false;
    } else if (value.length === 0) {
      return false;
    } else if (value < 0) {
      return false;
    } else if (/[a-zA-Z/*+-]/.test(value)) {
      // Assuming this condition should return false
      return false;
    } else {
      return true;
    }
  };

  const handleCiruclarIndicatar = (quiz, index) => {
    if (quiz.feedback !== null) {
    }
  };
  function stripHTMLTags(str) {
    return str.replace(/<p>/g, "");
  }
  const updateRecheckData = (updatedQuestion) => {
    setSelectedQuizQuestion((prevQuiz) => prevQuiz.map((question, index) => (index === selectedQuestion ? updatedQuestion : question)));
  };
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768; // Define mobile screen width (e.g., 768px or adjust accordingly)

      if (location.pathname === "/quiz-detail-review") {
        if (auth_type === "3") {
          if (open === true) {
            document.getElementById("moving").style.marginLeft = isMobile ? "10px" : "0px";
          } else {
            document.getElementById("moving").style.marginLeft = isMobile ? "10px" : "380px";
          }
        }
      } else {
        const formDiv = document.getElementsByClassName("form-div2")[0];
        if (open === true) {
          formDiv.style.justifyContent = isMobile ? "left" : "left"; // Mobile and non-mobile behavior
        } else {
          formDiv.style.justifyContent = isMobile ? "left" : "center";
        }
      }
    };

    handleResize(); // Call the function on component mount to apply the styles initially
    window.addEventListener("resize", handleResize); // Add event listener to adjust styles on window resize

    return () => window.removeEventListener("resize", handleResize); // Cleanup the event listener on unmount
  }, [open]);
  let parsedFeedback;
  return (
    console.log("SPINNER VISIBLITY: ", spinnerVisibility),
    (
      <div>
        <RecheckDialog
          open={open}
          closePanel={handleClose}
          selectedQuestion={selectedQuestion}
          totalQuestions={totalQuestions}
          selectedQuizQuestion={selectedQuizQuestion}
          thumbs_up_down={thumbs_up_down}
          setThumbs_up_down={setThumbs_up_down}
          setQuiz_performance={props.setQuiz_performance}
          quiz_performance={props.quiz_performance}
        />
        {quizType === 1 || quizType === 3 || (mock_exam === null && quizType === 2) ? (
          <div>
            {quizData?.map((quiz, index) => (
              <div className="quiz-item !overflow-visible" key={index}>
                {/* {spinnerVisibility !== undefined && spinnerVisibility[index] && (
                <LoadingSpinner />
              )} */}
                <div className="flex mb-[10px]">
                  <div
                    className="ml-[0px]"
                    style={{
                      width: spinnerVisibility !== undefined && spinnerVisibility[index] ? "400%" : "100%",
                    }}
                  >
                    <div className=" sm:hidden md:block w-[100%]">
                      <div className=" sm:ml-[0px]" style={{ width: "100%" }}>
                        <p
                          className="answer-paragraph md:whitespace-break-spaces "
                          style={{
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            color: "#49454F",
                          }}
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html: `<div><span class="question" style=" color: #212529"><b>Question</b>&nbsp;${index + 1}:</span> ${stripHTMLTags(
                                JSON.parse(quiz.quiz_question).question.split("##code")[0]
                              )}</div>`,
                            }}
                          />
                        </p>
                      </div>
                    </div>
                    <div className="sm:block md:hidden  w-[100%]">
                      <div className=" ml-[0px]" style={{ width: "100%" }}>
                        <p
                          className="answer-paragraph md:whitespace-break-spaces "
                          style={{
                            fontSize: "16px",
                            fontFamily: "Roboto",
                            color: "#49454F",
                          }}
                        >
                          <p
                            dangerouslySetInnerHTML={{
                              __html: `<div><span class="question" style=" color: #212529"><b>Question</b>&nbsp;${index + 1}:</span> ${stripHTMLTags(
                                JSON.parse(quiz.quiz_question).question.split("##code")[0]
                              )}</div>`,
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  </div>

                  {spinnerVisibility !== undefined && spinnerVisibility[index] ? (
                    <ShimmerEffect />
                  ) : (
                    <div
                      className="teepee-label justify-end"
                      style={{
                        display: spinnerVisibility !== undefined && spinnerVisibility[index] ? "none" : "block",
                      }}
                    >
                      <div className="ml-2 question-score">
                        <div style={{ display: "flex" }}>
                          <div>
                            {localStorage.getItem("user_type") === "3" || localStorage.getItem("user_type") === null ? (
                              <>
                                <div
                                  style={{
                                    display: spinnerVisibility !== undefined && spinnerVisibility[index] ? "none" : "block",
                                  }}
                                >
                                  {quiz.score}
                                </div>
                              </>
                            ) : (
                              <EdiText
                                showButtonsOnHover
                                submitOnUnfocus
                                saveButtonClassName="hidden"
                                cancelButtonClassName="hidden"
                                viewContainerClassName="mr-[0px] flex"
                                // editButtonClassName="hidden"
                                editOnViewClick
                                className="w-full"
                                type="text"
                                validation={(value) => validation(value, quiz)}
                                onSave={(value) => onSaveMarks(value, quiz)}
                                value={quiz.score}
                              />
                            )}
                          </div>
                          <div>{"/" + quiz.question_marks}</div>
                        </div>

                        <div
                          className="edited_status text-center mt-[5px]"
                          style={{
                            display: quiz.score_edited_status === 0 || localStorage.getItem("user_type") === "3" ? "none" : "block",
                          }}
                        >
                          (edited)
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {JSON.parse(quiz.quiz_question).image_file_name !== null && (
                  <div className="flex justify-center mb-[20px] mt-[20px]">
                    <img
                      alt=""
                      className=""
                      src={process.env.REACT_APP_CDN_URL_FOR_QUESTION_IMAGES + JSON.parse(quiz.quiz_question).image_file_name}
                    ></img>
                  </div>
                )}
                {spinnerVisibility !== undefined && spinnerVisibility[index] ? (
                  <div className="mb-[15px]">
                    <ShimmerEffect />
                  </div>
                ) : (
                  <div className="flex mb-[15px]">
                    <p className="answer-paragraph overflow-anywhere w-full">
                      <b>Student Answer:</b>

                      <>
                        {props.is_code === "0" && (JSON.parse(quiz.quiz_question).have_code === 0 || !JSON.parse(quiz.quiz_question).have_code) ? (
                          `${quiz.student_response}`
                        ) : (
                          <CodeEditor
                            language="javascript" // Replace with the language you want or leave empty for generic text
                            theme="monokai"
                            id={"" + index}
                            readOnly={props.readOnly}
                            name="code"
                            // onChange={(newValue) => onChange(index, newValue)}
                            value={quiz.student_response} // Pass the current value for controlled input
                          />
                        )}
                      </>
                    </p>
                  </div>
                )}

                {/* <div>
            <div className="mt-2 mb-3 question">
              Student Answer: {quiz.student_response}
            </div>
          </div> */}
                {spinnerVisibility !== undefined && spinnerVisibility[index] ? (
                  <ShimmerEffect />
                ) : (
                  <div className="mb-[30px]">
                    <div className="flex quiz-review mb-[5px]">
                      <p className="answer-paragraph">
                        {localStorage.getItem("user_type") === "3" || localStorage.getItem("user_type") === null ? (
                          <div>
                            <div
                              style={{
                                display: spinnerVisibility !== undefined && spinnerVisibility[index] ? "none" : "block",
                              }}
                            >
                              <b>Teepee: </b>
                              {quiz?.feedback && !quiz.feedback.startsWith("{") && !quiz.feedback.endsWith("}") ? (
                                quiz.feedback
                              ) : (
                                <FeedbackDisplay feedback={JSON.parse(quiz.feedback)} />
                              )}
                            </div>
                          </div>
                        ) : (
                          <div>
                            {quiz?.feedback && !quiz.feedback.startsWith("{") && !quiz.feedback.endsWith("}") ? (
                              <EdiText
                                showButtonsOnHover
                                className="w-[100%] "
                                viewContainerClassName="flex items-start min-h-[10px]"
                                type="text"
                                value={quiz.feedback}
                                onSave={(value) => onSaveFeedback(value, quiz)}
                                renderValue={(value) => (
                                  <>
                                    <strong>Teepee:</strong> {value}
                                  </>
                                )}
                              />
                            ) : (
                              <FeedbackDisplay feedback={JSON.parse(quiz?.feedback)} />
                            )}
                          </div>
                        )}

                        <div
                          className="edited_status  mt-[5px] self-center"
                          style={{
                            display: quiz.feedback_edited_status === 0 || localStorage.getItem("user_type") === "3" ? "none" : "block",
                          }}
                        >
                          (edited)
                        </div>
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <div className="text-wrap" style={{ fontFamily: "Roboto" }}>
                        <p className="text-[11px] leading-[16px] feedback_text" style={{ display: quiz.recheck_status === 1 ? "none" : "" }}>
                          ⚠️ Not happy with this result?
                          <span className="ml-1 text-[#1061F5] underline cursor-pointer" onClick={() => handleOpen(index, quizData)}>
                            [Request AI Recheck]
                          </span>
                        </p>
                        <p className="text-[11px] leading-[16px] feedback_text" style={{ display: quiz.recheck_status === 0 ? "none" : "" }}>
                          💡 Rechecked on{" "}
                          {new Date(quiz.time).toLocaleDateString("en-GB", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                          .
                          <span className="ml-1 text-[#1061F5] underline cursor-pointer" onClick={() => handleOpen(index, quizData)}>
                            [View History]
                          </span>
                        </p>
                      </div>
                      <div className="text-wrap">
                        {props.viewThumbsAction && <LikeDislike show_teacher_feedback={show_teacher_feedback} quiz={quiz} />}
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className="pb-[40px] mt-[50px]"
                  style={{
                    display: JSON.parse(quiz.quiz_question).answer === null && localStorage.getItem("user_type") === "3" ? "none" : "block",
                    marginTop: spinnerVisibility !== undefined && spinnerVisibility[index] && "15px",
                  }}
                >
                  {spinnerVisibility !== undefined && spinnerVisibility[index] ? (
                    <ShimmerEffect />
                  ) : props.isAnswerRubric === 1 ? (
                    <></>
                  ) : (
                    <CustomizedAccordions title="Actual Answer" actualAnswer={JSON.parse(quiz.quiz_question).answer}></CustomizedAccordions>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          mock_exam !== null &&
          quizData.length > 0 &&
          JSON.parse(mock_exam.question_context_list)?.map((question_context, index) => (
            <Fragment key={index}>
              <div style={{ display: "none" }}>{(q_index -= q_index)}</div>
              <div style={{}}>
                <div
                  style={{
                    // padding: "10px",
                    borderRadius: "5px",
                    width: "100%",
                    // marginBottom: "30px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <p
                    className="answer-paragraph"
                    style={{
                      fontSize: "16px",
                      fontFamily: "Roboto",
                      color: "#49454F",
                    }}
                  >
                    <b className="mr-[4px]">Question {index + 1}: </b>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: stripHTMLTags(question_context.question_context_name),
                      }}
                    ></span>
                  </p>

                  {JSON.parse(question_context.predefined_question_answer_list)?.map((predefined_question_answer, index) => (
                    <div className="m-[30px] mb-[30px]">
                      <div id="question" className="flex">
                        <div>
                          <b>({String.fromCharCode(q_index++ + "A".charCodeAt(0)).toLowerCase()}) </b>
                        </div>
                        <div className="ml-[10px]" style={{ width: "100%" }}>
                          <p
                            className="answer-paragraph md:whitespace-break-spaces mt-[2px]"
                            style={{
                              fontSize: "16px",
                              fontFamily: "Roboto",
                              color: "rgb(73, 69, 79)",
                            }}
                          >
                            <p
                              dangerouslySetInnerHTML={{
                                __html: predefined_question_answer.question,
                              }}
                            ></p>
                          </p>
                        </div>
                        <div className="teepee-label justify-end">
                          <div className="ml-2 question-score ">
                            <div
                              style={{
                                display: spinnerVisibility !== undefined && spinnerVisibility[index] ? "none" : "flex",
                              }}
                            >
                              {localStorage.getItem("user_type") === "3" || localStorage.getItem("user_type") === null ? (
                                <>
                                  <div>
                                    {getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index) !== undefined &&
                                    getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).score === null
                                      ? " "
                                      : getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index) !== undefined &&
                                        getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).score}
                                  </div>
                                  <div>
                                    {"/" + getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).question_marks}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <EdiText
                                    style={{
                                      display: "none",
                                    }}
                                    showButtonsOnHover
                                    editButtonClassName=""
                                    submitOnUnfocus
                                    saveButtonClassName="hidden"
                                    cancelButtonClassName="hidden"
                                    viewContainerClassName="mr-[0px] flex items-center"
                                    // editButtonClassName="hidden"
                                    editContainerClassName="w-[50%]"
                                    editOnViewClick
                                    className="w-full "
                                    validation={(value) => validation(value, predefined_question_answer)}
                                    type="text"
                                    onSave={(value) =>
                                      onSaveMarks(value, getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index))
                                    }
                                    value={
                                      getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index) !== undefined &&
                                      getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).score
                                    }
                                  />
                                  <div>
                                    {"/" + getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).question_marks}
                                  </div>
                                </>
                              )}
                            </div>
                            <div
                              className="edited_status text-center  mt-[5px]"
                              style={{
                                display:
                                  getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).score_edited_status ===
                                    0 || localStorage.getItem("user_type") === "3"
                                    ? "none"
                                    : "block",
                              }}
                            >
                              (edited)
                            </div>
                          </div>
                        </div>
                      </div>
                      {predefined_question_answer.image_file_name !== null && (
                        <div className="flex justify-center mb-[20px] mt-[20px]">
                          <img
                            alt=""
                            className=""
                            src={process.env.REACT_APP_CDN_URL_FOR_QUESTION_IMAGES + predefined_question_answer.image_file_name}
                          ></img>
                        </div>
                      )}
                      {spinnerVisibility !== undefined && spinnerVisibility[index] ? (
                        <div className="mt-[10px]">
                          <ShimmerEffect />
                        </div>
                      ) : (
                        <div className="flex mb-[15px]">
                          <p className="answer-paragraph overflow-anywhere">
                            <b>Student Answer: </b>
                            {getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index) !== undefined &&
                              getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).student_response}
                          </p>
                        </div>
                      )}

                      {spinnerVisibility !== undefined && spinnerVisibility[index] ? (
                        <div className="mt-[10px]">
                          <ShimmerEffect />
                        </div>
                      ) : (
                        <div className="mb-[30px]">
                          <div className="flex quiz-review mb-[5px] ">
                            <p className="answer-paragraph">
                              {localStorage.getItem("user_type") === "3" || localStorage.getItem("user_type") === null ? (
                                <>
                                  <b>Teepee: </b>
                                  {getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index) !== undefined &&
                                    getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).feedback}
                                </>
                              ) : (
                                <>
                                  <EdiText
                                    showButtonsOnHover
                                    editContainerClassName="w-full"
                                    className="w-full"
                                    viewContainerClassName="items-start flex min-h-[10px]"
                                    editButtonClassName=""
                                    type="text"
                                    onSave={(value) =>
                                      onSaveFeedback(value, getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index))
                                    }
                                    value={
                                      getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).feedback !==
                                        undefined &&
                                      getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).feedback
                                    }
                                    renderValue={(value) => (
                                      <>
                                        <strong>Teepee:</strong> {value}
                                      </>
                                    )}
                                  />
                                  <div
                                    className="edited_status  mt-[5px] self-center "
                                    style={{
                                      display:
                                        getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index)
                                          .feedback_edited_status === 0 || localStorage.getItem("user_type") === "3"
                                          ? "none"
                                          : "block",
                                    }}
                                  >
                                    (edited)
                                  </div>
                                </>
                              )}
                            </p>
                          </div>
                          {props.viewThumbsAction && (
                            <LikeDislike
                              show_teacher_feedback={show_teacher_feedback}
                              quiz={
                                getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index) !== undefined &&
                                getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index)
                              }
                            />
                          )}
                        </div>
                      )}
                      {spinnerVisibility !== undefined && spinnerVisibility[index] ? (
                        <div className="mt-[10px]">
                          <ShimmerEffect />
                        </div>
                      ) : (
                        <div
                          style={{
                            display:
                              JSON.parse(getQuizResponseByQuestionContextId(predefined_question_answer.question_context_id, index).quiz_question)
                                .answer === null && localStorage.getItem("user_type") === "3"
                                ? "none"
                                : "block",
                          }}
                        >
                          <AnswerAccordian
                            title="Actual Answer"
                            display_rubric={props.display_rubric}
                            predefined_question_answer={predefined_question_answer}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </Fragment>
          ))
        )}
        <div
          className="mt-[10px] mb-[10px]"
          style={{
            display: quizData.length === 0 ? "none" : "block",
          }}
        >
          <TrialAttemptsButton quiz_setting={props?.quizSetting} experience={location.state?.experience} />
        </div>
      </div>
    )
  );
}

export default QuizReviewCard;
