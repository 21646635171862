import React from "react";
import EdiText from "react-editext";
import { SortableElement } from "react-sortable-hoc";

const SortableItem = ({
  key,
  index,
  value,
  onSaveSubject,
  subject,
  sendDeleteRequest,
  isVideo,
}) => {
  const options = {
    height: "290",
    width: "100",
    playerVars: {
      autoplay: 1,
      controls: 1,
    },
  };
  const _onReady = (event) => {
    event.target.pauseVideo();
  };
  return (
    <div
      className={`${
        isVideo ? "w-[30%]" : "w-[40%]"
      } p-3 rounded mb-[1%] justify-between`}
      style={{
        border: "1px solid #1D192B",
        background: "rgba(232, 222, 248, 0.50)",
      }}
    >
      <div className="w-[100%] flex justify-between">
        <EdiText
          className="w-[80%]"
          submitOnEnter={false}
          type="text"
          value={value}
          onSave={(value) => onSaveSubject(value, subject)}
        />
        <button
          type="submit"
          id={subject.subject_interest_id}
          key={subject.subject_interest_id}
          className="self-center"
          onClick={() =>
            sendDeleteRequest(
              isVideo ? subject.id_tutorial_videos : subject.subject_interest_id
            )
          }
        >
          Delete
        </button>
      </div>
      {isVideo && (
        <div className="mt-[20px]">
          <iframe
            width="100%"
            height="200"
            src={`https://www.youtube.com/embed/${value}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; 
  autoplay; 
  clipboard-write; 
  encrypted-media; 
  gyroscope; 
  picture-in-picture; 
  web-share"
            allowfullscreen
          ></iframe>
          {/* <YouTube
            videoId={value}
            options={options}
            onReady={_onReady}
            id="video"
          /> */}
        </div>
      )}
    </div>
  );
};

export default SortableElement(SortableItem);
