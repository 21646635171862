import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { useLocation } from "react-router-dom";
import NoStudentsGif from "../../images/no-students.gif";
import SubscriptionExpirationBanner from "../Teacher/widgets/SubscriptionExpirationBanner";
import ManageSubjectStudentTable from "./widgets/ManageSubjectStudentTable";
import axios from "axios";
import ConfirmationDialogue from "../Teacher/confirmationdialogue/ConfirmationDialogue";
import moment from "moment";
import AcknowledgementDialogue from "../Teacher/confirmationdialogue/AcknowledgementDialogue";
import UpgradePlan from "./UpgradePlan";
import appContext from "../../context/appContext";
import setting_icon from "../../images/settings1.png";
function ManageSubject() {
  const location = useLocation();
  const state = useContext(appContext);
  const { selectedSubject } = location.state !== null && location.state;

  const [subjectStudents, setSubjectStudents] = useState(null);
  const [selectedOption, setSelectedOption] = useState("1");
  const [selectedStudent, setSelectedStudent] = useState();
  const [openDeleteConfirmationDialogue, setOpenDeleteConfirmationDialogue] = useState(false);
  const [openCancelSubscriptionDialogue, setOpenCancelSubscriptionDialogue] = useState(false);
  const [openCancelSubscriptionAcknowledgmentDialogue, setOpenCancelSubscriptionAcknowledgmentDialogue] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [bannerObject, setBannerObject] = useState();
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);

  const handleOpenCancelSubscriptionDialogue = () => {
    setOpenCancelSubscriptionDialogue(true);
  };
  const handleCloseCancelSubscriptionDialogue = () => {
    setOpenCancelSubscriptionDialogue(false);
  };
  const handleCloseConfirmCancelSubscriptionDialogue = () => {
    handleOpenCancelSubscriptionAcknowledgmentDialogue();
    setOpenCancelSubscriptionDialogue(false);
  };
  const handleOpenCancelSubscriptionAcknowledgmentDialogue = () => {
    setOpenCancelSubscriptionAcknowledgmentDialogue(true);
  };
  const handleCloseCancelSubscriptionAcknowledgmentDialogue = () => {
    setOpenCancelSubscriptionAcknowledgmentDialogue(false);
  };
  function onValueChange(event) {
    // Updating the state with the selected radio button's value
    setSelectedOption(parseInt(event.target.value));
  }

  const changeStudentEnrollmentStatus = () => {
    getSubjectStudents();
  };
  const handleClose = () => {
    state.setOpenBuyVoucherPopUp(false);
    // setVouchersRate(5);
    // setOffIndicator(-1);
    // setSliderRange(1);
    // setNoOfStudents(1);
    // isStep.current = false;
  };
  useEffect(() => {
    getSubjectStudents();
  }, []);

  const getSubjectStudents = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .request({
        method: "POST",

        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_all_students",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: JSON.stringify({
          subject_id: selectedSubject && selectedSubject.subject_id,
          account_status: selectedSubject && selectedSubject.account_status,
          trial_start_date: moment().format("Y-MM-DD HH:mm:ss"),
        }),
      })
      .then((res) => {
        console.log(res.data);
        setSubjectStudents(res.data.data);
        setBannerObject(res.data.trial_data);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const handleClickDeleteStudent = (student, index) => {
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/delete_student_management_tab", {
        method: "POST",
        student_id_by_teacher: student.student_id,
        subject_id: selectedSubject.subject_id,
      })
      .then((response) => {
        removeStudent(index);
        setOpenDeleteConfirmationDialogue(false);
      })
      .catch((error) => {
        console.log(error);
        alert("failed to delete student");
      });
  };

  const handleOpen = (student, index) => {
    setOpenDeleteConfirmationDialogue(true);
    setSelectedStudent(student);
    setSelectedIndex(index);
  };
  const handleCloseWithYes = () => {
    handleClickDeleteStudent(selectedStudent, selectedIndex);
  };
  const handleCloseWithCancel = () => {
    setOpenDeleteConfirmationDialogue(false);
  };

  const removeStudent = (index) => {
    console.log("REMOVED CALLED");
    var array = [...subjectStudents]; // make a separate copy of the array

    //var index = e.target.value;
    if (index !== -1) {
      array.splice(index, 1);
      setSubjectStudents(array);
    }
  };
  const createCustomerPortalSession = async (customerId, returnUrl) => {
    let url = process.env.REACT_APP_REST_API_BASE_URL;
    const response = await fetch(`${url}/create_customer_portal_session`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        customer_id: customerId,
        return_url: returnUrl,
      }),
    });
    const data = await response.json();
    return data.url;
  };
  const createOrRetrieveCustomer = async (email) => {
    let url = process.env.REACT_APP_REST_API_BASE_URL;
    const response = await fetch(`${url}/create_or_retrieve_customer`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    });
    const data = await response.json();
    return data.customer_id;
  };
  const handleCustomerPortal = async () => {
    setSpinnerVisibility(true);
    const email = localStorage.getItem("email_address");
    const customerId = await createOrRetrieveCustomer(email);
    let cancel_url = process.env.REACT_APP_CANCEL_URL;
    const returnUrl = `${cancel_url}/subscription`; // Replace with the actual return URL
    const portalUrl = await createCustomerPortalSession(customerId, returnUrl);
    setSpinnerVisibility(false);
    if (portalUrl) {
      window.location.href = portalUrl; // Redirect to the Stripe Customer Portal
    } else {
      console.error("Failed to create customer portal session");
    }
  };
  return (
    <div>
      {spinnerVisibility && (
        <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
          <svg
            aria-hidden="true"
            class="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      )}
      <ConfirmationDialogue
        title="Delete"
        text="Are you sure, you want to delete this student?"
        open={openDeleteConfirmationDialogue}
        handleCloseWithConfirm={handleCloseWithYes}
        handleCloseWithCancel={handleCloseWithCancel}
      />
      <ConfirmationDialogue
        open={openCancelSubscriptionDialogue}
        title="Delete"
        text="Are you sure you want to cancel the subscription?"
        handleCloseWithConfirm={handleCloseConfirmCancelSubscriptionDialogue}
        handleCloseWithCancel={handleCloseCancelSubscriptionDialogue}
      />
      <AcknowledgementDialogue
        title="Subscription cancelled"
        text="The subscription has been cancelled"
        handleCloseWithConfirm={handleCloseCancelSubscriptionAcknowledgmentDialogue}
        open={openCancelSubscriptionAcknowledgmentDialogue}
      />
      <Sidebar item_id="subscription_teacher" />

      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px]">
        {bannerObject && (
          <SubscriptionExpirationBanner setOpen={state.setOpenBuyVoucherPopUp} bannerObject={bannerObject} subjectData={selectedSubject} />
        )}

        {selectedSubject && (
          <UpgradePlan
            open={state.openBuyVoucherPopup}
            handleClose={handleClose}
            subjectName={selectedSubject?.subject_name}
            subject={selectedSubject}
          />
        )}
        <div className="home sm:ml[25px]  bg-[#F7F2F9] h-[93px] justify-around">
          <div className="md:classroom_name_div sm:w-[90%] md:w-[20%] md:!overflow-visible sm:overflow-hidden ">
            <div className="font-medium text-[22px] text-[#49454F] sm:text-with-dots-without-width md:!overflow-visible sm:whitespace-nowrap sm:overflow-hidden sm:text-ellipsis ">
              {"Subject: " + selectedSubject.subject_name}
            </div>
            <div className="classroom_details_label !font-extralight	!text-[16px] pt-[4%]">
              {`Students :  ${subjectStudents && subjectStudents.length}`}
            </div>
          </div>
          <div className="classroom_details_label flex !font-extralight	!text-[16px] mt-[3%] ml-[20%] cursor-pointer" onClick={handleCustomerPortal}>
            <img src={setting_icon} className="mr-2 h-4 w-4 mt-1" alt="" /> Manage Subscription
          </div>
          <div>
            <label className="classroom_details_label"></label>
          </div>
          <div className="flex mb-[13px]">
            <div className="mr-[8px] mt-[auto] mb-[3px]">
              <button
              //    onClick={() => handleOpenLeaveClassroomDialogue()}
              >
                {/* <img src={ExitClassroom}></img> */}
              </button>
            </div>
            <div className="mt-[auto] mb-[12px] ">
              <button className="text-[#6750A4] !font-medium !text-[14px]" onClick={() => handleOpenCancelSubscriptionDialogue()}>
                {/* Cancel Subscription */}
              </button>
            </div>
          </div>
        </div>

        <div
          className="flex flex-row md:justify-left md:items-center sm:ml-0 md:ml-[60px] mt-[20px]   "
          style={{
            overflow: "hidden",
            overflowX: "scroll",
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {subjectStudents && subjectStudents.length > 0 ? (
            <ManageSubjectStudentTable
              subjectStudents={subjectStudents}
              handleChange={onValueChange}
              changeStudentEnrollmentStatus={changeStudentEnrollmentStatus}
              handleOpen={handleOpen}
              bannerObject={selectedSubject}
            />
          ) : (
            <div className="flex justify-center w-[100%]">
              <div>
                <div className="text-gray-700 p-4 antialiased font-sans text-base font-light leading-relaxed">
                  <b style={{ fontWeight: "bold" }}>No students to manage yet, Add students in your classroom.</b>
                </div>
                <div className="flex justify-center">
                  <img className="w-[200px]" src={NoStudentsGif} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ManageSubject;
