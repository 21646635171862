import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Delete from "../../../images/delete.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axios from "axios";
import ConfirmationDialogue from "../confirmationdialogue/ConfirmationDialogue";
import Edit from "../../../images/edit-student.png";
import DeleteStudent from "../../../images/delete-student.png";
import NoStudentsGif from "../../../images/no-students.gif";
import { styled } from "@mui/material/styles";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

function TeacherStudentTabBody(props) {
  console.log("DATA:", props.classroomData);
  const [openDeleteConfirmationDialogue, setOpenDeleteConfirmationDialogue] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState();
  const [studentid, setStudentid] = useState();
  const [studentName, setStudentName] = useState();
  const [table, setTable] = useState("student");
  const [data, setData] = useState({ r1: [], r2: [], r3: [], r4: [] });
  const [selectedTooltipIndexR1, setSelectedTooltipIndexR1] = useState(null);
  const [selectedTooltipIndexR2, setSelectedTooltipIndexR2] = useState(null);
  const [selectedTooltipIndexR3, setSelectedTooltipIndexR3] = useState(null);
  const [selectedTooltipIndexR4, setSelectedTooltipIndexR4] = useState(null);
  var [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;

  const data2 = [{ r1: [], r2: [], r3: [], r4: [] }];

  const handleOpenQuizTip = (index, column) => {
    if (column === "r1") {
      setSelectedTooltipIndexR1(index);
    } else if (column === "r2") {
      setSelectedTooltipIndexR2(index);
    } else if (column === "r3") {
      setSelectedTooltipIndexR3(index);
    } else if (column === "r4") {
      setSelectedTooltipIndexR4(index);
    }
  };

  // Function to handle closing of the tooltip
  const handleCloseQuizTip = (column) => {
    if (column === "r1") {
      setSelectedTooltipIndexR1(null);
    } else if (column === "r2") {
      setSelectedTooltipIndexR2(null);
    } else if (column === "r3") {
      setSelectedTooltipIndexR3(null);
    } else if (column === "r4") {
      setSelectedTooltipIndexR4(null);
    }
  };

  // const [student, setStudent] = useState(props.classroomData.student);
  var student = props.studentData;

  console.log("STUDENTS ARE: ", props.studentData);

  const handleClickDeleteStudent = (student, index) => {
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/change_status_of_student", {
        method: "POST",
        student_id_by_teacher: student.student_id,
        class_id: props.classroomData.classroom_id,
      })
      .then((response) => {
        props.removeStudent(index);
        setOpenDeleteConfirmationDialogue(false);
      })
      .catch((error) => {
        console.log(error);
        alert("failed to delete student");
      });
  };
  const handleClickChangeStudentEnrollmentStatus = (student, index, event) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/change_enrollment_status_of_student", {
        method: "POST",
        student_id_by_teacher: student.student_id,
        class_id: props.classroomData.classroom_id,
        status: parseInt(event.target.value),
        subject_id: props.classroomData.subject.subject_id,
        account_status: props.bannerObject.account_status,
      })
      .then((response) => {
        props.handleChange(event);
        props.changeStudentEnrollmentStatus(index, event.target.value);
      })
      .catch((error) => {
        console.log(error);
        alert("failed to delete student");
      });
  };

  const handleOpen = (student, index) => {
    setOpenDeleteConfirmationDialogue(true);
    setSelectedStudent(student);
    setSelectedIndex(index);
  };
  const handleCloseWithYes = () => {
    handleClickDeleteStudent(selectedStudent, selectedIndex);
  };
  const handleCloseWithCancel = () => {
    setOpenDeleteConfirmationDialogue(false);
  };

  const handleTable = (studentId, studentname) => {
    setStudentid(studentId);
    setStudentName(studentname);
    setTable("setting");
  };
  function stripHTMLTags(html) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  const paginate = (pageNumber) => {
    // getRecalls();
    setCurrentPage(pageNumber);

    console.log("Total Pages", totalPages);
  };

  // Assuming data is an array of your rows
  const lastItemIndex = currentPage * itemsPerPage;
  const firstItemIndex = lastItemIndex - itemsPerPage;
  let R1 = [];
  try {
    R1 = JSON.parse(data?.r1);
  } catch (error) {
    console.log("Error parsing JSON data:", error);
  }
  let R2 = [];
  try {
    R2 = JSON.parse(data?.r2);
  } catch (error) {
    console.log("Error parsing JSON data:", error);
  }
  let R3 = [];
  try {
    R3 = JSON.parse(data?.r3);
  } catch (error) {
    console.log("Error parsing JSON data:", error);
  }
  let R4 = [];
  try {
    R4 = JSON.parse(data?.r4);
  } catch (error) {
    console.log("Error parsing JSON data:", error);
  }
  data2.r1 = R1.slice(firstItemIndex, lastItemIndex);
  data2.r2 = R2.slice(firstItemIndex, lastItemIndex);
  data2.r3 = R3.slice(firstItemIndex, lastItemIndex);
  data2.r4 = R4.slice(firstItemIndex, lastItemIndex);
  const totalData = data2?.r1?.length + data2?.r2?.length + data2?.r3?.length + data2?.r4?.length;

  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} placement="top" />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      color: "#49454F",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
      borderRadius: "8px",
      opacity: "0.8",
      backgroundColor: "#FFF",
      padding: "12px 16px",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 250,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));
  const totalPages = Math.ceil((R1?.length + R2?.length + R3?.length + R4?.length) / itemsPerPage);

  const getRecalls = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_student_questions",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
        data: {
          student_id: studentid,
          classroom_id: props.classroomData.classroom_id,
          limit: itemsPerPage + 2 * currentPage,
        },
      })
      .then((res) => {
        var data2 = res.data;
        console.log("Total data:", itemsPerPage * currentPage);

        setData(res.data);
        console.log("DATA ISL", data.r1);
      });
  };
  useEffect(() => {
    if (table === "setting") {
      getRecalls();
    }
  }, [table, currentPage]);

  return (
    <div>
      <ConfirmationDialogue
        title="Delete"
        text="Are you sure, you want to delete this student?"
        open={openDeleteConfirmationDialogue}
        handleCloseWithConfirm={handleCloseWithYes}
        handleCloseWithCancel={handleCloseWithCancel}
      />
      <div
        className="home md:ml-[20px] mt-[20px] h-[100vh] "
        style={{
          justifyContent: student.length === 0 && "center",
          width: "100%",
        }}
      >
        {student.length > 0 && table === "student" ? (
          <div class="relative overflow-x-auto" style={{ width: "100%" }}>
            <table class="w-full text-sm text-left text-white dark:text-white">
              <thead class="text-xs text-white  bg-[#6750A4] dark:bg-white dark:text-white">
                <tr>
                  <th scope="col" class="px-6 py-3">
                    ID
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Joining Date
                  </th>
                  <th scope="col" class="px-6 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {student ? (
                  <>
                    {student.map((student, index) => {
                      return (
                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {index + 1}
                          </th>
                          <td class="px-6 py-4 " style={{ color: "blue" }}>
                            <button onClick={() => handleTable(student.student_id, student.student_name)}> {student.student_name}</button>
                          </td>{" "}
                          <td class="px-6 py-4">{student.student_email}</td>
                          <td class="px-6 py-4">{student.classroom_joined_timestamp}</td>
                          <td class="px-6 py-4">
                            <Dropdown className="w-full h-full mb-[12%]">
                              <Dropdown.Toggle id="profile-dropdown" className="w-full">
                                <div
                                  className={`${
                                    student.student_status === 1 || student.student_status === -1 ? "bg-[#62C370]" : "bg-[#DC362E]"
                                  } w-fit	 rounded text-white`}
                                >
                                  <div className="flex justify-between">
                                    <div className="ml-2 flex items-center p-2">
                                      {student.student_status === 1 || student.student_status === -1 ? "Enrolled" : "Disabled"}
                                    </div>
                                    <button className="bg-white mr-[2px] mt-[3px] mb-[3px] rounded-r flex items-center p-2">
                                      <img style={{ width: "14px" }} src={Edit} />
                                    </button>
                                  </div>
                                </div>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="!bg-[#F3EDF8] !pb-0">
                                <div
                                  className="p-3 w-[200px]"
                                  style={{
                                    display: student.student_status === -1 && "none",
                                  }}
                                >
                                  <div
                                    className="mb-[10%]"
                                    style={{
                                      display: student.student_status === -1 && "none",
                                    }}
                                  >
                                    <label className="flex">
                                      <input
                                        type="radio"
                                        value={1}
                                        checked={student.student_status === 1}
                                        onChange={(e) => handleClickChangeStudentEnrollmentStatus(student, index, e)}
                                      />
                                      <div className="ml-[10%] font-medium	">Assign License</div>
                                    </label>
                                    <br />
                                  </div>
                                  <div>
                                    <label
                                      className="flex"
                                      style={{
                                        display: student.student_status === -1 && "none",
                                      }}
                                    >
                                      <input
                                        type="radio"
                                        value={2}
                                        checked={student.student_status === 2}
                                        onChange={(e) => handleClickChangeStudentEnrollmentStatus(student, index, e)}
                                      />
                                      <div className="ml-[10%] font-medium	">Disable License</div>
                                    </label>
                                  </div>
                                </div>

                                <Dropdown.Divider
                                  className="m-0"
                                  style={{
                                    display: student.student_status === -1 && "none",
                                  }}
                                />
                                <Dropdown.Item onClick={() => handleOpen(student, index)}>
                                  <div className="flex mt-[5%] mb-[5%]">
                                    <div className="flex items-center">
                                      <img style={{ maxWidth: "fit-content" }} src={DeleteStudent} />
                                    </div>
                                    <div className="ml-[12%] text-[#DC362E] " onClick={() => handleOpen(student, index)}>
                                      Delete Student
                                    </div>
                                  </div>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>

                            {/* <button
                            onClick={() =>
                              // handleClickDeleteStudent(student, index)
                              handleOpen(student, index)
                            }
                          >
                            <img src={Delete}></img>
                          </button> */}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : null}
              </tbody>
            </table>
          </div>
        ) : student.length === 0 ? (
          <div>
            <div className="text-gray-700 p-4 antialiased font-sans text-base font-light leading-relaxed">
              <b style={{ fontWeight: "bold" }}> Get started by adding students in classroom through Class Code! </b>
            </div>
            <div className="flex justify-center">
              <img className="w-[200px]" src={NoStudentsGif} />
            </div>
          </div>
        ) : (
          table === "setting" && (
            <div className="w-full h-full overflow-auto">
              <div className=" mb-[15px]">
                <label className="disabled-label" style={{ color: "#9a7ba2" }} onClick={() => setTable("student")}>
                  Back
                </label>
              </div>
              <p
                style={{
                  color: "#49454F",
                  fontFamily: "Roboto",
                  fontSize: "24px",
                  lineHeight: " 36px",
                  fontWeight: "600",
                  paddingBottom: "22px",
                }}
              >
                Student Name: {studentName}
              </p>
              <div className="flex overflow-auto">
                <table class="w-full text-sm text-left text-white dark:text-white">
                  <thead class="text-xs text-white bg-[#403151] dark:bg-white dark:text-white">
                    <tr style={{ border: "1px solid white" }}>
                      <th scope="col" colspan="5" class="px-6 py-1 text-center hidden"></th>
                    </tr>
                    <tr>
                      <th scope="col" class="px-2 py-2 hidden">
                        S.no
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.from(
                      {
                        length: totalData > 30 ? totalData - 2 : totalData,
                      },
                      (_, index) => (
                        <tr key={index} style={{ border: "1px solid black", display: "none" }}>
                          <td
                            className="text-center"
                            style={{
                              color: "#49454F",
                              borderRight: "1px solid black",
                            }}
                          >
                            {index + 1}
                          </td>

                          {/* Accessing R2 data */}

                          {/* You can add more cells here to display more data from R2 and R3 if needed */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <table class="w-full text-sm text-left text-white dark:text-white">
                  <thead class="text-xs text-white bg-[#403151] dark:bg-white dark:text-white">
                    <tr style={{ border: "1px solid white" }}>
                      <th scope="col" colspan="5" class="px-6 py-1 text-center ">
                        R1
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" class="px-6 py-2">
                        S.no
                      </th>
                      <th scope="col" class="px-6 py-2">
                        Question
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Date
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Time
                      </th>
                      <th scope="col" class="px-2 py-1 border-r">
                        Priority
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data2.r1.map((row, index) => (
                      <tr key={index} style={{ border: "1px solid black" }}>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                          // Close tooltip when not hovering
                        >
                          {index + 1}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                          onMouseEnter={() => handleOpenQuizTip(index, "r1")} // Open tooltip on hover
                          onMouseLeave={() => handleCloseQuizTip("r1")} // Close tooltip when not hovering
                        >
                          <div
                            className="text-center"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              lineHeight: "1.6em",
                              maxHeight: "4.8em",
                            }}
                          >
                            {stripHTMLTags(row.quiz_question)}
                          </div>
                          <ClickAwayListener onClickAway={handleCloseQuizTip}>
                            <div className="flex item-center text-center justify-center">
                              <HtmlTooltip
                                enterTouchDelay={0}
                                leaveTouchDelay={10}
                                title={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        margin: 0,
                                        fontWeight: "600",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Question: {stripHTMLTags(row.quiz_question)}
                                    </p>
                                    <p>
                                      History:{" "}
                                      {
                                        row.recall_status_activities
                                          ? row.recall_status_activities
                                              .split(",")
                                              .map((activity) => {
                                                switch (activity.trim()) {
                                                  case "1":
                                                    return "r1";
                                                  case "2":
                                                    return "r2";
                                                  case "3":
                                                    return "r3";
                                                  case "4":
                                                    return "r4";
                                                  default:
                                                    return activity; // or 'N/A' for unknown values
                                                }
                                              })
                                              .filter((activity) => activity) // Optional, to filter out any empty strings
                                              .join(", ")
                                          : "N/A" // Display this when row.recall_status_activities is null or undefined
                                      }
                                    </p>
                                  </React.Fragment>
                                }
                                text={row.recall_status_activities}
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                open={selectedTooltipIndexR1 === index} // Control tooltip visibility based on state
                              ></HtmlTooltip>
                            </div>
                          </ClickAwayListener>
                        </td>

                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.date}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.time}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.quiz_question_importance === 1 ? "High" : "Low"}
                        </td>
                        {/* Accessing R2 data */}

                        {/* You can add more cells here to display more data from R2 and R3 if needed */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table class="w-full text-sm text-left text-white dark:text-white">
                  <thead class="text-xs text-white bg-[#403151] dark:bg-white dark:text-white">
                    <tr style={{ border: "1px solid white" }}>
                      <th scope="col" colspan="5" class="px-6 py-1 text-center">
                        R2
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" className="px-6 py-2">
                        S.no
                      </th>
                      <th scope="col" class="px-6 py-2">
                        Question
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Date
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Time
                      </th>
                      <th scope="col" class="px-2 py-1 border-r">
                        Priority
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data2.r2.map((row, index) => (
                      <tr key={index} style={{ border: "1px solid black" }}>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                          onMouseEnter={() => handleOpenQuizTip(index, "r2")} // Open tooltip on hover
                          onMouseLeave={() => handleCloseQuizTip("r2")} // Close tooltip when not hovering
                        >
                          <div
                            className="text-center"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              lineHeight: "1.6em",
                              maxHeight: "4.8em",
                            }}
                          >
                            {stripHTMLTags(row.quiz_question)}
                          </div>
                          <ClickAwayListener onClickAway={handleCloseQuizTip}>
                            <div className="flex item-center text-center justify-center">
                              <HtmlTooltip
                                enterTouchDelay={0}
                                leaveTouchDelay={10}
                                title={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        margin: 0,
                                        fontWeight: "600",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Question: {stripHTMLTags(row.quiz_question)}
                                    </p>
                                    <p>
                                      History:{" "}
                                      {
                                        row.recall_status_activities
                                          ? row.recall_status_activities
                                              .split(",")
                                              .map((activity) => {
                                                switch (activity.trim()) {
                                                  case "1":
                                                    return "r1";
                                                  case "2":
                                                    return "r2";
                                                  case "3":
                                                    return "r3";
                                                  case "4":
                                                    return "r4";
                                                  default:
                                                    return activity; // or 'N/A' for unknown values
                                                }
                                              })
                                              .filter((activity) => activity) // Optional, to filter out any empty strings
                                              .join(", ")
                                          : "N/A" // Display this when row.recall_status_activities is null or undefined
                                      }
                                    </p>
                                  </React.Fragment>
                                }
                                text={row.recall_status_activities}
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                open={selectedTooltipIndexR2 === index} // Control tooltip visibility based on state
                              ></HtmlTooltip>
                            </div>
                          </ClickAwayListener>
                        </td>

                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.date}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.time}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.quiz_question_importance === 1 ? "High" : "Low"}
                        </td>
                        {/* Accessing R2 data */}

                        {/* You can add more cells here to display more data from R2 and R3 if needed */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table class="w-full text-sm text-left text-white dark:text-white">
                  <thead class="text-xs text-white bg-[#403151] dark:bg-white dark:text-white">
                    <tr style={{ border: "1px solid white" }}>
                      <th scope="col" colspan="5" class="px-6 py-1 text-center">
                        R3
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" className="px-6 py-2">
                        S.no
                      </th>
                      <th scope="col" class="px-6 py-2">
                        Question
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Date
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Time
                      </th>
                      <th scope="col" class="px-2 py-1 border-r">
                        Priority
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data2.r3.map((row, index) => (
                      <tr key={index} style={{ border: "1px solid black" }}>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                          onMouseEnter={() => handleOpenQuizTip(index, "r3")} // Open tooltip on hover
                          onMouseLeave={() => handleCloseQuizTip("r3")} // Close tooltip when not hovering
                        >
                          <div
                            className="text-center"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              lineHeight: "1.6em",
                              maxHeight: "4.8em",
                            }}
                          >
                            {stripHTMLTags(row.quiz_question)}
                          </div>
                          <ClickAwayListener onClickAway={handleCloseQuizTip}>
                            <div className="flex item-center text-center justify-center">
                              <HtmlTooltip
                                enterTouchDelay={0}
                                leaveTouchDelay={10}
                                title={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        margin: 0,
                                        fontWeight: "600",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Question: {stripHTMLTags(row.quiz_question)}
                                    </p>
                                    <p>
                                      History:{" "}
                                      {
                                        row.recall_status_activities
                                          ? row.recall_status_activities
                                              .split(",")
                                              .map((activity) => {
                                                switch (activity.trim()) {
                                                  case "1":
                                                    return "r1";
                                                  case "2":
                                                    return "r2";
                                                  case "3":
                                                    return "r3";
                                                  case "4":
                                                    return "r4";
                                                  default:
                                                    return activity; // or 'N/A' for unknown values
                                                }
                                              })
                                              .filter((activity) => activity) // Optional, to filter out any empty strings
                                              .join(", ")
                                          : "N/A" // Display this when row.recall_status_activities is null or undefined
                                      }
                                    </p>
                                  </React.Fragment>
                                }
                                text={row.recall_status_activities}
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                open={selectedTooltipIndexR3 === index} // Control tooltip visibility based on state
                              ></HtmlTooltip>
                            </div>
                          </ClickAwayListener>
                        </td>

                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.date}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.time}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.quiz_question_importance === 1 ? "High" : "Low"}
                        </td>
                        {/* Accessing R2 data */}

                        {/* You can add more cells here to display more data from R2 and R3 if needed */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <table class="w-full text-sm text-left text-white dark:text-white">
                  <thead class="text-xs text-white bg-[#403151] dark:bg-white dark:text-white">
                    <tr style={{ border: "1px solid white" }}>
                      <th scope="col" colspan="5" class="px-6 py-1 text-center">
                        R4
                      </th>
                    </tr>
                    <tr>
                      <th scope="col" className="px-6 py-2">
                        S.no
                      </th>
                      <th scope="col" class="px-6 py-2">
                        Question
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Date
                      </th>
                      <th scope="col" class="px-4 py-2">
                        Time
                      </th>
                      <th scope="col" class="px-2 py-1 border-r">
                        Priority
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data2.r4.map((row, index) => (
                      <tr key={index} style={{ border: "1px solid black" }}>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                        >
                          {index + 1}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            verticalAlign: "middle",
                            maxWidth: "10px",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "20px",
                            borderRight: "1px solid black",
                          }}
                          onMouseEnter={() => handleOpenQuizTip(index, "r4")} // Open tooltip on hover
                          onMouseLeave={() => handleCloseQuizTip("r4")} // Close tooltip when not hovering
                        >
                          <div
                            className="text-center"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              lineHeight: "1.6em",
                              maxHeight: "4.8em",
                            }}
                          >
                            {stripHTMLTags(row.quiz_question)}
                          </div>
                          <ClickAwayListener onClickAway={handleCloseQuizTip}>
                            <div className="flex item-center text-center justify-center">
                              <HtmlTooltip
                                enterTouchDelay={0}
                                leaveTouchDelay={10}
                                title={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        margin: 0,
                                        fontWeight: "600",
                                        fontFamily: "Roboto",
                                      }}
                                    >
                                      Question: {stripHTMLTags(row.quiz_question)}
                                    </p>
                                    <p>
                                      History:{" "}
                                      {
                                        row.recall_status_activities
                                          ? row.recall_status_activities
                                              .split(",")
                                              .map((activity) => {
                                                switch (activity.trim()) {
                                                  case "1":
                                                    return "r1";
                                                  case "2":
                                                    return "r2";
                                                  case "3":
                                                    return "r3";
                                                  case "4":
                                                    return "r4";
                                                  default:
                                                    return activity; // or 'N/A' for unknown values
                                                }
                                              })
                                              .filter((activity) => activity) // Optional, to filter out any empty strings
                                              .join(", ")
                                          : "N/A" // Display this when row.recall_status_activities is null or undefined
                                      }
                                    </p>
                                  </React.Fragment>
                                }
                                text={row.recall_status_activities}
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                open={selectedTooltipIndexR4 === index} // Control tooltip visibility based on state
                              ></HtmlTooltip>
                            </div>
                          </ClickAwayListener>
                        </td>

                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.date}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.time}
                        </td>
                        <td
                          className="text-center"
                          style={{
                            color: "#49454F",
                            borderRight: "1px solid black",
                          }}
                        >
                          {row.quiz_question_importance === 1 ? "High" : "Low"}
                        </td>
                        {/* Accessing R2 data */}

                        {/* You can add more cells here to display more data from R2 and R3 if needed */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mt-[15px] mr-[0px] float-right flex gap-3">
                <button
                  className="btn"
                  style={{
                    fontSize: "16px",
                    backgroundColor: "#403151",
                    color: "white",
                    fontFamily: "Roboto",
                  }}
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  {"<"}
                </button>
                <span className="btn" style={{ fontSize: "20px", color: "#49454F" }}>
                  {" "}
                  {currentPage}
                </span>
                <button
                  className="btn"
                  style={{
                    fontSize: "16px",
                    backgroundColor: "#403151",
                    color: "white",
                    fontFamily: "Roboto",
                  }}
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                >
                  {">"}
                </button>
                {/* {Array.from({ length: totalPages }, (_, i) => i + 1).map(
              (number) => (
                <button
                  key={number}
                  onClick={() => paginate(number)}
                  disabled={currentPage === number}
                >
                  {number}
                </button>
              )
            )} */}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default TeacherStudentTabBody;
