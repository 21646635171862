import React, { useState, useImperativeHandle, forwardRef } from "react";

const CheckoutForm = forwardRef((props, ref) => {
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    if (props.handleInputFieldValidation()) {
      return;
    }
    e.preventDefault();
  };

  useImperativeHandle(ref, () => ({
    handleSubmit,
  }));

  return (
    <div className="container sm:!pl-0 sm:!pr-0 ">
      <div className="col-md-12">
        <form id="payment-form" onSubmit={handleSubmit} className="subscription-card-box-shadow !rounded-xl">
          <div className="form-group">
            <p
              style={{
                fontFamily: "Roboto",
                fontWeight: "700",
                fontSize: "24px",
                lineHeight: "32px",
              }}
            >
              {"Submit Purchase Order Details"}
            </p>
            <div className="teepee-divider" style={{ marginBottom: "24px" }}></div>

            <div className="row" style={{ marginBottom: "24px" }}>
              <label htmlFor="schoolName" className="form-label font-bold">
                1. School name<span style={{ color: "red" }}>*</span>
              </label>

              <input type="text" className="form-control" id="schoolName" placeholder="Enter school name" />
            </div>

            <div className="row" style={{ marginBottom: "24px", display: props.value === "credit" && "none" }}>
              <label htmlFor="purchaseOrderNumber" className="form-label font-bold">
                2. Purchase Order Number
              </label>
              <input type="text" className="form-control" id="purchaseOrderNumber" placeholder="Enter purchase order number" />
            </div>
            <div
              className="row w-[100%] mr-0 ml-0 "
              style={{
                marginBottom: "24px",

                display: props.value !== "credit" && "none",
              }}
            >
              {/* <PaymentElement id="payment-element" className="" /> */}
            </div>
            <div className="row" style={{ marginBottom: "24px" }}>
              <label htmlFor="contactPerson" className="form-label font-bold">
                3. Contact Person<span style={{ color: "red" }}>*</span>
              </label>
              <input type="text" className="form-control" id="contactPerson" placeholder="Enter your name" />
            </div>

            <div className="row" style={{ marginBottom: "24px" }}>
              <label htmlFor="emailAddress" className="form-label font-bold">
                4. Email Address<span style={{ color: "red" }}>*</span>
              </label>
              <input type="email" className="form-control" id="emailAddress" placeholder="Enter email" />
            </div>

            <div className="row" style={{ marginBottom: "24px" }}>
              <label htmlFor="phoneNumber" className="form-label font-bold">
                5. Phone Number
              </label>
              <input type="text" className="form-control" id="phoneNumber" placeholder="Enter mobile number" />
            </div>

            <div className="row" style={{ marginBottom: "12px" }}>
              <label htmlFor="additionalComments" className="form-label font-bold">
                6. Additional Comments/Instructions
              </label>
              <textarea className="form-control" id="additionalComments" placeholder="Add additional Comments/instruction" rows="3"></textarea>
            </div>
            <div className="row" style={{ marginBottom: "12px" }}>
              <p className="font-roboto text-[14px] leading-[20px]">
                You can also submit your order via email at
                <a href="mailto:order@teepee.ai" className="text-blue-500 underline ml-1 ">
                  order@teepee.ai
                </a>
              </p>
              <p className="font-roboto text-[14px] leading-[20px]">
                For any queries, feel free to contact us at
                <a href="mailto:support@teepee.ai" className="text-blue-500 underline ml-1">
                  support@teepee.ai
                </a>
              </p>
            </div>
            {message && (
              <div id="payment-message" className="alert alert-warning mt-4">
                {message}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
});

export default CheckoutForm;
