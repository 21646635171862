import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import EditPredifinedQuestions from "./EditPredefinedQuestions";
import Typography from "@mui/material/Typography";
import SubAccordions from "./SubAccordian";
import EdiText from "react-editext";
import Button from "@mui/material/Button";
import axios from "axios";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import QuestionContextAccordian from "./QuestionContextAccordian";
import IncDecCounter from "./IncDecCounter";
import PaperCategoryDialogue from "./widgets/PaperCategoryDialogue";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
//   flexDirection: "row-reverse",
//   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//     transform: "rotate(90deg)",
//   },
//   "& .MuiAccordionSummary-content": {
//     marginLeft: theme.spacing(1),
//   },
// }));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const onUpdateMockExamName = (value, mockExam) => {
  // chapter.chapter_name=value;
  var token = "Bearer " + localStorage.getItem("access_token");
  axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  axios.defaults.headers.post["Content-Type"] =
    "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.post["authorization"] = token;
  axios
    .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_mock_exam_name", {
      method: "POST",
      mock_exam_name: value,
      mock_exam_id: mockExam.mock_exam_id,
    })

    .then((response) => {})
    .catch((error) => {
      console.log(error);
      alert("unable to update subject");
    });
};

export default function MockExamAccordian(props) {
  console.log("MOCK EXAM IN ACCORDIAN: ", props.mock_exam);
  const [expand, setExpand] = React.useState(false);
  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
    props.getPaperCategories(props.subject.subject_id);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  const onIncDecClick = (value) => {
    onUpdateMockExamDuration(value, props.mock_exam);
  };

  const onUpdateMockExamDuration = (value, mockExam) => {
    // chapter.chapter_name=value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/update_mock_exam_duration",
        {
          method: "POST",
          mock_exam_duration: value,
          mock_exam_id: mockExam.mock_exam_id,
        }
      )

      .then((response) => {
        props.addTodo();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to update subject");
      });
  };

  const addNewQuestionContext = () => {
    // alert(subject.subject_name);

    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL +
          "/create_new_question_context",
        {
          method: "POST",
          mock_exam_id: props.mock_exam.mock_exam_id,
        }
      )

      .then((response) => {
        props.addTodo();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to add new topic");
      });
  };
  const addNewPaperCategory = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_category", {
        method: "POST",
        paper_name: "Paper " + `${props.papers.length + 1}`,
        subject_id: props.subject.subject_id,
      })
      .then((response) => {
        props.getPaperCategories(props.subject.subject_id);
      })
      .catch((error) => {
        console.log(error);
        alert("unable to add new topic");
      });
  };

  const onChange = (event) => {
    var paperCategory = props.papers.filter(
      (paper) => paper.paper_name === event.target.value
    );

    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/update_mock_exam_category",
        {
          method: "POST",
          mock_exam_id: props.mock_exam.mock_exam_id,
          category: paperCategory[0].paper_id,
        }
      )
      .then((response) => {
        props.addTodo();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to add new topic");
      });
  };

  return (
    <div>
      <PaperCategoryDialogue
        open={open}
        handleClickClose={handleClickClose}
        subject_id={props.subject.subject_id}
      />
      <Accordion expanded={expand}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            onClick: toggleAcordion,
          }}
          style={{
            backgroundColor: "rgba(255, 255, 255, .05)",
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
            "& .MuiAccordionSummary-content": {
              marginLeft: 0,
            },
            padding: 0,
          }}
        >
          <Typography className="w-full">
            {" "}
            <EdiText
              className="w-full"
              type="text"
              onSave={(value) => onUpdateMockExamName(value, props.mock_exam)}
              value={props.mock_exam.mock_exam_name}
            />
          </Typography>
          <div className="w-[35%]">
            {" "}
            <select
              style={{ height: "30px" }}
              placeholder="select paper type"
              id="modules_option"
              onChange={onChange}
              name="paper"
              className=" teepee-select shadow-none leading-[30px] font-[300] px-4 w-[100%] h-[100%] bg-transparent"
            >
              <option
                hidden
                value="select paper type"
                defaultValue={"Select paper type"}
              >
                Select paper type
              </option>
              {props.getPaperCategoriesList(props.mock_exam)}
            </select>
          </div>
          <div className="w-[35%]">
            <Button
              className="delete-button"
              style={{ color: "#1976d2", padding: "6px 8px" }}
              onClick={() => {
                // addNewPaperCategory()
                handleClickOpen();
              }}
            >
              Add Paper Category
            </Button>
          </div>

          <Button
            style={{ float: "right" }}
            onClick={() =>
              props.handleOpenDeleteDialogue(props.mock_exam.mock_exam_id)
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              <div style={{ display: "flex" }}>
                <b className="mt-[5px] mr-[20px]">Exam Duration</b>
                <IncDecCounter
                  marks={props.mock_exam.mock_exam_duration}
                  onIncDecClick={onIncDecClick}
                  label=""
                  uper_limit={150}
                  lower_limit={1}
                />
                <b className="mt-[5px] ml-[20px]">Minutes</b>
              </div>

              {JSON.parse(props.mock_exam.question_context_list)?.map(
                (questionContext, subIopicIndex) => (
                  <Fragment key={subIopicIndex}>
                    <div>
                      <p
                        style={{
                          fontSize: "14px",
                          fontFamily: "Roboto",
                          color: "#49454F",
                        }}
                      >
                        <b>Question: {subIopicIndex + 1}</b>
                        {
                          <QuestionContextAccordian
                            addTodoParent={props.addTodoParent}
                            addTodo={props.addTodo}
                            question_context={questionContext}
                            questioncontextIndex={subIopicIndex}
                            editMockExamQuestion={props.editMockExamQuestion}
                            mockExamsListIndex={props.mockExamsListIndex}
                            subject={props.subject}
                            predefinedQuestionsPromptList={
                              props.predefinedQuestionsPromptList
                            }
                          />
                        }
                      </p>
                    </div>
                  </Fragment>
                )
              )}
              <Button
                className="delete-button"
                style={{ color: "#1976d2", padding: "6px 8px" }}
                onClick={() => addNewQuestionContext()}
              >
                Add Question
              </Button>
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
