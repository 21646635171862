import React from "react";
import { Navigate } from "react-router-dom";

function ProtectedTeacherRoutes({ element }) {
  const user_type = localStorage.getItem("user_type");
  if (user_type === "1" || user_type === "2") {
    return element;
  } else {
    if (user_type === "3") {
      return <Navigate to="/student-dashboard" />;
    }
    if (user_type === null) {
      return <Navigate to="/login" />;
    }
    return <Navigate to="/" />;
  }
}

export default ProtectedTeacherRoutes;
