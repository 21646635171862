import React, { useContext, useRef } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import TrackingTableOutsideClassroom from "../TrackingTableOutsideClassroom";
import TrackingTableInsideClassroom from "../TrackingTableInsideClassroom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import appContext from "../../../context/appContext";
function TrackingTabs() {
  const [startData, setStartDate] = useState(null);
  const [endData, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [insideClassroomStats, setinsideClassroomStats] = useState([]);
  const [outsideClassroomStats, setOutsideClassroomStats] = useState([]);
  const state = useContext(appContext);
  // const [loadCount, setLoadCount] = useState(1);
  var tabState = localStorage.getItem("tabState");
  const [subjects, setSubjects] = useState([]);
  const selectedSubjectGlobal = useRef([]);

  useEffect(() => {
    if (state.loadCount === 1) {
      localStorage.setItem("tabState", 1);
      console.log("LOCAL STORAGE HSA BEEN UPDATED: ", localStorage);
      state.setLoadCount(2);
    }
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/stat_inside_class", {
        method: "POST",
      })

      .then((response) => {
        console.log(response.data);
        setinsideClassroomStats(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/stat_outside_class", {
        method: "POST",
      })
      .then((response) => {
        console.log("outside data: in api ", response.data);
        setOutsideClassroomStats(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    getSubjectsList();
  }, []);

  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log("objedsdct");
        console.log(res.data);
        setSubjects(res.data);
        var subjects = res.data;
      });
  };

  const subjectList = subjects.map((subject) => (
    <option id={subject.subject_id}>{subject.subject_name}</option>
  ));

  const data = [
    {
      label: "Inside Classroom",
      value: "1",
      body: (
        <TrackingTableInsideClassroom
          insideClassroomStats={insideClassroomStats}
        />
      ),
    },
    {
      label: "Outside Classroom",
      value: "2",
      body: (
        <TrackingTableOutsideClassroom
          outsideClassroomStats={outsideClassroomStats}
        />
      ),
    },
  ];
  const selectFieldStyles = {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid",
      borderColor: "black",
      // Change the border color to your desired focus color
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#79747e", // Change the normal border color here
    },
    "& .MuiSelect-select.MuiSelect-select": {
      marginLeft: "13px",
      color: "#474747", // Change the color of the selected option(s)
      fontSize: "18px",
      fontWeight: "399",
      fontFamily:
        'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"', // Change the font size of the selected option(s)
    },
  };

  const handleClose = () => {
    // setStartDate(null);
    // setEndDate(null);
    setOpen(false);
  };

  const handleConfirm = () => {
    if (selectedSubjectGlobal.current.length > 0) {
      trackingBySubject();
      return;
    }
    if (startData === null || endData === null) {
      alert("please add both dates");
      return;
    }
    console.log("TAB VALUE : ", tabState, startData, endData);
    const year = startData.$y;
    const month = startData.$M + 1; // Adding 1 because months are 0-based
    const day = startData.$D;

    const year2 = endData.$y;
    const month2 = endData.$M + 1; // Adding 1 because months are 0-based
    const day2 = endData.$D;

    const startDate = `${year}-${month}-${day}`;
    const endDate = `${year2}-${month2}-${day2}`;

    const url =
      tabState === "1"
        ? "/filter_stat_inside_class"
        : "/filter_stat_outside_class";
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + url, {
        method: "POST",
        start_date: startDate,
        end_date: endDate,
      })

      .then((response) => {
        console.log(response);
        if (tabState === "1") {
          setinsideClassroomStats(response.data);
        } else {
          setOutsideClassroomStats(response.data);
        }
        handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangeSubject = (e) => {
    if (e.target.value === "-1") {
      selectedSubjectGlobal.current = null;
      return;
    }
    var selectedSubject = subjects.filter(
      (subject) =>
        subject.subject_id === parseInt(e.target.selectedOptions[0].id)
    );
    selectedSubjectGlobal.current = selectedSubject;
    trackingBySubject();
  };

  const trackingBySubject = () => {
    var startDate;
    var endDate;
    if (startData || endData) {
      const year = startData.$y;
      const month = startData.$M + 1;
      const day = startData.$D;

      const year2 = endData.$y;
      const month2 = endData.$M + 1;
      const day2 = endData.$D;

      startDate = `${year}-${month}-${day}`;
      endDate = `${year2}-${month2}-${day2}`;
    }
    var url;

    if (startData || endData) {
      url = "/tracking_analytics_subject_filter_by_date";
    } else {
      url = "/tracking_analytics_subject_filte";
    }
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + url, {
        method: "POST",
        start_date: startData ? startDate : null,
        end_date: endData ? endDate : null,
        subject_id: selectedSubjectGlobal.current[0].subject_id,
      })

      .then((response) => {
        console.log(response);
        setinsideClassroomStats(response.data);
        // handleClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Tabs id="custom-animation" value={tabState} className="w-[100%] ">
        <div>
          <center>
            <div className="flex">
              <div className="w-[30%] ml-[auto]">
                <TabsHeader
                  className="w-[100%] h-[58px] justify-center bg-[#6750A414]"
                  indicatorProps={{
                    className: "bg-[#6750A414] shadow-none !text-gray-900",
                  }}
                >
                  {data.map(({ label, value, icon }) => (
                    <div
                      onClick={() => {
                        localStorage.setItem("tabState", value);
                      }}
                    >
                      <Tab
                        id="ABC"
                        key={value}
                        value={value}
                        icon={icon}
                        className="w-[100%] mr-[20px] "
                      >
                        <div>{label}</div>
                      </Tab>
                    </div>
                  ))}
                </TabsHeader>
              </div>
            </div>
          </center>
        </div>

        <div className="mt-[9%]">
          <div>
            <div className="flex justify-end">
              <div className="w-[20%] mr-[2%]">
                <div className="w-[100%]">
                  <select
                    // value={
                    //   state.selectedSubjectForPopUp !== null
                    //     ? state.selectedSubjectForPopUp.subject_name
                    //     : newSelectedSubject.current &&
                    //       newSelectedSubject.current.subject_name
                    // }
                    // disabled={state.selectedSubjectForPopUp !== null}
                    onChange={onChangeSubject}
                    name="quizSubject"
                    className="class_name_input_create shadow-none !h-[54px] leading-[30px]  mb-5 font-[300] px-4 sm:w-[100%] md:w-[100%]  h-[100%] "
                  >
                    <option value="-1">Select Subject</option>
                    {subjectList}
                  </select>
                </div>
              </div>
              <div className="w-[10%]  mb-[10px]">
                <FormControl sx={selectFieldStyles} className="w-[100%]">
                  <InputLabel
                    shrink={false}
                    id="demo-simple-select-label"
                    style={{ marginBottom: "10px" }}
                  >
                    Date
                  </InputLabel>
                  <Select
                    open={open}
                    onClose={handleClose}
                    onOpen={() => setOpen(true)}
                    style={{ height: "53px" }}
                    className=""
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    name="chapter"
                  >
                    <div className="pr-[35px] pl-[35px] pt-[12px]">
                      <div>
                        <div className="mb-[8px]">Start Date</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={startData}
                            onChange={(newValue) => setStartDate(newValue)}
                            format="YYYY-MM-DD"
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="pt-[8px]">End Date</div>
                      <div className="mt-[8px]">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={endData}
                            onChange={(newValue) => setEndDate(newValue)}
                            format="YYYY-MM-DD"
                          />
                        </LocalizationProvider>
                      </div>
                      <div className="">
                        <button
                          className="dialogue-button-text mt-[30px]"
                          onClick={() => handleClose()}
                        >
                          Cancel
                        </button>
                        <button
                          className="teepee-button  mt-[20px] mb-[17px]"
                          onClick={() => handleConfirm()}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <TabsBody animate={{}}>
            {data.map(({ value, body }) => (
              <TabPanel key={value} value={value} className="p-0 overflow-auto">
                {body}
              </TabPanel>
            ))}
          </TabsBody>
        </div>
      </Tabs>
    </div>
  );
}

export default TrackingTabs;
