import React, { useContext, useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import google_icon from "../images/ic_google.svg";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import amplitude from "amplitude-js";
import { useNavigate } from "react-router-dom";
import microsoft_icon from "../images/ic_microsoft.svg";
import LoginWithEmail from "./LoginWithEmail";
import appContext from "../context/appContext";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";
const PurpleCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.getContrastText("#6750A4"), // Color for unchecked state
  "&.Mui-checked": {
    color: "#6750A4", // Custom color for checked state
  },
  "&.MuiCheckbox-root": {
    color: "#6750A4", // Ensures the checkbox has a visible color when unchecked
    // You can adjust the opacity to make the checkbox visible but distinct from the checked state
  },
}));

function LoginOptions(props) {
  const [user, setUser] = useState(null);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [loginWithEmail, setLoginWithEmail] = useState(false);
  const [update, setUpdate] = useState(true);
  const state = useContext(appContext);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
    },
    onError: (error) => {
      console.log("Login Failed:", error);
    },
  });
  const loginWithEmailStatus = () => {
    setLoginWithEmail(true);
  };

  const loginWithMicrosoft = () => {
    const isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);

    if (isMobile) {
      // Implement alternative login flow for mobile
      // For example, using loginRedirect instead of loginPopup

      instance
        .loginRedirect({
          scopes: ["email"],
        })
        .then((result) => {
          return result;
        })
        .then((result) => {
          console.log("MICROSOFT DATA: ", result);

          checkUserAccountAndThenLogin(result.account, 3);
        })
        .catch((err) => {
          alert("Error: " + JSON.stringify(err, null, 2));
          console.log(err.errName, err.errType);
          amplitude.getInstance().setUserId(null);
          amplitude.getInstance().logEvent("LP-CwithMicroSoft-Unsuccessful");
        });
    } else {
      // Existing loginPopup flow for desktop
      instance
        .loginPopup({
          scopes: ["email"],
        })
        .then((result) => {
          return result;
        })
        .then((result) => {
          console.log("MICROSOFT DATA: ", result);

          checkUserAccountAndThenLogin(result.account, 3);
        })
        .catch((err) => {
          console.log(err.errName, err.errType);
          amplitude.getInstance().setUserId(null);
          amplitude.getInstance().logEvent("LP-CwithMicroSoft-Unsuccessful");
        });
    }
  };

  function checkUserAccountAndThenLogin(email, auth_type) {
    const nameParts = email.name.split(" ");

    // Extract the first name (assuming the first part is the first name)
    const firstName = nameParts[0];

    // Join the remaining parts as the last name
    const lastName = nameParts.slice(1).join(" ");
    const url = props.user_type
      ? "/student_login_with_google_or_ms_verified_email"
      : "/login_with_google_or_ms_verified_email";

    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + url,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email: auth_type === 3 ? email.username : email.email,
          auth_type: auth_type,
          class_code: props.classCode ? props.classCode : null,
          name: email.name,
          first_name: firstName,
          last_name: lastName,
        }),
      })
      .then((res) => {
        console.log(res);

        amplitude
          .getInstance()
          .setUserId(auth_type === 3 ? email.username : email.email);
        if (auth_type === 2) {
          if (state.loginState === 1) {
            amplitude.getInstance().logEvent("LP-CwithGoogle-Student");
          } else {
            amplitude.getInstance().logEvent("LP-CwithGoogle-Teacher");
          }
        } else {
          if (state.loginState === 1) {
            amplitude.getInstance().logEvent("LP-CwithMicroSoft-Student");
          } else {
            amplitude.getInstance().logEvent("LP-CwithMicroSoft-Teacher");
          }
        }

        localStorage.setItem("access_token", res.data.access_token);
        if (props.user_type) {
          localStorage.setItem("user_type", "3");
        } else {
          localStorage.setItem("user_type", res.data.user_type);
        }
        localStorage.setItem(
          "email_address",
          auth_type === 3 ? email.username : email.email
        );
        if (props.user_type) {
          localStorage.setItem("student_name", email.name);
        }
        console.log("DATA:", res.data);
        if (state.loginState === 1) {
          amplitude
            .getInstance()
            .setUserId(localStorage.getItem("email_address"));
          amplitude
            .getInstance()
            .logEvent(
              "LoginSuccessful-Student",
              "user successfully logged in with email: " + auth_type === 3
                ? email.username
                : email.email
            );
        } else {
          amplitude
            .getInstance()
            .setUserId(localStorage.getItem("email_address"));
          amplitude
            .getInstance()
            .logEvent(
              "LoginSuccessful-Teacher",
              "user successfully logged in with email: " + auth_type === 3
                ? email.username
                : email.email
            );
        }
        if (props.user_type) {
          navigate("/student-dashboard", {});
        } else {
          if (res.data.new_user) {
            navigate("/onboarding");
            return;
          }

          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);

        amplitude
          .getInstance()
          .setUserId(auth_type === 3 ? email.username : email.email);
        if (auth_type === 2) {
          if (state.loginState === 1) {
            amplitude
              .getInstance()
              .logEvent("LP-CwithGoogle-Unsuccessful-Student");
          } else {
            amplitude
              .getInstance()
              .logEvent("LP-CwithGoogle-Unsuccessful-Teacher");
          }
        } else {
          if (state.loginState === 1) {
            amplitude
              .getInstance()
              .logEvent("LP-CwithMicroSoft-Unsuccessful-Student");
          } else {
            amplitude
              .getInstance()
              .logEvent("LP-CwithMicroSoft-Unsuccessful-Teacher");
          }
        }
      });
  }
  useEffect(() => {
    if (user != null) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          // setProfile(res.data);
          checkUserAccountAndThenLogin(res.data, 2);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);
  return (
    <div>
      {!loginWithEmail ? (
        <div>
          <div>
            <div className="w-full">
              <div className="login-options">
                {" "}
                Choose how would you like to sign in your<br></br> Teepee.ai
                account
              </div>
              <div
                className="mt-[20px]"
                style={{
                  position: "relative",
                  //  display:'flex',
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <GoogleButton  style={{width: "100%"}} label="Sign in with Google 🚀 " onClick={() => login()}/> */}
                <button
                  type="submit"
                  className="create-new-account-button border-[1px] border-[#474747] rounded-md hover:bg-black text-[black] hover:text-white font-bold py-2 px-4 w-full  h-auto"
                  onClick={() => login()}
                >
                  <div style={{ display: "inherit" }}>
                    <div className="flex">
                      <img
                        alt=""
                        className="float-left w-[26px] mr-[10px]"
                        src={google_icon}
                      ></img>
                      Continue with Google
                      <div style={{ visibility: "hidden" }}>ds</div>
                    </div>
                  </div>
                </button>
              </div>
              <div
                className="mt-[20px]"
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button
                  type="submit"
                  className="create-new-account-button border-[1px] border-[#474747] rounded-md hover:bg-black text-[black] hover:text-white font-bold py-2 px-4 w-full  h-auto"
                  onClick={() => loginWithMicrosoft()}
                >
                  <div style={{ display: "inline-block" }}>
                    <div className="flex">
                      <img
                        alt=""
                        className="float-left w-[26px] mr-[10px]"
                        src={microsoft_icon}
                      ></img>
                      Continue with Microsoft
                    </div>
                  </div>
                </button>
              </div>
              <div
                className="mt-[20px]"
                style={{
                  position: "relative",
                  //  display:'flex',
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <GoogleButton  style={{width: "100%"}} label="Sign in with Google 🚀 " onClick={() => login()}/> */}
                <button
                  type="submit"
                  className="create-new-account-button border-[1px] border-[#474747] rounded-md hover:bg-black text-[black] hover:text-white font-bold py-2 px-4 w-full  h-auto"
                  onClick={() => loginWithEmailStatus()}
                >
                  <div style={{ display: "inherit" }}>
                    <div className="flex">
                      <img
                      // alt=""
                      // className="float-left w-[26px] mr-[10px]"
                      // src={google_icon}
                      ></img>
                      Continue with Email
                    </div>
                  </div>
                </button>
                <div className="mt-2">
                  {" "}
                  <FormControlLabel
                    control={
                      <PurpleCheckbox
                        checked={update}
                        onChange={() => setUpdate(!update)}
                      />
                    }
                    label="I want to receive updates about Teepee"
                  />
                </div>
              </div>

              <button
                type="submit"
                className="create-new-account-text w-[100%] mt-[12px]"
                onClick={() => {
                  navigate("/register");
                  amplitude.getInstance().setUserId(null);
                  amplitude.getInstance().logEvent("FPP-Create new account");
                }}
              >
                Create new account
              </button>
            </div>
          </div>
        </div>
      ) : (
        <LoginWithEmail classCode={props.classCode} />
      )}
    </div>
  );
}

export default LoginOptions;
