import React from "react";
import StudentDoTheseCard from "./StudentDoTheseCard";
import StudentDoneThemCard from "./StudentDoneThemCard";
import task_icon from "../../../images/No_icon.svg";
function StudentDashbaordTabBody(props) {
  const doThese = props.doThese;
  const doneThem = props.doneThem;

  return (
    <div className="mt-[-25px]">
      <div style={{ display: props.activeTab === "do" ? "" : "none" }} className="classroom_heading">
        <div>
          <label className="dashboard-label text-[#000000]" style={{ fontSize: "20px", color: "#000000" }}>
            Tasks
          </label>
        </div>
      </div>
      <>
        {props.activeTab === "do" && (
          <>
            {doThese && doThese.length > 0 ? (
              <StudentDoTheseCard doThese={doThese} viewQuiz={props.viewQuiz} quizType={1} />
            ) : (
              <div>
                <center>
                  <img src={task_icon} alt="no" />
                </center>
                <div className="flex justify-center font-bold">No tasks available!</div>
                <div className="flex justify-center ">we'll inform you when new tasks are on the way!</div>
              </div>
            )}

            <div className="classroom_heading">
              <div>
                <label className="dashboard-label text-[#000000]" style={{ fontSize: "20px", color: "#000000" }}>
                  Retrieval Practice
                </label>
              </div>
            </div>
            {props.doTheseRetrieval && props.doTheseRetrieval.length > 0 ? (
              <StudentDoTheseCard doThese={props.doTheseRetrieval} viewQuiz={props.viewQuizRetrieval} quizType={2} />
            ) : (
              <div>
                <center>
                  <img src={task_icon} alt="no" />
                </center>
                <div className="flex justify-center font-bold">No retrieval quizzes available!</div>{" "}
                <div className="flex justify-center ">we'll inform you when new quizzes are on the way!</div>
              </div>
            )}
          </>
        )}
      </>

      {props.activeTab === "done" && (
        <>
          <div className="classroom_heading mt-[30px]">
            <p className="font-bold sm:ml-[25px] md:!ml-0 xs:ml-[25px] xs:text-[22px]">Your Completed Tasks and Quizzes</p>
          </div>
        </>
      )}

      {doneThem && props.activeTab === "done" && props.doTheseRetrieval && (doneThem.length > 0 || props.doneThemRetrieval.length > 0) ? (
        <StudentDoneThemCard
          handleOnClickDoneThemQuiz={props.handleOnClickDoneThemQuiz}
          doneThem={doneThem}
          doneThemRetrieval={props.doneThemRetrieval}
          viewQuizRetrieval={props.viewQuizRetrieval}
        />
      ) : (
        <div style={{ display: props.activeTab === "done" ? "" : "none" }} className="flex justify-center mt-4">
          Completed Quizzes will be displayed here.
        </div>
      )}
    </div>
  );
}

export default StudentDashbaordTabBody;
