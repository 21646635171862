import React, { useState } from "react";
import { Tooltip } from "@mui/material";

function OnBoardingSubjectsWidgetTeacher({
  subjectsList,
  handleOnClick,
  activeButton,
}) {
  const [onHover, setOnHover] = useState(-1);

  const onMouseEnter = (index) => {
    setOnHover(index);
  };
  const onMouseLeave = () => {
    setOnHover(-1);
  };
  return (
    <div className="md:w-[40%] sm:w-[90%] grid md:grid-cols-2 sm:grid-cols-1 gap-2">
      {subjectsList.map((subject, index) => (
        <button
          onMouseEnter={() => onMouseEnter(index)}
          onMouseLeave={() => onMouseLeave()}
          onClick={() => handleOnClick(subject.subject_interest_id, index)}
          id={subject.subject_interest_id}
          className="flex w-[100%] md:p-3 sm:items-center sm:pl-[2%] rounded mb-[1%]  md:h-[auto] sm:h-[8vh]"
          style={{
            fontWeight:
              index === subjectsList.length - 1 ||
              activeButton.includes(subject.subject_interest_id)
                ? "bold"
                : "normal",
            border: activeButton.includes(subject.subject_interest_id)
              ? "2px solid var(--material-theme-sys-light-primary, #6750A4)"
              : index === subjectsList.length - 1
              ? "2px dashed var(--m-3-sys-light-outline, #79747E)"
              : "1px solid #1D192B",
            borderStyle: index === subjectsList.length - 1 && "dashed",
            background:
              activeButton.includes(subject.subject_interest_id) ||
              onHover === index
                ? "rgba(208, 188, 255, 0.72)"
                : "rgba(232, 222, 248, 0.50)",
          }}
        >
          <div
            style={{
              display: index !== subjectsList.length - 1 && "none",
              marginRight: "3%",
            }}
          >
            ➕
          </div>
          {index !== subjectsList.length - 1 ? (
            subject.subject_name
          ) : (
            <Tooltip
              key={index}
              placement="right"
              title={"Specify your subject"}
            >
              <div>{"Where's My Subject?"}</div>
            </Tooltip>
          )}
        </button>
      ))}
    </div>
  );
}

export default OnBoardingSubjectsWidgetTeacher;
