import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import close_icon from "../../images/closed.svg";
import checked from "../../images/checked.svg";
import bg_image from "../../images/pop_bg.png";
import useGetPricing from "../PricingApiCall/GetPricing";
import { useNavigate } from "react-router-dom";
import checked_icon from "../../images/Tick.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import header_logo from "../../images/card_header.svg";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import stripe_logo from "../../images/stripe.svg";
import { loadStripe } from "@stripe/stripe-js";
import LoadingSpinner from "../widgets/LoadingSpinner";
const UpgradePlan = (props) => {
  const navigate = useNavigate();
  const [students, setStudents] = useState();
  const [noOfStudents, setNoOfStudents] = useState("");
  const [price, setPrice] = useState();
  const [paymentOption, setPaymentOption] = useState("annually");
  const [id, setId] = useState();
  const [showPaymentOptions, setShowPaymentOptions] = useState(false); // State to toggle view
  const [selectedOption, setSelectedOption] = useState(null);
  const [updatedPrice, setUpdatedPrice] = useState();
  const [stripePromise, setStripePromise] = useState(null);
  const [spinnerVisibility, setSpinnerVisibility] = useState(false);
  const getPricing = useGetPricing();

  useEffect(() => {
    getPricing(setPrice, setStudents, setId);
  }, []);

  const handleOnClickBuy = () => {
    setShowPaymentOptions(true);
  };
  const fetchConfig = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/config`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const { publishableKey } = await response.json();

        console.log("GOT YA ", publishableKey);
        setStripePromise(loadStripe(publishableKey));
      } else {
        console.error("Failed to fetch config");
      }
    } catch (error) {
      console.error("Error fetching config:", error);
    }
  };
  const handlePurchaseOrder = () => {
    console.log("DATA IN THE BUTTON", props);
    selectPaymentOption("purchase_order");
    setUpdatedPrice(null);
    const data = {
      subjectName: props.subjectName,
      noOfStudent: noOfStudents,
      vouchersRate: updatedPrice ? parseFloat(updatedPrice).toFixed(2) : (price * noOfStudents).toFixed(2),
      subject: props.subject,
      plan: paymentOption === "annually" ? "Year" : "Month",
    };

    console.log("SUBJECT_DATA: ", props.subject);

    sessionStorage.setItem("checkoutState", JSON.stringify(data));
    window.open("/checkout", "_blank");
  };

  const handleBackClick = () => {
    setShowPaymentOptions(false);
    setSelectedOption("credit_card");
  };

  const selectPaymentOption = (option) => {
    setSelectedOption(option);
  };

  const createOrRetrieveCustomer = async (email) => {
    let url = process.env.REACT_APP_REST_API_BASE_URL;
    const response = await fetch(`${url}/create_or_retrieve_customer`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    });
    const data = await response.json();
    return data.customer_id;
  };
  const createSubscriptionSession = async (customerId, amount, subjectId, interval, successUrl, cancelUrl) => {
    let url = process.env.REACT_APP_REST_API_BASE_URL;
    const response = await fetch(`${url}/create_subscription`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ customer_id: customerId, amount, subject_id: subjectId, interval, success_url: successUrl, cancel_url: cancelUrl }),
    });
    const data = await response.json();
    return data.session_id;
  };
  const createVoucherPurchaseSession = async (customerId, amount, subjectId, voucherCount, successUrl, cancelUrl) => {
    let url = process.env.REACT_APP_REST_API_BASE_URL;
    const response = await fetch(`${url}/create_voucher_purchase`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        customer_id: customerId,
        amount,
        subject_id: subjectId,
        voucher_count: voucherCount,
        success_url: successUrl,
        cancel_url: cancelUrl,
      }),
    });
    const data = await response.json();
    localStorage.setItem("subscription_id", data.subscription_id);

    return data.session_id;
  };
  const handleCheckout = async () => {
    setSpinnerVisibility(true);
    const data = {
      subjectName: props.subjectName,
      noOfStudent: noOfStudents,
      vouchersRate: updatedPrice ? parseFloat(updatedPrice).toFixed(2) : (price * noOfStudents).toFixed(2),
      subject: props.subject,
      is_stripe_active: props.subject.is_stripe_active ? props.subject.is_stripe_active : 0,
      plan: paymentOption === "annually" ? "Year" : "Month",
    };

    console.log("SUBJECT_DATA: ", props.subject);
    let cancel_url = process.env.REACT_APP_CANCEL_URL;
    sessionStorage.setItem("checkoutState", JSON.stringify(data));
    console.log("here");
    const emails = localStorage.getItem("email_address");
    const email = emails; // Replace with actual user email
    const subjectId = props.subject.subject_id; // Replace with actual subject ID
    const amount = updatedPrice
      ? ((parseFloat(updatedPrice) + parseFloat((updatedPrice * 20) / 100)) * 100).toFixed(2)
      : (price * noOfStudents).toFixed(2) * 100; // Replace with actual amount in pence (e.g., £10.00 is 1000 pence)
    const interval = paymentOption === "annually" ? "year" : "month"; // or 'year'
    const successUrl = `${cancel_url}/success`;
    const cancelUrl = `${cancel_url}/subscription`;
    const customerId = await createOrRetrieveCustomer(email);
    const sessionId = await createSubscriptionSession(customerId, parseInt(amount), subjectId, interval, successUrl, cancelUrl);
    const stripe = await stripePromise;
    setSpinnerVisibility(false);
    await stripe.redirectToCheckout({ sessionId });
  };
  const handleVoucherPurchase = async () => {
    setSpinnerVisibility(true);
    const email = localStorage.getItem("email_address"); // Replace wit
    const data = {
      subjectName: props.subjectName,
      noOfStudent: noOfStudents,
      vouchersRate: updatedPrice ? parseFloat(updatedPrice).toFixed(2) : (price * noOfStudents).toFixed(2),
      subject: props.subject,
      is_stripe_active: props.subject.is_stripe_active ? props.subject.is_stripe_active : 0,
      plan: paymentOption === "annually" ? "Year" : "Month",
    };
    console.log("SUBJECT_DATA: ", props.subject);
    let cancel_url = process.env.REACT_APP_CANCEL_URL;
    sessionStorage.setItem("checkoutState", JSON.stringify(data));
    const subjectId = props.subject.subject_id; // Replace with actual subject ID
    const amount = updatedPrice ? (parseFloat(updatedPrice) + parseFloat((updatedPrice * 20) / 100)) * 100 : (price * noOfStudents).toFixed(2) * 100;
    const voucherCount = noOfStudents;
    const successUrl = `${cancel_url}/success`;
    const cancelUrl = `${cancel_url}/subscription`;
    const customerId = await createOrRetrieveCustomer(email);
    const sessionId = await createVoucherPurchaseSession(customerId, parseInt(amount), subjectId, voucherCount, successUrl, cancelUrl);
    const stripe = await stripePromise;
    setSpinnerVisibility(false);

    await stripe.redirectToCheckout({ sessionId });
  };
  useEffect(() => {
    fetchConfig();
  }, []);
  useEffect(() => {
    if (props?.subject?.subscription_start_date && props?.subject?.subscription_end_date) {
      const startDate = new Date(props.subject.subscription_start_date);
      const endDate = new Date(props.subject.subscription_end_date);

      console.log("START AND END DATA: ", startDate, endDate);

      const monthDifference = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());

      if (monthDifference <= 1) {
        setPaymentOption("monthly");
        setUpdatedPrice((price * noOfStudents) / 12);
      } else if (monthDifference >= 12) {
        setPaymentOption("annually");
        setUpdatedPrice(price * noOfStudents);
      } else {
        // Handle cases where the subscription period does not fit exactly into 1 month or 1 year
        setPaymentOption("annually"); // Default to annual payment if the period is not clear
        setUpdatedPrice(price * noOfStudents);
      }
    } else {
      // Default behavior when subscription dates are not available
      setPaymentOption("annually");
      setUpdatedPrice(price * noOfStudents);
    }
  }, [props.subject?.subscription_start_date, props.subject?.subscription_end_date, price, noOfStudents]);

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.handleClose();
        setUpdatedPrice(null);
        setSelectedOption(false);
        setSelectedOption("credit_card");
      }}
      sx={{
        "& .MuiDialog-paper": {
          maxHeight: "100vh",
          maxWidth: "100vw",
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle
        style={{
          backgroundImage: `url(${bg_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex items-center w-full">
          <img
            src={close_icon}
            className="mr-2 cursor-pointer"
            alt="Close Icon"
            onClick={() => {
              props.handleClose();
              setUpdatedPrice(null);
              setSelectedOption(false);
              setSelectedOption(null);
              setNoOfStudents("");
            }}
          />
          <span className="text-white float-left font-bold text-[22px] leading-[28px]">Upgrade Your Plan</span>
        </div>
      </DialogTitle>

      <DialogContent className="hide-scrollbar">
        {spinnerVisibility && (
          <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
            <svg
              aria-hidden="true"
              class="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        )}
        {!showPaymentOptions ? (
          <>
            <div className="text-center mt-[60px]">
              <h1 className="font-bold text-2xl text-gray-800 mb-2">Unmatched Affordability, Unparalleled Student Success</h1>
              <p className="text-gray-600">500x More Affordable Than Other Tools—Empower Your Students Without Breaking the Bank</p>
            </div>
            <div className="max-w-4xl  mx-auto my-10 bg-white shadow-lg rounded-lg">
              <p className="">
                <br></br>
              </p>
              <div className="flex item-center justify-center p-3 m-3 mt-0 bg-[#6750A4] text-[#FFFFFF] rounded-lg">
                <div>
                  <p className="font-bold text-center text-[56px] leading-[64px]">£{price}</p>
                  <p> per student, per subject, per year</p>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row" style={{ fontFamily: "Roboto" }}>
                <div className="flex-1 md:p-2  sm:p-2">
                  <div className="p-2 rounded-lg">
                    <p className="sm:text-[12px] md:text-[16px] font-semibold mb-2">How many students would you like to enrol?</p>
                    <input
                      type="text"
                      value={noOfStudents}
                      placeholder="Enter number of students"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          setNoOfStudents(value);
                        }
                      }}
                      className="w-full lg:w-[390px] h-[48px] p-2 border border-gray-300 rounded mb-2"
                    />

                    <p className="text-xs text-gray-600 mb-4 italic">
                      Note: If you enrol {students} or more students, you'll have the option to pay monthly.
                    </p>
                    <div className="p-3 rounded-[12px] border-[1px] border-gray-200 mb-4">
                      <div className="flex justify-between mb-1 ">
                        <span className="font-bold">Cost (per student):</span>
                        <span>
                          £
                          {!updatedPrice
                            ? parseFloat(props?.subject?.subscription_start_date && paymentOption !== "annually" ? price / 12 : price).toFixed(2)
                            : (updatedPrice / (noOfStudents ? noOfStudents : 1)).toFixed(2)}{" "}
                          {paymentOption === "annually" ? "per year" : "per month"}{" "}
                        </span>
                      </div>{" "}
                      <div className="flex justify-between mb-1">
                        <span className="font-bold">Students:</span>
                        <span>{noOfStudents ? noOfStudents : "0"}</span>
                      </div>
                      <div className="flex justify-between ">
                        <span className="font-bold">Total cost:</span>
                        <span>
                          £
                          {updatedPrice
                            ? parseFloat(updatedPrice).toFixed(2)
                            : ((noOfStudents ? noOfStudents : 0) * (props?.subject?.subscription_start_date ? updatedPrice : price)).toFixed(2)}{" "}
                          {paymentOption === "annually" ? "per year" : "per month"}
                        </span>
                      </div>
                    </div>
                    <span style={{ display: noOfStudents < students || props.subject?.subscription_start_date ? "none" : "" }}>
                      <p className="font-semibold mb-2 text-[16px] leading-[20px]">You Qualify for Monthly Payments!</p>
                      <div className="mb-4">
                        <label className="flex items-center mb-2 ">
                          <input
                            type="radio"
                            name="payment"
                            value="annually"
                            onChange={(e) => {
                              setUpdatedPrice(price * noOfStudents);
                              setPaymentOption(e.target.value);
                            }}
                            className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2"
                            checked={paymentOption === "annually"}
                          />
                          <span>
                            <b> Pay Annually: £{price * noOfStudents} per year </b>
                            <span className="text-gray-500 underline font-roboto  text-[12px]">cancel anytime</span>
                          </span>
                        </label>
                        <label className="flex items-center">
                          <input
                            type="radio"
                            name="payment"
                            value="monthly"
                            className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out mr-2"
                            onChange={(e) => {
                              setUpdatedPrice(((price * noOfStudents) / 12).toFixed(2));
                              setPaymentOption(e.target.value);
                            }}
                            checked={paymentOption === "monthly"}
                          />
                          <span>
                            <b>Pay Monthly: £{((price * noOfStudents) / 12).toFixed(2)} per month </b>
                            <span className="text-gray-500 underline font-roboto  text-[12px]">cancel anytime</span>
                          </span>
                        </label>
                      </div>
                    </span>
                    <button
                      disabled={noOfStudents === "0" || noOfStudents?.length === 0}
                      className={`w-full ${
                        noOfStudents === "0" || noOfStudents.length === 0 ? "disable-button" : "bg-[#6750A4]"
                      } text-white p-2 rounded-[4px] transition duration-150 ease-in-out`}
                      onClick={() => {
                        if (paymentOption === "monthly") {
                          selectPaymentOption("credit_card");

                          if (props.subject.is_stripe_active === 0 || props.subject.is_stripe_active === undefined) {
                            handleCheckout();
                          } else {
                            handleVoucherPurchase();
                          }
                        } else {
                          handleOnClickBuy();
                        }
                      }}
                    >
                      Continue to Payment Options
                    </button>
                    <p className="text-xs text-center text-gray-500 mt-2 p-2">
                      By subscribing, you agree to Teepee's{" "}
                      <a href="#" className="underline">
                        Terms of Service
                      </a>{" "}
                      and<br></br>
                      <a href="https://teepee.ai/privacy-policy-for-teepee-ai/" target="_blank" className="underline ml-2">
                        Privacy Policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
                <div className="flex-1 p-4 mt-8 lg:mt-0" style={{ fontFamily: "Roboto" }}>
                  <p className="font-bold text-center text-[18px] mb-3">
                    What's included?<br></br> Everything!
                  </p>
                  <ul className="md:pl-5 sm:pl-1 text-[#49454F] text-[14px] leading-[24px]">
                    <li className="mb-3 flex items-start">
                      <span className="flex-shrink-0">
                        <img src={checked} className="mt-0 mr-2" />
                      </span>
                      <span>Create Multiple Year Groups Under One Subject (e.g., Year 10, Year 11)</span>
                    </li>

                    <li className="mb-3 flex items-start">
                      <span className="flex-shrink-0">
                        <img src={checked} className="mt-0 mr-2" />
                      </span>
                      <span>Instant AI Marking & Feedback for Students</span>
                    </li>

                    <li className="mb-3 flex items-start">
                      <span className="flex-shrink-0">
                        <img src={checked} className="mt-0 mr-2" />
                      </span>
                      <span>Automated & Personalised Spaced Retrieval Practice</span>
                    </li>

                    <li className="mb-3 flex items-start">
                      <span className="flex-shrink-0">
                        <img src={checked} className="mt-0 mr-2" />
                      </span>
                      <span>Access to Exam-Style Question Database</span>
                    </li>

                    <li className="mb-3 flex items-start">
                      <span className="flex-shrink-0">
                        <img src={checked} className="mt-0 mr-2" />
                      </span>
                      <span>Topic/Sub-Topic-Based MCQs Database</span>
                    </li>

                    <li className="mb-3 flex items-start">
                      <span className="flex-shrink-0">
                        <img src={checked} className="mt-0 mr-2" />
                      </span>
                      <span>Reports & Dashboard for Teachers</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="payment-options h-full flex flex-col justify-center items-center">
            <div className="w-full flex justify-start max-w-4xl">
              <button onClick={handleBackClick} className="text-blue-500 underline mb-8 ">
                Back
              </button>
            </div>

            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-4xl">
              <div className="flex items-center mb-4">
                <img src={header_logo} alt="Payment Logo" className="h-[72px] w-[72px] mr-4" />
                <div>
                  <p className="font-bold text-[1.4vw] text-gray-800 mb-1 mt-2 leading-[1.0vw]" style={{ fontFamily: "Roboto, sans-serif" }}>
                    Choose Your Payment Method
                  </p>
                  <p className="text-gray-500 text-[14px] leading-[24px]" style={{ fontFamily: "Roboto, sans-serif" }}>
                    All transactions are secure and encrypted
                  </p>
                </div>
              </div>
              <div className="flex justify-center space-x-6">
                <button
                  disabled={spinnerVisibility}
                  className="w-1/2  rounded-lg p-6 cursor-pointer bg-[#F8F8F8]"
                  style={{
                    border: selectedOption === "credit_card" ? "2px solid  #40B181" : "1px solid #d1d5db",
                  }}
                  onClick={() => {
                    selectPaymentOption("credit_card");
                    props.subject.is_stripe_active === 0 || props.subject.is_stripe_active === undefined ? handleCheckout() : handleVoucherPurchase();
                  }}
                >
                  <div className="flex items-center mb-0">
                    {selectedOption === "credit_card" ? (
                      <img src={checked_icon} alt="Checked" className="h-6 w-6 mr-2" />
                    ) : (
                      <FontAwesomeIcon icon={faCircle} className="h-6 w-6 mr-2 text-gray-500" />
                    )}
                    <p className="text-[18px] font-semibold">Pay with Credit/Debit Card</p>
                  </div>
                  <p className="text-sm text-[#49454F] text-left mb-2 ml-8">
                    Pay instantly using your credit card. Access will be granted immediately upon successful payment.
                  </p>
                  <div className="flex items-center mt-4">
                    <img src={stripe_logo} className="w-[272px] h-[71px] ml-5" alt="none" />
                  </div>
                </button>

                <button
                  disabled={spinnerVisibility}
                  className="w-1/2  rounded-lg p-6 cursor-pointer bg-[#F8F8F8]"
                  style={{
                    border: selectedOption === "purchase_order" ? "2px solid  #40B181" : "1px solid #d1d5db",
                  }}
                  onClick={handlePurchaseOrder}
                >
                  <div className="flex items-center mb-0">
                    {selectedOption === "purchase_order" ? (
                      <img src={checked_icon} alt="Checked" className="h-6 w-6 mr-2" />
                    ) : (
                      <FontAwesomeIcon icon={faCircle} className="h-6 w-6 mr-2 text-gray-500" />
                    )}
                    <p className="text-[18px] font-semibold">Pay via Purchase Order</p>
                  </div>
                  <p className="text-sm text-[#49454F] text-left  ml-8">
                    Submit a purchase order from your school to initiate access. The school will process payment after receiving the invoice.
                  </p>
                  <p className="text-[#6750A4] font-bold float-right mt-[38px]">Submit Purchase Order Details</p>
                </button>
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UpgradePlan;
