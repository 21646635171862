import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import EditableSubTopicAccordion from "./EditableSubTopicAccordian";
import EdiText from "react-editext";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import Button from "@mui/material/Button";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const onSaveTopic = (topic_name, content, topic) => {
  topic.topic_name = topic_name;
  topic.content = content;
  var token = "Bearer " + localStorage.getItem("access_token");
  axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  axios.defaults.headers.post["Content-Type"] =
    "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.post["authorization"] = token;
  axios
    .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_chapter", {
      method: "POST",
      topic_id: topic.topic_id,
      topic_name: topic_name,
    })

    .then((response) => {
      // alert("Subtopic Updated");
    })
    .catch((error) => {
      console.log(error);
      // alert("unable to update Subtopic");
    });
};

const addNewSubTopic = (topic, addTodo, addTodoOld) => {
  // alert(subject.subject_name);
  var statuss = localStorage.getItem("lesson_status");
  var token = "Bearer " + localStorage.getItem("access_token");
  axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  axios.defaults.headers.post["Content-Type"] =
    "application/json;charset=utf-8";
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.post["authorization"] = token;
  axios
    .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_topic", {
      method: "POST",
      topic_id: topic.topic_id,
      subject_status_have_lesson: parseInt(statuss),
    })

    .then((response) => {
      addTodo();
    })
    .catch((error) => {
      console.log(error);
      // alert("unable to add subtopic");
    });
};

export default function SubAccordion(props) {
  const [expand, setExpand] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [predefinedQuestionsPromptList, setPredefinedQuestionsPromptList] =
    React.useState([]);
  const [
    formativePredefinedQuestionsPromptList,
    setFormativePredefinedQuestionsPromptList,
  ] = useState([]);
  const [
    minimockPredefinedQuestionsPromptList,
    setMinimockPredefinedQuestionsPromptList,
  ] = useState([]);
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };
  var stats = localStorage.getItem("lesson_status");
  // React.useEffect(() => {
  //   getPredefinedQuestionsPrompts();
  // }, []);

  // function getPredefinedQuestionsPrompts() {
  //   var token = "Bearer " + localStorage.getItem("access_token");
  //   axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
  //   axios.defaults.headers.post["Content-Type"] =
  //     "application/json;charset=utf-8";
  //   axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  //   axios.defaults.headers.post["authorization"] = token;
  //   axios
  //     .post(
  //       process.env.REACT_APP_REST_API_BASE_URL +
  //         "/get_predefined_questions_prompt_list",
  //       {
  //         method: "POST",
  //         subject_id: props.subject.subject_id,
  //         show_disabled: false,
  //       }
  //     )

  //     .then((response) => {
  //       var predefinedQuestionsPromptList = JSON.parse(
  //         response.data["predefined_questions_prompt_list"]
  //       );
  //       var formativePredefinedQuestionsPrompt = JSON.parse(
  //         response.data["formative_predefined_questions_prompt_list"]
  //       );
  //       var minimockPredefinedQuestionsPrompt = JSON.parse(
  //         response.data["mini_mock_predefined_questions_prompt_list"]
  //       );
  //       setPredefinedQuestionsPromptList(predefinedQuestionsPromptList);
  //       setFormativePredefinedQuestionsPromptList(
  //         formativePredefinedQuestionsPrompt
  //       );
  //       setMinimockPredefinedQuestionsPromptList(
  //         minimockPredefinedQuestionsPrompt
  //       );
  //       //generation_model
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  return (
    <div>
      <Accordion expanded={expand}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
          IconButtonProps={{
            onClick: toggleAcordion,
          }}
          style={{
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(90deg)",
            },
            "& .MuiAccordionSummary-content": {
              marginLeft: 0,
            },
            padding: 0,
          }}
        >
          <Typography className="w-full">
            {" "}
            <EdiText
              className="w-full"
              type="text"
              onSave={(value) =>
                onSaveTopic(value, props.title.content, props.title)
              }
              value={props.title.topic_name}
            />
          </Typography>
          <Button
            style={{ float: "right" }}
            onClick={() =>
              props.onDeleteClick(
                props.title,
                process.env.REACT_APP_DELETING_OBJECT_TYPE_CHAPTER
              )
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              {stats === "0" ? <b>Subtopic: </b> : <b>Lessons:</b>}
              {props.sub_topics_list?.map((subTopic, subIopicIndex) => (
                <Fragment key={subIopicIndex}>
                  <div>
                    <p
                      style={{
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        color: "#49454F",
                      }}
                    >
                      <EditableSubTopicAccordion
                        subject={props.subject}
                        predefinedQuestionsPromptList={
                          props.predefined_question_answer_status === 1
                            ? formativePredefinedQuestionsPromptList
                            : props.predefined_question_answer_status === 2
                            ? predefinedQuestionsPromptList
                            : minimockPredefinedQuestionsPromptList
                        }
                        chapter={props.chapter}
                        topic={props.title}
                        sub_topic={subTopic}
                        addTodo={props.addTodo}
                        onDeleteClick={props.onDeleteClick}
                        predefined_question_answer_status={
                          props.predefined_question_answer_status
                        }
                        addTodoOld={props.addTodoOld}
                      />
                    </p>
                  </div>
                </Fragment>
              ))}

              <Button
                className="delete-button"
                style={{ color: "#1976d2", padding: "6px 8px" }}
                onClick={() =>
                  addNewSubTopic(props.title, props.addTodo, props.addTodoOld)
                }
              >
                {stats === "0" ? "Add New Subtopics" : "Add New Lessons"}
              </Button>
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
