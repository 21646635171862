import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../Sidebar";
import NoSubscription from "../../images/no_subjects.png";
import axios from "axios";
import AvailableSubjectsCard from "./AvailableSubjectsCard";
import TrialSubjectsCard from "./TrialSubjectsCard";
import BuyVoucherPopup from "./BuyVoucherPopup";
import AdminSubscriptionTab from "./Admin/AdminSubscriptionTab";
import moment from "moment";
import UpgradePlan from "./UpgradePlan";
function Subscribe() {
  const [availableSubjects, setAvailableSubjects] = useState();
  const [trialSubjects, setTrialSubjects] = useState();
  const [open, setOpen] = useState(false);
  const [subjectName, setSubjectName] = useState();
  const [subject, setSubject] = useState();
  const [subscriptionDetails, setSubscriptionDetails] = useState();
  const userType = localStorage.getItem("user_type");

  const [trialSubjectsToShow, setTrialSubjectsToShow] = useState();
  const isStep = useRef(false);
  const handleOpen = (subject) => {
    setSubjectName(subject.subject_name);
    setSubject(subject);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // setVouchersRate(5);
    // setOffIndicator(-1);
    // setSliderRange(1);
    // setNoOfStudents(1);
    // isStep.current = false;
  };
  useEffect(() => {
    getSubjects();
    if (userType === "2") {
      getSubscriptionDetailsForAdmin();
    }
  }, []);

  const getSubjects = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_subscription_tab_data",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: {
          // Changed from 'body' to 'data'
          current_date: moment().format("YYYY-MM-DD HH:mm:ss"), // Make sure the format string is correct
        },
      })
      .then((res) => {
        console.log(res.data);
        setAvailableSubjects(res.data.available_subject_data);
        setTrialSubjects(res.data.trial_data);
        var trialSubject = res.data.trial_data.filter((trialSubject) => trialSubject.subject_name !== undefined);
        setTrialSubjectsToShow(trialSubject);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const getSubscriptionDetailsForAdmin = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.get["authorization"] = token;
    axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_purchase_orders",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log(res.data);
        setSubscriptionDetails(res.data.admin_data);
      })
      .catch((res) => {
        console.log(res);
      });
  };
  return (
    <>
      {userType === "1" ? (
        <div>
          {/* <BuyVoucherPopup
            open={open}
            handleClose={handleClose}
            subjectName={subjectName}
            subject={subject}
            vouchersRate={vouchersRate}
            setVouchersRate={setVouchersRate}
            OffIdicator={OffIdicator}
            setOffIndicator={setOffIndicator}
            sliderRangeText={sliderRangeText}
            setSliderRange={setSliderRange}
            noOfStudent={noOfStudent}
            setNoOfStudents={setNoOfStudents}
            isStep={isStep}
          /> */}
          <UpgradePlan subjectName={subjectName} subject={subject} open={open} handleClose={handleClose} />
          <Sidebar item_id={userType === "1" ? "subscription_teacher" : "subscription"} />
          <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px]">
            <div className="home sm:ml[25px] md:ml-[60px]">
              <div>
                <label className="dashboard-label">Trial & Subscription </label>
                <div className="teepee-divider"></div>
              </div>
            </div>
            <div className="flex flex-row md:justify-left md:items-center sm:ml-0 md:ml-[60px] mt-[20px] w-auto">
              {trialSubjects && trialSubjects.length > 0 && trialSubjectsToShow.length > 0 ? (
                <div className=" flex flex-col items-center pb-[50px] mt-[1%] mr-[2%] w-full">
                  <div className=" grid xlg:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 mt-0  w-full">
                    {trialSubjects !== undefined &&
                      trialSubjects.map(
                        (trialSubject, index) =>
                          trialSubject.subject_name && (
                            <TrialSubjectsCard
                              subject={trialSubject}
                              subjectIndex={index}
                              open={open}
                              handleClose={handleClose}
                              handleOpen={handleOpen}
                            />
                          )
                      )}
                  </div>
                </div>
              ) : (
                <div className="mt-[2%] w-full">
                  <img src={NoSubscription} />
                </div>
              )}
            </div>
            <div className="home sm:ml[25px] md:ml-[60px]">
              <div>
                <label className="dashboard-label">Available Subjects for trial & subscription </label>
                <div className="teepee-divider"></div>
              </div>
            </div>
            <div className="sm:ml[25px] md:ml-[60px] flex flex-col items-center pb-[50px] mt-[1%] mr-[2%]">
              <div className=" grid xlg:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 mt-0  w-full">
                <>
                  {availableSubjects !== undefined && availableSubjects.length > 0 ? (
                    availableSubjects.map((subject, index) => <AvailableSubjectsCard subject={subject} subjectIndex={index} />)
                  ) : (
                    <div className="mt-[4%] ml-[4%] w-[80%] text-center text-[#888888] italic	text-[14px]">
                      No subjects available for Trial or Subscription yet, Coming soon!
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <AdminSubscriptionTab subscriptionDetails={subscriptionDetails} getSubscriptionDetailsForAdmin={getSubscriptionDetailsForAdmin} />
      )}
    </>
  );
}

export default Subscribe;
