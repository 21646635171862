import React, { useState } from "react";
import { useRef } from "react";
import Sidebar from "../Sidebar";
import axios from "axios";
import OnBoardingSubjectWidget from "./OnBoardingSubjectWidget";
import { arrayMoveImmutable } from "array-move";
import { useEffect } from "react";

function OnBoardingSubject() {
  const isSubmitting = useRef(false);
  const [subjectsList, setSubjectsList] = useState([]);

  useEffect(() => {
    getSubjectsList();
  }, []);

  const onSaveSubject = (value, subject) => {
    subject.subject_name = value;
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/update_onboarding_subjects",
        {
          method: "POST",
          subject_interest_id: subject.subject_interest_id,
          subject_name: subject.subject_name,
        }
      )

      .then((response) => {})
      .catch((error) => {
        console.log(error);
        alert("unable to update subject");
      });
  };

  const sendDeleteRequest = (object_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/delete_onboarding_subjects",
        {
          method: "POST",
          subject_interest_id: object_id,
        }
      )
      .then((response) => {
        alert("successfully deleted");
        getSubjectsList();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to delete the content");
      });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSubjectsList((prevItem) =>
      arrayMoveImmutable(prevItem, oldIndex, newIndex)
    );

    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/update_subjects_order",
        {
          method: "POST",
          list_of_subjects: arrayMoveImmutable(
            subjectsList,
            oldIndex,
            newIndex
          ),
        }
      )
      .then((response) => {
        console.log(response);
        setSubjectsList(JSON.parse(response.data.subject_data));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSubmitOptionClick = (event) => {
    var subject_name = document.getElementById("subject_name").value;
    if (subject_name.length > 0) {
    } else {
      alert("Please enter all details");
      return;
    }
    isSubmitting.current = true;
    submitForm();
  };
  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url:
          process.env.REACT_APP_REST_API_BASE_URL + "/get_onboarding_subjects",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log(JSON.parse(res.data.data));
        setSubjectsList(JSON.parse(res.data.data));
      });
  };
  function submitForm() {
    var token = "Bearer " + localStorage.getItem("access_token");
    var subject_name = document.getElementById("subject_name").value;
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] =
      "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(
        process.env.REACT_APP_REST_API_BASE_URL + "/insert_onboarding_subjects",
        {
          method: "POST",
          subject_name: subject_name,
        }
      )

      .then((response) => {
        console.log(response);
        isSubmitting.current = false;
        alert("New subject created successfully");
        document.getElementById("subject_name").value = "";
        getSubjectsList();
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <>
      <Sidebar item_id="view_onboarding_subject" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px] ">
        <div className="flex flex-row md:ml-[60px] items-center ">
          <div className=""></div>
          <div className="selectionDiv sm:w-[100%] md:w-[600px]">
            <div className="form-div">
              <div className="md:w-[600px]">
                <div>
                  <label className="create_quiz_label">
                    Enter subject name
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    name="subject_name"
                    id="subject_name"
                    className="teepee-input"
                    placeholder=""
                    required
                  />
                </div>
                <button
                  onClick={handleSubmitOptionClick}
                  disabled={isSubmitting.current}
                  className="teepee-button mt-[10px] hover:bg-black"
                  style={{ width: "46%" }}
                >
                  {isSubmitting.current && (
                    <div
                      className="spinner-border spinner-border-m float-left"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="ml-[60px] mt-[3%]">
          <OnBoardingSubjectWidget
            subjectsList={subjectsList}
            onSortEnd={onSortEnd}
            onSaveSubject={onSaveSubject}
            sendDeleteRequest={sendDeleteRequest}
          />
        </div>
      </div>
    </>
  );
}

export default OnBoardingSubject;
