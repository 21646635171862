import React from "react";
import trophyIcon from "../../../images/trophy2.svg"; // Replace with actual path
import starIcon from "../../../images/star2.svg";
import searchIcon from "../../../images/search2.svg";
import targetIcon from "../../../images/spot2.svg";
import magicIcon from "../../../images/magic.svg";

const RewardsTooltip = ({ quizScore, recallStatusCount, quizTotalMarks }) => {
  const rewardsData = [
    {
      icon: trophyIcon,
      title: "Quiz Champion",
      description: "Awarded for completing the quiz.",
      xp: 50,
    },
    {
      icon: starIcon,
      title: "Flawless Victory",
      description: "No errors! You've completed the quiz perfectly.",
      xp: 100,
    },
    {
      icon: searchIcon,
      title: "Question Mastery",
      description: "You've mastered a particular question by answering it correctly three times.",
      xp: 200,
    },
    {
      icon: targetIcon,
      title: "Spot-On",
      description: "You answered a question correctly.",
      xp: 10,
    },
  ];

  return (
    <div className="bg-white border border-gray-300 rounded-lg shadow-lg p-4 md:w-[450px] sm:w-[180px] md:text-left sm:text-centers">
      {rewardsData.map((reward, index) => (
        <div key={index} className="flex md:flex-row sm:flex-col md:items-start sm:items-center md:gap-0 sm:gap-2 mb-3 last:mb-0">
          {/* Icon */}
          <img src={reward.icon} alt={reward.title} className="w-6 h-6 mr-2" />
          {/* Title, Description, and XP */}
          <div className="flex-1">
            <div className="text-sm font-semibold text-gray-800">{reward.title}</div>
            <div className="text-xs text-gray-600">{reward.description}</div>
          </div>
          {/* XP */}
          <div className="flex w-[90px] p-1 items-center ml-2 bg-[#8B5CF6] font-semibold text-white rounded-lg text-sm">
            <img src={magicIcon} alt="XP icon" className="w-4 h-4 mr-1" />+{reward.xp} XP
          </div>
        </div>
      ))}
    </div>
  );
};

export default RewardsTooltip;
