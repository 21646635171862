import axios from "axios";
import React, { useEffect, useState } from "react";

function TrackingTableOutsideClassroom(props) {
  console.log("OUTSIDE DATA: ",props.outsideClassroomStats)


  const thStyles = {
    backgroundColor: "lightgray",
    color: "white",
    fontWeight: "bold",
    width: "100px",
    /* Other styles here */
  };
  return (
    <div>
      <table
        class="w-full text-sm text-left text-white dark:text-white"
        className="tracking_table w-[100%] "
        id="tbl"
      >
        <thead class="text-xs text-black dark:bg-white dark:text-white ">
          <tr className="tracking_table">
            <th scope="col" class="px-6 py-3 tracking_table spth bg-[#F7F2F9]">
              ID
            </th>
            <th
              scope="col"
              class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%]"
            >
              Email
            </th>
            <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
              Account Creation
            </th>
            <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
              Total Quizzes Created
            </th>
            <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
              Total Quizzes Shared
            </th>
            <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
              Total Attempts
            </th>
            <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
              Last Quiz Created
            </th>
            <th
           scope="col" class="px-6 py-3 tracking_table whitespace-nowrap"
              >
                Last Quiz Attempted
              </th>
          </tr>
        </thead>
        <tbody>

            {props.outsideClassroomStats.map((stats, index) => (
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white tracking_table_td"
                style={{ backgroundColor: "rgba(103, 80, 164, 0.08)" }}
              >
                {index + 1}
              </th>
              <td
                class="px-6 py-4  tracking_table tracking_table_td"
                style={{ backgroundColor: "rgba(103, 80, 164, 0.08)" }}
              >
                {stats.email_address}
              </td>
              <td class="px-6 py-4 text-center tracking_table_td">
                {stats.account_creation}
              </td>
              <td class="px-6 py-4 text-center tracking_table_td">{stats.total_quiz_created > 0 ? stats.total_quiz_created : 0}</td>
              <td class="px-6 py-4 text-center tracking_table_td">
                {stats.total_quiz_shared}
              </td>
              <td class="px-6 py-4 text-center tracking_table_td">
                {stats.total_quiz_attempted}
              </td>
              <td class="px-6 py-4 text-center tracking_table_td">
                {stats.latest_quiz_created_timestamp}
              </td>
              <td class="px-6 py-4 text-center tracking_table_td">{stats.latest_quiz_attempted_timestamp}</td>
            </tr>
          ))}
       
       
        </tbody>
      </table>
    </div>
  );
}

export default TrackingTableOutsideClassroom;
