import React from "react";
import { Progress } from "@material-tailwind/react";

function SubtopicAnalysisWidget({
  data,
  percentage,
  forSingleSubtopicIndex,
  value,
}) {
  return (
    <div>
      {value === 1 ? (
        Array.isArray(data.sub_topic) !== false ? (
          data.sub_topic.map((subtopic, index) => (
            <div>
              <div className="flex justify-between">
                <div className="mt-[20px] progress_analytics_parent_sub_heading">
                  {subtopic.name}
                </div>
                <div className="progress_analytics_parent_sub_heading mt-[auto]">
                  {subtopic.percentage.toFixed(0) + "%"}
                </div>
              </div>

              <div>
                <Progress
                  value={subtopic.percentage}
                  color={
                    subtopic.percentage >= 80
                      ? "green"
                      : subtopic.percentage >= 70
                      ? "light-green"
                      : subtopic.percentage >= 50
                      ? "yellow"
                      : "red"
                  }
                />
              </div>
            </div>
          ))
        ) : (
          <div>
            <div className="flex justify-between">
              <div className="mt-[20px] progress_analytics_parent_sub_heading">
                {data.sub_topic_name}
              </div>
              <div className="mt-[20px] progress_analytics_parent_sub_heading">
                {percentage[forSingleSubtopicIndex].percentage.toFixed(0) + "%"}
              </div>
            </div>
            <div>
              {percentage[forSingleSubtopicIndex] !== undefined && (
                <Progress
                  value={percentage[forSingleSubtopicIndex].percentage}
                  color={
                    percentage[forSingleSubtopicIndex].percentage >= 80
                      ? "green"
                      : percentage[forSingleSubtopicIndex].percentage >= 70
                      ? "light-green"
                      : percentage[forSingleSubtopicIndex].percentage >= 50
                      ? "yellow"
                      : "red"
                  }
                />
              )}
            </div>
          </div>
        )
      ) : Array.isArray(data.area) !== false ? (
        data.area.map((area, index) => (
          <div>
            <div className="flex justify-between">
              <div className="mt-[20px] progress_analytics_parent_sub_heading">
                {area.name}
              </div>
              <div className="mt-[20px] progress_analytics_parent_sub_heading">
                {area.percentage.toFixed(0) + "%"}
              </div>
            </div>
            <div>
              <Progress
                value={area.percentage}
                color={
                  area.percentage >= 80
                    ? "green"
                    : area.percentage >= 70
                    ? "light-green"
                    : area.percentage >= 50
                    ? "yellow"
                    : "red"
                }
              />
            </div>
          </div>
        ))
      ) : (
        <div>
          <div className="flex justify-between">
            <div className="mt-[20px] progress_analytics_parent_sub_heading">
              {data.area_name}
            </div>
            <div className="mt-[20px] progress_analytics_parent_sub_heading">
              {percentage[forSingleSubtopicIndex].toFixed(0) + "%"}
            </div>
          </div>
          <div>
            <Progress
              value={percentage[forSingleSubtopicIndex]}
              color={
                percentage[forSingleSubtopicIndex] >= 80
                  ? "green"
                  : percentage[forSingleSubtopicIndex] >= 70
                  ? "light-green"
                  : percentage[forSingleSubtopicIndex] >= 50
                  ? "yellow"
                  : "red"
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SubtopicAnalysisWidget;
