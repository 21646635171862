import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const SuccessDialog = ({ open, onClose, message, isDeleted }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: "16px",
          maxWidth: "400px",
        },
      }}
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" component="div">
          {isDeleted ? <b>Deleted</b> : <b>Co-Teacher Approved</b>}
        </Typography>
        <IconButton onClick={onClose} style={{ padding: 0, top: "-20px", right: "-20px" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{isDeleted ? message : "The co-teacher has been successfully added to your classroom."}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{ backgroundColor: "#6750A4", color: "#fff" }} variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
