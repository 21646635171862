import React from "react";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import CircularProgress from "../../Teacher/widgets/CircularProgress";
import sticky_icon from "../../../images/sticky_icon.svg";

function TopicAccordion(props) {
  const [open, setOpen] = React.useState(1);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  const handleParticipationText = (participationPercentage) => {
    if (!isNaN(participationPercentage)) {
      if (participationPercentage >= 80) return "Mastered";
      if (participationPercentage >= 60) return "Competent";
      if (participationPercentage >= 40) return "Intermediate";
      if (participationPercentage >= 20) return "Developing";
      return "Insufficient";
    }
  };

  const getColorForPercentage = (percentageString) => {
    const value = parseInt(percentageString, 10);
    if (value >= 80) return "#55CE98";
    if (value >= 60) return "#F2D249";
    if (value >= 40) return "#F2C474";
    if (value >= 20) return "#F8B250";
    return "#FAAD98";
  };

  return (
    <>
      {props.topicData &&
        props.topicData.map((topic, index) => (
          <Accordion icon={false} open={open === index + 1} key={index} className="border border-gray-300 rounded-lg mb-4 mt-4">
            {/* Header Section */}
            <AccordionHeader
              onClick={() => handleOpen(index + 1)}
              className="bg-[#F9F9F9] px-4 py-3 rounded-lg text-gray-700 font-semibold text-base !flex !justify-between"
            >
              <div className="flex items-center w-[100%]">
                {/* Custom Down Arrow Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  className={`w-5 h-5 mr-2 transition-transform ${open === index + 1 ? "rotate-180" : ""}`}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                </svg>
                <span>{topic.topic_name}</span>
              </div>
              <div className="mr-[-30px]">
                <CircularProgress
                  StudentParticipationRate={topic.mastered_questions_percentage}
                  label={handleParticipationText(parseInt(topic.mastered_questions_percentage))}
                  color={getColorForPercentage(parseInt(topic.mastered_questions_percentage) + "%")}
                  isSize={true}
                />
              </div>
            </AccordionHeader>

            {/* Body Section */}
            <AccordionBody className="bg-white px-4 py-3">
              {topic.subtopics.map((subtopic, subIndex) => (
                <div key={subIndex} className="flex justify-between items-center py-2 border-b last:border-b-0">
                  <div className="flex items-center text-sm text-gray-600">
                    <img src={sticky_icon} className="mr-1" alt="sticky icon" />
                    {subtopic.sub_topic_name}
                  </div>
                  <div>
                    <CircularProgress
                      StudentParticipationRate={subtopic.mastered_questions_percentage}
                      label={handleParticipationText(parseInt(subtopic.mastered_questions_percentage))}
                      color={getColorForPercentage(parseInt(subtopic.mastered_questions_percentage) + "%")}
                      isSize={true}
                    />
                  </div>
                </div>
              ))}
            </AccordionBody>
          </Accordion>
        ))}
    </>
  );
}

export default TopicAccordion;
