import { useState } from "react";
import axios from "axios";
const usePostApiToken = () => {
  const [apiData, setApiData] = useState({
    loading: false,
    data: null,
    error: null,
  });

  const postApiCall = async (url, body) => {
    setApiData({ ...apiData, loading: true });
    let response = {};
    try {
      const token = "Bearer " + localStorage.getItem("access_token");
      axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
      axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
      axios.defaults.headers.post["authorization"] = token;
      axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
      response = await axios.post(url, body); // Pass body directly as the second argument
      setApiData({ ...apiData, data: response.data }); // Set the data from the API response
    } catch (err) {
      setApiData({ ...apiData, error: err.message }); // Handle error
      alert(err.message);
    } finally {
      setApiData({ ...apiData, loading: false }); // Always set loading to false in the end
      return {
        loading: false,
        data: response.data,
        error: null,
      };
    }
  };
  return postApiCall;
};

export default usePostApiToken;
