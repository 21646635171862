import React from "react";
import { useNavigate } from "react-router-dom";

const UpdatePopUp = ({ onClose, handleCreate, mode }) => {
  const navigate = useNavigate();
  const handleDone = () => {
    if (mode === 1) {
      handleCreate();
    } else {
      navigate(-1);
    }
  };
  return (
    <div className="fixed inset-0 z-50 ml-[200px] flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm relative">
        <button className="absolute top-1 right-2 text-gray-400 hover:text-gray-600 text-[24px]" onClick={onClose}>
          <span className="sr-only">Close</span>
          &times;
        </button>
        <div className="text-left">
          <p className="text-[16px] font-bold mt-3"> {mode === 1 ? "Your schedule has been successfully updated!" : "Update Successful!"} </p>
          <p className="text-gray-600 text-[12px] mb-6">
            Our spaced retrieval engine is now fully aligned with your plan, and your students will receive quizzes accordingly.
          </p>
          <div className="flex justify-end space-x-4 mt-4">
            <button className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300" onClick={onClose}>
              Cancel
            </button>
            <button className="px-4 py-2 bg-[#6750A4] text-white rounded-md " onClick={handleDone}>
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatePopUp;
