import React from "react";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import ReactSwitch from "react-switch";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}
function QuizFeedbackAccordian({ handleChange, hideAnswerToggle }) {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <>
      <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
        <AccordionHeader onClick={() => handleOpen(1)}>
          <div>
            <div style={{ fontSize: "16px" }}>
              <div className="disabled-label text-start" style={{ fontSize: "16px" }}>
                Task feedback{" "}
              </div>
              <div className="classroom_details_label  sm:hidden md:block  ">Manage how students receive feedback</div>
              <div className="classroom_details_label sm:truncate md:hidden sm:block  sm:max-w-[80px]">Manage how students receive feedback</div>
            </div>
          </div>
          <div></div>
          <div className="classroom_details_label"></div>
        </AccordionHeader>
        <AccordionBody>
          <div className="flex  mr-[20px]">
            <div className="w-[60%] p-[15px] pl-[20px]">
              <div className="disabled-label" style={{ fontSize: "16px" }}>
                Hide correct answers
              </div>
              <div className="classroom_details_label">Exclude correct responses in instant student feedback</div>
            </div>
            <div className="w-[50%] pt-[10px] ">
              <div className="classroom_name_div ">
                <div className="classroom_details_label flex float-right">
                  <ReactSwitch className="float-right" checked={hideAnswerToggle} onChange={(value) => handleChange(4, value)} />
                </div>
              </div>
            </div>
          </div>
        </AccordionBody>
      </Accordion>
    </>
  );
}

export default QuizFeedbackAccordian;
