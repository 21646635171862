import React, { useState, useEffect, useRef } from "react";
import Sidebar from "../../Componenets/Sidebar";

import axios from "axios";
import amplitude from "amplitude-js";
import EventCalender from "../../Componenets/Calendar/containers/eventCalendar";
import ClassroomDetailBanner from "./widgets/ClassroomDetailBanner";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { ToastHeader } from "react-bootstrap";
import UpdatePopUp from "../Student/widget/UpdatePopUp";
function UpdateLessonPlans() {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [topics, setTopics] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [counterEffect, setCounterEffect] = useState(false);
  const [lessonPlanAdded, setLessonPlanAdded] = useState(false);
  console.log("DATA IN THE LESSON PLAN", location.state.selectedSubject, topics, location.state);
  const getTopicList = () => {
    var token = "Bearer " + localStorage.getItem("access_token");

    let id = location.state.selectedSubject.subject_id;

    console.log("brand id:", id);
    console.log("Classes data", location.state);
    const url = location.state.subject_status_have_lesson === 0 ? "/get_topic_for_lesson" : "/get_super_topic_for_lesson";
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + url,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: token,
        },
        data: JSON.stringify({
          subject_id: id,
        }),
      })
      .then((res) => {
        console.log("objedsdct");
        console.log(res.data);

        setTopics(res.data);
        setCounterEffect(true);
      });
  };
  const handleCreate = () => {
    console.log("class id", location.state.classes_data);
    setShow(true);

    // Navigate after the toast has been shown for 3 seconds
    navigate("/teacher-classroom-home?id=" + location.state.class_data.classroom_id, {
      state: {
        folder_id: selectedFolder !== null ? selectedFolder.folder_id : null,
      },
    });
  };
  useEffect(() => {
    console.log("new data", location.state.class_data);
    if (counterEffect === false) {
      getTopicList();
    }
  }, []);
  return (
    <>
      <div className="sm:ml-[10px] md:ml-[315px] ">
        {show && <UpdatePopUp onClose={() => setShow(false)} handleCreate={handleCreate} mode={1} />}
        {/* <ToastContainer className="p-3 position-fixed top-12 right-0">
          <Toast style={{ backgroundColor: "#f7f2f9" }} onClose={() => setShow(false)} show={show} delay={3000} autohide>
            <ToastHeader>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <mask id="mask0_99_1907" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_99_1907)">
                  <path
                    d="M9.54961 18.0001L3.84961 12.3001L5.27461 10.8751L9.54961 15.1501L18.7246 5.9751L20.1496 7.4001L9.54961 18.0001Z"
                    fill="#0AB30A"
                  />
                </g>
              </svg>
              <span className="snackbarh" style={{ marginRight: "auto" }}>
                Updated
              </span>
            </ToastHeader>
            <Toast.Body>
              <span className="snackbarp">Awesome! Lesson Plan is updated. 🎉</span>
            </Toast.Body>
          </Toast>
        </ToastContainer> */}
        <ToastContainer className="p-3 position-fixed top-12 right-0">
          <Toast style={{ backgroundColor: "#f7f2f9" }} onClose={() => setShow2(false)} show={show2} delay={3000} autohide>
            <ToastHeader>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <mask id="mask0_99_1907" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_99_1907)">
                  <path
                    d="M9.54961 18.0001L3.84961 12.3001L5.27461 10.8751L9.54961 15.1501L18.7246 5.9751L20.1496 7.4001L9.54961 18.0001Z"
                    fill="#0AB30A"
                  />
                </g>
              </svg>
              <span className="snackbarh" style={{ marginRight: "auto", marginLeft: "5%" }}>
                Deleted
              </span>
            </ToastHeader>
            <Toast.Body>
              <span className="snackbarp">Lesson plan has been deleted</span>
            </Toast.Body>
          </Toast>
        </ToastContainer>
        <Sidebar item_id="quiz" />

        {location.state.classroomData ? [<ClassroomDetailBanner classroomData={location.state.classroomData} />] : <div></div>}
        <div className="home sm:ml[0px] md:ml-[65px]">
          <div>
            <label
              className="dashboard-label"
              style={{
                color: "var(--material-theme-sys-light-on-surface-variant, #4D444C)",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px" /* 142.857% */,
                letterspacing: "0.1px",
              }}
            >
              Update lesson Plans{" "}
              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="14" viewBox="0 0 100 14" fill="none">
                <path d="M2.5 14C2.5 12.3431 3.84315 11 5.5 11H94.5C96.1569 11 97.5 12.3431 97.5 14V14H2.5V14Z" fill="#403151" />
              </svg>
            </label>
          </div>
          {location.state ? <>{!location.state.classroomData ? <div className="pl-10"></div> : <div></div>}</> : null}
        </div>

        <div className="container ">
          <div className="  justify-center items-center sm:mr-[0px] md:mr-[0%] ml-[2%] md:mt-[3%]">
            <EventCalender
              subject_id={location?.state?.selectedSubject}
              newTopic={topics}
              classes={location?.state}
              setlessonPlanAdded={setLessonPlanAdded}
              setShow2={setShow2}
            />{" "}
            <div style={{ display: "flex" }}>
              <div className="md:w-[100%] sm:w-[90%] mt-[1%] ml-[28px] mb-[40px]">
                <button
                  onClick={() => {
                    navigate("/teacher-classroom-home?id=" + location.state.class_data.classroom_id, {
                      state: {
                        folder_id: selectedFolder !== null ? selectedFolder.folder_id : null,
                      },
                    });
                  }}
                  className="teepee-button sm:w-[6%] md:w-[10%] hover:bg-black hover:cursor-pointer"
                  style={{
                    background: "transparent",
                    border: "1px solid #7E418B",
                    color: "#7E418B",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontWeight: "600px",
                    fontFamily: "Roboto",
                    float: "left",
                    fontSize: "12px",
                  }}
                >
                  Back
                </button>

                <button
                  className={`teepee-button ${
                    !lessonPlanAdded ? "disable-button" : ""
                  } sm:w-[30%] md:w-[10%] hover:bg-black hover:cursor-pointer whitespace-nowrap`}
                  style={{
                    backgroundColor: "#6750A4",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    setShow(true);
                  }}
                  disabled={!lessonPlanAdded}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UpdateLessonPlans;
