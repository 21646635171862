import React, { useState } from "react";
import CircularProgress from "./CircularProgress";
import warning_icon from "../../../images/users-2.png";
import dot_icon from "../../../images/dots.png";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#FFF",
    border: "1px solid #dadde9",
    borderRadius: "8px",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(12),
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    maxWidth: 250,
    opacity: "0.8",
    padding: "12px 16px",
  },
}));
const StudentParticipationRate = ({
  StudentParticipationRate,
  participated_quizzes,
  total_quizzes,
}) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getColorForPercentage = (percentageString) => {
    const value = parseInt(percentageString, 10); // Parse percentage as integer
    // if (value >= 80) {
    //   return "#14D38E";
    // } else if (value >= 60) {
    //   return "#835BEF";
    // } else if (value >= 40) {
    //   return "#F8B250";
    // } else if (value >= 20) {
    //   return "#0293EE";
    // } else {
    //   return "#DF557F";
    // }
    if (value >= 80) {
      return "#55CE98";
    } else if (value >= 60) {
      return "#F2D249";
    } else if (value >= 40) {
      return "#F2C474";
    } else if (value >= 20) {
      return "#F8B250";
    } else {
      return "#FAAD98";
    }
  };

  const handleParticipationText = (participationPercentage) => {
    if (participationPercentage !== NaN) {
      if (participationPercentage >= 80) {
        return "Highly Engaged";
      } else if (
        participationPercentage >= 60 &&
        participationPercentage < 80
      ) {
        return "Moderately Engaged";
      } else if (
        participationPercentage >= 40 &&
        participationPercentage < 60
      ) {
        return "Inconsistently Engaged";
      } else if (participationPercentage < 40 && participationPercentage > 0) {
        return "Barely Engaged";
      } else if (participationPercentage === 0) {
        return "Non-Engaged";
      }
    }
  };
  return (
    <div className="p-4 border-gray-300 border-t-[white] shadow-sm">
      <div className="flex justify-between items-center mb-4">
        <div className="flex text-lg font-bold font-roboto text-[#322E33]">
          <ClickAwayListener onClickAway={handleClose}>
            <div className="">
              <HtmlTooltip
                title="The percentage of quizzes in which a student has attempted at least one question."
                PopperProps={{
                  disablePortal: true,
                }}
                enterTouchDelay={0}
                leaveTouchDelay={10}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
              >
                <button className="ml-[5px] mt-[2px] flex" onClick={handleOpen}>
                  Student Participation Rate{" "}
                  <img
                    className="ml-2 h-[20px] w-[20px] mt-1"
                    src={warning_icon}
                    alt="Warning icon"
                  />
                </button>
              </HtmlTooltip>
            </div>
          </ClickAwayListener>
        </div>
      </div>
      <div className="flex sm:hidden md:block" style={{ float: "right" }}>
        <ul className="list-none text-xs font-roboto">
          <li className="flex items-center">
            <span className="h-[10px] w-[10px] rounded-full bg-green-500 mr-2"></span>
            Above 80% - Highly Engaged
          </li>
          <li className="flex items-center">
            <span className="h-[10px] w-[10px] rounded-full bg-[#F2D249]  mr-2"></span>
            60 - 79% - Moderately Engaged
          </li>
          <li className="flex items-center">
            <span className="h-[10px] w-[10px] rounded-full bg-[#F2C474]  mr-2"></span>
            40 - 59% - Inconsistently Engaged
          </li>
          <li className="flex items-center">
            <span className="h-[10px] w-[10px] rounded-full bg-[#F8B250] mr-2"></span>
            Below 40% - Barely Engaged
          </li>
          <li className="flex items-center">
            <span className="h-[10px] w-[10px] rounded-full bg-[#FAAD98] mr-2"></span>
            0% - Non-Engaged
          </li>
        </ul>
      </div>
      <div className="flex items-center justify-center mb-2">
        <CircularProgress
          StudentParticipationRate={StudentParticipationRate}
          label={handleParticipationText(parseInt(StudentParticipationRate))}
          color={getColorForPercentage(
            parseInt(StudentParticipationRate) + "%"
          )}
        />
      </div>

      <div className="flex justify-around mt-4">
        <div className="flex items-center">
          <img className="mr-2 w-[8px] h-[8px]" src={dot_icon} alt="Dot icon" />
          <span className="text-[#252744] font-bold text-[14px] leading-[20px] font-roboto">
            Participated in Quizzes | {participated_quizzes}
          </span>
        </div>
        <div className="flex items-center">
          <img className="mr-2 w-[8px] h-[8px]" src={dot_icon} alt="Dot icon" />
          <span className="text-[#252744] font-bold text-[14px] leading-[20px] font-roboto">
            Quizzes sent | {total_quizzes}
          </span>
        </div>
      </div>
    </div>
  );
};
export default StudentParticipationRate;
