import React from "react";

const table = (props) => {
  let parsedRows = [];
  try {
    parsedRows = JSON.parse(props.rows);
  } catch (error) {
    console.error("Error parsing JSON data:", error);
  }
  return (
    <div>
      <table
        class="w-full text-sm text-left text-white dark:text-white"
        className="tracking_table w-[100%] "
        id="tbl"
      >
        <thead class="text-xs text-black dark:bg-white dark:text-white ">
          <tr className="tracking_table">
            <th scope="col" class="px-6 py-3 tracking_table spth bg-[#F7F2F9]">
              S.no
            </th>
            <th
              scope="col"
              class="px-6 py-3 bg-[#F7F2F9] tracking_table w-[25%]"
            >
              Email
            </th>
            <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
              Status
            </th>
            <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
              Date
            </th>
            <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
              Time
            </th>
            <th scope="col" class="px-6 py-3 tracking_table whitespace-nowrap">
              Selected Subject
            </th>
          </tr>
        </thead>
        <tbody>
          {parsedRows.map((stats, index) => (
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white tracking_table_td"
                style={{ backgroundColor: "rgba(103, 80, 164, 0.08)" }}
              >
                {index + 1}
              </th>
              <td
                class="px-6 py-4  tracking_table tracking_table_td"
                style={{ backgroundColor: "rgba(103, 80, 164, 0.08)" }}
              >
                {stats.email}
              </td>
              <td class="px-6 py-4 tracking_table  tracking_table_td">
                {stats.status === 0
                  ? "Visitor"
                  : stats.status === 1
                  ? "Teacher"
                  : "N/A"}
              </td>
              <td class="px-6 py-4 tracking_table  tracking_table_td">
                {stats.date}
              </td>
              <td class="px-6 py-4 tracking_table tracking_table_td">
                {stats.time}
              </td>
              <td class="px-6 py-4 tracking_table  tracking_table_td">
                {stats.subject_name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default table;
