import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import amplitude from "amplitude-js";
import wait from "../../images/wait.png";
const TrialAttemptsButton = (props) => {
  console.log("quiz_setting IN THE BUTTON: ", props.quiz_setting);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onHandleClick = () => {
    amplitude.getInstance().logEvent("VEJoinTeepeeBtnClk");
    setIsSubmitting(true);
    navigate("/login");
  };
  const onHandleClassClick = () => {
    amplitude.getInstance().logEvent("TECreateFirstClassBtnClk");
    localStorage.removeItem("experience");
    setIsSubmitting(true);
    navigate("/", {
      state: {
        classCreate: 1,
      },
    });
  };
  return (
    <>
      <button
        style={{
          display:
            props?.quiz_setting && props?.experience !== "Teacher"
              ? "block"
              : "none",
        }}
        onClick={onHandleClick}
        disabled={isSubmitting}
        className="teepee-button w-full mt-[16px] mb-[12px] "
      >
        {isSubmitting && (
          <div
            className="spinner-border spinner-border-m float-left"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        )}
        Join Teepee For Free!
      </button>
      <button
        style={{
          display:
            props?.quiz_setting && props?.experience === "Teacher"
              ? "block"
              : "none",
        }}
        onClick={onHandleClassClick}
        disabled={isSubmitting}
        className="teepee-button w-full mt-[16px] mb-[12px] "
      >
        {isSubmitting && (
          <div
            className="spinner-border spinner-border-m float-left"
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        )}
        Create Your First Class!
      </button>
      <button
        disabled={true}
        style={{
          color: "rgba(29, 25, 43, 0.72)",
          fontFamily: "Roboto",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20px", // You can use a number for unitless values
          letterSpacing: "0.1px",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          width: "100%",

          display: props?.quiz_setting === undefined ? "none" : "flex",
        }}
      >
        {/* <img src={wait} className="mr-[8px]" /> */}
        {/* Teepee is free to use until June 2024 */}
      </button>
    </>
  );
};
export default TrialAttemptsButton;
