import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import bolt_icon from "../../../images/bolt.svg";
import streak_icon from "../../../images/streak.svg";
import info_icon from "../../../images/info_new.svg";
import trophy_icon from "../../../images/rounded_trophy.svg";
import XPPopUp from "./XPPopUp";
import StreakPopUp from "./StreakPopUp";

const XPLevelComponent = ({ currentXp, streaks, streakDates }) => {
  const xpPerLevel = 3000;

  // Calculate level and remaining XP for the current level
  const level = Math.floor(currentXp / xpPerLevel) + 1;
  const levelXp = currentXp % xpPerLevel;
  const xpPercentage = (levelXp / xpPerLevel) * 100; // Calculate percentage for the progress bar
  const [isXPPopUpOpen, setIsXPPopUpOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenXPPopUp = () => {
    setIsXPPopUpOpen(true);
  };

  const handleCloseXPPopUp = () => {
    setIsXPPopUpOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <XPPopUp isOpen={isXPPopUpOpen} onClose={handleCloseXPPopUp} totalXp={levelXp} />
      <StreakPopUp isOpen={isOpen} onClose={() => setIsOpen(false)} streakCount={streaks} streakDates={streakDates} />

      <div className="mb-2 space-y-4">
        {/* XP and Streaks Section */}
        <div className="flex items-center space-x-6">
          {/* XP Display */}
          <div className="flex items-center">
            <img className="mr-1" src={bolt_icon} alt="" />
            <span className="text-[24px] leading-[42%] font-semibold text-[#454545]"> {levelXp}</span>
            <Tooltip
              title="⚡ XP (Experience Points) shows your progress and achievements! Earn more XP by completing quizzes and leveling up!"
              placement="top"
            >
              <span className="text-[#454545]  text-[12px] leading-[16%] ml-1 mt-1 cursor-pointer">XP</span>
            </Tooltip>
            <img className="ml-1 w-[16px] h-[16px] mt-1 cursor-pointer" onClick={handleOpenXPPopUp} src={info_icon} alt="" />
          </div>

          {/* Streaks Display */}
          <div className="flex items-center">
            <img className="mr-1" src={streak_icon} alt="" />
            <span className="text-[24px] leading-[42%] font-semibold text-[#454545]">{streaks}</span>
            <Tooltip title="🔥 Streaks show your daily quiz streak! Keep going to build strong learning habits and boost progress!" placement="top">
              <span className="text-[#454545] text-[12px] leading-[16%] ml-1 mt-1 cursor-pointer">{streaks > 1 ? "Days Streaks" : "Day Streak"}</span>
            </Tooltip>
            <img className="ml-1 w-[16px] h-[16px] mt-1 cursor-pointer" onClick={handleOpen} src={info_icon} alt="" />
          </div>
        </div>

        {/* Level Progress Bar Section */}
        <div className="p-4 bg-gray-50 rounded-lg flex items-center justify-between">
          {/* Level and Progress */}
          <div className="flex flex-col space-y-2">
            <span className="text-lg font-semibold text-gray-800">Level {level}</span>
            <div className="relative w-[250px] mr-2 bg-gray-200 h-4 rounded-full overflow-hidden">
              <div className="absolute top-0 left-0 h-full bg-orange-400 rounded-full" style={{ width: `${xpPercentage}%` }}></div>
            </div>
            <span className="text-sm text-gray-600">
              {levelXp}/{xpPerLevel} XP
            </span>
          </div>

          {/* Level Badge */}
          <img src={trophy_icon} alt="" />
        </div>
      </div>
    </>
  );
};

export default XPLevelComponent;
